import { Component, OnInit } from '@angular/core';
import { GenerationUnitManagerService } from "./../../generation-unit/services/generation-unit-manager.service";
import { ReportsManagerService } from '../services/reports-manager.service';
import { ExportUtility } from './../../../shared/utilities/utility.export';

@Component({
  selector: 'app-regulatory-compliance-rpt',
  templateUrl: './regulatory-compliance-rpt.component.html',
  styleUrls: ['./regulatory-compliance-rpt.component.scss']
})
export class RegulatoryComplianceRptComponent implements OnInit {

  cssDate: string;
  minDate: string;
  maxDate: string;
  dtBegin: string;
  dtEnd: string;
  dtEndMaxDate: string;
  reportData: any[];
  isReportLoading: boolean = false;
  searchClicked: boolean;

  constructor(private genUnitMgr: GenerationUnitManagerService,
      private reportsMgr: ReportsManagerService,
      private _export: ExportUtility) { }

  ngOnInit(): void {
    this.getCSSdate();
  }

  getCSSdate() {
    this.genUnitMgr.getCSSDate().subscribe({
      next: (resp) => {
        this.cssDate = resp.substring(0, 10);
        let dt = new Date(this.cssDate);
        
        this.dtEndMaxDate = this.getISOStringInLocalTime(dt).split("T")[0];
        this.maxDate = this.getISOStringInLocalTime(dt).split("T")[0];
        
        let fromDate = new Date(this.cssDate);
        this.dtBegin = this.getISOStringInLocalTime(new Date(fromDate.setDate(fromDate.getDate() - 2))).split("T")[0];
        
        let toDate = new Date(this.cssDate);
        this.dtEnd = this.getISOStringInLocalTime(new Date(toDate.setDate(toDate.getDate() - 1))).split("T")[0];              
      },
    });
  }

  onChangeFromDate(){
    let fromDate = new Date(this.dtBegin);
    let currentDate = new Date(this.cssDate);
    if (Math.floor((currentDate.getTime() - fromDate.getTime()) / (1000 * 60 * 60 * 24)) > 90){
      this.dtEndMaxDate = this.getISOStringInLocalTime(new Date(fromDate.setDate(fromDate.getDate() + 90))).split("T")[0];
      this.dtEnd = this.dtEndMaxDate;
    }
    else{
      this.dtEndMaxDate = this.getISOStringInLocalTime(currentDate).split("T")[0];
    }   
  }

  onChangeToDate(){
    let toDate = new Date(this.dtEnd);
    this.minDate = this.getISOStringInLocalTime(new Date(toDate.setDate(toDate.getDate() - 90))).split("T")[0];
  }

  getISOStringInLocalTime(date: Date){
    return new Date((date.getTime() + (-date.getTimezoneOffset() * 60000))).toISOString()
  }

  search(){
    this.searchClicked = true;
    this.isReportLoading = true;
    this.reportsMgr.getRegulatoryComplianceReport(this.dtBegin,this.dtEnd).subscribe({
      next: (resp) => {                                         
        this.reportData = resp;
        this.isReportLoading = false;
      },
      error: () => {
        this.isReportLoading = false;             
        console.log("An error occured in report data retrieval");
      }
    });        
  }

  exportReport(){
    let tableKeyHeaders: string[] = [];
    let modifiedlist: any = [];
    let headerInfoList: any[] = [];
    let tableDisplayHeaders: string[] = [      
      "Generation Owner Name",
      "Generation ID",      
      "Date Energized",
      "Subscriber Name",      
      "Subscriber FEIN/Customer SSN",
      "Subscriber Identification Number",
      "Subscribed KW",
      "Active Date",
      "Termination Date",
      "DS Rate Class"
    ];

    headerInfoList.push(['Regulatory Compliance Report','']);
    headerInfoList.push(['','']);
    headerInfoList.push(["Export Date: " , this.getISOStringInLocalTime(new Date())]);
    headerInfoList.push(['','']);
    headerInfoList.push(tableDisplayHeaders);

    tableKeyHeaders = [      
      "generationOwnerName",
      "generationID",
      "dateEnergized",
      "subscriberName",
      "subscriberFEIN/CustomerSSN",
      "subscriberIdentificationNumber",
      "subscribedKW",
      "activeDate",
      "terminationDate",
      "dsRateClass"
    ];   

    this.reportData.forEach((row: any) => {
      let temp: any = {};
            
      temp["generationOwnerName"] = row.ownerName;
      temp["generationID"] = row.owner;
      temp["dateEnergized"] = row.genUnitactiveDate;
      temp["subscriberName"] = row.subscriberName;      
      temp["subscriberFEIN/CustomerSSN"] = row.ssnFein;
      temp["subscriberIdentificationNumber"] = row.customerIdentifier;
      temp["subscribedKW"] = row.subscribeQuantity;
      temp["activeDate"] = row.effectiveDate;
      temp["terminationDate"] = row.terminationDate == "9999-12-31" ? "" : row.terminationDate;
      temp["dsRateClass"] = row.rateClassDesc;
      modifiedlist.push(temp);
    });
    this._export.exportJsonToExcelFile(
      modifiedlist,
      "Regulatory Compliance Report",
      false,
      tableKeyHeaders,
      tableDisplayHeaders,
      headerInfoList
    );
  }

}
