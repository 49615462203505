<div class="generation-unit-container">
  <div class="mb-5">
    <div class="generation-unit-header">Generation Units</div>
    <div class="col-md-12">
      <em>
        Disclaimer: COGF Participating Customers will be referred to throughout this portal as Subscribers.
      </em>
    </div>
  </div>
  <div class="row gen_unit_search_container mb-3">
    <div class="col-md-2"></div>
    <div class="col-md-2 form-check">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="includeInactiveUnits"
        [checked]="includeInactiveUnits"
        (click)="onToggleActiveInactive()"
      />
      <label class="form-check-label" for="inactiveOwners">
        Show Inactive Units
      </label>
    </div>

    <div class="col-md-4">
      <div class="d-flex flex-row gen_owner__search">
        <div class="input-group search_button">
          <input
            type="search"
            class="form-control mt-0"
            placeholder="Search"
            aria-label="searchAmerenUser"
            [(ngModel)]="searchString"
            (ngModelChange)="searchFilter()"
            aria-describedby="searchAmerenUser"
          />
          <span
            class="input-group-text"
            id="searchAmerenUser"
            (click)="searchFilter()"
            ><em class="fa fa-search"></em
          ></span>
        </div>
      </div>
    </div>
    <div class="col-md-2 text-right">
      <button
        type="button"
        class="btn btn-success gen_owner_export"
        (click)="exportgenUnits()"
      >
        <img
          src="assets/icon/excelicon.svg"
          alt="CalendarImage"
          width="22"
          height="19"
        />
        Export
      </button>
    </div>
    <div class="col-md-2 text-right">
      <button class="btn btn-success" [routerLink]="['/add-generation-unit']" *ngIf="functionalityAccess?.isAmerenAdmin || functionalityAccess?.allowCreateGenerationUnit">
        Add Generation Unit
      </button>
    </div>
  </div>
  <div class="mt-3">
    <mat-paginator
      *ngIf="units && units.length > 9"
      #paginator
      [pageSize]="pageSize"
      [pageSizeOptions]="[10]"
      [showFirstLastButtons]="true"
      [length]="totalSize"
      [pageIndex]="currentPage"
      (page)="handlePage($event)"
    >
    </mat-paginator>
  </div>
  <table class="table gen_unit_grid mt-4" aria-describedby="Generation Unit">
    <thead>
      <tr>
        <th scope="col">
          <span class="table-head" [appSort]="units" (sortComplete)="onSort()" data-order="asc" data-name="ownerName">Owner Name <em
            class="fa-solid fa-sort"></em></span>            
        </th>       

        <th scope="col">
          <span class="table-head" [appSort]="units" (sortComplete)="onSort()" data-order="asc" data-name="unitId">Unit ID <em
            class="fa-solid fa-sort"></em></span>
          </th>
        <th scope="col">
          <span class="table-head" [appSort]="units" (sortComplete)="onSort()" data-order="asc" data-name="nickName">Nickname <em
            class="fa-solid fa-sort"></em></span>            
        </th>       
        <th scope="col">
          <span class="table-head" [appSort]="units" (sortComplete)="onSort()" data-order="asc" data-name="numOfSubscriptions">Number Of Subscribers <em
            class="fa-solid fa-sort"></em></span>            
        </th>       
        <th scope="col">
          <span class="table-head" [appSort]="units" (sortComplete)="onSort()" data-order="asc" data-name="billAccountNumber">Bill Account Number <em
            class="fa-solid fa-sort"></em></span>            
        </th>       
        <th scope="col">
          <span class="table-head" [appSort]="units" (sortComplete)="onSort()" data-order="asc" data-name="capacity">Nameplate Capacity <em
            class="fa-solid fa-sort"></em></span>            
        </th>       
        <th scope="col">
          <span class="table-head" [appSort]="units" (sortComplete)="onSort()" data-order="asc" data-name="totalSubscriberUsage">Total subscribed KW <em
            class="fa-solid fa-sort"></em></span>            
        </th>       
        <th scope="col">
          <span class="table-head" [appSort]="units" (sortComplete)="onSort()" data-order="asc" data-name="type">Type <em
            class="fa-solid fa-sort"></em></span>            
        </th>       
        <th scope="col">
          <span class="table-head" [appSort]="units" (sortComplete)="onSort()" data-order="asc" data-name="fuelSourceDesc">Fuel Source <em
            class="fa-solid fa-sort"></em></span>            
        </th>       
        <th scope="col">
          <span class="table-head" [appSort]="units" (sortComplete)="onSort()" data-order="asc" data-name="status">Status <em
            class="fa-solid fa-sort"></em></span>            
        </th>       
        <th scope="col">
          <span class="table-head" [appSort]="units" (sortComplete)="onSort()" data-order="asc" data-name="powerClerk">Power Clerk Number<em
            class="fa-solid fa-sort"></em></span>            
        </th>       
        <th scope="col">Remove Unit</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngIf="
          !isGetUnitsApiLoading &&
          !isGetUnitsApiError &&
          (!units || !units.length)
        "
      >
        <td colspan="12" class="text-center">No data</td>
      </tr>
      <tr *ngIf="isGetUnitsApiError">
        <td colspan="12" class="text-center text-danger">
          {{ 'Something went wrong. Please try again.' }}         
        </td>
      </tr>
      <tr *ngIf="isGetUnitsApiLoading">
        <td colspan="12" class="text-center">
          <p>Data Loading: Thanks for your patience as we download your requested data.</p>
          <ameren-loading></ameren-loading>
        </td>
      </tr>
      <tr *ngFor="let unit of pagedUnits">
        <td>
          <a
            [routerLink]="['/generation-owner/owner-dashboard']"
            [queryParams]="{ ownerId: unit.owner }"
          >
            {{ unit.ownerName }}</a
          >
        </td>
        <td>
          {{ unit.unitId }}
        </td>
        <td>
          <a
            [routerLink]="['/subscription-detail']"
            [queryParams]="{
              unitId: unit.unitId,
              acct: unit.billAccountNumber,
              ownerId: unit.owner
            }"
            *ngIf="functionalityAccess.allowEditGenerationUnit"
          >
            {{ unit.nickName }}</a
          >
          <span  *ngIf="!functionalityAccess.allowEditGenerationUnit">
            {{ unit.nickName }}
          </span>
        </td>
        <td>
          {{ unit.numOfSubscriptions }}
        </td>
        <td>
          {{ unit.billAccountNumber }}
        </td>
        <td>
          {{ unit.capacity }}
        </td>
        <td>{{ unit.totalSubscriberUsage }}</td>
        <td>{{ unit.type }}</td>
        <td>{{ unit.fuelSourceDesc }}</td>
        <td [class.text-success]="unit.status == 2">
          {{
            unit.status == '1'
              ? 'Inactive'
              : unit.status == '2'
              ? 'Active'
              : 'Pending'
          }}
        </td>
        <td>{{ unit.powerClerk }}</td>
        <td>
          <button
            class="btn_remove_unit"
            (click)="onRemoveClick(unit)"
            data-bs-toggle="modal"
            data-bs-target="#removeGenUnitModal"
            *ngIf="functionalityAccess.allowRemoveGenerationUnit"
          >
            Remove
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div
  class="modal fade"
  id="removeGenUnitModal"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header remove_gen_unit_modal_header border-0">
        <h5
          class="modal-title text-success remove_gen_unit_modal_title"
          id="removeGenUnitModalLabel"
        >
          {{
            !isRemoveSuccess && !isRemoveError
              ? 'Remove Generation Unit'
              : 'Remove Status'
          }}
        </h5>
        <button
          type="button"
          (click)="closeRemoveModal()"
          *ngIf="!isRemoveLoading"
          class="btn-close gen_unit_modal_close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="remove_gen_unit_modal_content">
          <div
            class="text-center"
            *ngIf="!isRemoveLoading && !isRemoveError && !isRemoveSuccess"
          >
            Are you sure you want to remove this generation unit?
          </div>
          <div
            *ngIf="isRemoveLoading"
            class="text-center justify-content-center"
          >
            <ameren-loading></ameren-loading>
            <p>Please wait..</p>
          </div>
          <div
            class="text-danger text-center justify-content-center"
            *ngIf="isRemoveError"
          >
            <img src="../../../assets/images/failure.svg" alt="success" />
            <p class="mt-2">{{ removeErrorMsg }}</p>
          </div>
          <div
            class="text-success text-center justify-content-center"
            *ngIf="isRemoveSuccess"
          >
            <img src="../../../assets/images/success.svg" alt="success" />
            <p class="mt-2">Generation Unit removed Successfully.</p>
          </div>
        </div>
      </div>
      <div
        class="text-center justify-content-center mb-3"
        *ngIf="!isRemoveSuccess && !isRemoveError && !isRemoveLoading"
      >
        <button
          type="button"
          (click)="closeRemoveModal()"
          class="btn btn-outline-secondary ps-4 px-4"
          data-bs-dismiss="modal"
        >
          No
        </button>

        <button
          type="button"
          (click)="confirmRemoveUnit()"
          class="btn btn-success ms-2 ps-4 px-4"
        >
          Yes
        </button>
      </div>
    </div>
  </div>
</div>
