import { ReportsManagerService } from './../services/reports-manager.service';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError } from 'rxjs';
import { takeUntil, Subject, combineLatest } from 'rxjs';
import { SubscriberManagerServiceService } from "./../../subscriber/services/subscriber-manager-service.service";
import { GenerationOwnerManagerService } from "./../../generation-owner/services/generation-owner-manager.service";
import { ExportUtility } from "./../../../shared/utilities/utility.export";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { GenerationUnitManagerService } from '../../generation-unit/services/generation-unit-manager.service';

@Component({
  selector: "app-gen-unit-all-sub-bill-account-rpt",
  templateUrl: "./gen-unit-all-sub-bill-account-rpt.component.html",
  styleUrls: ["./gen-unit-all-sub-bill-account-rpt.component.scss"],
})
export class GenUnitAllSubBillAccountRptComponent implements OnInit, OnDestroy {
  constructor(
    private subMgr: SubscriberManagerServiceService,
    private genOwnerMgr: GenerationOwnerManagerService,
    private reportMgrSvc: ReportsManagerService,
    private _export: ExportUtility,
    private genUnitMgr: GenerationUnitManagerService,
  ) {}

  unsubscribe$ = new Subject();

  subscribers: any[];
  selectedSubscribers: any[];
  billAccounts: any[];
  meters: any[];
  allOwners: any[];

  owners: any[];

  selectedAccount: any;
  selectedOwner: any;
  selectedMeter: any;

  isReportCrieteriaLoading: boolean;
  dtBegin: string;
  dtEnd: string;

  startDates: any[] = [];
  endDates: any[] = [];
  searchClicked: boolean;
  reportData: any[] = [];
  isReportLoading: boolean;

  units: any[];
  selectedUnit: any;
  isApiLoading: boolean;
  integrationErrorMsg:any;

  ngOnInit(): void {   
    this.getGenOwners();
  }

  getSubscribers() {
    this.subMgr.getSubscribers().subscribe({
      next: (resp) => {
        this.subscribers = resp;
      },
    });
  }

  getSubscribersByOwner() {
    this.integrationErrorMsg = "";
    this.isApiLoading = true;
    let allSubscribers = [];
   
    this.genUnitMgr.getSubscribers('0', '0', this.selectedOwner.ownerId).subscribe({
      next: (resp) => {
        this.isApiLoading = false;
       
        allSubscribers = resp;
        allSubscribers.forEach((sub: any, i:any) => {
          if (sub.statusCode == '01') sub.statusDescription = 'Inactive';
          else if (sub.statusCode == '02') sub.statusDescription = 'Active';
          else if (sub.statusCode == '03') sub.statusDescription = 'Pending';
          else sub.statusDescription = 'Finaled';

          sub['selected'] = false;
          sub['index'] = i;
        });       

        this.subscribers = allSubscribers.filter(
          (s:any) => s.statusCode == '02' && s.ownerId == this.selectedOwner?.ownerId
        ); 

        this.selectedSubscribers = this.subscribers.filter(
          (x) => x.ownerId == this.selectedOwner.ownerId
        );
       
      },
      error: (err:any) => {
        this.isApiLoading = false;
        allSubscribers = [];
        this.subscribers = [];
        this.integrationErrorMsg = err.error?.errorDetails ? err.error.errorDetails?.message : err.errorDescription; 
       
      },
    });
  }

  getGenOwners() {
    this.isApiLoading = true;
    this.genOwnerMgr.getAllOwners().subscribe({
      next: (resp: any) => {
        this.allOwners = resp;
        this.owners = this.allOwners;
        this.isApiLoading = false;
      },
    });
  }

  getUnitsByOwner() {
    this.integrationErrorMsg = "";
    this.isApiLoading = true;
    this.genOwnerMgr
      .getGenerationUnitsByOwnerId(this.selectedOwner.ownerId)
      .subscribe({
        next: (resp) => {        
          this.isApiLoading = false;         
          if(resp)
          {
            this.units = resp;
            if (this.units.length == 1) {
              this.selectedUnit = this.units[0];              
              this.onUnitChange();
          }
        }
        else
          this.integrationErrorMsg = resp?.errorDetails ? resp?.errorDetails?.message : 'Sorry, something went wrong. Please try again later.';
        },        
      error: (err) => {
        this.isApiLoading = false;
        this.units = []; 
        this.integrationErrorMsg = err.error?.errorDetails ? err.error.errorDetails?.message : err.errorDescription; 
      },
      });
  }

  onUnitChange() {   
    this.selectedSubscribers = this.selectedSubscribers?.filter( s=>s.unitId == this.selectedUnit.unitId);
  }

  onOwnerChange() {
    
    this.getSubscribersByOwner();
    this.getUnitsByOwner(); 
  }
  onSearchClick() {
    this.isReportLoading = true;
    this.searchClicked = true;

    let reportReqs: Observable<any>[] = [];
    this.selectedSubscribers.forEach((sub: any) => {
      reportReqs.push(
        this.reportMgrSvc.getGenUnitSubBillAccountReport(sub.billAccountNumber,this.dtBegin, this.dtEnd, this.selectedOwner.ownerId)
          .pipe(catchError(() => of(null)))
      );
    });
    

    
    combineLatest(reportReqs)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((resp) => {
      resp.forEach((rpt) => {
        if (rpt && rpt.length) {
          this.reportData.push(...rpt);
        }
      });
    
      this.isReportLoading = false;
    });
  }

  exportReport() {
    let keyHeaders: string[] = [];
    let modifiedlist: any = [];
    let displayHeaders: string[] = [
      "Bill Account",
      "Subscriber Name",
      "Meter Number",
      "Generation Owner",
      "Unit Nick Name",
      "Date Reading From",
      "Date Reading To",
      "KWH to Subscriber",
      "Bill Saving Rate",
      "Monetory Savings",
      "Sub Fees Billed",
    ];

    keyHeaders = [
      "billAccountNumber",
      "subscriberName",
      "meterNo",
      "ownerName",
      "nickName",
      "readingFromDate",
      "readingToDate",
      "subscribeQuantity",
      "billSavingsRate",
      "billSavingsAmount",
      "subFeesBilled",
    ];

    this.reportData.forEach((row: any) => {
      let temp: any = {};

      temp["billAccountNumber"] = row.billAccountNumber;
      temp["subscriberName"] = row.subscriberName;
      temp["meterNo"] = row.meterNo;
      temp["ownerName"] = row.ownerName;
      temp["nickName"] = row.nickName;

      temp["readingFromDate"] = row.readingFromDate;
      temp["readingToDate"] = row.readingToDate;
      temp["subscribeQuantity"] = row.subscribeQuantity;

      temp["billSavingsRate"] = row.billSavingsRate;
      temp["billSavingsAmount"] = row.billSavingsAmount;

      temp["subFeesBilled"] = row.subFeesBilled;

      modifiedlist.push(temp);
    });
    this._export.exportJsonToExcelFile(
      modifiedlist,
      "generation unit subscriber bill account report",
      false,
      keyHeaders,
      displayHeaders
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
