import { NgModule } from "@angular/core";

import { ManageProfileRoutingModule } from "./manage-profile-routing.module";
import { SharedModule } from "src/app/shared/shared.module";
import { EditProfileComponent } from "./edit-profile/edit-profile.component";
import { EditPasswordComponent } from "./edit-password/edit-password.component";
import { ManageprofileComponent } from "./manageprofile.component";

@NgModule({
  declarations: [
    EditProfileComponent,
    EditPasswordComponent,
    ManageprofileComponent,
  ],
  imports: [SharedModule, ManageProfileRoutingModule],
  exports: [
    EditProfileComponent,
    EditPasswordComponent,
    ManageprofileComponent,
  ],
})
export class ManageProfileModule {}
