import { MustMatch } from 'src/app/shared/validators/must-match';
import { AuthService } from '../../../shared/services/auth.service';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { RegexValidator } from 'src/app/shared/validators/regex';
import { RegistrationManagerService } from '../../../../../src/app/public/registration/services/registration-manager.service';
import { AmerenError } from 'src/app/shared/models/amerenError';
import { Router } from '@angular/router';

declare var window: any;
@Component({
  selector: 'app-edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.scss'],
})
export class EditPasswordComponent implements OnInit {
  currentUser: any;
  currentPasswordAttr: string = 'password';
  passwordAttr: string = 'password';
  confirmPasswordAttr: string = 'password';
  isAPIFailure: boolean = false;
  isLoading: boolean = false;
  integrationErrorMsg: string;
  confirmModal: any;
  successModal: any;
  textValue1: string = 'text';
  textValue2: string = 'password';
  constructor(
    private _fb: FormBuilder,
    private _authService: AuthService,
    private registrationManagerService: RegistrationManagerService,
    private router: Router
  ) {
    this._authService.getUser().then((user) => {
      this.currentUser = user?.profile;
    });
  }

  editPasswordForm: FormGroup;
  ngOnInit(): void {
    this.initEditPasswordForm();
    this.confirmModal = new window.bootstrap.Modal(
      document.getElementById('confirmationModal')
    );
    this.successModal = new window.bootstrap.Modal(
      document.getElementById('updateSuccessModal')
    );
  }

  initEditPasswordForm() {
    this.editPasswordForm = this._fb.group(
      {
        currentPassword: new FormControl(null, [Validators.required]),
        newPassword: new FormControl(null, [
          Validators.required,
          RegexValidator(
            new RegExp('^([ s]+|[ s]+$)'),
            { leadTrilSpaces: 'No leading or Trailing spaces are allowed' },
            true
          ),
          RegexValidator(new RegExp(/^(?=.*[a-zA-Z])(?=.*[0-9]).*$/), {
            alphaNumeric: 'Must contain letters and numbers',
          }),
          RegexValidator(
            new RegExp(/(\S)\1\1+/),
            {
              repeatChars:
                'Should not contain more than 2 repeating characters.',
            },
            true
          ),
          RegexValidator(
            new RegExp(/\s\s/),
            { noContinuous2Spaces: 'Should not contain 2 continuous spaces' },
            true
          ),
          RegexValidator(
            new RegExp(/[<]|[>]|[&]|[-]/),
            {
              invalidChars:
                'Should not contain any of these special characters <>&-',
            },
            true
          ),
          RegexValidator(new RegExp(/^(?=.{8,250}$).*/), {
            charRange: 'Must be between between 8-250 charcters',
          }),
          RegexValidator(
            new RegExp(/.*[~`!@#$%\^\(\)_*+=\\[\]\\';,\/{}|\\"":\?].*/),
            { specialChars: 'Must include at least one special character.' }
          ),
        ]),
        confirmNewPassword: new FormControl(null, [Validators.required]),
      },
      {
        validator: MustMatch('newPassword', 'confirmNewPassword'),
      }
    );
  }

  togglePasswordAttr() {
    this.passwordAttr == 'password'
      ? (this.passwordAttr = this.textValue1)
      : (this.passwordAttr = this.textValue2);
  }
  toggleConfirmPasswordAttr() {
    this.confirmPasswordAttr == 'password'
      ? (this.confirmPasswordAttr = this.textValue1)
      : (this.confirmPasswordAttr = this.textValue2);
  }
  toggleCurrentPasswordAttr() {
    this.currentPasswordAttr == 'password'
      ? (this.currentPasswordAttr = this.textValue1)
      : (this.currentPasswordAttr = this.textValue2);
  }
  openModal() {
    if (this.editPasswordForm.invalid) {
      for (const control of Object.keys(this.editPasswordForm.controls)) {
        this.editPasswordForm.controls[control].markAsTouched();
      }
      return;
    }
    this.confirmModal.show();
  }
  changePassword() {
    this.confirmModal.hide();
    this.successModal.hide();
    this.isLoading = true;
    this.isAPIFailure = false;
    this.registrationManagerService
      .changePassword(
        this.currentUser.email,
        this.editPasswordForm.value.currentPassword,
        this.editPasswordForm.value.newPassword
      )
      .toPromise()
      .then(
        (res: any) => {
          this.isLoading = false;
          this.isAPIFailure = false;
          this.editPasswordForm.reset();
          this.successModal.show();
          setTimeout(() => {
            this.successModal.hide();
            this.router.navigate(['/edit-profile']);
          }, 1000);
        },
        (error: AmerenError) => {
          this.isLoading = false;
          this.isAPIFailure = true;
          this.integrationErrorMsg = error.message;
        }
      );
  }
}
