export enum EnumRole {
  INTERNAL_ADMIN = "anmInternalAdmin",
  EXTERNAL_ADMIN = "anmExternalAdmin",
  INTERNAL_VIEW_ONLY = "anmInternalViewOnly",
  EXTERNAL_VIEW_ONLY = "anmExternalViewOnly",
  REBATE_VIEW_ONLY = "anmRebateViewOnly",
  REBATE_APPROVER = "anmRebateApprover",
  IPA_VIEW_ONLY = "anmIPAViewOnly",
  SECURITY_ADMIN = "anmSecurityAdmin",
}
