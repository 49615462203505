import { forkJoin, of, switchMap } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs';
import { AmerenError } from 'src/app/shared/models/amerenError';
import { map } from 'rxjs';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';
import { Injectable } from '@angular/core';
import { RebateApiService } from './rebate-api.service';
import { RebatesByUnitIdModel } from '../models/rebatesbyunitid.model';

@Injectable({
  providedIn: 'root',
})
export class RebateManagerService {
  constructor(private rebateService: RebateApiService) {}

  getRebatesByUnitId(unitId: string, billAccount: string, ownerId: string) {
    return this.rebateService
      .getRebatesByUnits(unitId, billAccount, ownerId)
      .pipe(
        map((res: IGlobalAPIResponse) => {
          if (!res.success) {
            throw new AmerenError(
              'Get Rebates by unit id Failed',
              400,
              null,
              null,
              null
            );
          }

          let rebatesByUnitId: any[] = [];
          res.data.forEach((res: any) => {
            rebatesByUnitId.push(new RebatesByUnitIdModel(res));
          });

          return rebatesByUnitId;
        }),
        catchError((error: any) => {
          console.log('err', error);
          return throwError(() => error);
        })
      );
  }

  getRebateHistoryByUnit(unitId: string) {
    return this.rebateService.getRebateHistoryByUnit(unitId).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Get Rebate History by unit id Failed',
            400,
            null,
            null,
            null
          );
        }

        return res.data;
      }),
      catchError((error: any) => {
        console.log('err', error);
        return throwError(() => error);
      })
    );
  }

  saveRebates(rebatesBody: any, audit: string) {
    return this.rebateService.saveRebates(rebatesBody, audit).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError('Save Rebates Failed', 400, null, null, null);
        }

        return res.data;
      }),
      catchError((error: any) => {
        console.log('err', error);
        return throwError(() => error);
      })
    );
  }

  getAllRebates(paginate: boolean = false) {
    if (!paginate) {
      return this.rebateService.getAllRebates().pipe(
        map((res: IGlobalAPIResponse) => {
          if (!res.success) {
            throw new AmerenError(
              "Get Gen Units failed",
              400,
              null,
              null,
              null
            );
          }

        return res.data;
        }),
        catchError((error: any) => {
          console.log("err", error);
          return throwError(() => error);
        })
      );
    } else {
      return this.rebateService.getAllRebates(0).pipe(
        switchMap((res: IGlobalAPIResponse) => {
          if (!res.success) {
            throw new AmerenError(
              "Get GenUnits failed",
              400,
              null,
              null,
              null
            );
          }

          if (res.data.totalPages > 1) {
            let pages = [...Array(res.data.totalPages - 1).keys()];
            const _observables = pages.map((pageNum) => {
              return this.rebateService.getAllRebates(pageNum + 1).pipe(
                catchError((err) => {
                  return of(err);
                })
              );
            });
            return forkJoin(_observables).pipe(
              map((responses) => {
                let results: any[] = [];
                responses.forEach((response: any) => {
                  results.push(...response.data.rebates);
                });
                results.push(...res.data.rebates);
                  return results;
              })
            );
          } else {
            return of(res.data.rebates);
          }
        })
      );
    }
  }
}
