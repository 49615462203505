import { Component, ViewEncapsulation, Input } from '@angular/core';
import { AmerenCarouselSlide } from 'oneuiframework/lib/interface/ameren-carousel-slide.interface';

@Component({
  selector: 'app-billing-and-payment-options',
  templateUrl: './billing-and-payment-options.component.html',
  styleUrls: ['./billing-and-payment-options.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BillingAndPaymentOptionsComponent {
  @Input() selectedState = '';

  getslides = (state: string): AmerenCarouselSlide[] => {
    if (state == 'illinois') return this.slides_IL;
    else if (state == 'missouri') return this.slides_MO;
    else return [];
  };
  slides_IL: AmerenCarouselSlide[] = [
    {
      img_src: '../../../assets/images/billing-options/familyOnDevices.webp',
      headline: 'Clean Energy Programs for Residents',
      description: 'Clean Energy Programs for Residents',
      linkText: 'Learn More',
      linkUrl:
        'https://www.ameren.com/missouri/residential/clean-energy-customer-programs',
      state: 'IL',
    },
    {
      img_src: '../../../assets/images/billing-options/Couple.webp',
      headline: 'Clean Energy Programs for Businesses',
      description: 'Clean Energy Programs for Businesses',
      linkText: 'Learn More',
      linkUrl:
        'https://www.ameren.com/missouri/business/clean-energy-customer-programs',
      state: 'IL',
    },
    {
      img_src: '../../../assets/images/billing-options/family.webp',
      headline: 'Gift of Energy',
      description:
        'Make a payment toward the Ameren energy balance of a friend, loved one, neighbor or even local business.',
      linkText: 'Learn More',
      linkUrl:
        'https://www.ameren.com/illinois/account/customer-service/payment-options/gift-of-energy',
      state: 'IL',
    },
    {
      img_src: '../../../assets/images/billing-options/19ManAtHome16x9.webp',
      headline: 'Keep Cool Illinois',
      description:
        "A collaborative effort by state agencies working together to serve the state's residents.",
      linkText: 'Learn More',
      linkUrl: 'https://keepcool.illinois.gov',
      state: 'IL',
    },
    {
      img_src:
        '../../../assets/images/billing-options/website-thumbnail-lighting2.jpg',
      headline: 'Energy Efficiency Rebate',
      description:
        'Learn more about saving money and energy with Energy Efficiency rebates and incentives.',
      linkText: 'Learn More',
      linkUrl:
        'https://www.ameren.com/company/environment-and-sustainability/social-responsibility/energy-efficiency-assistance',
      state: 'IL',
    },
  ];

  slides_MO: AmerenCarouselSlide[] = [
    {
      img_src: '../../../assets/images/billing-options/Clean Slate.svg',
      headline: 'Clean Energy Programs for Businesses',
      description: 'Clean Energy Programs for Businesses',
      linkText: 'Learn More',
      linkUrl:
        'https://www.ameren.com/missouri/business/clean-energy-customer-programs',
      state: 'MO',
    },
    {
      img_src: '../../../assets/images/billing-options/Seasonal Assistance.svg',
      headline: 'Seasonal Assistance',
      description:
        'You have multiple options if you need seasonal help paying your bill',
      linkText: 'Learn More',
      linkUrl:
        'https://www.ameren.com/missouri/residential/energy-assistance/seasonal-assistance',
      state: 'MO',
    },
    {
      img_src: '../../../assets/images/billing-options/Current.svg',
      headline: 'Keeping Cool / Current ',
      description:
        'A payment assistance program that helps make energy bills more affordable',
      linkText: 'Learn More',
      linkUrl:
        'https://www.ameren.com/-/media/missouri-site/files/brochures/keepingcurrentkeepingcoolonly.ashx?la=en-us-mo&hash=72E37F2E77EEFB884BF9BB8BD7E710160233CB4C',
      state: 'MO',
    },
  ];
}
