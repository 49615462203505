import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { isArray } from 'lodash';
import * as XLSX from 'xlsx';

@Injectable()
export class ExportUtility {
  constructor() {}

  public exportJsonToExcelFile(
    json: any[],
    excelFileName: string,
    skipFirst: boolean,
    keyHeaders?: string[],
    displayHeaders?: string[],
    topHeaders?: any[]
  ) {
    let worksheet: XLSX.WorkSheet;
    let displayhdrs: string[] = displayHeaders ? displayHeaders : [];
    let tophdrs: any[] = topHeaders ? topHeaders : [];

    if (topHeaders) {
      worksheet = XLSX.utils.json_to_sheet(tophdrs, { skipHeader: true });
      worksheet = XLSX.utils.sheet_add_json(
        worksheet,
        this.formatTableData(json, keyHeaders, false),
        { skipHeader: true, origin: -1 }
      );
    } else {
      if (skipFirst) {
        worksheet = XLSX.utils.json_to_sheet(
          this.formatTableData(json, keyHeaders, false),
          { skipHeader: true }
        );
      } else {
        worksheet = XLSX.utils.json_to_sheet(
          this.formatTableData(json, keyHeaders, false),
          { skipHeader: false }
        );
      }
      worksheet = XLSX.utils.sheet_add_aoa(worksheet, [displayhdrs]);
    }

    this.fixWidth(worksheet);

    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data'],
    };

    XLSX.writeFile(workbook, `${excelFileName}.xlsx`);
  }

  public exportJsonToPdfFile(
    json: any[],
    pdfFileName: string,
    keyHeaders: string[],
    displayHeaders?: string[],
    pdfTitle: string = ''
  ) {
    let doc = new jsPDF('l', 'pt', 'a4');
    let displayhdrs: string[] = displayHeaders ? displayHeaders : [];

    let jsonFormat = this.formatTableData(json, keyHeaders, false);
    let jsonArray: string[][] = jsonFormat.map((x: string) => Object.values(x));

    autoTable(doc, {
      head: [new Array(pdfTitle)],
      styles: { halign: 'center', fillColor: 'green' },
    });

    autoTable(doc, {
      head: [displayhdrs],
      body: jsonArray,
      margin: { top: 20 },
    });

    doc.save(`${pdfFileName}.pdf`);
  }

  private formatTableData(
    data: object[],
    columns?: string[],
    isTableData: boolean = false
  ): any[] {
    let table: any[] = [];
    let tempRow: any;
    if (columns) {
      if (isArray(data)) {
        if (isTableData) {
          tempRow = {};
          table.push(tempRow);
          table.push(tempRow);
        }
        data.forEach((row: any) => {
          tempRow = {};
          columns.forEach((x) => {
            if (x) {
              let isBoolean: boolean = row[x] == 'true' || row[x] == 'false';
              tempRow[x] = isBoolean
                ? row[x] == 'true'
                  ? 'Yes'
                  : 'No'
                : row[x];
            }
          });

          table.push(tempRow);
        });
      } else {
        tempRow = {};
        table.push(tempRow);
        table.push(tempRow);
        columns.forEach((x) => {
          if (x) {
            let isBoolean: boolean = data[x] == 'true' || data[x] == 'false';
            tempRow[x] = isBoolean
              ? data[x] == 'true'
                ? 'Yes'
                : 'No'
              : data[x];
          }
        });
        table.push(tempRow);
      }
      return table;
    }
    return data;
  }

  private fixWidth(worksheet: XLSX.WorkSheet) {
    const data = XLSX.utils.sheet_to_json<any>(worksheet);
    const colLengths = Object.keys(data[0]).map((k) => k.toString().length);

    for (const d of data) {
      Object.values(d).forEach((element: any, index) => {
        if (element) {
          const length = element.length;
          if (colLengths[index] < length) {
            colLengths[index] = length;
          }
        }
      });
    }
    worksheet['!cols'] = colLengths.map((l) => {
      return {
        wch: l,
      };
    });
  }
  public exportHTMLTableToExcel(table: HTMLElement, excelFileName: string) {
    let wb = XLSX.utils.table_to_book(table, <XLSX.Table2SheetOpts>{
      sheet: 'ExportResult',
    });
    XLSX.writeFile(wb, `${excelFileName}.xlsx`);
  }

  public exportHTMLTabletoPDF(htmlObject: HTMLElement, pdfFileName: string) {
    const doc = new jsPDF('l', 'pt', 'a4');
    autoTable(doc, {
      html: <HTMLTableElement>htmlObject,
    });

    doc.save(`${pdfFileName}.pdf`);
  }

  public ExportByteArrayToFile(byteString: string, contentType: string) {
    const blob = this.b64toBlob(byteString, contentType);
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl);
  }

  private b64toBlob(b64Data: any, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  private formatData(data: object[], columns?: string[]): any[] {
    const table: any[] = [];
    let tempRow: any;

    if (columns) {
      data.forEach((row: any) => {
        tempRow = {};
        columns.forEach((x) => {
          //console.log(x);
          if (x) {
            tempRow[x] = row[x];
          }
        });
        table.push(tempRow);
      });
      return table;
    }
    return data;
  }
}
