import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { ExportUtility } from "src/app/shared/utilities/utility.export";
import { AuthService } from "src/app/shared/services/auth.service";
import { Router } from "@angular/router";
import { FineGrainAuthorization } from '../../shared/models/fine-grain-authorization';
import { AuditTrailManagerService } from "./services/audit-trail-manager.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";


export interface AuditTrail {
  auditID: string;
  tsKyTimestamp: string;
  txEmailAddr:string;
  txPropertyName: string;
  anmAccountStatus: string;
  txInitialValue: string;
  txFinalValue: string;
  
}

@Component({
  selector: 'app-audit-trail',
  templateUrl: './audit-trail.component.html',
  styleUrls: ['./audit-trail.component.scss']
})



export class AuditTrailComponent implements OnInit {
  displayedColumns = ['id', 'name', 'progress', 'color'];
  columnsToDisplay =['auditID','tsKyTimestamp', 'txEmailAddr', 'txPropertyName', 'txInitialValue','txFinalValue'];

  dataSource: MatTableDataSource<AuditTrail>;
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  constructor(
    private AuditMgrService: AuditTrailManagerService,private router: Router,
    private _export: ExportUtility, private authService: AuthService
  ) {
    this.authService.setSelectedGenerationOwner(null);
  }

  
  allAudits: AuditTrail[] = [];
  Audits: AuditTrail[] = [];
  filteredAuditList: AuditTrail[] = [];
  minDate: string;
  maxDate: string;
  dtBegin: string;
  dtEnd: string;
  isApiLoading: boolean = false;
  apiError: boolean = false;
  isDataExist:boolean = false
  integrationErrorMsg: string = '';
 
  loggedInUser:any;
  currentUser:any;
  isInternalUser:boolean =false;
  functionalityAccess: FineGrainAuthorization;



  ngOnInit(): void {
    this.loggedInUser = this.authService.getCurrentUser(); 

    if (this.loggedInUser != null)
      this.currentUser = this.loggedInUser.profile;

      this.currentUser.anmRoleMap = this.currentUser?.anmRoleMap.filter(
        (lst:any, i:any, arr:any) => arr.findIndex((t:any) => t.generationOwner === lst.generationOwner && t.status === 'Active') === i
      );  
      let internal_roles = this.currentUser?.anmRoleMap.filter(
        (a:any) =>
        (a.role != 'anmExternalAdmin' && a.role != 'anmExternalViewOnly')  && (a.status === 'Active')
      );     

      this.isInternalUser = internal_roles[0] != undefined && internal_roles.length >= 0;
      let roles =  internal_roles.map((x:any) => x.role);           

     if (this.isInternalUser)
      this.authService.setSelectedGenerationOwnerRole(roles);

      this.functionalityAccess = this.authService.getPortalFunctioanalityAccesses('GENERATION_OWNERS', roles);
      

    let dt = new Date();
    this.dtEnd = new Date(dt.setDate(dt.getDate() - 1)).toISOString().split('T')[0];
    this.dtBegin = new Date(dt.setDate(dt.getDate() - 60)).toISOString().split('T')[0];

      
    this.getData(this.dtBegin,this.dtEnd);
    
  } 

  getData(startDate:string,endDate:string){
    
    this.isApiLoading = true;
     this.Audits = [];
     this.isDataExist =false;
   
    this.dataSource = new MatTableDataSource(this.Audits);
 

      this.AuditMgrService.getAllAudits(startDate, endDate).subscribe({
      next: (anm:any) => {       
        if (anm.success) { 

          if (anm.data?.v_returnCode)
          {
            this.isApiLoading = false;
            this.apiError = true;
            this.integrationErrorMsg = 'Something went wrong. Please try again.';
          }
          else
          {
          this.allAudits =  anm.data;

          this.allAudits.forEach(audit => {
           
              this.Audits = [...this.Audits, audit];
           
          });
         
          this.Audits.forEach(audit => {      
            audit.txInitialValue = JSON.stringify(audit.txInitialValue, null, 2);
            audit.txFinalValue = JSON.stringify(audit.txFinalValue, null, 2);        
           
        });

          this.dataSource = new MatTableDataSource(this.Audits);
          this.dataSource.sort = this.sort;         
          
          this.dataSource.paginator = this.paginator;
          this.isDataExist = this.Audits?.length > 0;

          this.isApiLoading = false;
          this.apiError = false;
        }
        }

      },
      error: (e: any) => {      
        this.isApiLoading =false;
        this.apiError = true;      
        this.isApiLoading = e.error?.errorDetails ? e.error.errorDetails?.message : e.errorDescription;
        console.log(this.integrationErrorMsg);
        if (!this.integrationErrorMsg)
        this.integrationErrorMsg = 'Something went wrong. Please try again.';
      }
    });
  
  }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
 

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue;  
    this.filteredAuditList = this.dataSource.filteredData;    
  }


  exportUsers() {   
    let exportUserList = this.filteredAuditList?.length > 0 ? this.filteredAuditList : this.Audits;
   
    let keyHeaders: string[] = [
      'auditID','tsKyTimestamp', 'txEmailAddr', 'txPropertyName', 'txInitialValue','txFinalValue'     
    ];
   
    let displayHeaders: string[] = [
      'Id',
      'Date',
      'User',
      'Event',
      'Initial Value',
      'Final Value'          
    ];
    let modifiedlist: any = [];
   
    exportUserList.forEach((row: any) => { 
    
      let temp: any = {};
      temp['auditID'] = row.auditID;
      temp['tsKyTimestamp'] = row.tsKyTimestamp;
      temp['txEmailAddr'] = row.txEmailAddr;
      temp['txPropertyName'] = row.txPropertyName; 
      temp['txInitialValue'] = row.txInitialValue;
      temp['txFinalValue'] =  row.txFinalValue;      
     
      modifiedlist.push(temp);
   
  });
   

    this._export.exportJsonToExcelFile(
      modifiedlist,
      'Audit_trail',
      false,
      keyHeaders,
      displayHeaders
    );
  }   

}

