import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManageprofileApiService {  
  
  constructor(private http:HttpClient) {}  

}
