import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { maintenance } from './shared/models/maintenance';
import { Subscription } from 'rxjs';
import { AuthService } from './shared/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  hide_banner: boolean = false;
  login_menu: boolean = true;
  currentState: string = '';
  maintenanceStatus: maintenance;
  showMaintenanceSnack: boolean = false;
  generationOwnerSubscription: Subscription;
  generationOwnerSelected: boolean = false;

  constructor(
    private router: Router,
    private authSvc: AuthService,
    private cdr: ChangeDetectorRef
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          event.url == '/' ||
          event.url == '/illinois' ||
          event.url == '/missouri' ||
          event.url.includes('?_gl=')
        ) {
          if (
            event.url.includes('/illinois') ||
            event.url.includes('/missouri')
          ) {
            let stateFromURL: string = event.url.replace('/', '');
            if (stateFromURL.includes('?_gl=')) {
              stateFromURL = stateFromURL.substring(
                0,
                stateFromURL.indexOf('?')
              );
            }
            this.currentState = stateFromURL;
            this.hide_banner = true;
          } else {
            this.hide_banner = false;
            this.currentState = '';
          }
          this.login_menu = true;
        } else if (event.url == '/dashboard') {
          this.login_menu = false;
          this.hide_banner = true;
        } else {
          this.hide_banner = true;
        }
      }
    });

    this.generationOwnerSubscription =
      this.authSvc.generationOwnerUpdated.subscribe((ownrStatus: any) => {
        this.generationOwnerSelected = ownrStatus;
      });
  }

  closeMaintenanceSnack() {
    this.showMaintenanceSnack = false;
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.generationOwnerSubscription.unsubscribe();
  }
}
