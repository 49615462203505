import { EditProfileComponent } from './secure/manageprofile/edit-profile/edit-profile.component';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './public/home/home.component';
import { RegistrationComponent } from './public/registration/registration.component';
import { AuthGuardService } from './shared/services/auth-guard.service';
import { RegistrationSuccessComponent } from './public/registration/registration-success/registration-success.component';
import { SigninRedirectCallbackComponent } from './public/signin-redirect-callback/signin-redirect-callback.component';
import { SignoutRedirectCallbackComponent } from './public/signout-redirect-callback/signout-redirect-callback.component';
import { IneligibleUserComponent } from './secure/ineligible-user/ineligible-user.component';
import { GenerationOwnerComponent } from './secure/generation-owner/generation-owner.component';
import { ManageprofileComponent } from './secure/manageprofile/manageprofile.component';
import { GenerationUnitComponent } from './secure/generation-unit/generation-unit.component';
import { ReportsComponent } from './secure/reports/reports/reports.component';
import { ManageRebateComponent } from './secure/rebate/manage-rebate/manage-rebate.component';
import { InternalusersComponent } from './secure/users/internalusers/internalusers.component';
import { SubscriberComponent } from './secure/subscriber/subscriber/subscriber.component';
import { ManageUsersAccessComponent } from './secure/manage-users-access/manage-users-access.component';
import { EmulationComponent } from './public/emulation/emulation.component';
import { LoginComponent } from './public/login/login.component';
import { RebateDetailComponent } from './secure/rebate/rebate-detail/rebate-detail.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: SigninRedirectCallbackComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'illinois',
    component: HomeComponent,
  },
  {
    path: 'missouri',
    component: HomeComponent,
  },
  {
    path: 'registration',
    component: RegistrationComponent,
  },
  {
    path: 'missouri/registration',
    component: RegistrationComponent,
  },
  {
    path: 'illinois/registration',
    component: RegistrationComponent,
  },
  {
    path: 'registration-success',
    component: RegistrationSuccessComponent,
  },
  {
    path: 'signin-callback',
    component: SigninRedirectCallbackComponent,
  },
  {
    path: 'signout-callback',
    component: SignoutRedirectCallbackComponent,
  },
  {
    path: 'manage-profile',
    component: ManageprofileComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'edit-profile',
    component: EditProfileComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'ineligible-user',
    component: IneligibleUserComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'users',
    component: InternalusersComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./secure/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'rebate',
    component: ManageRebateComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'rebate-detail',
    component: RebateDetailComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'generation-owner',
    component: GenerationOwnerComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'generation-unit',
    component: GenerationUnitComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'subscribers',
    component: SubscriberComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'manage-users-access',
    component: ManageUsersAccessComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'emulation/:flip',
    component: EmulationComponent,
  },
  {
    path: 'emulation',
    component: EmulationComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
