import { NgModule } from '@angular/core';

import { ReportsRoutingModule } from './reports-routing.module';
import { ReportsComponent } from './reports/reports.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { GenerationOwnerReportComponent } from './generation-owner-report/generation-owner-report.component';
import { GenUnitSubBillaccountReportComponent } from './gen-unit-sub-billaccount-report/gen-unit-sub-billaccount-report.component';
import { GenunitOwnerSubFeeComponent } from './genunit-owner-sub-fee/genunit-owner-sub-fee.component';
import { GenUnitBillAcctSubscriptionReportComponent } from './gen-unit-bill-acct-subscription-report/gen-unit-bill-acct-subscription-report.component';
import { GenUnitSubscriberContractReportComponent } from './gen-unit-subscriber-contract-report/gen-unit-subscriber-contract-report.component';
import { GenUnitSubDailyUsageComponent } from './gen-unit-sub-daily-usage/gen-unit-sub-daily-usage.component';
import { SubDailyKwhAllocationRptComponent } from './sub-daily-kwh-allocation-rpt/sub-daily-kwh-allocation-rpt.component';
import { RegulatoryComplianceRptComponent } from './regulatory-compliance-rpt/regulatory-compliance-rpt.component';
import { GenUnitDailyOutputRptComponent } from './gen-unit-daily-output-rpt/gen-unit-daily-output-rpt.component';
import { IpaGenUnitSubContractRptComponent } from './ipa-gen-unit-sub-contract-rpt/ipa-gen-unit-sub-contract-rpt.component';
import { GenUnitAllSubBillAccountRptComponent } from './gen-unit-all-sub-bill-account-rpt/gen-unit-all-sub-bill-account-rpt.component';

@NgModule({
  declarations: [
    ReportsComponent,
    GenerationOwnerReportComponent,
    GenUnitSubBillaccountReportComponent,
    GenunitOwnerSubFeeComponent,
    GenUnitBillAcctSubscriptionReportComponent,
    GenUnitSubscriberContractReportComponent,
    GenUnitSubDailyUsageComponent,
    SubDailyKwhAllocationRptComponent,
    RegulatoryComplianceRptComponent,
    GenUnitDailyOutputRptComponent,
    IpaGenUnitSubContractRptComponent,
    GenUnitAllSubBillAccountRptComponent
  ],

  imports: [SharedModule, ReportsRoutingModule],
})
export class ReportsModule {}
