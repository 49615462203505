<div class="gen-owner-report-container">
    <div class="gen_owner_report_title">
        Generation Owner Report
    </div>
    <div class="text-center" *ngIf="isOwnersLoading">
        <ameren-loading></ameren-loading>
        <p>
            Please wait..
        </p>
    </div>
    <div class="row" *ngIf="genOwners">
        <div class="col-md-8">
            <div class="row">
                <div class="col">
                    <label>
                        Generation Owner Name: <small class="text-danger">*</small>
                    </label>
                    <select class="form-select" [(ngModel)]="selectedOwner">
                        <option *ngFor="let g of genOwners" [value]="g.ownerId">{{g.name}}</option>
                    </select>
                </div>
                <div class="col">
                    <label for="date">Date:
                        <small class="text-danger">*</small>
                    </label>
                    <input id="date" type="date" class="form-control" [(ngModel)]="selectedDate">
                </div>
            </div>
        </div>
        <div class="col-md-4 gen_owner_report_search_btn">
            <div class="row">
                <div class="col text-right">
                </div>
                <div class="col text-right">
                    <button class="btn btn-outline-success m-2" (click)="getReportData()" [disabled]="!selectedDate && !selectedOwner">Search</button>
                    <button class="btn btn-outline-success" [disabled]="!reportData || !reportData.length || !searchClicked" (click)="exportReport()">
                        <img src="assets/icon/excelicon.svg" alt="CalendarImage" width="22" height="19" />
                        Export</button>
                </div>
            </div>

        </div>
    </div>


    <table class="table gen_owner_report_grid" *ngIf="searchClicked">
        <caption class="sr-only">
            repscribers List
        </caption>
        <thead>
            <tr>
                <th scope="col">
                    Generation Id
                </th>
                <th scope="col">
                    Nick Name
                </th>
                <th scope="col">
                    Bill Account Number
                </th>
                <th scope="col">
                    Capacity(KW)
                </th>
                <th scope="col">
                    Subscribed (KW)
                </th>
                <th scope="col">
                    Number of Subcribers
                </th>
                <th scope="col">
                    Fuel Source
                </th>
                <th scope="col">
                    Generation Type
                </th>
                <th scope="col">
                    Status
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="!isReportLoading && (!reportData || !reportData.length)">
                <td colspan="9" class="text-center">No data</td>
            </tr>
            <tr *ngIf="isReportLoading">
                <td colspan="9" class="text-center">
                    <p>Please wait..</p>
                    <ameren-loading></ameren-loading>
                </td>
            </tr>
            <ng-container *ngIf="!isReportLoading">
                <tr *ngFor="let rep of reportData">
                    <td>
                        {{ rep.owner }}
                    </td>
                    <td>
                        {{ rep.nickName }}
                    </td>
                    <td>
                        {{ rep.billAccountNumber }}
                    </td>
                    <td>
                        {{ rep.generationCapacityQuantity }}
                    </td>
                    <td>
                        {{ rep.subscribeQuantity }}
                    </td>
                    <td>
                        {{ rep.subscriberCount }}
                    </td>
                    <td>{{ rep.fuelSourceDescription }}</td>
                    <td>
                        {{rep.generationUnitTypeDescription}}
                    </td>
                    <td>
                        {{rep.statusDescription == 'ACT'? 'Active':rep.statusDescription}}
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>

</div>