import { ReportsManagerService } from './../services/reports-manager.service';
import { GenerationOwnerManagerService } from './../../generation-owner/services/generation-owner-manager.service';
import { Component, OnInit } from '@angular/core';
import { ExportUtility } from "src/app/shared/utilities/utility.export";


@Component({
  selector: 'app-generation-owner-report',
  templateUrl: './generation-owner-report.component.html',
  styleUrls: ['./generation-owner-report.component.scss']
})
export class GenerationOwnerReportComponent implements OnInit {

  constructor(private genOwnerMgr: GenerationOwnerManagerService, private reportMgrSvc: ReportsManagerService,
    private _export: ExportUtility,
    ) { }

  genOwners:any[];
  isOwnersLoading: boolean;
  selectedOwner: string;
  selectedDate: string;
  reportData:any[];
  isReportLoading: boolean; 
  searchClicked: boolean;

  ngOnInit(): void {
    this.getGenOwners();
  }

  getGenOwners(){
    this.isOwnersLoading = true;
    this.genOwnerMgr.getAllOwners().subscribe({
      next: (resp)=>{
        this.isOwnersLoading = false;
        this.genOwners = resp;
       
      },
      error: (err)=>{
        this.isOwnersLoading = false;
      }
    })
  }

  getReportData(){
    this.searchClicked = true;
    this.isReportLoading = true;
    this.reportMgrSvc.getGenerationOwnerReportData(this.selectedOwner, this.selectedDate).subscribe({
      next: (resp)=>{
       
        this.reportData = resp;
        this.isReportLoading = false;
      },
      error: (err)=>{
        console.log(err);
        this.isReportLoading = false
      }
    })
  }

  exportReport() {
    let keyHeaders: string[] = [];
    let modifiedlist: any = [];
    let displayHeaders: string[] = [
      "Generation Id",
      "Nick Name",
      "Bill Account Number",
      "Capacity(KW)",
      "Subscribed (KW)",
      "Number of Subcribers",
      "Fuel Source",
      "Generation Type",
      "Status"
    ];

    keyHeaders = [
      "owner",
      "nickName",
      "billAccountNumber",
      "generationCapacityQuantity",
      "subscribeQuantity",
      "subscriberCount",
      "fuelSourceDescription",
      "generationUnitTypeDescription",
      "statusDescription"
    ];

    this.reportData.forEach((row: any) => {
      let temp: any = {};

      temp["owner"] = row.owner;
      temp["nickName"] = row.nickName;
      temp["billAccountNumber"] = row.billAccountNumber;
      temp["generationCapacityQuantity"] = row.generationCapacityQuantity;
      temp["subscribeQuantity"] = row.subscribeQuantity;

      temp["subscriberCount"] = row.subscriberCount;
      temp["fuelSourceDescription"] = row.fuelSourceDescription;
      temp["generationUnitTypeDescription"] = row.generationUnitTypeDescription;

      temp["statusDescription"] = row.statusDescription == 'ACT'? 'Active':row.statusDescription;


      modifiedlist.push(temp);
    });
    this._export.exportJsonToExcelFile(
      modifiedlist,
      "generation owner report",
      false,
      keyHeaders,
      displayHeaders
    );
  }
}