<section class="program_banner">
  <div class="program_banner_row">
    <ul class="p-3">
      <li *ngFor="let l of Links">
        <a href="{{ l[state].href }}">
          <img src="{{ l[state].icon }}" alt="image" /><span
            class="long-text text_ellipsis"
            title="{{ l[state].long_text }}"
            >{{ l[state].long_text }}</span
          >
        </a>
      </li>
    </ul>
  </div>
</section>
