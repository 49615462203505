import { Injectable } from '@angular/core';
import { User, UserManager, UserManagerSettings } from 'oidc-client';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import * as _ from 'lodash';
import { ProfileManagerService } from './profilemanager.service';
import { Profile } from '../models/profile';
import { GenerationOwner } from "../models/generationowner";
import { HttpClient } from '@angular/common/http';
import { CacheService } from './cache.service';
import { IGlobalAPIResponse } from '../interfaces/iglobal-api-response';
import { FineGrainAuthorization } from '../models/fine-grain-authorization';



export function getSettings(): UserManagerSettings {
  return {
    authority: environment.authority,
    client_id: environment.client_id,
    redirect_uri: `${window.location.origin}/signin-callback`,
    post_logout_redirect_uri: `${window.location.origin}/signout-callback`,
    response_type: 'code',
    scope: environment.scope,
    filterProtocolClaims: true,
    loadUserInfo: true,
    automaticSilentRenew: true,
    acr_values: 'anmlogin',
    accessTokenExpiringNotificationTime: 120,
    silent_redirect_uri: `${window.location.origin}/silent-callback`,
    includeIdTokenInSilentRenew: false,
    silentRequestTimeout: 120000,
  };
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _userManager = new UserManager(getSettings());
  private _user: User | null;
  private _loginChangedSubject = new Subject<boolean>();
  private _userLoadedSubject = new Subject<User | null>();
  _profile: any;

  loginChanged = this._loginChangedSubject.asObservable();
  userLoaded = this._userLoadedSubject.asObservable();

  generationOwnerUpdatedSubject = new Subject();
  generationOwnerUpdated = this.generationOwnerUpdatedSubject.asObservable();
  _userGenerationOwners: any[];
  _userRoles: any[];
  _userGenerationOwnersAccessSubject : BehaviorSubject<any> = new BehaviorSubject<any>(null);
  _userRolesAccessSubject : BehaviorSubject<any> = new BehaviorSubject<any>(null);
  userGenerationOwnersAccess = this._userGenerationOwnersAccessSubject.asObservable();
  userRolesAccess = this._userRolesAccessSubject.asObservable();
  userSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  selectedGenOwnerSubject: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);
  selectedGenOwnerRoleSubject: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);
  selectedGenOwner:any;
  selectedGenOwnerRole:any[];

  _PortalFunctionalitiesAccess: any;
  _PortalFunctionalitiesAccessSubject  = new Subject<any>();
  PortalFunctionalitiesAccess = this._PortalFunctionalitiesAccessSubject.asObservable();

  constructor(private profileManager: ProfileManagerService, private http: HttpClient, private cache: CacheService) {
    this._userManager.events.addSilentRenewError(() => {
      this.Logout();
    });

    this._userManager.events.addUserSignedOut(() => {
      this.Logout();
    });

    this._userManager.events.addUserLoaded((u) => {
      this._userLoadedSubject.next(u);
    });
    
  }

  Login(): Promise<any> {
    return this._userManager.signinRedirect().catch((err) => {
      console.log('Login Error:', err);
    });
  }

  Logout(): Promise<any> {
    return this._userManager.signoutRedirect();
  }

  isLoggedIn(): Promise<boolean> {
    return this._userManager.getUser().then((user) => {
      this.refreshUserData(user);
      const userCurrent = !!user && !user.expired;
      if (this._user !== user) {
        this._loginChangedSubject.next(userCurrent);
      }
      this._user = user;
      return userCurrent;
    });
  }

  refreshUserData(currentUser: any) {
    this._profile = [];
    if (currentUser) {
      this._profile.push(
        new Profile(
          currentUser.profile.firstname,
          currentUser.profile.telephoneNumber,
          currentUser.profile.auditTrackingId,
          currentUser.profile.isverified,
          currentUser.profile.role,
          currentUser.profile.azp,
          currentUser.profile.email,
          currentUser.profile.lastname,
          currentUser.profile.lastLoginTime,
          currentUser.profile.pwdChangedTime,
          currentUser.profile.generationId,
          currentUser.profile.stateProvince,
          currentUser.profile.anmRoleMap
        )
      );
      this.profileManager.profile = this._profile;
    }
  }

  getUser(): Promise<User | null> { 
     return this._userManager.getUser().then((user:any) => {
      this.getandupdateUserRoles(user);
      this.SetLoggedinUser(user);   
   
      return this._user;
    });   
  
  }

  LoginComplete() {
    return this._userManager.signinRedirectCallback().then((user) => {
      this._user = user;
      this._loginChangedSubject.next(!!user && !user.expired);    
    
   
    this.getandupdateUserRoles(user);
    this.SetLoggedinUser(user);
      return user;
    });
  }

  LogOutComplete() {
    this._user = null;
    this._userLoadedSubject.next(null);
    this._loginChangedSubject.next(false);
    this.setSelectedGenerationOwner();
    this.setSelectedGenerationOwnerRole();
    this.generationOwnerUpdatedSubject.next(null);
    this._userGenerationOwnersAccessSubject.next(null);
    this._userRolesAccessSubject.next(null);
    this.userSubject.next(null);
    this.setPortalFunctionalitiesAccess();
    this.selectedGenOwnerSubject.next(null);
    this.selectedGenOwnerRoleSubject.next(null);
    this.profileManager.resetProfileManager();
    this.cache.clearCache();
    return this._userManager
      .signoutRedirectCallback()
      .then(() => {
        this._userManager.removeUser();
      })
      .catch((error) => this.handleError(error));
  }

  handleError(error: any) {
    console.log('Problem with ending the session: ', error);
  }

  getAccessToken() {
    let currentUser;
    return this._userManager.getUser().then((user) => {
      currentUser = user;
      return currentUser?.access_token;
    });
  }

  resetUser() {
    this._user = null;
    this._userLoadedSubject.next(null);
    this._loginChangedSubject.next(false);
    this.profileManager.resetProfileManager();
    return this._userManager
      .signoutRedirectCallback()
      .then(() => {
        this._userManager.removeUser();
      })
      .catch((error) => this.handleError(error));
  }

   getCurrentUser() {  
      
    if (this._user)
    { 
      this.SetLoggedinUser(this._user);     
      this.getandupdateUserRoles(this._user);
      return this._user;
    } 
    else 
    {
     return this.getUser();
    }   
  }
    

  setSelectedGenerationOwner(genOwner?: any): void {   

    if (!genOwner)
    { 
      this.selectedGenOwner = null;
      sessionStorage.removeItem('selectedGenerationOwner');
      this.selectedGenOwnerSubject.next(false);
      this.generationOwnerUpdatedSubject.next(false);
    }
    else {
      sessionStorage.setItem('selectedGenerationOwner', JSON.stringify(genOwner));
      this.selectedGenOwner = JSON.stringify(genOwner);
      this.selectedGenOwnerSubject.next(true);
      this.generationOwnerUpdatedSubject.next(true);
    }    

  }

  getSelectedGenerationOwner(): GenerationOwner {  
    let genOwner = this.selectedGenOwner ? this.selectedGenOwner : sessionStorage.getItem('selectedGenerationOwner')  || '';
    return genOwner != '' ? JSON.parse(genOwner) : null;
  }

  getSelectedGenerationOwnerID(): any {  
    let genOwner = this.getSelectedGenerationOwner();
    return genOwner?.ownerId;
  }

  setSelectedGenerationOwnerRole(genOwnerRole?: any): void {   
    if (!genOwnerRole)
    { this.selectedGenOwnerRole = [];
      sessionStorage.removeItem('selectedGenerationOwnerRole');
      this.selectedGenOwnerRoleSubject.next(false);
  }
    else {
      sessionStorage.setItem('selectedGenerationOwnerRole', genOwnerRole);
      this.selectedGenOwnerRole = genOwnerRole;
      this.selectedGenOwnerRoleSubject.next(true);
    }
    
  }

  getSelectedGenerationOwnerRole() {
  
    let selectedRole : any[] = [];
    selectedRole.push(sessionStorage.getItem('selectedGenerationOwnerRole'))

    let role:any  = this.selectedGenOwnerRole ? this.selectedGenOwnerRole : selectedRole ; 

    return  role;   
  }

  
  getaccess(){
    return this.cache.Cache<any>( 'ANM_CurrentAccesses', () =>
     this.http.get<any>("../../../assets/data/Rolebased_Functionality_Access.json")
     );
  }

  getPortalFunctioanalityAccesses(functionality?: string, role?: any): FineGrainAuthorization{
    let functionalityAccess:FineGrainAuthorization = new FineGrainAuthorization();
   
    let roles:any = [];
    let RoleAccess:any = [];  

    if(role == undefined)
    {
      let userRole = this._user?.profile?.['anmRoleMap'].filter(
        (lst:any, i:any, arr:any) => arr.findIndex((t:any) => t.generationOwner === lst.generationOwner && t.status === 'Active') === i
      );  

      let internal_roles = userRole?.filter(
        (a:any) =>
        (a.role != 'anmExternalAdmin' && a.role != 'anmExternalViewOnly')  && (a.status === 'Active')
      );     

      let isInternalUser = internal_roles[0] != undefined && internal_roles.length >= 0;
      if (isInternalUser)
        role =  internal_roles.map((x:any) => x.role);    
      else
        role = this.getSelectedGenerationOwnerRole();
    }  
   
    roles = role? role : this.getCurrentUserRolesAccess();
    RoleAccess = this.getFunctionalityAccesses(functionality, role?.toString()); 


    roles?.forEach((a:any) => {
   
        if ( a === 'anmExternalAdmin' || a === 'anmInternalAdmin') functionalityAccess.isAdmin = true;

        if ( (a == 'anmExternalAdmin' || a == 'anmExternalViewOnly')) 
        {
          functionalityAccess.isAmerenInternal = false;
          functionalityAccess.isExternal = true;
        }
        else
        {
          functionalityAccess.isAmerenInternal = true;
          functionalityAccess.isExternal = false;
        }

        if (a === 'anmInternalAdmin')  functionalityAccess.isAmerenAdmin = true;

        if (a === 'anmExternalAdmin')  functionalityAccess.isExternalAdmin = true;

        if (a === 'anmRebateApprover')  functionalityAccess.isRebateApprover = true;

        if (a === 'anmIPAViewOnly')  functionalityAccess.isIPAUser = true;
      
      });   


          functionalityAccess.allowUsersList = this.RoleContains(RoleAccess, 'READ_USERS' ) ;   
          functionalityAccess.allowEditUser = this.RoleContains(RoleAccess, 'EDIT_USERS' ) ;
          functionalityAccess.allowEnableDisableUsers = this.RoleContains(RoleAccess, 'ENABLE_DISABLE_USERS');   
          functionalityAccess.allowLockUnlockUsers = this.RoleContains(RoleAccess, 'LOCK_UNLOCK_USERS');   

          functionalityAccess.allowGenerationOwnersList = this.RoleContains(RoleAccess, 'READ_GENERATION_OWNER_LIST');   
          functionalityAccess.allowCreateGenerationOwner = this.RoleContains(RoleAccess, 'CREATE_GENERATION_OWNERS');   
          functionalityAccess.allowEditGenerationOwner = this.RoleContains(RoleAccess, 'UPDATE_GENERATION_OWNERS');   
          functionalityAccess.allowReadGenerationOwnerBankingInfo = this.RoleContains(RoleAccess, 'READ_GENERATION_OWNERS_BANK_INFO');   
          functionalityAccess.allowEditGenerationOwnerBankingInfo = this.RoleContains(RoleAccess, 'UPDATE_GENERATION_OWNERS_BANKINFO');  

          functionalityAccess.allowGenerationUnitsList = this.RoleContains(RoleAccess, 'READ_GENERATION_UNIT_LIST');   
          functionalityAccess.allowCreateGenerationUnit = this.RoleContains(RoleAccess, 'CREATE_GENERATION_UNIT');   
          functionalityAccess.allowEditGenerationUnit = this.RoleContains(RoleAccess, 'EDIT_GENERATION_UNIT'); 
          functionalityAccess.allowRemoveGenerationUnit = this.RoleContains(RoleAccess, 'REMOVE_GENERATION_UNIT');            

          functionalityAccess.allowSubscriberList = this.RoleContains(RoleAccess, 'READ_SUBSCRIBER_LIST');   
          functionalityAccess.allowCreateSubscriber = this.RoleContains(RoleAccess, 'CREATE_SUBSCRIBERS');   
          functionalityAccess.allowEditSubscriber = this.RoleContains(RoleAccess, 'EDIT_SUBSCRIBERS');   
          functionalityAccess.allowEnableDisableSubscribers = this.RoleContains(RoleAccess, 'ENABLE_DISABLE_SUBSCRIBERS');           
          
          functionalityAccess.allowRebateList = this.RoleContains(RoleAccess, 'READ_REBATE_LIST');   
          functionalityAccess.allowRebateDetail = this.RoleContains(RoleAccess, 'READ_REBATE_DETAIL');   
          functionalityAccess.allowEditRebate = this.RoleContains(RoleAccess, 'EDIT_REBATE');  
          
          functionalityAccess.allowReportsList = this.RoleContains(RoleAccess, 'READ_REPORT_LIST');   
          functionalityAccess.allowGenerationOwnerReport = this.RoleContains(RoleAccess, 'GENERATION_OWNER_REPORT');  
          functionalityAccess.allowGenerationUnitSubscriberBillAccountReport = this.RoleContains(RoleAccess, 'GENERATION_UNIT_SUBSCRIBER_BILL_ACCOUNT_REPORT'); 
          functionalityAccess.allowGenerationUnitDailyOutputReport = this.RoleContains(RoleAccess, 'GENERATION_UNIT_OUTPUT_REPORT');   
          functionalityAccess.allowGenerationUnitSubscriberContractReport = this.RoleContains(RoleAccess, 'GENERATION_UNIT_SUBSCRIBER_CONTRACT_REPORT'); 
          functionalityAccess.allowGenerationUnitSubscriberDailyAllocationReport = this.RoleContains(RoleAccess, 'GENERATION_UNIT_SUBSCRIBER_ALLOCATION_REPORT'); 
          functionalityAccess.allowSubscriberDailyAllocationReport = this.RoleContains(RoleAccess, 'SUBSCRIBER_DAILY_ALLOCATION_REPORT');  
          functionalityAccess.allowGenerationUnitAllSubscriberBillAccountReport = this.RoleContains(RoleAccess, 'GENERATION_UNIT_ALL_SUBSCRIBER_BILL_ACCOUNT_REPORT'); 
          functionalityAccess.allowIPAGenerationUnitSubscriberContractReport = this.RoleContains(RoleAccess, 'IPA_GENERATION_UNIT_SUBSCRIBER_CONTRACT_REPORT'); 
          functionalityAccess.allowRegulatoryComplianceReport = this.RoleContains(RoleAccess, 'REGULATORY_COMPLIANCE_REPORT');    
          functionalityAccess.allowGenerationUnitBillAccountSubscriptionRateReport = this.RoleContains(RoleAccess, 'GENERATION_UNIT_BILL_ACCOUNT_SUBSCRIPTION_RATE_REPORT');   
          functionalityAccess.allowGenerationUnitBillAccountSubscriptionReport = this.RoleContains(RoleAccess, 'GENERATION_UNIT_BILL_ACCOUNT_SUBSCRIPTION_REPORT'); 
          
          
          


    return functionalityAccess;

  }

  RoleContains(rolesAcess:any[],roles:any):boolean{
    let hasAccess:boolean = false;
      if ( rolesAcess.some(r => roles == r))
        hasAccess=true;  
    return hasAccess
  }

  getFunctionalityAccesses(functionality?: string, role?: string){
    let Roleaccess:any[] = [];
    let currentAccess: any[] = [];
    currentAccess = this.getPortalFunctionalitiesAccess();
   
    if (currentAccess.length <= 1 )
    {
      this.getaccess().subscribe(res => {
        currentAccess = res.Role_Functioanlty_Accesses;       
        this.setPortalFunctionalitiesAccess(currentAccess);
      }
      );
    }  

      Roleaccess.push(...currentAccess?.filter((a:any) =>
      functionality ? (a.Roles.includes(role) && a.Functioanlity === functionality) : (a.Roles.includes(role)) ).map((x:any) => x.Code));
        
     return Roleaccess;
  }
 

  getandupdateUserRoles(usr: User): void {
    let roles: any = [];
    let genOwners: any = []; 
    const govRoleMap = usr?.profile?.['anmRoleMap'];

    govRoleMap?.forEach((gen:any) => {
      let genOwnerid = gen.generationOwner.toString().trim();

      if (Array.isArray(gen.role)) {
        gen.role.forEach((rol: string) => {
          if (rol) {
            roles.push(rol.toString());
          }
        })
      } else {
        roles.push(gen.role.toString());
      }
            
      if (genOwnerid)
        genOwners.push(genOwnerid);         

    });

    //push unique gen owner ids to array
    genOwners = genOwners.filter((n: any, i: any) => genOwners.indexOf(n) === i);

    //push unique roles to array
    roles = roles.filter((n: any, i: any) => roles.indexOf(n) === i);   

    this._userGenerationOwners = genOwners;
    this._userRoles = roles;    

    this._userGenerationOwnersAccessSubject.next(genOwners);
    this._userRolesAccessSubject.next(roles);    

  }
 
  getCurrentUserGenerationOwnersAccess() {
    return this._userGenerationOwners;
  }
  getCurrentUserRolesAccess() {
    return this._userRoles;
  }

  getCurrentPortalFunctionalitiesAccess() {
    return this._PortalFunctionalitiesAccess;
  }

  SetLoggedinUser(usr:User) : void {
    this._user = usr;
    this.userSubject.next(usr);
  }

  public getLoggedinUser() {
    try{
      if (this._user != undefined)
      {
        return this._user;
      }
      else return null
  }
  catch(error) 
  {
    throw(error);
  }
  
    
  }
  
  setPortalFunctionalitiesAccess(access?: any): void {   

    if (!access)
    { 
      this._PortalFunctionalitiesAccess = [];
      sessionStorage["PortalFunctionalitiesAccess"] = [];          
    }
    else {    
      sessionStorage["PortalFunctionalitiesAccess"] = JSON.stringify(access);
      this._PortalFunctionalitiesAccess = access;    
     
    }    

  }

  getPortalFunctionalitiesAccess() {

    let currentAccess : any = [];
    if(sessionStorage['PortalFunctionalitiesAccess'])
      currentAccess.push(sessionStorage['PortalFunctionalitiesAccess']);
      

    return this._PortalFunctionalitiesAccess ? this._PortalFunctionalitiesAccess : (currentAccess?.length > 0 ? JSON.parse(currentAccess): currentAccess);   
   
  }
 
}
