import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth.service';

import { AmerenError } from 'src/app/shared/models/amerenError';
import { UsersManagerService } from '../users/services/users-manager.service';
import { GenerationOwnerManagerService } from '../generation-owner/services/generation-owner-manager.service';
import { ANMUserRole, externalANMAdminRoleOptions, userRoleOptions } from 'src/app/shared/enums/anm-userrole';
import { RebateApprovalLevels, RebateApprovalLevelsMapping } from 'src/app/shared/enums/rebate-approval-level';
declare var window: any;

@Component({
  selector: 'app-manage-users-access',
  templateUrl: './manage-users-access.component.html',
  styleUrls: ['./manage-users-access.component.scss']
})
export class ManageUsersAccessComponent implements OnInit {
  currentUser: any;
  UserId: any;
  isLoading: boolean = false;
  isnewUserAccesssubmitting:boolean = false;
  isAPIFailure: boolean = false;
  isnewAcctAPIFailure: boolean = false;
  integrationErrorMsg: string = "";
  integrationnewUserAccessErrorMsg:string = "";
  search_click: boolean = false;
  searchForm!: FormGroup;
  UserList : any[] = [];
  UserAccounts : any = [];
  revisedUserAccounts: any = [];
  selectedUserAccount: any = '';
  selectedUser: any = '' ;
  addnewUserAccess: any;
  addnewUserAccessMsgPopup: any;
  UserAccount:any;
  previouscolumn: string = '';
  previousflag: boolean = true;
  isAddUserformValid: boolean = false;
  selectedAccountsforDelete: any[] = [];
  isthisDeleteProcess:boolean = false;
  selectedAccountsList: string = "";
  role:string = "";
  generationOwner:string = "";
  rebateApproverLevel:string = "";
  @ViewChildren('checkboxes') checkboxes: QueryList<ElementRef>;
  ownerList: any[] = [];
  public userRoleOptions : any = JSON.parse(JSON.stringify(userRoleOptions)); 
  showGenOwnerOption: boolean = false;
  showRebateLevelOption: boolean =false;

  public roleOptions:any  = ANMUserRole;
  private _roleOptions = Object.keys(ANMUserRole);
  private _extRoleOptions = Object.keys(externalANMAdminRoleOptions);

  public rebateApprovalLevelDropDownOptions :any;
  public rebateApprovalLevelOptions = Object.values(RebateApprovalLevels)
  public rebateApprovalLevelMapping : any = RebateApprovalLevelsMapping;

  loggedInUser:any;
  isInternalUser:boolean = false;
  isUserAccessChanged: boolean = false;

  
  constructor(private fb: FormBuilder, private authService: AuthService, private UsersService: UsersManagerService,private generationOwnerManager: GenerationOwnerManagerService) { }
  
  public get roles(): Array<string> {
    return this.isInternalUser ? this._roleOptions : this._extRoleOptions;
  }

  ngOnInit(): void {
    this.initalizeForm();
    this.selectedUserAccount = '';
    this.isthisDeleteProcess = false
   
    this.loggedInUser = this.authService.getCurrentUser(); 
    if (this.loggedInUser != null)
      this.currentUser = this.loggedInUser.profile;

      this.currentUser.anmRoleMap = this.currentUser?.anmRoleMap.filter(
        (lst:any, i:any, arr:any) => arr.findIndex((t:any) => t.generationOwner === lst.generationOwner && t.status === 'Active') === i
      );  
   
      let internal_roles = this.currentUser?.anmRoleMap.filter(
        (a:any) =>
        (a.role === 'anmInternalAdmin' ||
        a.role === 'anmInternalViewOnly' ||
        a.role === 'anmRebateApprover') && (a.status === 'Active')
      );

      this.isInternalUser =  internal_roles[0] != undefined && internal_roles.length >= 0;
 
    this.addnewUserAccess = new window.bootstrap.Modal(
      document.getElementById('addnewAccess'));
    this.addnewUserAccessMsgPopup = new window.bootstrap.Modal(
      document.getElementById('addnewAccesstMsgPopup'));
    
     this.rebateApprovalLevelDropDownOptions = this.rebateApprovalLevelOptions;  


    
      this.getGenerationOwnerList();
      this.getUsersList();
    

  }

  initalizeForm() {
    this.searchForm = this.fb.group({
      selectedUserId: [
        null,
        [
          Validators.required,
         
        ],
      ],
    });
  }

  get selectedUserId() {
    return this.searchForm.get('selectedUserId')!;
    
  }

  getUsersList() {
    this.UserList = [];
    this.isLoading = true;
    this.search_click = true;
    this.isAPIFailure = false;
    this.integrationErrorMsg = "";
    let Users: any[] = [];
   
    let genOwners = this.isInternalUser? undefined: (this.currentUser.anmRoleMap ? this.currentUser.anmRoleMap.filter((a:any) =>
    a.role === 'anmExternalAdmin' && a.status === 'Active').map((x:any) => x.generationOwner).join(',') : undefined);
    if( this.isInternalUser || genOwners)
    {
    this.UsersService.getAllUsers(false, genOwners).subscribe({
      next: (anm) => {
        if (anm.success) {         
          Users = anm.data;
          
          Users.forEach(user => {
            if (user.anmRoleMap && Array.isArray(user.anmRoleMap) && user.anmRoleMap.length) {
              if (!this.isInternalUser) 
                {
                  let roleMap = user.anmRoleMap?.filter(
                    (a:any) =>
                    (genOwners?.includes(a.generationOwner))
                  );

                  Users = [...Users, {...user, anmRoleMap: roleMap}];
                }
                else
                  Users = [...Users, {...user, anmRoleMap: user.anmRoleMap}];
            } else {
              Users = [...Users, user];
            }
          });

          Users = Users.filter(
            (lst, i, arr) => arr.findIndex(t => t.userName === lst.userName) === i
          ); 

          Users = Users.filter(partner => partner.anmAccountStatus != 'Pending' && partner.userName != this.currentUser.email);

          Users = Users.map(partner=>{
            let x:any = {}           
           
            x["userName"]=partner["userName"];
            x["givenName"]=partner["givenName"];
            x["sn"]=partner["sn"];
            x["anmAccountStatus"]=partner["anmAccountStatus"];
            x["anmRoleMap"]=partner["anmRoleMap"];
            x["fullName"]=`${partner["givenName"]} ${partner["sn"]} - ${partner["userName"]}`
           
          return x;
          })
          this.UserList = Users;  
          this.sortOn('UserName');    
          this.isLoading = false;
        }
      },
      error: (e: any) => {
        this.isLoading = false;
        this.isAPIFailure = true;
        this.integrationErrorMsg =  e.error?.errorDetails ? e.error.errorDetails?.message : e.errorDescription;
        this.UserList = [];
      }
    }); 
  }
  else{
    this.isLoading =false;
    this.isAPIFailure = true;      
    this.integrationErrorMsg = "You are not authorized to access this functiaonlity!";
  }   

  }

 getGenerationOwnerList() {
    this.ownerList = [];

    let genOwners = this.currentUser?.anmRoleMap ? this.currentUser?.anmRoleMap.filter((a:any) =>
    (a.role === 'anmExternalAdmin') && (a.status === 'Active')).map((x:any) => x.generationOwner).join(',') : '00018';

    this.generationOwnerManager.getAllOwners().subscribe({
      next: (data: any) => {  
        this.ownerList = data.sort((a: any, b: any) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });  

        if (!this.isInternalUser) 
        {
          this.ownerList = this.ownerList?.filter(
            (a:any) =>
            (genOwners?.includes(a.ownerId)) 
          );

        }
            
      },
      error: (error: any) => {
        console.log('error', error);       
      },
    });
  }

  getOwnerName(ownerId:string){
   
    if (ownerId == '00018')
      return 'Ameren Illinois'
    else
    {
      let owner = this.ownerList.filter(onr  => (onr.ownerId == ownerId));     
      return owner[0] != undefined? owner[0].name : "";        
    }
  }
  
  onSearch(){    
    this.UserAccounts = [];
    this.revisedUserAccounts = [];
    this.selectedAccountsforDelete = [];
    this.isthisDeleteProcess = false;
    this.selectedUserAccount = this.searchForm.get('selectedUserId')?.value;
    this.selectedUser = this.UserList.filter(partner => partner.userName == this.selectedUserAccount)[0];   
    this.isLoading = true;
    this.isUserAccessChanged = false;

    let genOwners = this.currentUser?.anmRoleMap ? (this.currentUser?.anmRoleMap.filter((a:any) =>
    (a.role === 'anmExternalAdmin') && (a.status === 'Active')).map((x:any) => x.generationOwner).join(',')) : undefined;

    if( this.selectedUser?.anmRoleMap  && this.selectedUser?.anmRoleMap?.length > 0  )
    {
     
        if (!this.isInternalUser) 
          {
            let roleMap = this.selectedUser.anmRoleMap?.filter(
              (a:any) =>
              (genOwners?.includes(a.generationOwner)) 
            );

            this.selectedUser.anmRoleMap =  roleMap;
          }
         
    

      this.selectedUser.anmRoleMap.forEach((roleMap: any) => {
        let temp: any = {};
        temp['generationOwnerId'] = roleMap.generationOwner;
        temp['generationOwner'] = this.getOwnerName(roleMap.generationOwner);
        temp['roleId'] = roleMap.role;
        temp['role'] = this.userRoleOptions[roleMap.role];      
        temp['status'] = roleMap.status; 
        if (roleMap.role == 'anmRebateApprover')
          temp['anmRebateApprovalLevel'] = roleMap.anmRebateApprovalLevel? roleMap.anmRebateApprovalLevel : ""; 
        
          this.UserAccounts.push(temp);       
      });     
    }
    this.revisedUserAccounts = this.UserAccounts;

    this.isLoading = false;  
    this.isAPIFailure = false; 

 

  }
  sortOn(column: string) {
    let flag: boolean = true;
    let sortedUsers : any = [];
    
    if (this.previouscolumn == '') {
      this.previouscolumn = column;
    }
    else if (this.previouscolumn == column) {
      flag = false;
      if (this.previousflag == flag) {
        flag = !flag;
      }
    }
    this.previouscolumn = column;
    this.previousflag = flag;
    sortedUsers = this.UserList.sort((obj1, obj2) => {
      if (obj1[column] > obj2[column]) {
        return flag ? 1 : -1;
      }
      if (obj1[column] < obj2[column]) {
        return flag ? -1 : 1;
      }
      return 0;
    });
    this.UserList = sortedUsers;
  }

  selectUserAccounts(event: any,index:any) {
   
    let selectedUserAccount: number = parseInt(
      event.currentTarget.getAttribute('data-UserAccount')
    );   
    let selectedRole:string = event.currentTarget.getAttribute('data-role'); 

    
    if (event.currentTarget.checked) {
      let accounts: any[] = this.revisedUserAccounts.filter(
        (x: any) => parseInt(x.generationOwnerId) === selectedUserAccount && x.roleId === selectedRole
      );   

      if (accounts && accounts.length === 1) {
        this.selectedAccountsforDelete.push(accounts[0]);
      }
    } else {     
        var found = this.selectedAccountsforDelete.findIndex((x:any) => parseInt(x.generationOwnerId) === selectedUserAccount && x.roleId === selectedRole);
       
        if (found !== -1) {
          this.selectedAccountsforDelete.splice(found, 1);
        }
    }   
   
  }

  onSelectedRoleChange(event: any){
    this.generationOwner = '00018';  
    if (event == 'anmExternalAdmin' || event == 'anmExternalViewOnly')
    {
      this.showGenOwnerOption =true;
      this.showRebateLevelOption = false;  
      this.generationOwner = 'Select Generation Owner';        
    }
    else if(event == 'anmRebateApprover')
    {
      this.showGenOwnerOption =false;
      this.showRebateLevelOption = true; 
      this.rebateApproverLevel = 'Select Approval Level';      
    }
    else{
      this.showGenOwnerOption =false;
      this.showRebateLevelOption = false;       
    }
    
    this.ValidateAddUserAccessForm();  
  }

  onSelectedOwnerChange(event: any){
    if(event == 'Select Generation Owner')   
        this.isAddUserformValid = false;  
    else
      this.isAddUserformValid = true;

      this.ValidateAddUserAccessForm();
  }

  onSelectedApprovalLevelChange(event: any){
    if(event == 'Select Approval Level')   
        this.isAddUserformValid = false;  
    else
      this.isAddUserformValid = true;    

    this.ValidateAddUserAccessForm();
  }

  ValidateAddUserAccessForm()
  {
    this.isAddUserformValid = true; 

    if (this.role == 'anmExternalAdmin' || this.role == 'anmExternalViewOnly')
    {     
      if(this.generationOwner == 'Select Generation Owner')
      {
        this.isAddUserformValid = false;  
        this.integrationnewUserAccessErrorMsg = 'Plaase select Generation Owner.';
      }
    }
    else if(this.role == 'anmRebateApprover')
    {     
      if(this.rebateApproverLevel == 'Select Approval Level')
      {
        this.isAddUserformValid = false;  
        this.integrationnewUserAccessErrorMsg = 'Plaase select a rebate approval level.';
      }
    }
    else{     
      if(this.role == 'Select Role')
      {
        this.isAddUserformValid = false; 
        this.integrationnewUserAccessErrorMsg = 'Plaase select a Role.';    
      }
    }
  }

  onCancel(){
      this.resetNewUserAccessModel()
      this.addnewUserAccess.hide();
   }


  onOpennewUserAccess(isthisDelete:boolean) {
    this.isthisDeleteProcess = isthisDelete;
    this.resetNewUserAccessModel()
    this.onSelectedRoleChange('Select Role');
    this.addnewUserAccess.show()
  }
  resetNewUserAccessModel(){
    this.role = 'Select Role';
    this.generationOwner = 'Select Generation Owner';
    this.rebateApproverLevel = 'Select Approval Level';
    this.integrationnewUserAccessErrorMsg = "";
    this.isnewAcctAPIFailure = false;
    this.showGenOwnerOption =false;
    this.showRebateLevelOption = false;    
  }

  onAddUserAccessSubmit(){
    this.integrationnewUserAccessErrorMsg = "";
    this.isthisDeleteProcess = false;
    this.isnewAcctAPIFailure = true;

    this.ValidateAddUserAccessForm();

    let CheckExistingUserAccess : any[]  = this.revisedUserAccounts.filter((x: any) => x.generationOwnerId === this.generationOwner && x.roleId === this.role)
    if(CheckExistingUserAccess.length > 0)
    {
      this.isnewAcctAPIFailure = true;
      this.integrationnewUserAccessErrorMsg = "This role is already assigned to this user";
    }
    else if (this.isAddUserformValid) {  
    this.isnewAcctAPIFailure = false;   
    
    let temp: any = {};
    temp['roleId'] = this.role;
    temp['role'] = this.userRoleOptions[this.role];
    temp['generationOwnerId'] = this.generationOwner;
    temp['generationOwner'] = this.getOwnerName(this.generationOwner);
      
    temp['status'] = 'Active'; 
    if (this.role == 'anmRebateApprover')
      temp['anmRebateApprovalLevel'] = this.rebateApproverLevel ? this.rebateApproverLevel : ""; 

      this.revisedUserAccounts.push(temp);    
      
      this.addnewUserAccess.hide();
      this.isUserAccessChanged = true;

      this.resetNewUserAccessModel();      
      this.addnewUserAccessMsgPopup.show();     
      setTimeout(() => {             
        this.addnewUserAccessMsgPopup.hide();
      }, 3000);  

    }   
  }
  onDeleteUsersAccess(isthisDelete:boolean){
    this.isthisDeleteProcess = isthisDelete;
    this.resetNewUserAccessModel()
    this.selectedAccountsList = this.selectedAccountsforDelete.map(x => x.generationOwnerId).join(', ');
    this.addnewUserAccess.show()

  }
  onDeleteUserAccessSubmit(){
    this.integrationnewUserAccessErrorMsg = "";   
    this.isnewAcctAPIFailure = false;
    this.isnewUserAccesssubmitting = false;
    this.isthisDeleteProcess = true;   
    this.selectedAccountsforDelete.forEach((userAccess: any) => {

    let index = this.revisedUserAccounts.findIndex((x:any) => x.generationOwnerId === userAccess.generationOwnerId && x.roleId === userAccess.roleId);    
    this.revisedUserAccounts.splice(index, 1);
        
    }); 

      this.addnewUserAccess.hide();
      this.isUserAccessChanged = true;

      this.resetNewUserAccessModel();  
      this.selectedAccountsforDelete = [];
      this.addnewUserAccessMsgPopup.show();     
      setTimeout(() => {             
        this.addnewUserAccessMsgPopup.hide();
      }, 3000); 

  }

  onUpdateUsersAccess(){
    this.integrationErrorMsg = "";
    this.isthisDeleteProcess = false;
  
    this.isAPIFailure = false;
    this.isnewUserAccesssubmitting = true;

    let body:any =  
      `{"userRoleUpdateBody": {
        "email": "${this.selectedUser?.userName}",
        "anmRoleMap": {
            "REPLACE": [`
       
          
 let count = 0;

        this.revisedUserAccounts.forEach((userAccess: any) => {
          
            body = body + (count > 0 ? ',':'') + `{
              "generationOwner": "${userAccess.generationOwnerId}",
              "role":  "${userAccess.roleId}",
              "status": "Active" ` ;  
        
            if(userAccess.anmRebateApprovalLevel)
              body = body +`,"anmRebateApprovalLevel" : "${userAccess.anmRebateApprovalLevel}" `;


          body = body +` } `;
          count ++;
      });

        body = body +`]
      }}}`;
  
     
      this.UsersService.updateUserRoles(body).subscribe({
        next: (res) => {
  
          if (res.success) { 
            this.isAPIFailure = false; 
            this.getUsersList();

            this.onResetUsersAccess();
            this.searchForm.reset();

          } else {
            
            this.isAPIFailure = true;
            this.integrationErrorMsg = res.errorDetails['message'];
          }

          this.addnewUserAccessMsgPopup.show();     
          setTimeout(() => {             
            this.addnewUserAccessMsgPopup.hide();
            this.isnewUserAccesssubmitting = false;
          }, 2000); 

          

        },
        error: (e: any) => {
          this.isnewUserAccesssubmitting = false;
          this.isAPIFailure = true;
         
          this.integrationErrorMsg = e.error?.errorDetails.message === "string" ? e.error?.errorDetails.message : 'Internal Error. Please try again.'
         
        }
      });
  }

  onResetUsersAccess(){
    this.resetNewUserAccessModel(); 
    this.UserAccounts = [];          
    this.revisedUserAccounts = [];
    this.selectedAccountsforDelete = [];
    this.selectedUserAccount = '';
    this.selectedUser = '';
    this.isUserAccessChanged = false;
    this.searchForm.controls?.['selectedUserId'].setValue('');
    
  }
 
}
