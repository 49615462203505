import { ExportUtility } from 'src/app/shared/utilities/utility.export';
import { GenerationUnitManagerService } from './services/generation-unit-manager.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MatPaginator } from '@angular/material/paginator';
import { FineGrainAuthorization } from 'src/app/shared/models/fine-grain-authorization';
import { GenerationOwnerManagerService } from '../generation-owner/services/generation-owner-manager.service';

@Component({
  selector: 'app-generation-unit',
  templateUrl: './generation-unit.component.html',
  styleUrls: ['./generation-unit.component.scss'],
})
export class GenerationUnitComponent implements OnInit {
  units: any[] = [];
  currentUser: any;
  includeInactiveUnits: boolean = false;
  searchString: string;
  allUnits: any[];
  isGetUnitsApiLoading: boolean = true;
  isGetUnitsApiError: boolean;
  isRemoveLoading: boolean;
  isRemoveError: boolean;
  isRemoveSuccess: boolean;
  unitTobeRemoved: any;
  removeErrorMsg: string;

  pageSize = 10;
  currentPage = 0;
  totalSize = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pagedUnits: any[];
  functionalityAccess: FineGrainAuthorization;
  selectedGenerationOwner:any;
  hasSelectedGenerationOwner:boolean = false;
  integrationErrorMsg:any;

  constructor(
    private genUnitMgr: GenerationUnitManagerService,
    private _export: ExportUtility,
    private _authService: AuthService,
    private genOwnerMgrService: GenerationOwnerManagerService,
  ) {
    this._authService.getUser().then((res) => {
      this.currentUser = res?.profile;
    });
  }

  ngOnInit(): void {
    this.isGetUnitsApiLoading = true;

    this.selectedGenerationOwner = this._authService.getSelectedGenerationOwner();
    this.hasSelectedGenerationOwner = this.selectedGenerationOwner ? true : false;

    this.getUnits(this.hasSelectedGenerationOwner?  this.genOwnerMgrService.getGenerationUnitsByOwnerId(this.selectedGenerationOwner?.ownerId) : this.genUnitMgr.getGenUnits(true) )
   
  }

  getUnits(srvc: any) {
    this.isGetUnitsApiError = false;
    srvc.subscribe({
      next: (resp:any) => {
        this.isGetUnitsApiLoading = false;
        this.allUnits = resp;

        this.allUnits.forEach((a: any) => {
          if (a.status == '2') a.statusDesc = 'Active';
          else if (a.status == '1') a.statusDesc = 'Inactive';
          else a.statusDesc = 'Pending';
        });
        this.units = this.allUnits?.filter((x) => x.status != '1');       

        let role = this._authService.getSelectedGenerationOwnerRole();
        let functionality = "GENERATION_UNITS"              
        this.functionalityAccess = this._authService.getPortalFunctioanalityAccesses(functionality, role);     
    

        if (this.hasSelectedGenerationOwner)
          this.units = this.allUnits?.filter((x) => x.owner == this.selectedGenerationOwner?.ownerId);

        this.iterator();
      },
      error: (error:any) => {
        this.isGetUnitsApiLoading = false;
        this.isGetUnitsApiError = true;
        this.integrationErrorMsg = error.error?.errorDetails ? error.error.errorDetails?.message : error.errorDescription; 
      },
    });
  }


  searchFilter() {
    let originalUnits: any[] = [];

    if (this.hasSelectedGenerationOwner)
      this.units = this.allUnits?.filter((x) => x.owner == this.selectedGenerationOwner?.ownerId);
    else
      this.units = this.allUnits;

    if (this.includeInactiveUnits) originalUnits = this.units?.filter((x) => x.status != '2');
    else originalUnits = this.units?.filter((x) => x.status == '2');

    if (this.searchString && this.searchString.trim() != '') {
      let adjustedSearch = this.searchString.trim().toLocaleLowerCase();
      this.units = originalUnits?.filter(
        (data) =>
          data.nickName?.toLocaleLowerCase()?.match(adjustedSearch) ||
          data.billAccountNumber?.toLocaleLowerCase()?.match(adjustedSearch) ||
          data.numOfSubscriptions?.toLocaleLowerCase()?.match(adjustedSearch) ||
          data.totalSubscriberUsage
            ?.toLocaleLowerCase()
            ?.match(adjustedSearch) ||
          data.unitId?.toLocaleLowerCase()?.match(adjustedSearch) ||
          data.typeDesc?.toLocaleLowerCase()?.match(adjustedSearch) ||
          data.type?.match(adjustedSearch) ||
          data.ownerName?.toLocaleLowerCase()?.match(adjustedSearch) ||
          data.fuelSourceDesc?.toLocaleLowerCase()?.match(adjustedSearch) ||
          data.powerClerk?.toLocaleLowerCase()?.match(adjustedSearch) ||
          data.statusDesc.toLocaleLowerCase().match(adjustedSearch)
      );
    } else {
      this.units = originalUnits;
    }

    this.iterator();
  }

  exportgenUnits() {
    let keyHeaders: string[] = [
      'ownerName',
      'unitID',
      'nickName',
      'numOfSubscriptions',
      'billAccountNumber',
      'capacity',
      'totalSubscriberUsage',
      'type',
      'fuelSourceDesc',
      'status',
      'powerClerk'
    ];
    let modifiedlist: any = [];
    let displayHeaders: string[] = [
      'Owner Name',
      'Unit ID',
      'Nickname',
      'Number Of Subscribers',
      'Bill Account Number',
      'Nameplate Capacity kW',
      'Total Subscribed kW',
      'Type',
      'Fuel Source',
      'Status',
      'Pwer Clerk Number',
    ];
  
      this.units.forEach((row: any) => {
        let temp: any = {};
        temp['ownerName'] = row.ownerName;
        temp['unitID'] = row.unitId;
        temp['nickName'] = row.nickName;
        temp['numOfSubscriptions'] = row.numOfSubscriptions;
        temp['billAccountNumber'] = row.billAccountNumber;
        temp['capacity'] = row.capacity;
        temp['totalSubscriberUsage'] = row.totalSubscriberUsage;
        temp['type'] = row.type;
        temp['fuelSourceDesc'] = row.fuelSourceDesc;
        temp['status'] =
          row.status == '1'
            ? 'Inactive'
            : row.status == '2'
            ? 'Active'
            : 'Pending';
        temp['powerClerk'] = row.powerClerk;

        modifiedlist.push(temp);
      });
    this._export.exportJsonToExcelFile(
      modifiedlist,
      'generationUnits',
      false,
      keyHeaders,
      displayHeaders
    );
  }

  onToggleActiveInactive() {   

    if (this.hasSelectedGenerationOwner)
        this.units = this.allUnits?.filter((x) => x.owner == this.selectedGenerationOwner?.ownerId);
    else
      this.units = this.allUnits;
  
    this.includeInactiveUnits = !this.includeInactiveUnits;    

    if (this.includeInactiveUnits) this.units = this.units?.filter((x) => x.status != '2');
    else this.units = this.units?.filter((x) => x.status == '2');

    if (this.searchString && this.searchString.trim() != '')
      this.searchFilter();

    this.iterator(); 
  }


  onRemoveClick(unit: any) {
    this.unitTobeRemoved = unit;
  }
  closeRemoveModal() {
   this.getUnits(this.hasSelectedGenerationOwner?  this.genOwnerMgrService.getGenerationUnitsByOwnerId(this.selectedGenerationOwner?.ownerId) : this.genUnitMgr.getGenUnits() );

    this.unitTobeRemoved = null;
    this.isRemoveError = false;
    this.isRemoveSuccess = false;
  }

  confirmRemoveUnit() {
    this.isRemoveLoading = true;
    const unit = {
      subscribedAllocation: this.unitTobeRemoved.subscribedAllocation
        ? +this.unitTobeRemoved.subscribedAllocation
        : 0,
      rebateCapacityQuantity: +this.unitTobeRemoved.rebateCapacityQuantity,
      rebateRequestQuantity: +this.unitTobeRemoved.rebateRequestQuantity,
      requestRebate: this.unitTobeRemoved.requestRebate,
      capacity: +this.unitTobeRemoved.capacity,
      owner: this.unitTobeRemoved.owner,
      meterNo: this.unitTobeRemoved.meterNo,
      effectiveDate: this.unitTobeRemoved.effectiveDate,
      billAccountNumber: this.unitTobeRemoved.billAccountNumber,
      powerClerk: this.unitTobeRemoved.powerClerk,
      fuelSource: this.unitTobeRemoved.fuelSource,
      rebateRateChangeCode: this.unitTobeRemoved.rebateRateChangeCode || '',
      nickName: this.unitTobeRemoved.nickName,
      unitId: this.unitTobeRemoved.unitId,
      type: this.unitTobeRemoved.type,
      status: '1',
      audit: this.currentUser?.email,
      removeUnitFlg: true,
    };

    this.genUnitMgr.updateGenUnit(unit).subscribe({
      next: (resp) => {
        this.isRemoveLoading = false;
        this.isRemoveError = false;
        this.isRemoveSuccess = true;
      },
      error: (err) => {
        this.isRemoveLoading = false;
        this.isRemoveError = true;
        this.isRemoveSuccess = false;
        if (err?.status == 400) {
          this.removeErrorMsg = err?.error?.errorDetails?.message;
        } else this.removeErrorMsg = 'Something went wrong. Please try again..';
      },
    });
  }

  handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();
  }

  onSort(){
    this.iterator();
   }

  private iterator() {
    this.totalSize = this.units?.length;
    if (this.totalSize > this.pageSize)
    {
      const end = (this.currentPage + 1) * this.pageSize;
      const start = this.currentPage * this.pageSize;
      this.pagedUnits = this.units.slice(start, end);
    }
    else{
      this.pagedUnits = this.units;
    }

  }
}
