import { GenerationOwnerManagerService } from './../../generation-owner/services/generation-owner-manager.service';
import { User } from 'oidc-client';
import { AuthService } from './../../../shared/services/auth.service';
import { GenerationUnitManagerService } from './../../generation-unit/services/generation-unit-manager.service';
import { ExportUtility } from 'src/app/shared/utilities/utility.export';
import { MatPaginator } from '@angular/material/paginator';
import { SubscriberManagerServiceService } from './../services/subscriber-manager-service.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FineGrainAuthorization } from 'src/app/shared/models/fine-grain-authorization';
import { SubscriberListModel } from '../../generation-unit/models/subsriberlist.model';

@Component({
  selector: 'app-subscriber',
  templateUrl: './subscriber.component.html',
  styleUrls: ['./subscriber.component.scss'],
})
export class SubscriberComponent implements OnInit {
  constructor(
    private _subscriberMgr: SubscriberManagerServiceService,
    private _authService: AuthService,
    private _export: ExportUtility,
    private genUnitMgr: GenerationUnitManagerService,
    private genOwnerMgrService: GenerationOwnerManagerService
  ) {
    this._authService.getUser().then((user: any) => {
      this.loggedInUserProfile = user?.profile;
    });
  }

  subscribers: SubscriberListModel[];
  allSubscribers: SubscriberListModel[];
  searchString: string;
  isSubscriberListLoading: boolean;
  isSubscriberListApiError: boolean = false;
  showInactiveSubscribers: boolean;
  selectedSubscribersToRemove: any[] = [];
  currentUser: any;
  isRemoveSuccess: boolean;
  isRemoveError: boolean;
  isRemoveLoading: boolean;
  removeEffectiveDt: any;
  removeErrorMsg: string;

  pageSize = 10;
  currentPage = 0;
  totalSize = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pagedSubscribers: any[];
  functionalityAccess: FineGrainAuthorization;
  selectedGenerationOwner: any;
  hasSelectedGenerationOwner: boolean = false;

  selectedOwnerId: string;
  loggedInUserProfile: any;
  isCRGP: boolean;

  ngOnInit(): void {
    this.selectedGenerationOwner =
      this._authService.getSelectedGenerationOwner();
    this.hasSelectedGenerationOwner = this.selectedGenerationOwner
      ? true
      : false;

    this.getSubscribers();
    this.selectedOwnerId =
      this._authService.getSelectedGenerationOwner()?.ownerId || '';
    if (
      !this.loggedInUserProfile.anmRoleMap?.filter(
        (x: any) =>
          x.role == 'anmInternalAdmin' || x.role == 'anmInternalViewOnly'
      ).length
    ) {
      this.getUnitsByOwnerId();
    }
  }

  getSubscribers() {
    this.isSubscriberListLoading = true;

    this._subscriberMgr.getSubscribers(true).subscribe({
      next: (resp: any) => {
        this.isSubscriberListLoading = false;
        this.isSubscriberListApiError = false;
        this.allSubscribers = resp;
        this.allSubscribers.forEach((sub: any, i) => {
          if (sub.statusCode == '01') sub.statusDescription = 'Inactive';
          else if (sub.statusCode == '02') sub.statusDescription = 'Active';
          else if (sub.statusCode == '03') sub.statusDescription = 'Pending';
          else sub.statusDescription = 'Finaled';

          sub['selected'] = false;
          sub['index'] = i;
        });
        this.subscribers = this.allSubscribers.filter(
          (s) => s.statusCode == '02'
        );

        let role = this._authService.getSelectedGenerationOwnerRole();
        let functionality = 'SUBSCRIBERS';
        this.functionalityAccess =
          this._authService.getPortalFunctioanalityAccesses(
            functionality,
            role
          );

        if (this.hasSelectedGenerationOwner)
          this.subscribers = this.subscribers.filter(
            (x) => x.ownerId == this.selectedGenerationOwner?.ownerId
          );

        this.iterator();
      },
    });
  }
  getUnitsByOwnerId() {
    this.genOwnerMgrService
      .getGenerationUnitsByOwnerId(this.selectedOwnerId)
      .subscribe({
        next: (ownerResp: any) => {
          if (ownerResp.filter((x: any) => x.type == 'CRGP')?.length)
            this.isCRGP = true;
        },
        error: () => {},
      });
  }

  handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();
  }
  private iterator() {
    this.totalSize = this.subscribers.length;

    if (this.totalSize > this.pageSize) {
      const end = (this.currentPage + 1) * this.pageSize;
      const start = this.currentPage * this.pageSize;
      this.pagedSubscribers = this.subscribers.slice(start, end);
    } else {
      this.pagedSubscribers = this.subscribers;
    }
  }

  exportSubscribers() {
    let keyHeaders: string[] = [];
    let modifiedlist: any = [];
    let displayHeaders: string[] = [
      'Subscriber Bill Account Number',
      'Subscriber Name',
      'Meter Number',
      'Subscribed KW',
      'Active Date',
      'Termination Date',
      'Annual Period',
      'Status',
    ];

    keyHeaders = [
      'billAccountNumber',
      'subscriberName',
      'meterNo',
      'usageQuantity',
      'effectiveDate',
      'terminationDate',
      'annualMonth',
      'statusDescription',
    ];

    this.subscribers.forEach((row: any) => {
      let temp: any = {};

      temp['billAccountNumber'] = row.billAccountNumber;
      temp['subscriberName'] = row.subscriberName;
      temp['meterNo'] = row.meterNo;
      temp['usageQuantity'] = row.usageQuantity;
      temp['effectiveDate'] = row.effectiveDate;
      if (this.showInactiveSubscribers) {
        temp['terminationDate'] = row.terminationDate;
      }
      temp['annualMonth'] = row.annualMonth;
      temp['statusDescription'] = row.statusDescription;
      if (this.showInactiveSubscribers) {
        temp['terminationDate'] = row.terminationDate;
      } else {
        temp['terminationDate'] = row.showTerminationDate
          ? row.terminationDate
          : '';
      }

      modifiedlist.push(temp);
    });
    this._export.exportJsonToExcelFile(
      modifiedlist,
      'subscribers',
      false,
      keyHeaders,
      displayHeaders
    );
  }

  onToggleActiveInactive() {
    if (this.hasSelectedGenerationOwner)
      this.subscribers = this.allSubscribers.filter(
        (x) => x.ownerId == this.selectedGenerationOwner?.ownerId
      );
    else this.subscribers = this.allSubscribers;

    this.showInactiveSubscribers = !this.showInactiveSubscribers;
    if (this.showInactiveSubscribers) {
      this.subscribers = this.subscribers.filter((a) => a.statusCode != '02');
    } else {
      this.subscribers = this.subscribers.filter((x) => x.statusCode == '02');
    }
    this.iterator();
    if (this.searchString && this.searchString.trim() != '') {
      this.searchFilter();
    }
  }

  searchFilter() {
    if (this.showInactiveSubscribers) this.subscribers = this.allSubscribers;
    else
      this.subscribers = this.allSubscribers.filter(
        (x) => x.statusCode == '02'
      );

    if (this.hasSelectedGenerationOwner)
      this.subscribers = this.subscribers.filter(
        (x) => x.ownerId == this.selectedGenerationOwner?.ownerId
      );
    else {
      let subsList = this.subscribers;
      this.subscribers = subsList;
    }

    let originalSubs: any[] = this.subscribers;

    if (this.searchString && this.searchString.trim() != '') {
      let adjustedSearch = this.searchString.trim().toLocaleLowerCase();
      this.subscribers = originalSubs.filter(
        (data) =>
          data.billAccountNumber?.toLocaleLowerCase()?.match(adjustedSearch) ||
          data.subscriberName?.toLocaleLowerCase()?.match(adjustedSearch) ||
          data.meterNo?.toLocaleLowerCase()?.match(adjustedSearch) ||
          data.usageQuantity?.toLocaleLowerCase()?.match(adjustedSearch) ||
          data.effectiveDate?.toLocaleLowerCase()?.match(adjustedSearch) ||
          data.terminationDate?.match(adjustedSearch) ||
          data.annualMonth?.toLocaleLowerCase()?.match(adjustedSearch) ||
          data.statusDescription?.toLocaleLowerCase()?.match(adjustedSearch) ||
          data.subscriberAddress?.toLocaleLowerCase()?.match(adjustedSearch)
      );
    } else {
      this.subscribers = originalSubs;
    }

    this.iterator();
  }

  selectSubscriber(sub: any) {
    sub.selected = !sub.selected;
    if (sub.selected) this.selectedSubscribersToRemove.push(sub);
    else
      this.selectedSubscribersToRemove =
        this.selectedSubscribersToRemove.filter(
          (s: any) => s.index != sub.index
        );
  }

  closeRemoveModal() {
    this.removeEffectiveDt = undefined;
    this.removeErrorMsg = '';
    this.isRemoveError = false;
    this.isRemoveSuccess = false;
    this.selectedSubscribersToRemove = [];
    this.subscribers = [];
    this.allSubscribers = [];
    this.getSubscribers();
  }

  removeSubscribers() {
    this.isRemoveLoading = true;
    let removeSubsApiBody: any[] = [];
    this.selectedSubscribersToRemove.forEach((elm: any) => {
      removeSubsApiBody.push({
        unitId: elm.unitId,
        owner: elm.ownerId,
        billAccountNumber: elm.billAccountNumber,
        subscriberName: elm.subscriberName,
        meterNo: elm.meterNo,
        effectiveDate: this.removeEffectiveDt || elm.effectiveDate,
        usageQuantity: elm.usageQuantity,
        premiseNo: elm.premiseNo,
        servicePt: elm.servicePt,
      });
    });

    this.genUnitMgr
      .removeSubscribers(removeSubsApiBody, this.loggedInUserProfile.email)
      .subscribe({
        next: () => {
          this.isRemoveLoading = false;
          this.isRemoveError = false;
          this.isRemoveSuccess = true;
        },
        error: (error) => {
          console.log(error);
          this.isRemoveLoading = false;
          this.isRemoveError = true;
          this.isRemoveSuccess = false;
          if (error?.status == 400) {
            this.removeErrorMsg = error?.error?.errorDetails?.message;
          } else {
            this.removeErrorMsg = 'Something went wrong. Please try again..';
          }
        },
      });
  }
}
