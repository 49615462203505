import { NgModule } from "@angular/core";

import { RegistrationRoutingModule } from "./registration-routing.module";
import { RegistrationComponent } from "./registration.component";
import { RegistrationSuccessComponent } from "./registration-success/registration-success.component";
import { SharedModule } from "src/app/shared/shared.module";

@NgModule({
  declarations: [RegistrationComponent, RegistrationSuccessComponent],
  imports: [SharedModule, RegistrationRoutingModule],
})
export class RegistrationModule {}
