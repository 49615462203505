import * as _ from 'lodash';

export class Profile {
  firstName?: string;
  telephoneNumber?: string;
  auditTrackingId?: string;
  isverified?: string;
  generationId?: any;
  role?: any;
  azp?: string;
  email?: string;
  isMigrated?: string;
  stateProvince?: string;
  lastName?: string;
  lastLoginTime?: string;
  isOtpEnabled?: string;
  pwdChangedTime?: string;
  selectedGeneration?: any;
  isUserWithMultiGenerationOwner?: any;
  selectedGenerationName?: string;
  selectedGenerationId?: any;
  selectedGenerationAddress?: any;
  anmRoleMap?: any;
  anmRebateApprovalLevel?: any;
  isRebateApprover?: any;

  constructor(
    firstName?: string,
    telephoneNumber?: string,
    auditTrackingId?: string,
    isverified?: string,
    role?: any,
    azp?: string,
    email?: string,
    lastName?: string,
    lastLoginTime?: string,
    pwdChangedTime?: string,
    generationId?: any,
    stateProvince?: string,
    anmRoleMap?: any
  ) {
    this.firstName = !_.isNil(firstName) ? firstName : '';
    this.telephoneNumber = !_.isNil(telephoneNumber) ? telephoneNumber : '';
    this.auditTrackingId = !_.isNil(auditTrackingId) ? auditTrackingId : '';
    this.isverified = !_.isNil(isverified) ? isverified : '';
    this.generationId = !_.isNil(this.getGenerationMapDetails(generationId))
      ? this.getGenerationMapDetails(generationId)
      : [];
    this.role = !_.isNil(role) ? this.getRole(role) : '';
    this.azp = !_.isNil(azp) ? azp : '';
    this.email = !_.isNil(email) ? email : '';
    this.stateProvince = !_.isNil(stateProvince) ? stateProvince : '';
    this.lastName = !_.isNil(lastName) ? lastName : '';
    this.lastLoginTime = !_.isNil(lastLoginTime) ? lastLoginTime : '';
    this.isOtpEnabled = !_.isNil(this.isOtpEnabled) ? this.isOtpEnabled : '';
    this.pwdChangedTime = !_.isNil(pwdChangedTime) ? pwdChangedTime : '';
    this.selectedGenerationId =
      !_.isNil(this.generationId) && this.generationId[0]
        ? this.generationId[0]
        : [];

    this.selectedGenerationName = !_.isNil(this.selectedGenerationName)
      ? this.selectedGenerationName
      : '';
    this.selectedGenerationId = !_.isNil(this.selectedGenerationId)
      ? this.selectedGenerationId
      : '';
    this.selectedGenerationAddress = !_.isNil(this.selectedGenerationAddress)
      ? this.selectedGenerationAddress
      : '';
    this.anmRoleMap = !_.isNil(anmRoleMap)
      ? this.getanmRoleMap(anmRoleMap)
      : '';

    this.anmRebateApprovalLevel = this.getANMRebateApproverLevel(
      this.anmRoleMap
    );

    this.isUserWithMultiGenerationOwner =
      !_.isNil(this.anmRoleMap) && this.anmRoleMap.length > 1 ? true : false;

    this.isRebateApprover = this.checkRebateApprover(this.anmRoleMap);
  }

  getGenerationMapDetails(GenOwnerList: GenerationRoleDetails[]) {
    if (GenOwnerList) {
      return GenOwnerList.filter(
        (value1, i, arr) =>
          arr.findIndex(
            (value2) => value2.generationOwner === value1.generationOwner
          ) === i
      );
    }
  }

  getRole(role: any) {
    let roleList: any[] = [];
    role.forEach((a: any) => {
      let roleid = a.replace(/['"]+/g, '');
      roleList.push({
        role: roleid,
        status: 'pending',
        generationOwnerId: '',
      });
    });

    return roleList;
  }

  getANMRebateApproverLevel(roleMap: any) {
    let rebateApproverLevel = '';
    roleMap.forEach((data: any) => {
      if (data.role === 'anmRebateApprover') {
        rebateApproverLevel = data.anmRebateApprovalLevel;
      } else {
        rebateApproverLevel = '';
      }
    });

    return rebateApproverLevel;
  }

  checkRebateApprover(roleMap: any[]) {
    return roleMap.some((data: any) => data.role === 'anmRebateApprover');
  }

  getanmRoleMap(rolemap: any) {
    let roleMapList: any[] = [];
    rolemap.forEach((a: any) => {
      roleMapList.push({
        generationOwner: a.generationOwner,
        role: a.role,
        status: a.status,
        anmRebateApprovalLevel:
          a.role == 'anmRebateApprover' ? a.anmRebateApprovalLevel : '',
      });
    });
    roleMapList = roleMapList.filter(
      (lst, i, arr) =>
        arr.findIndex(
          (t) =>
            t.generationOwner === lst.generationOwner && t.status === 'Active'
        ) === i
    );

    return roleMapList;
  }
}

export class GenerationRoleDetails {
  generationOwner: string;
  role: string;
  status: string;
  anmRebateApprovalLevel: string;
}
