import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { GenerationOwnerManagerService } from "./services/generation-owner-manager.service";
import { ExportUtility } from "src/app/shared/utilities/utility.export";
import { AuthService } from "src/app/shared/services/auth.service";
import { Router } from "@angular/router";
import { FineGrainAuthorization } from '../../shared/models/fine-grain-authorization';

@Component({
  selector: "app-generation-owner",
  templateUrl: "./generation-owner.component.html",
  styleUrls: ["./generation-owner.component.scss"],
})
export class GenerationOwnerComponent implements OnInit {
  constructor(
    private generationOwnerMgrService: GenerationOwnerManagerService,private router: Router,
    private _export: ExportUtility, private authService: AuthService
  ) {
    this.authService.setSelectedGenerationOwner(null);
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  allOwners: any[];
  owners: any[];
  searchString: string;
  ownerType: string = "O";
  includeInactiveOwners: boolean;
  isApiLoading: boolean;
  apiError: boolean;
  pagedOwners: any[];

  pageSize = 10;
  currentPage = 0;
  totalSize = 0;
  loggedInUser:any;
  currentUser:any;
  isInternalUser:boolean =false;
  functionalityAccess: FineGrainAuthorization; 


  ngOnInit(): void {
    this.loggedInUser = this.authService.getCurrentUser(); 

    if (this.loggedInUser != null)
      this.currentUser = this.loggedInUser.profile;

      this.currentUser.anmRoleMap = this.currentUser?.anmRoleMap.filter(
        (lst:any, i:any, arr:any) => arr.findIndex((t:any) => t.generationOwner === lst.generationOwner && t.status === 'Active') === i
      );  
      let internal_roles = this.currentUser?.anmRoleMap.filter(
        (a:any) =>
        (a.role != 'anmExternalAdmin' && a.role != 'anmExternalViewOnly')  && (a.status === 'Active')
      );     

      this.isInternalUser = internal_roles[0] != undefined && internal_roles?.length >= 0;
      let roles =  internal_roles.map((x:any) => x.role);           

     if (this.isInternalUser)
      this.authService.setSelectedGenerationOwnerRole(roles);

      this.functionalityAccess = this.authService.getPortalFunctioanalityAccesses('GENERATION_OWNERS', roles);
   
      if(!this.isInternalUser && this.currentUser?.anmRoleMap && this.currentUser?.anmRoleMap?.length == 1 )
      {
        this.router.navigateByUrl('/generation-owner/owner-dashboard?ownerId=' + this.currentUser?.anmRoleMap[0].generationOwner);        
      }    

    this.getGenerationOwnersByType();
  }

  getAllOwners() {
    this.allOwners = [];
    this.owners = [];
    this.isApiLoading = true;
    this.generationOwnerMgrService.getAllOwners().subscribe({
      next: (data) => {
        this.apiError = false;
        this.allOwners = data;
        if (this.ownerType != "-1")
          this.allOwners = this.allOwners?.filter(
            (x) => x.ownerType == this.ownerType
          );
        this.isApiLoading = false;

        this.allOwners.forEach((owner: any) => {
          owner.status =
            owner.generatingUnitsQuantity == "0" ? "inactive" : "active";
          let DER_contact = owner.contacts?.filter(
            (x: any) => x.contactType == "01"
          )[0];
          let ExSub_contact = owner.contacts?.filter(
            (x: any) => x.contactType == "09"
          )[0];
          let poSub_contact = owner.contacts?.filter(
            (x: any) => x.contactType == "06"
          )[0];

          if (DER_contact) {
            owner.contact = DER_contact;
            owner.contactType = "DER";
            owner.contact.contactTypeDecode = "DER";
          } else if (ExSub_contact) {
            owner.contact = ExSub_contact;
            owner.contact.contactTypeDecode = "ExSub";
          } else if (poSub_contact) {
            owner.contact = poSub_contact;
            owner.contact.contactTypeDecode = "PoSub";
          }
        });
        if (!this.includeInactiveOwners)
          this.allOwners = this.allOwners?.filter(
            (x) => x.generatingUnitsQuantity != "0"
          );

        this.owners = this.allOwners;
        this.iterator();
      },
      error: (error) => {
        this.apiError = false;
        this.isApiLoading = false;
      },
    });
  }

  onOwnerTypeChange() {
    if (this.ownerType == "-1") {
      this.getAllOwners();
    } else {
      this.getGenerationOwnersByType();
    }
  }

  getGenerationOwnersByType() {
    this.isApiLoading = true;
    this.generationOwnerMgrService
      .getOwnersByOwnerType(this.ownerType)
      .subscribe({
        next: (data) => {
          this.apiError = false;
          this.allOwners = data;
          if (this.ownerType != "-1")
            this.allOwners = this.allOwners?.filter(
              (x) => x.ownerType == this.ownerType
            );
          this.isApiLoading = false;

          this.allOwners.forEach((owner: any) => {
            owner.status =
              owner.generatingUnitsQuantity == "0" ? "inactive" : "active";
            let DER_contact = owner.contacts?.filter(
              (x: any) => x.contactType == "01"
            )[0];
            let ExSub_contact = owner.contacts?.filter(
              (x: any) => x.contactType == "09"
            )[0];
            let poSub_contact = owner.contacts?.filter(
              (x: any) => x.contactType == "06"
            )[0];

            if (DER_contact) {
              owner.contact = DER_contact;
              owner.contactType = "DER";
              owner.contact.contactTypeDecode = "DER";
            } else if (ExSub_contact) {
              owner.contact = ExSub_contact;
              owner.contact.contactTypeDecode = "ExSub";
            } else if (poSub_contact) {
              owner.contact = poSub_contact;
              owner.contact.contactTypeDecode = "PoSub";
            }
          });
          if (!this.includeInactiveOwners)
            this.owners = this.allOwners?.filter(
              (x) => x.generatingUnitsQuantity != "0"
            );

          this.iterator();
        },
        error: (error) => {
          this.owners = [];
          this.allOwners = [];
          this.apiError = true;
          this.isApiLoading = false;
        },
      });
  }
  onToggleActiveInactive() {
    this.includeInactiveOwners = !this.includeInactiveOwners;
    if (this.includeInactiveOwners) 
    this.owners = this.allOwners?.filter(
      (x) => x.generatingUnitsQuantity == "0"
    );
    else
      this.owners = this.allOwners?.filter(
        (x) => x.generatingUnitsQuantity != "0"
      );
      
    if (this.searchString && this.searchString.trim() != '')
      this.searchFilter();

    this.iterator();
  }

  searchFilter() {
    let originalOwners = [];

    if (this.includeInactiveOwners) 
      originalOwners = this.allOwners?.filter(
      (x) => x.generatingUnitsQuantity == "0"
    );
    else
      originalOwners = this.allOwners?.filter(
        (x) => x.generatingUnitsQuantity != "0"
      );

    if (this.searchString && this.searchString.trim() != "") {
      let adjustedSearch = this.searchString.trim().toLocaleLowerCase();
      this.owners = originalOwners?.filter(
        (data) =>
          data.ownerId.match(adjustedSearch) ||
          data.name.toString().toLowerCase().match(adjustedSearch) ||
          data.contact.contactPerson
            .toLocaleLowerCase()
            .match(adjustedSearch) ||
          data.status.toString().toLowerCase().match(adjustedSearch) ||
          data.contact.mailingAddress.addressLine1
            .toString()
            .toLowerCase()
            .match(adjustedSearch) ||
          data.contact.mailingAddress.addressLine1
            .toString()
            .toLowerCase()
            .match(adjustedSearch) ||
          data.contact.mailingAddress.addressLine2
            .toString()
            .toLowerCase()
            .match(adjustedSearch) ||
          data.contact.mailingAddress.state
            .toString()
            .toLowerCase()
            .match(adjustedSearch) ||
          data.contact.mailingAddress.city
            .toString()
            .toLowerCase()
            .match(adjustedSearch) ||
          data.contact.mailingAddress.postalCode
            .toString()
            .toLowerCase()
            .match(adjustedSearch) ||
          data.contact.email.toString().toLowerCase().match(adjustedSearch) ||
          data.contact.contactPhone.toString().match(adjustedSearch)
      );
    } else {
      this.owners = originalOwners;
    }  

    this.iterator();
  }

  exportgenOwners() {
    let keyHeaders: string[] = [];
    let modifiedlist: any = [];
    let displayHeaders: string[] = [
      "Owner ID",
      "Name",
      "Bill AccountNumber",
      "Contact Information",
      "Address",
      "Number of Generation Units",
    ];
    keyHeaders = [
      "ownerId",
      "name",
      "ownerBillAccount",
      "genOwnerContact",
      "genOwnerAddress",
      "generatingUnitsQuantity",
    ];

    this.owners.forEach((row: any) => {
      let temp: any = {};

      temp["ownerId"] = row.ownerId;
      temp["name"] = row.name;
      temp["ownerBillAccount"] = row.ownerBillAccount;
      temp["genOwnerContact"] =
        "Contact type: " +
        row.contact.contactTypeDecode +
        ",Name:" +
        row.contact.contactPerson +
        ",Phone:" +
        row.contact.contactPhone +
        ",Email:" +
        row.contact.email;
      temp["genOwnerAddress"] =
        row.contact.mailingAddress.addressLine1 +
        " " +
        row.contact.mailingAddress.addressLine2 +
        " " +
        row.contact.mailingAddress.city +
        " " +
        row.contact.mailingAddress.state +
        " " +
        row.contact.mailingAddress.postalCode +
        " " +
        row.contact.mailingAddress.country;
      temp["generatingUnitsQuantity"] = row.generatingUnitsQuantity;

      modifiedlist.push(temp);
    });
    this._export.exportJsonToExcelFile(
      modifiedlist,
      "generationOwners",
      false,
      keyHeaders,
      displayHeaders
    );
  }
  
  onSort(){
    this.iterator();
   }

  handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();
  }
  private iterator() {
    this.totalSize = this.owners?.length;
    if (this.totalSize > this.pageSize)
    {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    this.pagedOwners = this.owners.slice(start, end);
    }
    else{
      this.pagedOwners = this.owners;
    }
  }
}
