import { Component, OnInit } from '@angular/core';
import { UsersManagerService } from '../services/users-manager.service';
import { GenerationOwnerManagerService } from '../../generation-owner/services/generation-owner-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FineGrainAuthorization } from 'src/app/shared/models/fine-grain-authorization';
import { ANMUserRole, externalANMAdminRoleOptions, userRoleOptions } from 'src/app/shared/enums/anm-userrole';
import { RebateApprovalLevels, RebateApprovalLevelsMapping } from 'src/app/shared/enums/rebate-approval-level';

declare var window: any;

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  isLoading: boolean = true;
  isAPIFailure: boolean = false;
  integrationErrorMsg: string;
  userEmail:string;
  userDetails: any = "";

  updateMsg: any;
  firstName: any;
  declineReason:any;
  lastName: any;
  companyName: any;
  phoneNumber: any;
  email: any;
  role: any;
  dunsNumber: any;
  statusEdit = '';
  status: any;
  generationOwner:string = "";
  rebateApproverLevel:string = "";
  loggedInUser: any;
  user: any;
  userUpdateMsg : string;
  isEditUserformValid: boolean = false;
  isInternalUser:boolean = false;
  ownerList: any[] = [];
  functionalityAccess: FineGrainAuthorization;
  showRoleOption: boolean = false;
  showGenOwnerOption: boolean = false;
  showRebateLevelOption: boolean =false;
  public roleOptions:any  = ANMUserRole;
  private _roleOptions = Object.keys(ANMUserRole);
  private _extRoleOptions = Object.keys(externalANMAdminRoleOptions);
  
  public rebateApprovalLevelDropDownOptions :any;
  public rebateApprovalLevelOptions = Object.values(RebateApprovalLevels)
  public rebateApprovalLevelMapping : any = RebateApprovalLevelsMapping;


  constructor( private _route: ActivatedRoute, private _router: Router,private authService: AuthService, private UsersManagerService: UsersManagerService,private generationOwnerManager: GenerationOwnerManagerService) {
    this._route.queryParams.subscribe((data) => {
      this.userEmail = data['email'];    
    });

    this.loggedInUser = this.authService.getCurrentUser();    
    if (this.loggedInUser != null)
      this.user = this.loggedInUser.profile;
   }

  ngOnInit(): void {
   

     this.user.anmRoleMap = this.user?.anmRoleMap.filter(
      (lst:any, i:any, arr:any) => arr.findIndex((t:any) => t.generationOwner === lst.generationOwner && t.status === 'Active') === i
    );  
 
    let internal_roles = this.user?.anmRoleMap.filter(
      (a:any) =>
        ( a.role != 'anmExternalAdmin' && 
        a.role != 'anmExternalViewOnly') && (a.status === 'Active')
    );     

    this.isInternalUser = internal_roles[0] != undefined && internal_roles.length >= 0;

     let role = this.authService.getSelectedGenerationOwnerRole();
     let functionality = "USERS"          
    
     role = role ? role : (this.isInternalUser ? this.user?.anmRoleMap.map((x:any) => x.role) : ['anmExternalAdmin']);   
 
     this.functionalityAccess = this.authService.getPortalFunctioanalityAccesses(functionality, role);
     

     this.getGenerationOwnerList();
     this.getData(this.userEmail);

     this.updateMsg = new window.bootstrap.Modal(
      document.getElementById('updateMsg'));

  }

  getData(email:string){
    this.isLoading = true;
    this.isAPIFailure = false;
    this.firstName = '';
    this.lastName = '';
    this.companyName = '';
    this.phoneNumber = '';
    this.email = '';   
    this.status = '';
    this.declineReason = '';
    this.generationOwner = '';
    this.rebateApproverLevel = '';

    this.UsersManagerService.getUserbyEmail(email).subscribe({
      next: (res) => {
        if (res.success) {
          this.isLoading = false; 
         
          if(res.data[0]){
            const userdata = res.data[0];
            this.firstName = userdata.givenName || '';
            this.lastName = userdata.sn || '';
            this.companyName = userdata.companyName || '';
            this.phoneNumber = userdata.telephoneNumber || '';
            this.email = userdata.mail || '';           
            this.status = userdata.anmAccountStatus || '';

            this.statusEdit = this.status;
          }         

        }         
      },
      error: (e: any) => {
        this.isLoading = false;
        this.isAPIFailure = true;       
        this.integrationErrorMsg = e.error?.errorDetails ? e.error.errorDetails?.message : e.errorDescription;
        console.log(this.integrationErrorMsg);
      }
    });     
  }

  public get roles(): Array<string> {
    return this.isInternalUser ? this._roleOptions : this._extRoleOptions;
  }
  
  getGenerationOwnerList() {
    this.ownerList = [];

    let genOwners = this.user?.anmRoleMap ? this.user?.anmRoleMap.filter((a:any) =>
    (a.role === 'anmExternalAdmin') && (a.status === 'Active')).map((x:any) => x.generationOwner).join(',') : '00018';

    this.generationOwnerManager.getAllOwners().subscribe({
      next: (data: any) => {  
        this.ownerList = data.sort((a: any, b: any) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });  

        if (!this.isInternalUser) 
        {
          this.ownerList = this.ownerList?.filter(
            (a:any) =>
            (genOwners?.includes(a.ownerId)) 
          );

        }
            
      },
      error: (error: any) => {
        console.log('error', error);       
      },
    });
  }

  changeUserStatus(body: any) {  
  
    this.isLoading = true;
    this.userUpdateMsg = "";
    if(this.isEditUserformValid)
    {    
     
    this.UsersManagerService.updateUserStatus(body).subscribe({
      next: (res) => {
      if (!res.success) {       
        this.isAPIFailure = true;
        this.isLoading = false;
        this.integrationErrorMsg = res.errorDetails;
      }
      else {       
        if (this.status == 'Active')
        {
          let body:any =  
          `{"userRoleUpdateBody": {
            "email": "${this.email}",
            "anmRoleMap": {
              "REPLACE": [{
                "generationOwner": "${this.generationOwner}",
                "role":  "${this.role}",
                "status": "Active" `;
           

            if (this.rebateApproverLevel)
              body = body +`,"anmRebateApprovalLevel" : "${this.rebateApproverLevel}" `;

            body = body + `} ]}}}`;
  
            this.UsersManagerService.updateUserRoles(body).subscribe({
              next: (res) => {
        
                if (res.success) {        
                  this.isLoading = false;                  
                  this.isAPIFailure = false;                
                  this.userUpdateMsg = 'User updated succesfully';        
                  
                } else {
                  this.isLoading = false; 
                  this.isAPIFailure = true;
                  this.integrationErrorMsg = res.errorDetails['message'];
                }  
            
              },
              error: (e: any) => {               
                this.isAPIFailure = true;
                this.isLoading = false; 
               
                this.integrationErrorMsg = e.error?.errorDetails.message === "string" ? e.error?.errorDetails.message : 'Internal Error. Please try again.'
               
              }
            });
        }
        else{
          this.isLoading = false;                  
          this.isAPIFailure = false;                
          this.userUpdateMsg = 'User updated succesfully';   

         

        }
        this.updateMsg.show();
          setTimeout(() => {        
            this.closeSaveModal();       
         }, 2000);
      }
     
    },     
    error: (error: any) => {
      this.isLoading = false;     
      this.isAPIFailure = true;
      this.userUpdateMsg = 'Failed to update user status';
      this.integrationErrorMsg = error.error?.errorDetails ? error.error.errorDetails?.message : error.errorDescription; 
      console.log(this.integrationErrorMsg);

      if (!this.integrationErrorMsg)
      this.integrationErrorMsg = 'Something went wrong. Please try again.';
    }   
  });   
}   
     
  }

   activateInactivateUser(status: string, mail: string, reason?:string) { 
    const body = {
        "email": mail,
        "stateProvince": "IL",
        "anmAccountStatus": status,
        "comments": reason,
      }  

      this.ValidateAddUserAccessForm();

      if (this.isEditUserformValid)
        this.changeUserStatus(body); 
    
  }

  closeSaveModal() {
    this.isLoading = false;   
    this.isAPIFailure = false;
    this.userUpdateMsg = '';
    this.updateMsg.hide(); 
    this._router.navigateByUrl('/users');  
  }

  
  ValidateAddUserAccessForm()
  {
    this.isEditUserformValid = true; 
    if (this.status == 'Active')
    {
    if (this.role == 'anmExternalAdmin' || this.role == 'anmExternalViewOnly')
    {     
      if(this.generationOwner == 'Select Generation Owner' )
      {
        this.isEditUserformValid = false;  
        this.integrationErrorMsg = 'Please select Generation Owner.';
      }
    }
    else if(this.role == 'anmRebateApprover' )
    {     
      if(this.rebateApproverLevel == 'Select Approval Level')
      {
        this.isEditUserformValid = false;  
        this.integrationErrorMsg = 'Please select a rebate approval level.';
      }
    }
    else if(!this.role || this.role == 'Select Role')
      {
        this.isEditUserformValid = false; 
        this.integrationErrorMsg = 'Please select a Role.';    
      }
    }
    else{
      if(this.declineReason == '')
      {
        this.isEditUserformValid = false; 
        this.integrationErrorMsg = 'Please enter a decline reason.';    
      }
    }
    

    if(!this.isEditUserformValid)
      this.isAPIFailure = true;
  }

  onSelectedRoleChange(event: any){
    this.generationOwner = '00018';  
    if (event == 'anmExternalAdmin' || event == 'anmExternalViewOnly')
    {
      this.showGenOwnerOption =true;
      this.showRebateLevelOption = false;  
      this.generationOwner = 'Select Generation Owner';        
    }
    else if(event == 'anmRebateApprover')
    {
      this.showGenOwnerOption =false;
      this.showRebateLevelOption = true; 
      this.rebateApproverLevel = 'Select Approval Level';      
    }
    else{
      this.showGenOwnerOption =false;
      this.showRebateLevelOption = false;       
    }    
    this.integrationErrorMsg = '';
    this.isAPIFailure = false;  
  }

  onSelectedOwnerChange(event: any){
    if(event == 'Select Generation Owner')   
        this.isEditUserformValid = false;  
    else
      this.isEditUserformValid = true;

      this.integrationErrorMsg = '';
      this.isAPIFailure = false;    
  }

  onSelectedApprovalLevelChange(event: any){
    if(event == 'Select Approval Level')   
        this.isEditUserformValid = false;  
    else
      this.isEditUserformValid = true;    

      this.integrationErrorMsg = '';
      this.isAPIFailure = false;  
  }


}
