import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { EnumRole } from "src/app/shared/enums/role";
import { Profile } from "src/app/shared/models/profile";
import { ProfileManagerService } from "src/app/shared/services/profilemanager.service";
import { AuthService } from "../../shared/services/auth.service";


@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  isAuthenticated: boolean;
  currentUser: any;
  _profile: Profile[];
  isAdmin: boolean = false;

  @Input() login_menu: boolean;
  constructor(
    private authService: AuthService,
    private profileManager: ProfileManagerService,
    private router: Router
  ) {
    this.authService.loginChanged.subscribe((t) => {
      this.isAuthenticated = t;
    });

    this.authService.userLoaded.subscribe((user) => {
      this.currentUser = user;
      this._profile = [];
      if (this.currentUser) {
        this._profile.push(
          new Profile(
            this.currentUser.profile.firstname,
            this.currentUser.profile.telephoneNumber,
            this.currentUser.profile.auditTrackingId,
            this.currentUser.profile.isverified,
            this.currentUser.profile.role,
            this.currentUser.profile.azp,
            this.currentUser.profile.email,
            this.currentUser.profile.lastname,
            this.currentUser.profile.lastLoginTime,
            this.currentUser.profile.pwdChangedTime,
            this.currentUser.profile.generationId,
            this.currentUser.profile.stateProvince,
            this.currentUser.profile.anmRoleMap
          )
        );
        this.profileManager.profile = this._profile;
      }
    });

    this.authService.getUser().then((user: any) => {
      let userRole: any[] = [];
      userRole = user?.profile?.anmRoleMap;
      if (userRole) {
        let admin_roles = userRole.filter(
          (a) =>
            a.role === EnumRole.EXTERNAL_ADMIN ||
            a.role === EnumRole.INTERNAL_ADMIN ||
            a.role === EnumRole.INTERNAL_VIEW_ONLY
        );

        if (admin_roles && admin_roles.length > 0) {
          this.isAdmin = true;
        } else {
          this.isAdmin = false;
        }
      }
    });
  }

  ngOnInit(): void {
    this.authService.isLoggedIn().then((flag) => (this.isAuthenticated = flag));
  }

  login() {
    this.authService.Login();
  }
  logout() {
    this.authService.Logout();
  }

  goToPath() {
    if (this.isAdmin) {
      this.router.navigate(["administration"]);
    } else {
      this.router.navigate(["agency-pledges"]);
    }
  }
}
