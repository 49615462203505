<div class="rebate-container">
  <div class="mb-5">
    <div class="rebate-header">Manage Rebates</div>
    <div class="col-md-12">
      <em>
        Disclaimer: COGF Participating Customers will be referred to throughout
        this portal as Subscribers.
      </em>
    </div>
  </div>
  <div class="row rebate_search_container mb-3">
    <div class="col-md-2"></div>
    <div class="col-md-2 form-check">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="includeInactiveUnits"
        [checked]="showinactiveRebates"
        (click)="onToggleActiveInactive()"
      />
      <label class="form-check-label" for="inactiveOwners">
        Show Inactive Rebates
      </label>
    </div>

    <div class="col-md-4">
      <div class="d-flex flex-row rebate_owner__search">
        <div class="input-group search_button">
          <input
            type="search"
            class="form-control mt-0"
            placeholder="Search"
            aria-label="searchAmerenUser"
            [(ngModel)]="searchString"
            (ngModelChange)="searchFilter()"
            aria-describedby="searchAmerenUser"
          />
          <span
            class="input-group-text"
            id="searchAmerenUser"
            (click)="searchFilter()"
            ><em class="fa fa-search"></em
          ></span>
        </div>
      </div>
    </div>
    <div class="col-md-2 text-right">
      <button
        type="button"
        class="btn btn-success owner_export"
        (click)="exportRebates()"
      >
        <img
          src="assets/icon/excelicon.svg"
          alt="CalendarImage"
          width="22"
          height="19"
        />
        Export
      </button>
    </div>
  </div>

  <div class="mt-3">
    <mat-paginator
      *ngIf="rebates && rebates.length > 9"
      #paginator
      [pageSize]="pageSize"
      [pageSizeOptions]="[10]"
      [showFirstLastButtons]="true"
      [length]="totalSize"
      [pageIndex]="currentPage"
      (page)="handlePage($event)"
    >
    </mat-paginator>
  </div>
  <table class="table rebate_grid mt-4" aria-describedby="Generation Unit">
    <thead>
      <tr>
        <th scope="col">
          <span
            class="table-head"
            [appSort]="rebates"
            (sortComplete)="onSort()"
            data-order="asc"
            data-name="ownerName"
            >Owner Name <em class="fa-solid fa-sort"></em
          ></span>
        </th>
        <th scope="col">
          <span
            class="table-head"
            [appSort]="rebates"
            (sortComplete)="onSort()"
            data-order="asc"
            data-name="unitId"
            >Unit ID <em class="fa-solid fa-sort"></em
          ></span>
        </th>
        <th scope="col">
          <span
            class="table-head"
            [appSort]="rebates"
            (sortComplete)="onSort()"
            data-order="asc"
            data-name="billAccountNumber"
            >Bill Account Number <em class="fa-solid fa-sort"></em
          ></span>
        </th>
        <th scope="col">
          <span
            class="table-head"
            [appSort]="rebates"
            (sortComplete)="onSort()"
            data-order="asc"
            data-name="nickName"
            >Nick Name <em class="fa-solid fa-sort"></em
          ></span>
        </th>
        <th scope="col">
          <span
            class="table-head"
            [appSort]="rebates"
            (sortComplete)="onSort()"
            data-order="asc"
            data-name="rebateCapacityQuantity"
            >Rebate Capacity <em class="fa-solid fa-sort"></em
          ></span>
        </th>
        <th scope="col">
          <span
            class="table-head"
            [appSort]="rebates"
            (sortComplete)="onSort()"
            data-order="asc"
            data-name="rebateRequestQuantity"
            >Rebate Request <em class="fa-solid fa-sort"></em
          ></span>
        </th>
        <th scope="col">
          <span
            class="table-head"
            [appSort]="rebates"
            (sortComplete)="onSort()"
            data-order="asc"
            data-name="rebateEligibleQuantity"
            >Rebate Eligible <em class="fa-solid fa-sort"></em
          ></span>
        </th>
        <th scope="col">
          <span
            class="table-head"
            [appSort]="rebates"
            (sortComplete)="onSort()"
            data-order="asc"
            data-name="rebateNotSubscribedQuantity"
            >Rebate Not Requested <em class="fa-solid fa-sort"></em
          ></span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngIf="
          !isRebatesApiLoading &&
          !isRebatesApiError &&
          (!rebates || !rebates.length)
        "
      >
        <td colspan="12" class="text-center">No data</td>
      </tr>
      <tr *ngIf="isRebatesApiError">
        <td colspan="12" class="text-center">
          Something went wrong. Please try again.
        </td>
      </tr>
      <tr *ngIf="isRebatesApiLoading">
        <td colspan="12" class="text-center">
          <p>
            Data Loading: Thanks for your patience as we download your requested
            data.
          </p>
          <ameren-loading></ameren-loading>
        </td>
      </tr>
      <tr *ngFor="let rebate of pagedRebates">
        <td>
          {{ rebate.ownerName }}
        </td>
        <td>
          {{ rebate.unitId }}
        </td>
        <td>
          {{ rebate.billAccountNumber }}
        </td>
        <td>
          <a
            [routerLink]="['rebate-detail']"
            [queryParams]="{
              uId: rebate.unitId,
              oId: rebate.owner,
              ba: rebate.billAccountNumber
            }"
            class="rebate_nickName"
            *ngIf="functionalityAccess.allowRebateDetail || isRebateApprover"
          >
            <i class="fa fa-edit"></i>
            {{ rebate.nickName }}
          </a>
          <span
            *ngIf="!functionalityAccess.allowRebateDetail && !isRebateApprover"
          >
            {{ rebate.nickName }}
          </span>
        </td>
        <td>
          {{ rebate.rebateCapacityQuantity }}
        </td>
        <td>
          {{ rebate.rebateRequestQuantity }}
        </td>
        <td>{{ rebate.rebateEligibleQuantity }}</td>
        <td>{{ rebate.rebateNotSubscribedQuantity }}</td>
      </tr>
    </tbody>
  </table>
</div>
