<div class="manageview__profile_container">
  <p class="profile__title">Profile Information</p>
  <div class="view__profile__container">
    <div class="profile__initials">{{ userInitials }}</div>
    <p class="profile__name">{{ firstName }} {{ lastName }}</p>
    <p>
      {{ emailAddress }}
    </p>
    <hr />
    <p>{{ userRole }}</p>
   
    <button (click)="EditUserProfile()">Edit Profile</button>
  </div>
</div>
