<div class="anm__users-container">
  <div class="col-md-6 Users_page_header">Edit User</div>
  <div *ngIf="isAPIFailure" class="mt-3 alert alert-danger" role="alert">
    {{ integrationErrorMsg }}
  </div>

  <div
    class="d-flex flex-row justify-content-center col-md-12"
    *ngIf="isLoading"
  >
    <p class="userlables">
      {{
        statusEdit == '' || status == 'Pending'
          ? 'Loading user details'
          : 'Updating user details'
      }}
      , Please wait...
    </p>
    <ameren-loading class="d-flex justify-content-center"></ameren-loading>
  </div>

  <div *ngIf="!isLoading">
    <div class="row mt-2 col-md-6">
      <label>First Name</label>
      <input
        type="text"
        [(ngModel)]="firstName"
        class="form-control"
        disabled
      />
    </div>
    <div class="row mt-2 col-md-6">
      <label>Last Name</label>
      <input type="text" [(ngModel)]="lastName" class="form-control" disabled />
    </div>

    <div class="row mt-2 col-md-6">
      <label>Company Name</label>
      <input
        type="text"
        [(ngModel)]="companyName"
        class="form-control"
        disabled
      />
    </div>
    <div class="row mt-2 col-md-6">
      <label>Phone Number</label>
      <input
        type="text"
        [(ngModel)]="phoneNumber"
        class="form-control"
        disabled
      />
    </div>

    <div class="row mt-2 col-md-6">
      <label>Email</label>
      <input type="text" [(ngModel)]="email" class="form-control" disabled />
    </div>

    <div class="row mt-2 col-md-6">
      <div class="row mt-4" *ngIf="statusEdit == 'Pending'">
        <div
          class="custom-control custom-radio custom-control-inline w-auto col-md-2"
        >
          <input
            id="Approved"
            type="radio"
            class="custom-control-input mr-2"
            value="Active"
            name="type"
            [(ngModel)]="status"
          />
          <label class="custom-control-label" for="Approved">Approved</label>
        </div>
        <div
          class="custom-control custom-radio custom-control-inline w-auto col-md-2"
        >
          <input
            id="Denied"
            type="radio"
            class="custom-control-input mr-2"
            value="Inactive"
            name="type"
            [(ngModel)]="status"
          />
          <label class="custom-control-label" for="Denied">Denied</label>
        </div>
      </div>
      <div class="row mt-4 col-md-6" *ngIf="statusEdit != 'Pending'">
        <div
          class="custom-control custom-radio custom-control-inline w-auto col-md-2"
        >
          <input
            id="Active"
            type="radio"
            class="custom-control-input mr-2"
            value="Active"
            name="type"
            [(ngModel)]="status"
          />
          <label class="custom-control-label" for="Active">Active</label>
        </div>
        <div
          class="custom-control custom-radio custom-control-inline w-auto col-md-2"
        >
          <input
            id="Inactive"
            type="radio"
            class="custom-control-input mr-2"
            value="Inactive"
            name="type"
            [(ngModel)]="status"
          />
          <label class="custom-control-label" for="Inactive">Inactive</label>
        </div>
      </div>

      <div class="row mt-2" *ngIf="status == 'Active'">
        <label>Role:</label>
        <!-- <label class="col-md-4 text-bold">Role: </label><br> -->
        <select
          [(ngModel)]="role"
          class="role-dropdown"
          (ngModelChange)="onSelectedRoleChange($event)"
        >
          <option [value]="">Select Role</option>
          <option *ngFor="let opt of roles; let i = index" [value]="opt">
            {{ roleOptions[opt] }}
          </option>
        </select>
      </div>

      <div class="row mt-2" *ngIf="status == 'Active' && showGenOwnerOption">
        <!-- <label class="col-md-4 text-bold">Generation Owner: </label><br> -->
        <label>Generation Owner:</label>
        <select
          [(ngModel)]="generationOwner"
          class="role-dropdown"
          (ngModelChange)="onSelectedOwnerChange($event)"
        >
          <option [value]="">Select Generation Owner</option>
          <option
            *ngFor="let owners of ownerList; let i = index"
            [value]="owners.ownerId"
          >
            {{ owners.name }} ({{ owners.ownerId }})
          </option>
        </select>
      </div>
      <div class="row mt-2" *ngIf="status == 'Active' && showRebateLevelOption">
        <!-- <label class="col-md-4 text-bold">Rebate Approval Level: </label><br> -->
        <label>Rebate Approval Level:</label>
        <select
          [(ngModel)]="rebateApproverLevel"
          class="role-dropdown"
          (ngModelChange)="onSelectedApprovalLevelChange($event)"
        >
          <option [value]="">Select Approval Level</option>
          <option
            *ngFor="let lvl of rebateApprovalLevelOptions; let i = index"
            [value]="lvl"
          >
            {{ rebateApprovalLevelMapping[lvl] }}
          </option>
        </select>
      </div>
    </div>

    <div class="row mt-3 col-md-6" *ngIf="status == 'Inactive'">
      <div class="col-md-12">
        <label>Decline Reason</label>
        <textarea
          rows="2"
          cols="50"
          [(ngModel)]="declineReason"
          class="form-control"
        ></textarea>
      </div>
    </div>

    <div class="row mt-3 col-md-6 justify-content-center">
      <div class="col-md-6 d-flex" *ngIf="!isLoading">
        <a [routerLink]="['/users']" class="btn btn-default edit_user_btn edit_user_cancel_btn mx-3"
          >Cancel</a
        >
        <button
          class="btn btn-success edit_user_btn ms-3"
          (click)="activateInactivateUser(status, email, declineReason)"
          [disabled]="status == 'Pending'"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade modal-center"
  id="updateMsg"
  tabindex="-1"
  aria-labelledby="updateMsg"
  aria-hidden="true"
>
  <div class="modal-dialog" id="updateStatusDialog">
    <div class="modal-content" id="updateStatusContent">
      <div class="modal-header justify-content-center border-0">
        <h5
          class="modal-title text-success save_user_status_modal_title"
          id="saveGenUnitModalLabel"
        >
          {{ !isAPIFailure ? 'Success' : 'Failure' }}
        </h5>
        <button
          type="button"
          (click)="closeSaveModal()"
          *ngIf="!isAPIFailure"
          class="btn-close user_status_modal_close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <hr />
      <div class="modal-body text-center">
        <div class="row text-center">
          <div
            class="text-danger text-center justify-content-center"
            *ngIf="isAPIFailure"
          >
            <img src="../../../assets/images/failure.svg" alt="success" />
            <p class="mt-2">Failed to update user status information.</p>
          </div>
          <div
            class="text-success text-center justify-content-center"
            *ngIf="!isAPIFailure"
          >
            <img src="../../../assets/images/success.svg" alt="success" />
            <p class="mt-2">{{ userUpdateMsg }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
