import { Banking } from 'src/app/shared/models/banking';
import { catchError } from 'rxjs/operators';
import { GenerationOwnerApiService } from './generation-owner-api.service';
import { Injectable } from '@angular/core';
import { forkJoin, throwError, map } from 'rxjs';
import { AmerenError } from 'src/app/shared/models/amerenError';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';
import { CacheService } from 'src/app/shared/services/cache.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class GenerationOwnerManagerService {
  totalOwnersList: any[] = [];
  currentUser: any;
  loggedInUser: any;

  constructor(
    private generationOwnerService: GenerationOwnerApiService,
    private cache: CacheService,
    private authService: AuthService
  ) {
    this.loggedInUser = this.authService.getCurrentUser();

    if (this.loggedInUser != null) this.currentUser = this.loggedInUser.profile;
  }

  getAllOwners() {
    return this.generationOwnerService.getAllOwners().pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Generation Owners cannot be retrieved',
            400,
            null,
            null,
            null
          );
        }
        let ownerAccess =
          this.authService.getCurrentUserGenerationOwnersAccess();

        return ownerAccess.includes('00018')
          ? res.data
          : res.data?.filter((a: any) => ownerAccess?.includes(a.ownerId));
      }),
      catchError((error: any) => {
        console.log('err', error);
        return throwError(() => error);
      })
    );
  }

  get3rdPartyOwners() {
    return this.generationOwnerService.get3rdPartyOwners().pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Generation Owners cannot be retrieved',
            400,
            null,
            null,
            null
          );
        }

        return res.data;
      }),
      catchError((error: any) => {
        console.log('err', error);
        return throwError(() => error);
      })
    );
  }

  getOwnersList() {
    return forkJoin([this.get3rdPartyOwners(), this.getAllOwners()]).pipe(
      map((res: IGlobalAPIResponse[]) => {
        this.handleOwnersList(res);

        return this.totalOwnersList;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  handleOwnersList(res: any) {
    this.totalOwnersList = [];

    if (res[0]) {
      res[0].forEach((data: any) => {
        this.totalOwnersList.push(data);
      });
    }

    if (res[1]) {
      res[1].forEach((data: any) => {
        this.totalOwnersList.push(data);
      });
    }

    return this.totalOwnersList;
  }

  getOwnersByOwnerId(ownerId: string) {
    return this.generationOwnerService.getOwnersByOwnerId(ownerId).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Generation Owners by Owner ID cannot be retrieved',
            400,
            null,
            null,
            null
          );
        }
        return res.data;
      }),
      catchError((error: any) => {
        console.log('err', error);
        return throwError(() => error);
      })
    );
  }

  getOwnersByOwnerType(ownerType: string) {
    return this.generationOwnerService.getOwnersByOwnerType(ownerType).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Generation Owners by Owner Type cannot be retrieved',
            400,
            null,
            null,
            null
          );
        }

        return res.data;
      }),
      catchError((error: any) => {
        console.log('err', error);
        return throwError(() => error);
      })
    );
  }

  getGenerationUnitsByOwnerId(ownerId: string) {
    return this.generationOwnerService
      .getGenerationUnitsByOwnerId(ownerId)
      .pipe(
        map((res: IGlobalAPIResponse) => {
          if (!res.success) {
            throw new AmerenError(
              'Generation Units by OwnerId cannot be retrieved',
              400,
              null,
              null,
              null
            );
          }

          return res.data;
        }),
        catchError((error: any) => {
          console.log('err', error);
          return throwError(() => error);
        })
      );
  }

  getLocationByGenerationOwner(ownerid: any) {
    return this.generationOwnerService
      .getLocationByGenerationOwner(ownerid)
      .pipe(
        map((res: IGlobalAPIResponse) => {
          if (!res.success) {
            throw new AmerenError(
              'Location by Generation Owner has failed',
              400,
              null,
              null,
              null
            );
          }

          return res.data;
        }),
        catchError((error: any) => {
          return throwError(() => error);
        })
      );
  }

  getOwnerBankingInfo(ownerId: string) {
    return this.generationOwnerService.gtOwnerBankingInfo(ownerId).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          return new Banking();
        }
        return res.data;
      }),
      catchError((error: any) => {
        console.log('err', error);
        throw new AmerenError(
          'Generation Owner banking info cannot be retrieved',
          400,
          null,
          null,
          null
        );
      })
    );
  }

  updateOwnerBankingInfo(banking: any, ownerId: string, audit: string) {
    return this.generationOwnerService
      .updateBankingInfo(banking, ownerId, audit)
      .pipe(
        map((res: IGlobalAPIResponse) => {
          if (!res.success) {
            throw new AmerenError(
              'Generation Owner banking info cannot be updated',
              400,
              null,
              null,
              null
            );
          }

          return res.data;
        }),
        catchError((error: any) => {
          return throwError(() => error);
        })
      );
  }

  updateGenerationOwner(owner: any, audit: string) {
    return this.generationOwnerService.updateGenOwner(owner, audit).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Generation Owner cannot be updated',
            400,
            null,
            null,
            null
          );
        }
        return res.data;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  addGenerationOwner(owner: any, audit: string) {
    return this.generationOwnerService.addGenOwner(owner, audit).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Generation Owner cannot be added',
            400,
            null,
            null,
            null
          );
        }
        return res.data;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }
}
