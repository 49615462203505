import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UsersRoutingModule } from "./users-routing.module";
import { InternalusersComponent } from "./internalusers/internalusers.component";
import { ExternalusersComponent } from "./externalusers/externalusers.component";
import { SharedModule } from "src/app/shared/shared.module";
import {MatCardModule} from '@angular/material/card';
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { EditUserComponent } from './edit-user/edit-user.component';

@NgModule({
  declarations: [InternalusersComponent, ExternalusersComponent, EditUserComponent],
  imports: [CommonModule, UsersRoutingModule,SharedModule,MatCardModule,MatButtonToggleModule],
})
export class UsersModule {}
