import { ReportsManagerService } from './../services/reports-manager.service';
import { ExportUtility } from './../../../shared/utilities/utility.export';
import { GenerationOwnerManagerService } from "./../../generation-owner/services/generation-owner-manager.service";
import { SubscriberManagerServiceService } from "./../../subscriber/services/subscriber-manager-service.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-gen-unit-sub-billaccount-report",
  templateUrl: "./gen-unit-sub-billaccount-report.component.html",
  styleUrls: ["./gen-unit-sub-billaccount-report.component.scss"],
})
export class GenUnitSubBillaccountReportComponent implements OnInit {
  constructor(
    private subMgr: SubscriberManagerServiceService,
    private genOwnerMgr: GenerationOwnerManagerService,
    private reportMgrSvc: ReportsManagerService ,
    private _export: ExportUtility
  ) {}

  subscribers: any[];
  billAccounts: any[];
  meters: any[];
  allOwners: any[];

  owners: any[];

  selectedAccount: any;
  selectedOwner: any;
  selectedMeter: any;

  isReportCrieteriaLoading: boolean;
  dtBegin: string;
  dtEnd:string;

  startDates: any[]=[];
  endDates: any[]=[];
  searchClicked: boolean;
  reportData: any[];
  isReportLoading: boolean;
  ngOnInit(): void {
    this.getSubscribers();
    this.getGenOwners();
  }

  getSubscribers() {
    this.subMgr.getSubscribers().subscribe({
      next: (resp) => {      

        this.subscribers = resp?.filter(
          (s:any) => s.statusCode == "02"
        ); 
      },
    });
  }

  getGenOwners() {
    this.genOwnerMgr.getAllOwners().subscribe({
      next: (resp: any) => {
        this.allOwners = resp;
        this.owners = this.allOwners;
      },
    });
  }

  onBillAccountChange() {
  
    this.owners = this.allOwners.filter(
      (x) => x.ownerId == this.selectedAccount.ownerId
    );
    if(this.owners.length == 1)
    this.selectedOwner = this.owners[0];
    this.meters = this.subscribers.filter(
      (x) => x.billAccountNumber == this.selectedAccount.billAccountNumber
    );
  }
  onMeterChange() {
    this.isReportCrieteriaLoading = true;
    this.reportMgrSvc.getBillPeriods(this.selectedAccount.billAccountNumber,this.selectedMeter.servicePt).subscribe({
      next: (resp)=>{
        this.isReportCrieteriaLoading = false;
        resp.forEach((elm:any) => {
          this.startDates.push(elm.readingFromDate);
          this.endDates.push(elm.readingToDate)
        });
      }
    })
  }

  onSearchClick(){
    this.isReportLoading = true;
    this.searchClicked = true;
    this.reportMgrSvc.getGenUnitSubBillAccountReport(this.selectedAccount.billAccountNumber,this.dtBegin, this.dtEnd, this.selectedOwner.ownerId).subscribe({
      next: (resp)=>{
        this.isReportLoading = false;
        this.reportData = resp;
      },
      error:(err)=>{
        this.isReportLoading = false;
        if(err && err?.error && err?.error?.code == 400 && err?.error?.errorDetails){
          this.reportData = [];
        }
      }
    })
  }

  exportReport() {
    let keyHeaders: string[] = [];
    let modifiedlist: any = [];
    let displayHeaders: string[] = [
      "Bill Account",
      "Subscriber Name",
      "Meter Number",
      "Generation Owner",
      "Unit Nick Name",
      "Date Reading From",
      "Date Reading To",
      "KWH to Subscriber",
      "Bill Saving Rate",
      "Monetory Savings",
      "Sub Fees Billed"
    ];

    keyHeaders = [
      "billAccountNumber",
      "subscriberName",
      "meterNo",
      "ownerName",
      "nickName",
      "readingFromDate",
      "readingToDate",
      "subscribeQuantity",
      "billSavingsRate",
      "billSavingsAmount",
      "subFeesBilled"
    ];

    this.reportData.forEach((row: any) => {
      let temp: any = {};

      temp["billAccountNumber"] = row.billAccountNumber;
      temp["subscriberName"] = row.subscriberName;
      temp["meterNo"] = row.meterNo;
      temp["ownerName"] = row.ownerName;
      temp["nickName"] = row.nickName;

      temp["readingFromDate"] = row.readingFromDate;
      temp["readingToDate"] = row.readingToDate;
      temp["subscribeQuantity"] = row.subscribeQuantity;

      temp["billSavingsRate"] = row.billSavingsRate;
      temp["billSavingsAmount"] = row.billSavingsAmount;

      temp["subFeesBilled"] = row.subFeesBilled;



      modifiedlist.push(temp);
    });
    this._export.exportJsonToExcelFile(
      modifiedlist,
      "generation unit subscriber bill account report",
      false,
      keyHeaders,
      displayHeaders
    );
  }
}
