import { ReportsManagerService } from '../services/reports-manager.service';
import { Component, OnInit } from '@angular/core';
import { GenerationOwnerManagerService } from '../../generation-owner/services/generation-owner-manager.service';
import { ExportUtility } from 'src/app/shared/utilities/utility.export';

@Component({
  selector: 'app-gen-unit-sub-daily-usage',
  templateUrl: './gen-unit-sub-daily-usage.component.html',
  styleUrls: ['./gen-unit-sub-daily-usage.component.scss'],
})
export class GenUnitSubDailyUsageComponent implements OnInit {
  genOwners: any[];
  selectedOwner: any;
  cssDate: string;
  minDate: string;
  maxDate: string;
  reportData: any[];
  units: any[];
  selectedUnit: any;
  isApiLoading: boolean;
  isReportLoading: boolean;
  searchClicked: boolean;
  dtBegin: string;
  dtEnd: string;
  isGenUnitSubDailyUsageAPIFailure: boolean;

  constructor(
    private reportsManager: ReportsManagerService,
    private genOwnerMgr: GenerationOwnerManagerService,
    private _export: ExportUtility
  ) {}

  ngOnInit(): void {
    this.getOwners();
  }

  getOwners() {
    this.genOwnerMgr.getAllOwners().subscribe({
      next: (resp) => {
        this.genOwners = resp;
      },
    });
  }

  onOwnerChange() {
    this.selectedUnit = undefined;
    this.getUnitsByOwner();
  }

  getUnitsByOwner() {
    this.isApiLoading = true;
    this.genOwnerMgr
      .getGenerationUnitsByOwnerId(this.selectedOwner.ownerId)
      .subscribe({
        next: (resp) => {
          this.isApiLoading = false;
          this.units = resp;
          if (this.units.length == 1) {
            this.selectedUnit = this.units[0];
          }
        },
        error: (error: any) => {
          this.isApiLoading = false;
        },
      });
  }

  search() {
    this.searchClicked = true;
    this.isReportLoading = true;
    this.isGenUnitSubDailyUsageAPIFailure = false;
    this.reportsManager
      .getGenUnitSubDailyUsage(
        this.dtBegin,
        this.dtEnd,
        this.selectedOwner.ownerId,
        this.selectedUnit.unitId || '0'
      )
      .subscribe({
        next: (resp) => {
          this.isReportLoading = false;
          this.isGenUnitSubDailyUsageAPIFailure = false;
          this.reportData = resp;
        },
        error: (error: any) => {
          this.isReportLoading = false;
          if (error.errorDetails.code === 400) {
            this.isGenUnitSubDailyUsageAPIFailure = false;
          } else {
            this.isGenUnitSubDailyUsageAPIFailure = true;
          }
        },
      });
  }

  exportReport() {
    let keyHeaders: string[] = [];
    let modifiedlist: any = [];
    let displayHeaders: string[] = [
      'Date',
      'Subscriber Name',
      'Subscriber Account Number',
      'Meter Number',
      'kWh to Subscriber',
    ];

    keyHeaders = [
      'intervalReadingDate',
      'subscriberName',
      'billAccountNumber',
      'meterNo',
      'subscribeQuantity',
    ];

    this.reportData.forEach((row: any) => {
      let temp: any = {};

      temp['intervalReadingDate'] = row.intervalReadingDate;
      temp['subscriberName'] = row.subscriberName;
      temp['billAccountNumber'] = row.billAccountNumber;
      temp['meterNo'] = row.meterNo;
      temp['subscribeQuantity'] = row.subscribeQuantity;

      modifiedlist.push(temp);
    });
    this._export.exportJsonToExcelFile(
      modifiedlist,
      'Generation Unit Subscriber Daily kWh Allocation Report',
      false,
      keyHeaders,
      displayHeaders
    );
  }
}
