export enum ANMUserRole{
  anmInternalAdmin = 'Ameren Internal Admin',
  anmExternalAdmin = 'External Admin',
  anmInternalViewOnly = 'Ameren Internal View Only',
  anmExternalViewOnly = 'External View Only',
  anmRebateViewOnly = 'Ameren Rebate View Only',
  anmRebateApprover = 'Ameren Rebate Approver',  
  anmIPAViewOnly = 'IPA View Only'
  }

  export enum userRoleOptions {
    anmInternalAdmin = 'Ameren Internal Admin',
    anmExternalAdmin = 'External Admin',
    anmInternalViewOnly = 'Ameren Internal View Only',
    anmExternalViewOnly = 'External View Only',
    anmRebateViewOnly = 'Ameren Rebate View Only',
    anmRebateApprover = 'Ameren Rebate Approver',  
    anmIPAViewOnly = 'IPA View Only'
}

export enum externalANMAdminRoleOptions {
  anmExternalAdmin = 'External Admin',
  anmExternalViewOnly = 'External View Only',
}


