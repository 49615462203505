import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GenerationUnitComponent } from './generation-unit.component';
import { EditGenerationUnitComponent } from './edit-generation-unit/edit-generation-unit.component';
import { AddGenerationUnitComponent } from './add-generation-unit/add-generation-unit.component';
import { GenerationUnitSubscriptionDetailComponent } from './generation-unit-subscription-detail/generation-unit-subscription-detail.component';
import { ManageSubscriptionFeeComponent } from './manage-subscription-fee/manage-subscription-fee.component';
import { AuthGuardService } from 'src/app/shared/services/auth-guard.service';

const routes: Routes = [
  { path: '', component: GenerationUnitComponent },
  {
    path: 'generation-unit/edit',
    component: EditGenerationUnitComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'add-generation-unit',
    component: AddGenerationUnitComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'subscription-detail',
    component: GenerationUnitSubscriptionDetailComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'subscription-fee',
    component: ManageSubscriptionFeeComponent,
    canActivate: [AuthGuardService],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GenerationUnitRoutingModule {}
