import { Injectable } from "@angular/core";
import { Profile } from './../models/profile';


@Injectable({
    providedIn: 'root'
  })

  export class ProfileManagerService {

    private _profile: Profile[]=[];

    public set profile(profile: Profile[]) {
         this._profile = profile;
    }

    public get profile(): Profile[] {
        return this._profile;
    }

    resetProfileManager() {
        this._profile = [];   
        sessionStorage.removeItem('selectedGenerationOwner'); 
    }

   
    

  }