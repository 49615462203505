import { NgModule } from '@angular/core';

import { GenerationUnitRoutingModule } from './generation-unit-routing.module';
import { GenerationUnitComponent } from './generation-unit.component';
import { EditGenerationUnitComponent } from './edit-generation-unit/edit-generation-unit.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AddGenerationUnitComponent } from './add-generation-unit/add-generation-unit.component';
import { GenerationUnitSubscriptionDetailComponent } from './generation-unit-subscription-detail/generation-unit-subscription-detail.component';
import { ManageSubscriptionFeeComponent } from './manage-subscription-fee/manage-subscription-fee.component';

@NgModule({
  declarations: [
    GenerationUnitComponent,
    EditGenerationUnitComponent,
    AddGenerationUnitComponent,
    GenerationUnitSubscriptionDetailComponent,
    ManageSubscriptionFeeComponent,
  ],
  imports: [SharedModule, GenerationUnitRoutingModule],
})
export class GenerationUnitModule {}
