<div class="container edit-generation-owner-container">
  <div class="row">
    <div class="col-md-12">
      <h3 class="edit_gen_owner_header mb-5">Edit Generation Owner</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <div class="col-md-12 owner_dashboard_profile_container text-center p-4" *ngIf="currentUser">
        <ul class="list-unstyled">
          <li class="text-center d-flex justify-content-center">
            <div class="circle">
              <span class="characters">{{ currentUser?.firstname?.toString().substring(0, 1)
                }}{{ currentUser?.lastname?.toString().substring(0, 1) }}</span>
            </div>
          </li>
          <li class="text-center">
            <strong>{{ currentUser?.firstname }} {{ currentUser?.lastname }}</strong>
          </li>
          <li class="text-center">
            <span>{{ currentUser?.email }}</span>
          </li>
          <li class="text-center mt-4">
            <button class="owner_dashboard_edit_btn btn" [routerLink]="['/edit-profile']">
              Edit Profile
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-md-8">
      <h6 class="owner_info_header mb-5">Current Information</h6>
      <div class="col-md-12 text-center" *ngIf="isApiError">
        Something went wrong. Please try again.
      </div>
      <div class="col-md-12 text-center" id="edit_gen_owner_loading_container" *ngIf="isApiLoading">
        <ameren-loading></ameren-loading>
        <p>Please wait..</p>
      </div>

      <form [formGroup]="editGenOwnerForm" *ngIf="!isApiLoading && !isApiError">
        <div class="mb-3" id="genOwner_container">
          <label for="generationOwnerName" class="edit_info_label">OwnerId:*</label>
          <span>{{ ownerId }}</span>
        </div>
        <div class="mb-3 col-md-12" id="type_container">
          <label for="formControlName" class="edit_info_label">Type:*</label>
          <span>
            {{
            editGenOwnerForm.get("type")?.value == "O"
            ? "Off-Site"
            : "3rd Party"
            }}
          </span>
        </div>
        <div class="mb-3" id="genOwner_container">
          <label for="generationOwnerName" class="form-label">Generation Owner Name:*</label>
          <input type="text" class="form-control" formControlName="generationOwnerName" />
          <div class="field-validation-error" *ngIf="
              editGenOwnerForm.get('generationOwnerName')?.invalid &&
              editGenOwnerForm.get('generationOwnerName')?.dirty
            ">
            Generation Owner Name is required.
          </div>
        </div>

        <div formGroupName="bankingInfo">
          <div class="row mb-3">
            <div class="col-md-6" id="bankingAccountNumber_container">
              <label for="account" class="form-label">Account Number:</label>
              <input  type="number" maxlength="20" [class.invisible_account]="showMasked"  formControlName="account"
                class="form-control" [readOnly]="isInternalAdmin" (focusout)="onAccountNumberFocusOut()"/>
              <input type="text" [class.invisible_account]="!showMasked" formControlName="maskedAccount"
                class="form-control" [readOnly]="isInternalAdmin"
                 (focus)="onAccountNumberFocus()"/>
            </div>
            <div class="col-md-6" id="bankingRoutingNumber_container">
              <label for="routingNumber" class="form-label">Routing Number:</label>
              <input type="text" class="form-control" formControlName="routing" [readOnly]="isInternalAdmin">
            </div>
          </div>
          <div class="col-md-4 mb-3" *ngIf="isInternalAdmin && bankingInfo?.status == 'Pending Active'">
            <input type="checkbox" formControlName="amerenApproved" [checked]="bankingInfo?.amerenApproved == 'Y'"
              (change)="onToggleAmerenApproved($event)" [disabled]="bankingInfo?.amerenApproved != ''"
              [class.disabled]="bankingInfo?.amerenApproved != ''" />
              Ameren Approved
          </div>
          <div class="col-md-4 mb-3">
            <input type="checkbox" formControlName="ownerApproved" [checked]="bankingInfo?.ownerApproved == 'Y'" (change)="onToggleOwnerApproved($event)"
              [disabled]="!(isExternalAdmin && bankingInfo?.status == 'Pending Active')"
              [class.disabled]="!(isExternalAdmin && bankingInfo?.status == 'Pending Active')" />
            Owner Approved
          </div>
          <div class="mb-3" id="bankingStatus_container" *ngIf="bankingInfo?.status != 'Unknown'">
            <label for="bankingStatus" class="edit_info_label">Banking Status:</label>
            <span>{{bankingInfo?.status}}</span>
          </div>
          <div class="col-md-4 mb-3">
            <input type="checkbox" formControlName="termsAndConditions" [checked]="bankingInfo?.termsAndConditions == 'Y'"
              (change)="onToggleTermsAndConditions($event)"
              [class.disabled]="isInternalAdmin || (isExternalAdmin && bankingInfo?.status != 'Unknown') || bankingInfo.termsAndConditions == 'Y'"
              [disabled]="isInternalAdmin || (isExternalAdmin && bankingInfo?.status != 'Unknown') || bankingInfo.termsAndConditions == 'Y'"
               />
            Terms and Conditions
          </div>
          <div id="bankingNotes_container mb-3">
            <div id="termsMessage" [hidden]="bankingInfo?.termsAndConditions != 'Y'">
              <em>*The banking information you entered will be used for all generation units.<br></em>
            </div>
            <div id="OwnerMessage" *ngIf="isAmerenApprovedToggled">
              <em>Please allow 1 business day for Ameren to process the updates before trying to enter in subscription
                fee information.<br></em>
            </div>
            <div id="ResubmitMessage"
              [hidden]="(bankingInfo?.amerenApproved != 'N' && bankingInfo?.ownerApproved != 'N') || !(isExternalAdmin && bankingInfo?.status == 'Failed')">
              <em>Please re-enter banking information to re-submit the Penny Test.<br></em>
            </div>
          </div>
        </div>
        <div class="card border" formGroupName="amerenDERContact">
          <div class="card-header" (click)="toggleAmerenDERContact()">
            Contact for Ameren Distributed Energy Resources - Required

            <span type="button" class="add_gen_owner_accordian_btn">{{
              showAmerenDERContact ? "-" : "+"
              }}</span>
          </div>
          <div class="card-body" *ngIf="showAmerenDERContact">
            <em>
              The following contact information will be used by Ameren to
              contact you
            </em>
            <div class="mb-3 mt-3" id="contact_person_container">
              <label for="contactPerson" class="form-label">Contact Person:*</label>
              <input type="text" class="form-control" formControlName="contactPerson" />
              <div class="field-validation-error" *ngIf="
                  editGenOwnerForm.get('amerenDERContact')?.get('contactPerson')
                    ?.invalid &&
                  editGenOwnerForm.get('amerenDERContact')?.get('contactPerson')
                    ?.dirty
                ">
                Contact Person is required.
              </div>
            </div>
            <div class="mb-3">
              <div class="row">
                <div class="col-md-6" id="email_container">
                  <label for="emailAddress" class="form-label">Email Address:*</label>
                  <input type="text" class="form-control" formControlName="emailAddress" />
                  <div class="field-validation-error" *ngIf="
                      editGenOwnerForm
                        .get('amerenDERContact')
                        ?.get('emailAddress')?.invalid &&
                      editGenOwnerForm
                        .get('amerenDERContact')
                        ?.get('emailAddress')?.dirty
                    ">
                    Please enter a valid email address.
                  </div>
                </div>
                <div class="col-md-6" id="confirm_email_container">
                  <label for="confirmEmailAddress" class="form-label">Confirm Email Address:*</label>
                  <input type="text" class="form-control" formControlName="confirmEmailAddress" />
                  <div class="field-validation-error" *ngIf="
                      editGenOwnerForm
                        .get('amerenDERContact')
                        ?.get('confirmEmailAddress')?.invalid &&
                      editGenOwnerForm
                        .get('amerenDERContact')
                        ?.get('confirmEmailAddress')?.dirty
                    ">
                    <p
                      *ngIf="editGenOwnerForm?.get('amerenDERContact')?.get('confirmEmailAddress')?.errors?.['required']">
                      Confirm Email Address is required.
                    </p>
                    <p
                      *ngIf="editGenOwnerForm?.get('amerenDERContact')?.get('confirmEmailAddress')?.errors?.['mustMatch']">
                      Email Addresses must match.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <div class="row">
                <div class="col-md-9">
                  <label for="phoneNumber" id="phn_container" class="form-label">Phone Number:*</label>
                  <input amerenInputMask="999-999-9999" type="text" class="form-control"
                    formControlName="phoneNumber" />
                  <div class="field-validation-error" *ngIf="
                      editGenOwnerForm
                        .get('amerenDERContact')
                        ?.get('phoneNumber')?.invalid &&
                      editGenOwnerForm
                        .get('amerenDERContact')
                        ?.get('phoneNumber')?.dirty
                    ">
                    <p *ngIf="editGenOwnerForm?.get('amerenDERContact')?.get('phoneNumber')?.errors?.['required']">
                      Phone Number is required.
                    </p>
                    <p *ngIf="editGenOwnerForm?.get('amerenDERContact')?.get('phoneNumber')?.errors?.['pattern']">
                      Invalid Phone Number.
                    </p>
                  </div>
                </div>
                <div class="col-md-3" id="phnExt_container">
                  <label for="phoneExt" class="form-label">Ext:</label>
                  <input type="text" inputmode="numeric" class="form-control" formControlName="phoneExt" />
                </div>
              </div>
            </div>
            <h5 class="add_gen_owner_address_header">Mailing Address:</h5>
            <div class="mb-3" id="country_container">
              <label for="country" class="form-label">Country</label>
              <select class="form-control form-select" formControlName="country">
                <option>USA</option>
                <option>CANADA</option>
                <option>OTHER</option>
              </select>
              <div class="field-validation-error" *ngIf="
                  editGenOwnerForm.get('amerenDERContact')?.get('country')
                    ?.invalid &&
                  editGenOwnerForm.get('amerenDERContact')?.get('country')
                    ?.dirty
                ">
                Country is required.
              </div>
            </div>
            <div class="mb-3" id="other_country_container" *ngIf="
                editGenOwnerForm.get('amerenDERContact')?.get('country')
                  ?.value == 'Other'
              ">
              <label for="country" class="form-label">Country Name</label>
              <input type="text" class="form-control" formControlName="otherCountry" />
            </div>
            <div class="mb-3" id="address_container">
              <label for="address" class="form-label">Address:</label>
              <input type="text" class="form-control" formControlName="address" maxlength="28" />
              <div class="field-validation-error" *ngIf="
                  editGenOwnerForm.get('amerenDERContact')?.get('address')
                    ?.invalid &&
                  editGenOwnerForm.get('amerenDERContact')?.get('address')
                    ?.dirty
                ">
                Address is required.
              </div>
            </div>
            <div class="mb-3">
              <input type="text" class="form-control" formControlName="address2" maxlength="28" />
            </div>
            <div class="mb-3" id="city_state_container">
              <div class="row">
                <div class="col-md-4">
                  <label for="city" class="form-label">City</label>
                  <input type="text" class="form-control" formControlName="city" maxlength="18" />
                  <div class="field-validation-error" *ngIf="
                      editGenOwnerForm.get('amerenDERContact')?.get('city')
                        ?.invalid &&
                      editGenOwnerForm.get('amerenDERContact')?.get('city')
                        ?.dirty
                    ">
                    City is required.
                  </div>
                </div>
                <div class="col-md-4" id="state_container">
                  <label for="state" class="form-label">State/Province</label>
                  <input type="text" class="form-control" formControlName="state" maxlength="2" />
                  <div class="field-validation-error" *ngIf="
                      editGenOwnerForm.get('amerenDERContact')?.get('state')
                        ?.invalid &&
                      editGenOwnerForm.get('amerenDERContact')?.get('state')
                        ?.dirty
                    ">
                    State is required.
                  </div>
                </div>
                <div class="col-md-4" id="zip_container">
                  <label for="zip" class="form-label">Zip Code</label>
                  <input type="text" class="form-control" formControlName="zip" maxlength="6" />
                  <div class="field-validation-error" *ngIf="
                      editGenOwnerForm.get('amerenDERContact')?.get('zip')
                        ?.invalid &&
                      editGenOwnerForm.get('amerenDERContact')?.get('zip')
                        ?.dirty
                    ">
                    Zip Code is invalid.
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3" id="same_contact_chk">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" formControlName="sameContact" id="sameContact" />
                <label class="form-check-label" for="sameContact">
                  Check this box if contact for Ameren DER and contact for
                  subscribers are the same.
                </label>
                <em>* Contact for subscribers will be used by Ameren to forward
                  existing customers to generation owners.</em>
              </div>
            </div>
          </div>
        </div>
        <div class="card border mt-3" formGroupName="existingSubscriberContact">
          <div class="card-header" (click)="toggleExistingSubscriberContact()">
            Contact for Subscribers - Required
            <span type="button" class="add_gen_owner_accordian_btn">{{
              showExistingSubscriberContact ? "-" : "+"
              }}</span>
          </div>
          <div class="card-body" *ngIf="showExistingSubscriberContact">
            <em>
              The following contact information will be used by Ameren to
              forward existing customers to generation owners.
            </em>
            <div class="mb-3 mt-3" id="existingSubscriberContact_contact_person_container">
              <label for="contactPerson" class="form-label">Contact Person:*</label>
              <input type="text" class="form-control" formControlName="contactPerson" />
              <div class="field-validation-error" *ngIf="
                  editGenOwnerForm
                    .get('existingSubscriberContact')
                    ?.get('contactPerson')?.invalid &&
                  editGenOwnerForm
                    .get('existingSubscriberContact')
                    ?.get('contactPerson')?.dirty
                ">
                Contact Person is required.
              </div>
            </div>
            <div class="mb-3">
              <div class="row">
                <div class="col-md-6" id="existingSubscriberContact_email_container">
                  <label for="emailAddress" class="form-label">Email Address:*</label>
                  <input type="text" class="form-control" formControlName="emailAddress" />
                  <div class="field-validation-error" *ngIf="
                      editGenOwnerForm
                        .get('existingSubscriberContact')
                        ?.get('emailAddress')?.invalid &&
                      editGenOwnerForm
                        .get('existingSubscriberContact')
                        ?.get('emailAddress')?.dirty
                    ">
                    Please enter a valid email address.
                  </div>
                </div>
                <div class="col-md-6" id="existingSubscriberContact_confirm_email_container">
                  <label for="confirmEmailAddress" class="form-label">Confirm Email Address:*</label>
                  <input type="text" class="form-control" formControlName="confirmEmailAddress" />
                  <div class="field-validation-error" *ngIf="
                      editGenOwnerForm
                        .get('existingSubscriberContact')
                        ?.get('confirmEmailAddress')?.invalid &&
                      editGenOwnerForm
                        .get('existingSubscriberContact')
                        ?.get('confirmEmailAddress')?.dirty
                    ">
                    <p
                      *ngIf="editGenOwnerForm?.get('existingSubscriberContact')?.get('confirmEmailAddress')?.errors?.['required']">
                      Confirm Email Address is required.
                    </p>
                    <p
                      *ngIf="editGenOwnerForm?.get('existingSubscriberContact')?.get('confirmEmailAddress')?.errors?.['mustMatch']">
                      Email Addresses must match.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <div class="row">
                <div class="col-md-9" id="existingSubscriberContact_phn_number">
                  <label for="phoneNumber" id="phn_container" class="form-label">Phone Number:*</label>
                  <input amerenInputMask="999.999.9999" type="text" class="form-control"
                    formControlName="phoneNumber" />
                  <div class="field-validation-error" *ngIf="
                      editGenOwnerForm
                        .get('existingSubscriberContact')
                        ?.get('phoneNumber')?.invalid &&
                      editGenOwnerForm
                        .get('existingSubscriberContact')
                        ?.get('phoneNumber')?.dirty
                    ">
                    <p
                      *ngIf="editGenOwnerForm?.get('existingSubscriberContact')?.get('phoneNumber')?.errors?.['required']">
                      Phone Number is required.
                    </p>
                    <p
                      *ngIf="editGenOwnerForm?.get('existingSubscriberContact')?.get('phoneNumber')?.errors?.['pattern']">
                      Invalid Phone Number.
                    </p>
                  </div>
                </div>
                <div class="col-md-3" id="existingSubscriberContact_phnExt_container">
                  <label for="phoneExt" class="form-label">Ext:</label>
                  <input type="text" inputmode="numeric" class="form-control" formControlName="phoneExt" />
                </div>
              </div>
            </div>
            <h5 class="add_gen_owner_address_header">Mailing Address:</h5>
            <div class="mb-3" id="existingSubscriberContact_country_container">
              <label for="country" class="form-label">Country</label>
              <select class="form-control form-select" formControlName="country">
                <option>USA</option>
                <option>CANADA</option>
                <option>OTHER</option>
              </select>
              <div class="field-validation-error" *ngIf="
                  editGenOwnerForm
                    .get('existingSubscriberContact')
                    ?.get('country')?.invalid &&
                  editGenOwnerForm
                    .get('existingSubscriberContact')
                    ?.get('country')?.dirty
                ">
                Country is required.
              </div>
            </div>
            <div class="mb-3" id="existing_other_country_container" *ngIf="
                editGenOwnerForm
                  .get('existingSubscriberContact')
                  ?.get('country')?.value == 'Other'
              ">
              <label for="country" class="form-label">Country Name</label>
              <input type="text" class="form-control" formControlName="otherCountry" />
            </div>
            <div class="mb-3" id="existingSubscriberContact_address_container">
              <label for="address" class="form-label">Address:</label>
              <input type="text" class="form-control" formControlName="address" maxlength="28" />
              <div class="field-validation-error" *ngIf="
                  editGenOwnerForm
                    .get('existingSubscriberContact')
                    ?.get('address')?.invalid &&
                  editGenOwnerForm
                    .get('existingSubscriberContact')
                    ?.get('address')?.dirty
                ">
                Address is required.
              </div>
            </div>
            <div class="mb-3" id="existingSubscriberContact_address2">
              <input type="text" class="form-control" formControlName="address2" maxlength="28" />
            </div>
            <div class="mb-3" id="existingSubscriberContact_city_container">
              <div class="row">
                <div class="col-md-4">
                  <label for="city" class="form-label">City</label>
                  <input type="text" class="form-control" formControlName="city" maxlength="18" />
                  <div class="field-validation-error" *ngIf="
                      editGenOwnerForm
                        .get('existingSubscriberContact')
                        ?.get('city')?.invalid &&
                      editGenOwnerForm
                        .get('existingSubscriberContact')
                        ?.get('city')?.dirty
                    ">
                    City is required.
                  </div>
                </div>
                <div class="col-md-4" id="existingSubscriberContact_state_container">
                  <label for="state" class="form-label">State/Province</label>
                  <input type="text" class="form-control" formControlName="state" maxlength="2" />
                  <div class="field-validation-error" *ngIf="
                      editGenOwnerForm
                        .get('existingSubscriberContact')
                        ?.get('state')?.invalid &&
                      editGenOwnerForm
                        .get('existingSubscriberContact')
                        ?.get('state')?.dirty
                    ">
                    State is required.
                  </div>
                </div>
                <div class="col-md-4" id="existingSubscriberContact_zip_container">
                  <label for="zip" class="form-label">Zip Code</label>
                  <input type="text" class="form-control" formControlName="zip" maxlength="6" />
                  <div class="field-validation-error" *ngIf="
                      editGenOwnerForm
                        .get('existingSubscriberContact')
                        ?.get('zip')?.invalid &&
                      editGenOwnerForm
                        .get('existingSubscriberContact')
                        ?.get('zip')?.dirty
                    ">
                    Zip Code is invalid.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group edit_owner_btn_container d-flex">          
          <button type="button" data-bs-toggle="modal" data-bs-target="#editGenOwnerCancelModal" class="ameren-tertiary-green-btn">Cancel</button>
          <button data-bs-toggle="modal" data-bs-target="#updateOwnerModal" type="button"
            class="mx-1 ameren-primary-green-btn btn btn-success" [disabled]="editGenOwnerForm.pristine || !editGenOwnerForm.valid || isSaveComplete" (click)="onSaveClick()">
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="modal fade" id="termsModal" tabindex="-1" aria-labelledby="termsModalLabel" aria-hidden="true">
  <div class="modal-dialog terms_model" id="termsModalDialog">
    <div class="modal-content" id="termsModalContent">
      <div class="pop-header row" id="termsModalHeader">
        <button type="button" class="btn-close terms_modal_close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" id="termsModalText">
        <p>Terms and Conditions</p>
        <p>A)Authorization to work with CSD’s bank and set up EDI.</p>
        <p>B) Acknowledges that CSD is responsible for entering subscription fee amount that is structured as a fixed
          percentage of the bill credit value.</p>
        <p>C)Acknowledges that the “bill credit value” is equivalent to the kWh output of the customer’s subscription
          times the Price to Compare.</p>
        <p>C)Acknowledges that AIC has no collection responsibilities beyond remitting any subscription fee payments
          received to the CSD.</p>
        <p>D)Acknowledges that AIC will remit payments on each business day assuming that we have received a payment
          that day.</p>
        <p>E)Acknowledges that AIC will collect a 2% administrative fee on subscriptions billed, and that AIC will
          deduct that fee from payments.</p>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="updateOwnerModal" tabindex="-1" aria-labelledby="updateOwnerModal" aria-hidden="true">

  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header save_gen_owner_modal_header border-0">
        <h5 class="modal-title text-success save_gen_owner_modal_title" id="saveGenUnitModalLabel">
          Update Generation Owner
        </h5>
        <button type="button" (click)="closeSaveModal()" *ngIf="!isSaveLoading" class="btn-close gen_unit_modal_close"
          data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="remove_gen_unit_modal_content">
          <div *ngIf="isSaveLoading" class="text-center justify-content-center">
            <ameren-loading></ameren-loading>
            <p>Please wait..</p>
          </div>
          <div class="text-danger text-center justify-content-center" *ngIf="isSaveError || isSaveBankingError">
            <img src="../../../assets/images/failure.svg" alt="success" />
            <p class="mt-2">{{errorMsg}}{{errorBankingMsg}}</p>
          </div>
          <div class="text-success text-center justify-content-center" *ngIf="isSaveSuccess || isSaveBankingSuccess">
            <img src="../../../assets/images/success.svg" alt="success" />
            <p class="mt-2">{{successMsg}}{{successBankingMsg}}</p>
          </div>
        </div>
      </div>
      <div class="text-center justify-content-center mb-3" *ngIf="!isSaveSuccess && !isSaveError && !isSaveLoading">
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="editGenOwnerCancelModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-black" id="editGenOwnerCancelModalLabel">
          Confirm Cancellation
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>Are you sure you want to cancel?</p>
      </div>
      <div class="modal-footer">
        <a [routerLink]="['/generation-owner/owner-dashboard']" [queryParams]="{ ownerId: ownerId }" class="btn btn-success" data-bs-dismiss="modal">Yes</a>
        <button type="button" data-bs-dismiss="modal" class="btn btn-danger" data-bs-dismiss="modal">No</button>
      </div>
    </div>
  </div>
</div>