import * as _ from 'lodash';
import * as moment from 'moment';
import 'moment-timezone';

export class SubscriberListModel {
  ownerId: string;
  ownerName: string;
  unitId: string;
  unitName: string;
  billAccountNumber: string;
  subscriberName: string;
  meterNo: string;
  effectiveDate: string;
  terminationDate: string;
  statusCode: string;
  statusDescription: string;
  annualMonthReq: boolean;
  annualMonth: string;
  usageQuantity: string;
  premiseNo: string;
  servicePt: string;
  subscriberAddress: string;
  savingsReleaseFlag: boolean;
  rebateRecipient: string;
  showTerminationDate: boolean;

  constructor(subList?: any) {
    this.ownerId = !_.isNil(subList.ownerId) ? subList.ownerId : '';
    this.ownerName = !_.isNil(subList.ownerName) ? subList.ownerName : '';
    this.unitId = !_.isNil(subList.unitId) ? subList.unitId : '';
    this.unitName = !_.isNil(subList.unitName) ? subList.unitName : '';
    this.billAccountNumber = !_.isNil(subList.billAccountNumber)
      ? subList.billAccountNumber
      : '';
    this.subscriberName = !_.isNil(subList.subscriberName)
      ? subList.subscriberName
      : '';
    this.meterNo = !_.isNil(subList.meterNo) ? subList.meterNo : '';
    this.effectiveDate = !_.isNil(subList.effectiveDate)
      ? subList.effectiveDate
      : '';
    this.terminationDate =
      subList.terminationDate === '9999-12-31' ? '' : subList.terminationDate;

    let currentDateValue = moment().tz('America/Chicago').format('YYYY-MM-DD');

    this.showTerminationDate =
      subList.terminationDate >= currentDateValue ? true : false;
    this.statusCode = !_.isNil(subList.statusCode) ? subList.statusCode : '';
    this.statusDescription = !_.isNil(subList.statusDescription)
      ? subList.statusDescription
      : '';
    this.annualMonthReq = !_.isNil(subList.annualMonthReq)
      ? subList.annualMonthReq
      : false;
    this.annualMonth = !_.isNil(subList.annualMonth) ? subList.annualMonth : '';
    this.usageQuantity = !_.isNil(subList.usageQuantity)
      ? subList.usageQuantity
      : '';
    this.premiseNo = !_.isNil(subList.premiseNo) ? subList.premiseNo : '';
    this.servicePt = !_.isNil(subList.servicePt) ? subList.servicePt : '';
    this.subscriberAddress = !_.isNil(subList.subscriberAddress)
      ? subList.subscriberAddress
      : '';
    this.savingsReleaseFlag = !_.isNil(subList.savingsReleaseFlag)
      ? subList.savingsReleaseFlag
      : false;
    this.rebateRecipient = !_.isNil(subList.rebateRecipient)
      ? subList.rebateRecipient
      : '';
  }
}
