import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { InternalusersComponent } from "./internalusers/internalusers.component";
import { ExternalusersComponent } from "./externalusers/externalusers.component";
import { AuthGuardService } from "src/app/shared/services/auth-guard.service";
import { EditUserComponent } from './edit-user/edit-user.component';

const routes: Routes = [
  {
    path: "",
    component: InternalusersComponent,
  },
  {
    path: "externalusers",
    component: ExternalusersComponent,
  },
  {
    path: 'edit-user',
    component: EditUserComponent,
    canActivate: [AuthGuardService],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UsersRoutingModule {}
