import { GenUnitAllSubBillAccountRptComponent } from './gen-unit-all-sub-bill-account-rpt/gen-unit-all-sub-bill-account-rpt.component';
import { IpaGenUnitSubContractRptComponent } from './ipa-gen-unit-sub-contract-rpt/ipa-gen-unit-sub-contract-rpt.component';
import { GenUnitDailyOutputRptComponent } from './gen-unit-daily-output-rpt/gen-unit-daily-output-rpt.component';
import { GenUnitSubscriberContractReportComponent } from './gen-unit-subscriber-contract-report/gen-unit-subscriber-contract-report.component';
import { GenUnitSubBillaccountReportComponent } from './gen-unit-sub-billaccount-report/gen-unit-sub-billaccount-report.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GenerationOwnerReportComponent } from './generation-owner-report/generation-owner-report.component';
import { ReportsComponent } from './reports/reports.component';
import { GenunitOwnerSubFeeComponent } from './genunit-owner-sub-fee/genunit-owner-sub-fee.component';
import { GenUnitBillAcctSubscriptionReportComponent } from './gen-unit-bill-acct-subscription-report/gen-unit-bill-acct-subscription-report.component';
import { GenUnitSubDailyUsageComponent } from './gen-unit-sub-daily-usage/gen-unit-sub-daily-usage.component';
import { SubDailyKwhAllocationRptComponent } from './sub-daily-kwh-allocation-rpt/sub-daily-kwh-allocation-rpt.component';
import { RegulatoryComplianceRptComponent } from './regulatory-compliance-rpt/regulatory-compliance-rpt.component';

const routes: Routes = [
  { path: '', component: ReportsComponent },
  { path: 'reports/gen-owner', component: GenerationOwnerReportComponent },
  {
    path: 'reports/gen-unit-sub-billaccount',
    component: GenUnitSubBillaccountReportComponent,
  },
  {
    path: 'reports/gen-unit-owner_sub-fee',
    component: GenunitOwnerSubFeeComponent,
  },
  {
    path: 'reports/gen-unit-bill-acct-subscription',
    component: GenUnitBillAcctSubscriptionReportComponent,
  },
  {
    path: 'reports/gen-unit-sub-daily-usage',
    component: GenUnitSubDailyUsageComponent,
  },
  {
    path: 'reports/gen-unit-subscriber-contract',
    component: GenUnitSubscriberContractReportComponent,
  },
  {path: "reports/sub-daily-kwh-allocation-rpt", component: SubDailyKwhAllocationRptComponent},
  {path: "reports/regulatory-compliance-rpt", component: RegulatoryComplianceRptComponent},
  {path: "reports/gen-unit-daily-output", component: GenUnitDailyOutputRptComponent},
  {path: "reports/ipa-gen-unit-sub-billaccount", component: IpaGenUnitSubContractRptComponent},
  {path: "reports/gen-unit-all-sub-billaccount", component: GenUnitAllSubBillAccountRptComponent}

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReportsRoutingModule {}
