<div class="anm_registration_success__container">
  <div class="d-flex flex-column align-items-center">
    <div class="anm_registration_success_title">
      Application Submitted Successfully!
    </div>
    <div class="m-3">
      <p>
        Thank you for registering with the Renewables Portal.
        <br />
        The administrator will review your request for access to
        the site.
        <br />
        Once your request has been reviewed, we will send an email message
        <strong> {{ email }} </strong> to notify you whether your request was
        approved or denied.
        <br />
        Upon approval, you will be able to log in to the site with the User ID
        that you requested on the registration page.
      </p>
    </div>

    <div>
      <a [routerLink]="['/']" class="btn btn-success m-2">Return to Home</a>
    </div>
    <div></div>
  </div>
</div>
