<h3 class="faq-title">Renewables FAQs</h3>
<div>
  <section class="faq-container">
    <ameren-accordion
      *ngFor="let s of getFaqs(selectedState)"
      [header]="s.header"
      [innerHTML]="s.detail"
    ></ameren-accordion>
  </section>
</div>
