import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-externalusers",
  templateUrl: "./externalusers.component.html",
  styleUrls: ["./externalusers.component.scss"],
})
export class ExternalusersComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
