import { catchError } from "rxjs/operators";
import { UsersApiService } from "./users-api.service";
import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { forkJoin, throwError } from "rxjs";
import { AmerenError } from "src/app/shared/models/amerenError";
import { IGlobalAPIResponse } from "src/app/shared/interfaces/iglobal-api-response";

@Injectable({
  providedIn: 'root'
})
export class UsersManagerService {

  constructor(private UsersApiService: UsersApiService) { }


  getAllUsers(includeInactive: boolean, genOwners:string) {
    return this.UsersApiService.getAllUsers(includeInactive, genOwners).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            "Users cannot be retrieved",
            400,
            null,
            null,
            null
          );
        }

        return res;
      }),
      catchError((error: any) => {
        console.log("err", error);
        return throwError(() => error);
      })
    );
  }

  getUserbyEmail(email:string) {
    return this.UsersApiService.getUserbyEmail(email).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            "User details cannot be retrieved",
            400,
            null,
            null,
            null
          );
        }

        return res;
      }),
      catchError((error: any) => {
        console.log("err", error);
        return throwError(() => error);
      })
    );
  }

  updateUserStatus(data: any) {
    return this.UsersApiService
      .updateUserStatus(data)
      .pipe(
        map((res: IGlobalAPIResponse) => {
          if (!res.success)
            throw new AmerenError('Update User Status Failed',400,null,null,null);
          return res;
        }),
        catchError((error: any) => {
          return throwError(() => error);
        })
      );
  }
  
  updateUserRoleStatus(data: any) {
    return this.UsersApiService
      .updateUserRoleStatus(data)
      .pipe(
        map((res: IGlobalAPIResponse) => {
          if (!res.success)
            throw new AmerenError('Update User Role Status Failed',400,null,null,null);
          return res;
        }),
        catchError((error: any) => {
          return throwError(() => error);
        })
      );
  }

  updateUserRoles(data: any) {
    return this.UsersApiService
      .updateUserRoles(data)
      .pipe(
        map((res: IGlobalAPIResponse) => {
          if (!res.success)
            throw new AmerenError('Update User Roles Failed',400,null,null,null);
          return res;
        }),
        catchError((error: any) => {
          return throwError(() => error);
        })
      );
  }

}
