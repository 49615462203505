import { forkJoin, switchMap, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { GenerationUnitApiService } from './generation-unit-api.service';
import { throwError } from 'rxjs';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';
import { AmerenError } from 'src/app/shared/models/amerenError';
import { GenerationUnitModel } from '../models/generation-unit.model';
import { RatesModel } from '../models/rates.model';
import { CacheService } from 'src/app/shared/services/cache.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SubscriberListModel } from '../models/subsriberlist.model';

@Injectable({
  providedIn: 'root',
})
export class GenerationUnitManagerService {
  currentUser: any;
  loggedInUser: any;

  constructor(
    private genUnitService: GenerationUnitApiService,
    private cache: CacheService,
    private authService: AuthService
  ) {
    this.loggedInUser = this.authService.getCurrentUser();

    if (this.loggedInUser != null) this.currentUser = this.loggedInUser.profile;
  }

  getGenerationUnitByUnitId(unitId: string) {
    return this.genUnitService
      .getGenerationUnitByUnitId(unitId.toString())
      .pipe(
        map((res: IGlobalAPIResponse) => {
          if (!res.success) {
            throw new AmerenError(
              'Generation Unit By UnitID cannot be retrieved',
              400,
              null,
              null,
              null
            );
          }

          return new GenerationUnitModel(res.data);
        }),
        catchError((error: any) => {
          console.log('err', error);
          return throwError(() => error);
        })
      );
  }

  getDecodesByTableName(tableName: string) {
    return this.genUnitService.getDecodes().pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'decodes cannot be retrieved',
            400,
            null,
            null,
            null
          );
        }
        return res.data.filter((x: any) => x.tableName == tableName);
      }),
      catchError((error: any) => {
        console.log('err', error);
        return throwError(() => error);
      })
    );
  }

  updateGenUnit(genUnit: any) {
    return this.genUnitService.updateGenUnit(genUnit).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Update Generation Unit Failed',
            400,
            null,
            null,
            null
          );
        }

        return res.data;
      }),
      catchError((error: any) => {
        console.log('err', error);
        return throwError(() => error);
      })
    );
  }

  getGenUnits(paginate: boolean = false) {
    let ownerAccess = this.authService.getCurrentUserGenerationOwnersAccess();
    if (!paginate) {
      return this.genUnitService.getGenerationUnits().pipe(
        map((res: IGlobalAPIResponse) => {
          if (!res.success) {
            throw new AmerenError(
              'Get Gen Units failed',
              400,
              null,
              null,
              null
            );
          }

          return ownerAccess.includes('00018')
            ? res.data
            : res.data?.filter((a: any) => ownerAccess?.includes(a.ownerId));
        }),
        catchError((error: any) => {
          console.log('err', error);
          return throwError(() => error);
        })
      );
    } else {
      return this.genUnitService.getGenerationUnits(0).pipe(
        switchMap((res: IGlobalAPIResponse) => {
          if (!res.success) {
            throw new AmerenError('Get GenUnits failed', 400, null, null, null);
          }

          if (res.data.totalPages > 1) {
            let pages = [...Array(res.data.totalPages - 1).keys()];
            const _observables = pages.map((pageNum) => {
              return this.genUnitService.getGenerationUnits(pageNum + 1).pipe(
                catchError((err) => {
                  return of(err);
                })
              );
            });
            return forkJoin(_observables).pipe(
              map((responses) => {
                let results: any[] = [];
                responses.forEach((response: any) => {
                  results.push(...response.data.generationUnits);
                });
                results.push(...res.data.generationUnits);
                if (!ownerAccess.includes('00018'))
                  results = results.filter((a: any) =>
                    ownerAccess?.includes(a.ownerId)
                  );
                return results;
              })
            );
          } else {
            return of(res.data.generationUnits);
          }
        })
      );
    }
  }

  getGenerationUnitRateHistory(unitId: any) {
    return this.genUnitService.getGenerationUnitRateHistory(unitId).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Get Generation Unit Rate History has failed',
            400,
            null,
            null,
            null
          );
        }

        let response = [],
          rateHistory: any[] = [];
        response = res.data;
        response.forEach((element: any) => {
          rateHistory.push(new RatesModel(element));
        });

        return rateHistory;
      }),
      catchError((error: any) => {
        console.log('err', error);
        return throwError(() => error);
      })
    );
  }

  submitSubscriptionFee(body: any, unitId: any, isinternaluser: string) {
    return this.genUnitService
      .submitSubscriptionFee(body, unitId, isinternaluser)
      .pipe(
        map((res: IGlobalAPIResponse) => {
          if (!res.success) {
            throw new AmerenError(
              'submit Generation Unit Rate has failed',
              400,
              null,
              null,
              null
            );
          }

          return res.data;
        }),
        catchError((error: any) => {
          console.log('error', error);
          return throwError(() => error);
        })
      );
  }

  removeSubscribers(subscribers: any[], audit: string) {
    return this.genUnitService.removeSubscribers(subscribers, audit).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Delete Subscribers Failed',
            400,
            null,
            null,
            null
          );
        }

        return res.data;
      }),
      catchError((error: any) => {
        console.log('err', error);
        return throwError(() => error);
      })
    );
  }

  getSubscribersByBillAccount(acct: string, unitId: string, ownerId: string) {
    return this.genUnitService
      .getSubscribersByBillAccount(acct, unitId, ownerId)
      .pipe(
        map((res: IGlobalAPIResponse) => {
          if (!res.success) {
            throw new AmerenError(
              'Get Subscribers by Bill Account Failed',
              400,
              null,
              null,
              null
            );
          }

          return res.data;
        }),
        catchError((error: any) => {
          console.log('err', error);
          return throwError(() => error);
        })
      );
  }

  getSubscribers(
    acct?: string,
    unitId?: string,
    ownerId?: string,
    begindate?: string,
    enddate?: string
  ) {
    return this.genUnitService
      .getSubscribers(acct, unitId, ownerId, begindate, enddate)
      .pipe(
        map((res: IGlobalAPIResponse) => {
          if (!res.success) {
            throw new AmerenError(
              'Get Subscribers Failed',
              400,
              null,
              null,
              null
            );
          }

          let subsList: any[] = [];
          res.data.forEach((res: any) => {
            subsList.push(new SubscriberListModel(res));
          });
          let ownerAccess =
            this.authService.getCurrentUserGenerationOwnersAccess();

          return ownerAccess.includes('00018')
            ? subsList
            : subsList?.filter((a: any) => ownerAccess?.includes(a.ownerId));
        }),
        catchError((error: any) => {
          console.log('err', error);
          return throwError(() => error);
        })
      );
  }

  getCSSDate() {
    return this.genUnitService.getCSSDate().pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError('CSS Date Failed', 400, null, null, null);
        }

        return res.data;
      }),
      catchError((error: any) => {
        console.log('err', error);
        return throwError(() => error);
      })
    );
  }

  addGenerationUnit(addGenerationUnitBody: any) {
    return this.genUnitService.addGenerationUnit(addGenerationUnitBody).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Add Generation Unit has Failed',
            400,
            null,
            null,
            null
          );
        }

        return res.data;
      }),
      catchError((error: any) => {
        console.log('err', error);
        return throwError(() => error);
      })
    );
  }

  getRebates() {
    return this.genUnitService.getRebates().pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError('Get Rebates Failed', 400, null, null, null);
        }

        return res.data;
      }),
      catchError((error: any) => {
        console.log('err', error);
        return throwError(() => error);
      })
    );
  }
}
