export class GenerationOwner {
  ownerId: string;
  name: string;
  ownerType: string;
  ownerBillAccount: number;
  ownerServSupp?: string;    
  generatingUnitsQuantity?: number;
  contacts: GenerationOwnerContact[];    
}

export class GenerationOwnerContact{
  contactType: string;
  contactPerson: string;
  email: string;
  contactPhone: string;
  contactPhoneExt: string;
  mailingAddress: MailingAddress;
}

export class MailingAddress{
  addressLine1: string = '';
  addressLine2?: string = '';
  addressLine3?: string = '';
  city?: string = '';
  state?: string = '';
  postalCode?: string = '';
  country?: string = '';  
  
  constructor(addressline1?: string, addressline2?: string, city?: string, state?: string, postalcode?: string, country?: string){
    this.addressLine1 = addressline1 != null ? addressline1 : '';
    this.addressLine2 = addressline2 != null ? addressline2 : '';
    this.addressLine3 = '';
    this.city = city != null ? city : '';
    this.state = state != null ? state : '';
    this.postalCode = postalcode != null ? postalcode : '';
    this.country = country != null ? country : '';
  }
}