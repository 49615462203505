import * as _ from 'lodash';
import { IGenerationUnit } from '../interfaces/Igeneration-unit';

export class GenerationUnitModel {
  fuelSourceDesc: string;
  nickName: string;
  billAccountNumber: string;
  statusDesc: string;
  fuelSource: string;
  numOfSubscriptions: string;
  totalSubscriberUsage: string;
  status: string;
  owner: string;
  unitId: string;
  type: string;
  typeDesc: string;
  capacity: string;
  powerClerk: string;
  effectiveDate: string;
  terminationDate: string;
  ownerName: string;
  meterNo: string;
  rebateCapacityQuantity: string;
  rebateRequestQuantity: string;
  rebateEligibleQuantity: string;
  rebateNotSubscribedQuantity: number;
  rebateEligibleFlag: boolean;
  deliveryServiceDescription: string;
  riderDescription: string;
  waitingForApprovalFlag: boolean;
  rebateRateChangeFlag: boolean;
  locationID: string;
  locationDesc: string;
  constructor(generationUnitDetails: IGenerationUnit) {
    this.fuelSourceDesc = !_.isNil(generationUnitDetails.fuelSourceDesc)
      ? generationUnitDetails.fuelSourceDesc
      : '';
    this.nickName = !_.isNil(generationUnitDetails.nickName)
      ? generationUnitDetails.nickName
      : '';
    this.billAccountNumber = !_.isNil(generationUnitDetails.billAccountNumber)
      ? generationUnitDetails.billAccountNumber
      : '';
    this.statusDesc = !_.isNil(generationUnitDetails.statusDesc)
      ? generationUnitDetails.statusDesc
      : '';
    this.fuelSource = !_.isNil(generationUnitDetails.fuelSource)
      ? generationUnitDetails.fuelSource
      : '';
    this.numOfSubscriptions = !_.isNil(generationUnitDetails.numOfSubscriptions)
      ? generationUnitDetails.numOfSubscriptions
      : '';
    this.totalSubscriberUsage = !_.isNil(
      generationUnitDetails.totalSubscriberUsage
    )
      ? generationUnitDetails.totalSubscriberUsage
      : '';
    this.status = !_.isNil(generationUnitDetails.status)
      ? generationUnitDetails.status
      : '';
    this.owner = !_.isNil(generationUnitDetails.owner)
      ? generationUnitDetails.owner
      : '';
    this.unitId = !_.isNil(generationUnitDetails.unitId)
      ? generationUnitDetails.unitId
      : '';
    this.type = !_.isNil(generationUnitDetails.type)
      ? generationUnitDetails.type
      : '';
    this.typeDesc = !_.isNil(generationUnitDetails.typeDesc)
      ? generationUnitDetails.typeDesc
      : '';
    this.capacity = !_.isNil(generationUnitDetails.capacity)
      ? generationUnitDetails.capacity
      : '';
    this.powerClerk = !_.isNil(generationUnitDetails.powerClerk)
      ? generationUnitDetails.powerClerk
      : '';
    this.effectiveDate = !_.isNil(generationUnitDetails.effectiveDate)
      ? generationUnitDetails.effectiveDate
      : '';
    this.terminationDate = !_.isNil(generationUnitDetails.terminationDate)
      ? generationUnitDetails.terminationDate
      : '';
    this.ownerName = !_.isNil(generationUnitDetails.ownerName)
      ? generationUnitDetails.ownerName
      : '';
    this.meterNo = !_.isNil(generationUnitDetails.meterNo)
      ? generationUnitDetails.meterNo
      : '';
    this.rebateCapacityQuantity = !_.isNil(
      generationUnitDetails.rebateCapacityQuantity
    )
      ? generationUnitDetails.rebateCapacityQuantity
      : '';
    this.rebateRequestQuantity = !_.isNil(
      generationUnitDetails.rebateRequestQuantity
    )
      ? generationUnitDetails.rebateRequestQuantity
      : '';
    this.rebateEligibleQuantity = !_.isNil(
      generationUnitDetails.rebateEligibleQuantity
    )
      ? generationUnitDetails.rebateEligibleQuantity
      : '';
    this.rebateNotSubscribedQuantity = !_.isNil(
      generationUnitDetails.rebateNotSubscribedQuantity
    )
      ? generationUnitDetails.rebateNotSubscribedQuantity
      : 0.0;
    this.rebateEligibleFlag = !_.isNil(generationUnitDetails.rebateEligibleFlag)
      ? generationUnitDetails.rebateEligibleFlag
      : false;
    this.deliveryServiceDescription = !_.isNil(
      generationUnitDetails.deliveryServiceDescription
    )
      ? generationUnitDetails.deliveryServiceDescription
      : '';
    this.riderDescription = !_.isNil(generationUnitDetails.riderDescription)
      ? generationUnitDetails.riderDescription
      : '';
    this.waitingForApprovalFlag = !_.isNil(
      generationUnitDetails.waitingForApprovalFlag
    )
      ? generationUnitDetails.waitingForApprovalFlag
      : false;
    this.rebateRateChangeFlag = !_.isNil(
      generationUnitDetails.rebateRateChangeFlag
    )
      ? generationUnitDetails.rebateRateChangeFlag
      : false;
    this.locationID = !_.isNil(generationUnitDetails.locationID)
      ? generationUnitDetails.locationID
      : '';
    this.locationDesc = !_.isNil(generationUnitDetails.locationDesc)
      ? generationUnitDetails.locationDesc
      : '';
  }
}
