import { ExportUtility } from './../../../shared/utilities/utility.export';
import { ReportsManagerService } from './../services/reports-manager.service';
import { GenerationUnitManagerService } from './../../generation-unit/services/generation-unit-manager.service';
import { GenerationOwnerManagerService } from './../../generation-owner/services/generation-owner-manager.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gen-unit-daily-output-rpt',
  templateUrl: './gen-unit-daily-output-rpt.component.html',
  styleUrls: ['./gen-unit-daily-output-rpt.component.scss']
})
export class GenUnitDailyOutputRptComponent implements OnInit {


  constructor(
    private genOwnerMgr: GenerationOwnerManagerService,
    private genUnitMgr: GenerationUnitManagerService,
    private reportMgr: ReportsManagerService,
    private _export: ExportUtility
  ) {}
  genOwners: any[];
  selectedOwner: any;
  cssDate: string;
  minDate: string;
  maxDate: string;
  reportData: any[];
  units: any[];
  selectedUnit: any;
  isApiLoading: boolean;
  isReportLoading: boolean;
  searchClicked: boolean;
  integrationErrorMsg: any;

  dtBegin: string;
  dtEnd: string;
  ngOnInit(): void {
    this.getOwners();
    this.getCSSdate();
  }

  getOwners() {
    this.genOwnerMgr.getAllOwners().subscribe({
      next: (resp) => {
        this.genOwners = resp;
      },
    });
  }

  getCSSdate() {
    this.genUnitMgr.getCSSDate().subscribe({
      next: (resp) => {
        this.cssDate = resp?.substring(0, 10);
        let dt = new Date(this.cssDate);
       
        this.maxDate = new Date(dt.setDate(dt.getDate() - 1))
          .toISOString()
          .split('T')[0];
        this.minDate = new Date(dt.setDate(dt.getDate() - 90))
          .toISOString()
          .split('T')[0];
      },
    });
  }

  onOwnerChange() {
    this.getUnitsByOwner();
  }
  getUnitsByOwner() {
    this.integrationErrorMsg = "";
    this.isApiLoading = true;
    this.genOwnerMgr
      .getGenerationUnitsByOwnerId(this.selectedOwner.ownerId)
      .subscribe({
        next: (resp) => {
          this.isApiLoading = false;
          if (resp) {
            this.units = resp;
          }
          else
            this.integrationErrorMsg = resp?.errorDetails ? resp?.errorDetails?.message : 'Sorry, something went wrong. Please try again later.';
        },
        error: (err) => {
          this.isApiLoading = false;
          this.units = [];
          this.integrationErrorMsg = err.error?.errorDetails ? err.error.errorDetails?.message : err.errorDescription;
        },
      });
  }
  search() {
    this.integrationErrorMsg = "";
    this.reportData = [];
    this.searchClicked = true;
    this.isReportLoading = true;
    this.reportMgr
      .genUnitSubDailyUsage(
        this.selectedOwner.ownerId,
        this.selectedUnit.unitId || '0',
        this.dtBegin,
        this.dtEnd
      )
      .subscribe({       
        next: (resp:any) => {
          this.isReportLoading = false;
       
          if (resp.errorDetails) {           
              this.integrationErrorMsg = resp.errorDetails ? resp.errorDetails?.message :  'Sorry, something is not right. Please try again.'; 
          } else {
           
            this.reportData = resp;
          }
        },
        error: (AmerenError) => {       
          this.isReportLoading = false;
          this.integrationErrorMsg = AmerenError.error?.errorDetails ? AmerenError.error.errorDetails?.message : AmerenError.errorDescription;        
      }
    });
  }

  exportReport() {
    let keyHeaders: string[] = [];
    let modifiedlist: any = [];
    let displayHeaders: string[] = [
      'Date',
      'Generation kWh',
      'Subscriber Name',
      'kWh to Subscribers',
      'Unsubscribed kWh'
    ];

    keyHeaders = [
      'intervalReadingDate',
      'meteredQuantity',
      'subscribeQuantity',
      'unSubscribeQuantity'
    ];

    this.reportData.forEach((row: any) => {
      let temp: any = {};

      temp['intervalReadingDate'] = row.intervalReadingDate;
      temp['meteredQuantity'] = row.meteredQuantity;
      temp['subscribeQuantity'] = row.subscribeQuantity;
      temp['unSubscribeQuantity'] = row.meteredQuantity-row.subscribeQuantity;
      modifiedlist.push(temp);
    });
    this._export.exportJsonToExcelFile(
      modifiedlist,
      'generation unit daily output report',
      false,
      keyHeaders,
      displayHeaders
    );
  }

}
