import { ActivatedRoute } from '@angular/router';
import { Component, ElementRef, OnInit } from '@angular/core';
import {
  AbstractControlOptions,
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from '@angular/forms';
import { MustMatch } from 'src/app/shared/validators/must-match';
import { GenerationOwnerManagerService } from '../services/generation-owner-manager.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Banking } from 'src/app/shared/models/banking';
import { FineGrainAuthorization } from 'src/app/shared/models/fine-grain-authorization';
import { GenerationOwner } from 'src/app/shared/models/generationowner';
import { GeneralUtilities } from 'src/app/shared/utilities/general';
import { isUndefined } from 'lodash';
declare var window: any;

@Component({
  selector: 'app-edit-generation-owner',
  templateUrl: './edit-generation-owner.component.html',
  styleUrls: ['./edit-generation-owner.component.scss'],
})
export class EditGenerationOwnerComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private _generationOwnerMgrService: GenerationOwnerManagerService,
    private _route: ActivatedRoute,
    private authService: AuthService,
    private el: ElementRef
  ) {
    this._route.queryParams.subscribe((data) => {
      this.ownerId = data['ownerId'];
    });
    this.authService.getUser().then((res) => {
      this.currentUser = res?.profile;
    });
  }
  editGenOwnerForm: FormGroup;
  owner: GenerationOwner;
  showAmerenDERContact: boolean = true;
  showExistingSubscriberContact: boolean;
  ownerId: string;
  isApiLoading: boolean = true;
  isApiError: boolean;
  currentUser: any;
  bankingInfo: Banking;
  isAmerenApprovedToggled: boolean;
  termsAndConditionsModal: any;
  isInternalAdmin: boolean;
  isExternalAdmin: boolean;
  showMasked: boolean = true;
  isSaveLoading: boolean;
  isSaveError: boolean;
  isSaveBankingError: boolean;
  isSaveSuccess: boolean;
  isSaveBankingSuccess: boolean;
  successMsg: string;
  successBankingMsg: string;
  errorMsg: string;
  errorBankingMsg: string;
  isSaveComplete: boolean;
  functionalityAccess: FineGrainAuthorization;

  ngOnInit(): void {
    this.initeditGenOwnerForm();
    let role = this.authService.getSelectedGenerationOwnerRole();
    let functionality = 'GENERATION_OWNERS';

    this.functionalityAccess = this.authService.getPortalFunctioanalityAccesses(
      functionality,
      role
    );
    this.editGenOwnerForm
      ?.get('amerenDERContact')
      ?.get('sameContact')
      ?.valueChanges.subscribe((val) => {
        if (val) {
          (
            this.editGenOwnerForm.get('existingSubscriberContact') as FormGroup
          ).patchValue(this.editGenOwnerForm?.get('amerenDERContact')?.value);
        } else {
          (
            this.editGenOwnerForm.get('existingSubscriberContact') as FormGroup
          ).reset();
        }
      });

    this.editGenOwnerForm
      ?.get('existingSubscriberContact')
      ?.get('emailAddress')
      ?.valueChanges.subscribe((val) => {
        if (
          val !=
          this.editGenOwnerForm
            ?.get('existingSubscriberContact')
            ?.get('confirmEmailAddress')?.value
        ) {
          this.editGenOwnerForm
            ?.get('existingSubscriberContact')
            ?.get('confirmEmailAddress')
            ?.setErrors({ mustMatch: 'Emails must match' });
        }
      });

    this.editGenOwnerForm
      ?.get('existingSubscriberContact')
      ?.get('confirmEmailAddress')
      ?.valueChanges.subscribe((val) => {
        if (
          val !=
          this.editGenOwnerForm
            ?.get('existingSubscriberContact')
            ?.get('emailAddress')?.value
        ) {
          this.editGenOwnerForm
            ?.get('existingSubscriberContact')
            ?.get('confirmEmailAddress')
            ?.setErrors({ mustMatch: 'Emails must match' });
        }
      });

    this.editGenOwnerForm
      ?.get('bankingInfo')
      ?.get('account')
      ?.valueChanges.subscribe((val) => {
        let msk_account =
          val && val != ''
            ? val
                .toString()
                .substring(0, val.length - 4)
                .replace(/\d/g, '*') + val.toString().substring(val.length - 4)
            : val;

        this.editGenOwnerForm
          ?.get('bankingInfo')
          ?.get('maskedAccount')
          ?.setValue(msk_account);
      });

    this.editGenOwnerForm
      .get('amerenDERContact')
      ?.get('country')
      ?.valueChanges.subscribe((val) => {
        if (val) {
          this.editGenOwnerForm
            .get('amerenDERContact')
            ?.get('address')
            ?.setValidators(Validators.required);
          this.editGenOwnerForm
            .get('amerenDERContact')
            ?.get('city')
            ?.setValidators(Validators.required);
          this.editGenOwnerForm
            .get('amerenDERContact')
            ?.get('state')
            ?.setValidators(Validators.required);
          this.editGenOwnerForm
            .get('amerenDERContact')
            ?.get('zip')
            ?.setValidators(Validators.required);
        }
        this.editGenOwnerForm
          .get('amerenDERContact')
          ?.get('address')
          ?.updateValueAndValidity();
        this.editGenOwnerForm
          .get('amerenDERContact')
          ?.get('city')
          ?.updateValueAndValidity();
        this.editGenOwnerForm
          .get('amerenDERContact')
          ?.get('state')
          ?.updateValueAndValidity();
        this.editGenOwnerForm
          .get('amerenDERContact')
          ?.get('zip')
          ?.updateValueAndValidity();
      });

    this.editGenOwnerForm
      .get('existingSubscriberContact')
      ?.get('country')
      ?.valueChanges.subscribe((val) => {
        if (val) {
          this.editGenOwnerForm
            .get('existingSubscriberContact')
            ?.get('address')
            ?.setValidators(Validators.required);
          this.editGenOwnerForm
            .get('existingSubscriberContact')
            ?.get('city')
            ?.setValidators(Validators.required);
          this.editGenOwnerForm
            .get('existingSubscriberContact')
            ?.get('state')
            ?.setValidators(Validators.required);
          this.editGenOwnerForm
            .get('existingSubscriberContact')
            ?.get('zip')
            ?.setValidators(Validators.required);
        }
        this.editGenOwnerForm
          .get('existingSubscriberContact')
          ?.get('address')
          ?.updateValueAndValidity();
        this.editGenOwnerForm
          .get('existingSubscriberContact')
          ?.get('city')
          ?.updateValueAndValidity();
        this.editGenOwnerForm
          .get('existingSubscriberContact')
          ?.get('state')
          ?.updateValueAndValidity();
        this.editGenOwnerForm
          .get('existingSubscriberContact')
          ?.get('zip')
          ?.updateValueAndValidity();
      });

    this.getGenerationOwnerById();

    this.isInternalAdmin = this.functionalityAccess.isAmerenAdmin;
    this.isExternalAdmin = !this.functionalityAccess.isAmerenAdmin;
    this.termsAndConditionsModal = new window.bootstrap.Modal(
      document.getElementById('termsModal')
    );

    this.getGenerationOwnerBankingInfo();
  }

  initeditGenOwnerForm() {
    this.editGenOwnerForm = this.fb.group(
      {
        generationOwnerName: new FormControl(null, [Validators.required]),
        type: new FormControl(null, [Validators.required]),
        bankingInfo: new FormGroup({
          account: new FormControl(
            null,
            Validators.pattern('\\*{1,16}[0-9]|[0-9]{1,20}')
          ),
          maskedAccount: new FormControl(),
          routing: new FormControl(),
          termsAndConditions: new FormControl(),
          amerenApproved: new FormControl(),
          ownerApproved: new FormControl(),
          status: new FormControl(),
        }),
        amerenDERContact: new FormGroup({
          sameContact: new FormControl(),
          contactPerson: new FormControl(null, [Validators.required]),
          emailAddress: new FormControl(null, [
            Validators.required,
            Validators.email,
          ]),
          confirmEmailAddress: new FormControl(null, [Validators.required]),
          phoneNumber: new FormControl(null, [
            Validators.required,
            Validators.pattern('^[0-9]{3}-[0-9]{3}-[0-9]{4}$'),
          ]),
          phoneExt: new FormControl(null),
          country: new FormControl(null),
          otherCountry: new FormControl(null),
          address: new FormControl(null),
          address2: new FormControl(null),
          city: new FormControl(null),
          state: new FormControl(null),
          zip: new FormControl(null, [
            Validators.pattern('^[0-9]{5}$|^[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9]$'),
          ]),
        }),

        existingSubscriberContact: new FormGroup({
          contactPerson: new FormControl(null, [Validators.required]),
          emailAddress: new FormControl(null, [
            Validators.required,
            Validators.email,
          ]),
          confirmEmailAddress: new FormControl(null, [Validators.required]),
          phoneNumber: new FormControl(null, [
            Validators.required,
            Validators.pattern('^[0-9]{3}-[0-9]{3}-[0-9]{4}$'),
          ]),
          phoneExt: new FormControl(null),
          country: new FormControl(null),
          otherCountry: new FormControl(null),
          address: new FormControl(null),
          address2: new FormControl(null),
          city: new FormControl(null),
          state: new FormControl(null),
          zip: new FormControl(null, [
            Validators.pattern('^[0-9]{5}$|^[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9]$'),
          ]),
        }),
      },
      {
        validator: MustMatch(
          'amerenDERContact.emailAddress',
          'amerenDERContact.confirmEmailAddress'
        ),
      } as AbstractControlOptions
    );
  }

  saveGenerationOwner(formVal: any) {
    this.owner.name = formVal.generationOwnerName;

    let itemIndex = this.owner.contacts.findIndex(
      (item) => item.contactType == '01'
    );
    this.owner.contacts[itemIndex].contactPerson =
      formVal.amerenDERContact.contactPerson;
    this.owner.contacts[itemIndex].email =
      formVal.amerenDERContact.emailAddress;
    this.owner.contacts[itemIndex].contactPhone = GeneralUtilities.formatPhone(
      formVal.amerenDERContact.phoneNumber
    );
    this.owner.contacts[itemIndex].contactPhoneExt = formVal.amerenDERContact
      .phoneExt
      ? formVal.amerenDERContact.phoneExt
      : '';
    if (formVal.amerenDERContact.country != '') {
      this.owner.contacts[itemIndex].mailingAddress.addressLine1 =
        formVal.amerenDERContact.address;
      this.owner.contacts[itemIndex].mailingAddress.addressLine2 =
        formVal.amerenDERContact.address2;
      this.owner.contacts[itemIndex].mailingAddress.addressLine3 = '';
      this.owner.contacts[itemIndex].mailingAddress.city =
        formVal.amerenDERContact.city;
      this.owner.contacts[itemIndex].mailingAddress.state =
        formVal.amerenDERContact.state;
      this.owner.contacts[itemIndex].mailingAddress.postalCode =
        formVal.amerenDERContact.zip;
      this.owner.contacts[itemIndex].mailingAddress.country =
        formVal.amerenDERContact.country;
    }

    itemIndex = this.owner.contacts.findIndex(
      (item) => item.contactType == '09'
    );
    this.owner.contacts[itemIndex].contactPerson =
      formVal.existingSubscriberContact.contactPerson;
    this.owner.contacts[itemIndex].email =
      formVal.existingSubscriberContact.emailAddress;
    this.owner.contacts[itemIndex].contactPhone = GeneralUtilities.formatPhone(
      formVal.existingSubscriberContact.phoneNumber
    );
    this.owner.contacts[itemIndex].contactPhoneExt = formVal
      .existingSubscriberContact.phoneExt
      ? formVal.existingSubscriberContact.phoneExt
      : '';
    if (formVal.existingSubscriberContact.country != '') {
      this.owner.contacts[itemIndex].mailingAddress.addressLine1 =
        formVal.existingSubscriberContact.address;
      this.owner.contacts[itemIndex].mailingAddress.addressLine2 =
        formVal.existingSubscriberContact.address2;
      this.owner.contacts[itemIndex].mailingAddress.addressLine3 = '';
      this.owner.contacts[itemIndex].mailingAddress.city =
        formVal.existingSubscriberContact.city;
      this.owner.contacts[itemIndex].mailingAddress.state =
        formVal.existingSubscriberContact.state;
      this.owner.contacts[itemIndex].mailingAddress.postalCode =
        formVal.existingSubscriberContact.zip;
      this.owner.contacts[itemIndex].mailingAddress.country =
        formVal.existingSubscriberContact.country;
    }

    itemIndex = this.owner.contacts.findIndex(
      (item) => item.contactType == '06'
    );
    if (itemIndex != -1) {
      this.owner.contacts[itemIndex].contactPhone =
        GeneralUtilities.formatPhone(
          this.owner.contacts[itemIndex].contactPhone
        );
    }

    this._generationOwnerMgrService
      .updateGenerationOwner(this.owner, this.currentUser?.email)
      .subscribe({
        next: (resp) => {
          this.isSaveLoading = false;
          this.isSaveSuccess = true;
          this.isSaveError = false;
          this.successMsg = resp.successMessage
            ? resp.successMessage
            : 'Generation owner updated successfully. ';
        },
        error: (err) => {
          this.isSaveLoading = false;
          this.isSaveSuccess = false;
          this.isSaveError = true;
          this.errorMsg = err.errorDetails.message
            ? err.errorDetails.message
            : 'Failed to update Generation Owner. ';
        },
      });
  }

  saveBankingInfo(formVal: any) {
    let model = {
      termsAndConditions: this.bankingInfo.termsAndConditions,
      ownerApproved:
        this.bankingInfo.ownerApproved == ''
          ? ' '
          : this.bankingInfo.ownerApproved,
      amerenApproved:
        this.bankingInfo.amerenApproved == ''
          ? ' '
          : this.bankingInfo.amerenApproved,
      account:
        formVal.bankingInfo.account == ''
          ? formVal.bankingInfo.maskedAccount.toString()
          : formVal.bankingInfo.account.toString(),
      routing: formVal.bankingInfo.routing,
      status: this.bankingInfo.status,
    };

    this._generationOwnerMgrService
      .updateOwnerBankingInfo(model, this.ownerId, this.currentUser?.email)
      .subscribe({
        next: (resp) => {
          this.isSaveLoading = false;
          this.isSaveBankingSuccess = true;
          this.isSaveBankingError = false;
          this.successBankingMsg =
            'Please allow 3 to 5 business days for Ameren to process and validate the information before trying to enter in subscription fee information.';
        },
        error: (err) => {
          this.isSaveLoading = false;
          this.isSaveBankingSuccess = false;
          this.isSaveBankingError = true;
          this.errorBankingMsg = err.errorDetails.message
            ? err.errorDetails.message
            : 'Failed to update Owner Banking Information';
        },
      });
  }

  onSaveClick() {
    this.isSaveLoading = true;
    let formVal = this.editGenOwnerForm.value;

    this.saveGenerationOwner(formVal);
    if (this.editGenOwnerForm?.get('bankingInfo')?.dirty) {
      this.saveBankingInfo(formVal);
    }
  }

  toggleAmerenDERContact() {
    this.showAmerenDERContact = !this.showAmerenDERContact;
  }
  toggleExistingSubscriberContact() {
    this.showExistingSubscriberContact = !this.showExistingSubscriberContact;
  }

  getGenerationOwnerById() {
    this._generationOwnerMgrService.getOwnersByOwnerId(this.ownerId).subscribe({
      next: (resp: GenerationOwner) => {
        this.isApiLoading = false;
        this.owner = resp;
        let derContact = resp.contacts.filter(
          (x: any) => x.contactType == '01'
        )[0];
        let subContact = resp.contacts.filter(
          (x: any) => x.contactType == '09'
        )[0];

        let model = {
          amerenDERContact: {
            address: derContact.mailingAddress.addressLine1,
            address2: derContact.mailingAddress.addressLine2,
            city: derContact.mailingAddress.city,
            state: derContact.mailingAddress.state,
            zip: derContact.mailingAddress.postalCode,
            contactPerson: derContact.contactPerson,
            country: derContact.mailingAddress.country,
            emailAddress: derContact.email,
            confirmEmailAddress: derContact.email,
            otherCountry: derContact.mailingAddress.country,
            phoneNumber: derContact.contactPhone,
            phoneExt: derContact.contactPhoneExt,
          },
          existingSubscriberContact: {
            address: subContact.mailingAddress.addressLine1,
            address2: subContact.mailingAddress.addressLine2,
            city: subContact.mailingAddress.city,
            state: subContact.mailingAddress.state,
            zip: subContact.mailingAddress.postalCode,
            contactPerson: subContact.contactPerson,
            country: subContact.mailingAddress.country,
            emailAddress: subContact.email,
            confirmEmailAddress: subContact.email,
            otherCountry: subContact.mailingAddress.country,
            phoneNumber: subContact.contactPhone,
            phoneExt: subContact.contactPhoneExt,
          },
          generationOwnerName: resp.name,
          type: resp.ownerType,
        };

        this.editGenOwnerForm.patchValue(model);
      },
      error: () => {
        this.isApiError = true;
        this.isApiLoading = false;
      },
    });
  }

  onToggleAmerenApproved(event: any) {
    if (event.target.checked) {
      this.bankingInfo.amerenApproved = 'Y';
      this.isAmerenApprovedToggled = true;
    } else this.bankingInfo.amerenApproved = 'N';
  }

  onToggleOwnerApproved(event: any) {
    if (event.target.checked) this.bankingInfo.ownerApproved = 'Y';
    else {
      this.bankingInfo.ownerApproved = 'N';
    }
  }

  onToggleTermsAndConditions(event: any) {
    if (event.target.checked) {
      this.bankingInfo.ownerApproved = 'Y';
      this.termsAndConditionsModal.show();
    } else this.bankingInfo.ownerApproved = 'N';
  }

  validateAccountNumber(event: any) {
    let isNumeric: boolean = /^[0-9]/.test(event.key);
    if (!isNumeric) {
      event.preventDefault();
      return false;
    }
  }

  getGenerationOwnerBankingInfo() {
    this.isApiLoading = true;
    this._generationOwnerMgrService
      .getOwnerBankingInfo(this.ownerId)
      .subscribe({
        next: (banking: Banking) => {
          this.isApiLoading = false;
          this.bankingInfo = banking;
          this.editGenOwnerForm?.get('bankingInfo')?.patchValue(banking);
          let msk_account =
            banking.account && banking.account != ''
              ? banking.account
                  .substring(0, banking.account.length - 4)
                  .replace(/\d/g, '*') +
                banking.account.substring(banking.account.length - 4)
              : banking.account;

          this.editGenOwnerForm
            ?.get('bankingInfo')
            ?.get('maskedAccount')
            ?.setValue(msk_account);
        },
        error: () => {
          this.isApiLoading = false;
          this.bankingInfo = new Banking();
          this.editGenOwnerForm
            ?.get('bankingInfo')
            ?.patchValue(this.bankingInfo);
        },
      });
  }

  onAccountNumberFocus() {
    if (this.bankingInfo.termsAndConditions == 'Y') {
      this.showMasked = false;
      const elm = this.el.nativeElement.querySelector(
        '[formcontrolname="account"]'
      );
      elm.focus();
    }
  }
  onAccountNumberFocusOut() {
    this.showMasked = true;
  }

  closeSaveModal() {
    if (this.isSaveSuccess) this.isSaveComplete = true;
    this.isSaveError = false;
    this.isSaveBankingError = false;
    this.isSaveLoading = false;
    this.isSaveSuccess = false;
    this.isSaveBankingSuccess = false;
    this.successMsg = '';
    this.successBankingMsg = '';
    this.errorMsg = '';
    this.errorBankingMsg = '';
    this.getGenerationOwnerBankingInfo();
  }
}
