<div class="clearfix hero">
  <div class="hero__background">
    <div class="hero_banner">
      <h1>Renewables Portal</h1>
      <div class="hero__description">
        Allows generation owners to manage the participating customers/subscribers with their billing usage. 
        In addition to be portable and transferrable, renewable energy reduces the billing usage at 
        participating customers' homes and businesses.
      </div>
    </div>
  </div>

  <div class="hero__login">
    <app-login></app-login>
  </div>
</div>
