import { NumberDirective } from './shared/directives/numbers-only.directive';
import { BillingAndPaymentOptionsComponent } from './public/billing-and-payment-options/billing-and-payment-options.component';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './public/header/header.component';
import { SigninRedirectCallbackComponent } from './public/signin-redirect-callback/signin-redirect-callback.component';
import { SignoutRedirectCallbackComponent } from './public/signout-redirect-callback/signout-redirect-callback.component';
import { HomeComponent } from './public/home/home.component';
import { MenuComponent } from './public/menu/menu.component';
import { HeroComponent } from './public/hero/hero.component';
import { LoginComponent } from './public/login/login.component';
import { ContactusComponent } from './public/contactus/contactus.component';
import { PhoneMaskDirective } from './shared/directives/phone-mask.diretive';
import { FaqComponent } from './public/faq/faq.component';
import { SavingTipsComponent } from './public/saving-tips/saving-tips.component';
import { StateSelectorComponent } from './public/state-selector/state-selector.component';
import { ProgramBannerComponent } from './public/program-banner/program-banner.component';
import { AuthService } from './shared/services/auth.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CorrelationInterceptor } from './shared/interceptors/correlation-interceptor';
import { AuthInterceptor } from './shared/interceptors/auth-interceptor';
import { IneligibleUserComponent } from './secure/ineligible-user/ineligible-user.component';
import { DollarAmountPipe } from './shared/pipes/dollarAmount.pipe';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { RetryInterceptor } from './shared/interceptors/retry-interceptor';
import { AccountNumberPipe } from './shared/pipes/accountNumber.pipe';
import { SearchFilterPipe } from './shared/pipes/search-filter';
import { SafePipe } from './shared/pipes/safe.pipe';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SharedModule } from './shared/shared.module';
import { GenerationOwnerModule } from './secure/generation-owner/generation-owner.module';
import { ManageProfileModule } from './secure/manageprofile/manage-profile.module';
import { GenerationUnitModule } from './secure/generation-unit/generation-unit.module';
import { RebateModule } from './secure/rebate/rebate.module';
import { ReportsModule } from './secure/reports/reports.module';
import { SubscriberModule } from './secure/subscriber/subscriber.module';
import { RegistrationModule } from './public/registration/registration.module';
import { UsersModule } from './secure/users/users.module';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { ManageUsersAccessModule } from './secure/manage-users-access/manage-users-access.module';
import { EmulationModule } from './public/emulation/emulation.module';
import { SplunkInterceptor } from './shared/interceptors/splunk-interceptor';
import { AuditTrailModule } from './secure/audit-trail/audit-trail.module';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SigninRedirectCallbackComponent,
    SignoutRedirectCallbackComponent,
    HomeComponent,
    MenuComponent,
    HeroComponent,
    LoginComponent,
    ContactusComponent,
    PhoneMaskDirective,
    FaqComponent,
    SavingTipsComponent,
    StateSelectorComponent,
    ProgramBannerComponent,
    BillingAndPaymentOptionsComponent,
    IneligibleUserComponent,
    DollarAmountPipe,
    NumberDirective,
    AccountNumberPipe,
    SafePipe,
    AccountNumberPipe,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    RecaptchaV3Module,
    AngularEditorModule,
    SharedModule,
    GenerationOwnerModule,
    ManageProfileModule,
    GenerationUnitModule,
    RebateModule,
    ReportsModule,
    SubscriberModule,
    RegistrationModule,
    ReportsModule,
    UsersModule,
    MatTableModule,
    MatCardModule,
    ManageUsersAccessModule,
    EmulationModule,
    AuditTrailModule,
  ],
  exports: [PhoneMaskDirective, AccountNumberPipe],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CorrelationInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SplunkInterceptor, multi: true },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaSiteKey },
    DatePipe,
    CurrencyPipe,
    PercentPipe,
    AccountNumberPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
