import { Component, OnInit } from '@angular/core';
import { ExportUtility } from './../../../shared/utilities/utility.export';
import { ReportsManagerService } from '../services/reports-manager.service';
import { SubscriberManagerServiceService } from "./../../subscriber/services/subscriber-manager-service.service";
import { GenerationUnitManagerService } from "./../../generation-unit/services/generation-unit-manager.service";
import { SubscriberModel } from '../../subscriber/models/subscriber.model';
import { AuthService } from "src/app/shared/services/auth.service";
import * as _ from 'lodash';

@Component({
  selector: 'app-sub-daily-kwh-allocation-rpt',
  templateUrl: './sub-daily-kwh-allocation-rpt.component.html',
  styleUrls: ['./sub-daily-kwh-allocation-rpt.component.scss']
})
export class SubDailyKwhAllocationRptComponent implements OnInit {

  constructor(
    private genUnitMgr: GenerationUnitManagerService,
    private subMgr: SubscriberManagerServiceService,
    private reportsMgr: ReportsManagerService,
    private _export: ExportUtility,
    private authService: AuthService
  ) { 
    this.authService.getUser().then((res) => {
      this.currentUser = res?.profile;
    });
  }  
  
  isReportLoading: boolean = false;
  searchClicked: boolean;
  cssDate: string;
  minDate: string;
  maxDate: string;
  dtBegin: string;
  dtEnd: string;
  dtEndMaxDate: string;
  subAcctNumber: string;
  selectedSubscriber: SubscriberModel;  
  currentUser: any;  
  reportData: any[];  
  currentGenerationOwner: any;

  ngOnInit(): void {
    this.getCSSdate();

    this.authService.getUser().then((res) => {
      this.currentUser = res?.profile;
    });
    let external_roles = this.currentUser?.anmRoleMap.filter(
      (a:any) =>
      (a.role == 'anmExternalAdmin' || a.role == 'anmExternalViewOnly')  && (a.status === 'Active')
    );
    if (external_roles) this.currentGenerationOwner = this.authService.getSelectedGenerationOwner();
  }

  getCSSdate() {
    this.genUnitMgr.getCSSDate().subscribe({
      next: (resp) => {
        this.cssDate = resp.substring(0, 10);
        let dt = new Date(this.cssDate);
        
        this.dtEndMaxDate = this.getISOStringInLocalTime(dt).split("T")[0];
        this.maxDate = this.getISOStringInLocalTime(dt).split("T")[0];
        
        let fromDate = new Date(this.cssDate);
        this.dtBegin = this.getISOStringInLocalTime(new Date(fromDate.setDate(fromDate.getDate() - 2))).split("T")[0];
        
        let toDate = new Date(this.cssDate);
        this.dtEnd = this.getISOStringInLocalTime(new Date(toDate.setDate(toDate.getDate() - 1))).split("T")[0];              
      },
    });
  }

  onChangeFromDate(){
    let fromDate = new Date(this.dtBegin);
    let currentDate = new Date(this.cssDate);
    if (Math.floor((currentDate.getTime() - fromDate.getTime()) / (1000 * 60 * 60 * 24)) > 90){
      this.dtEndMaxDate = this.getISOStringInLocalTime(new Date(fromDate.setDate(fromDate.getDate() + 90))).split("T")[0];
      this.dtEnd = this.dtEndMaxDate;
    }
    else{
      this.dtEndMaxDate = this.getISOStringInLocalTime(currentDate).split("T")[0];
    }   
  }

  onChangeToDate(){
    let toDate = new Date(this.dtEnd);
    this.minDate = this.getISOStringInLocalTime(new Date(toDate.setDate(toDate.getDate() - 90))).split("T")[0];
  }

  getISOStringInLocalTime(date: Date){
    return new Date((date.getTime() + (-date.getTimezoneOffset() * 60000))).toISOString()
  }  

  search() {
    this.searchClicked = true;
    this.isReportLoading = true;
    this.subAcctNumber = this.subAcctNumber.length < 10 ? this.subAcctNumber.padStart(10,'0') : this.subAcctNumber;
    
    this.subMgr.getSubscriberByBillAccount(0,0,this.subAcctNumber).subscribe({
      next: (resp) => {        
        this.selectedSubscriber = resp;        

        if ( _.isNil(this.currentGenerationOwner) || 
        ( !_.isNil(this.currentGenerationOwner) && this.currentGenerationOwner == this.selectedSubscriber.ownerId) ||
        new Date(this.selectedSubscriber.effectiveDate).getTime() != new Date(this.cssDate).getTime()){
          this.reportsMgr.getSubDailyKwhAllocationReport(this.dtBegin,this.dtEnd,this.subAcctNumber).subscribe({
            next: (resp) => { 
              let ownerAccess = this.authService.getCurrentUserGenerationOwnersAccess();

              this.reportData = ownerAccess.includes('00018') ? resp :resp?.filter((a:any) =>(ownerAccess?.includes(a.owner)));

              this.isReportLoading = false;
            },
            error: () => {
              this.isReportLoading = false;             
              console.log("An error occured in report data retrieval");
            }
          });
        }        
      },
      error: () => {        
        this.isReportLoading = false;
        console.log("An error occured in subscriber search");
      }
    });    
  }

  exportReport() {
    let tableKeyHeaders: string[] = [];
    let modifiedlist: any = [];    
    let headerInfoList: any[] = [];
    let tableDisplayHeaders: string[] = [      
      "Date",
      "Generation Owner",      
      "Generation ID",
      "Nick Name",      
      "kWh to Subscriber"
    ];

    tableKeyHeaders = [      
      "date",
      "generationOwnerName",
      "generationID",
      "generationUnitNickname",
      "subscribedKWH"
    ];

    headerInfoList.push(['Subscriber Daily kWh Allocation Report','']);
    headerInfoList.push(['','']);
    headerInfoList.push(["Subscriber Account Number: ", this.selectedSubscriber.billAccountNumber]);
    headerInfoList.push(["Subscriber Name: ", this.selectedSubscriber.subscriberName]);
    headerInfoList.push(["Service From: ", this.dtBegin]);
    headerInfoList.push(["Service To: ", this.dtEnd]);
    headerInfoList.push(["Subscriber Premise Address: ", this.selectedSubscriber.subscriberAddress]);
    headerInfoList.push(["Export Date: " , this.getISOStringInLocalTime(new Date())]);
    headerInfoList.push(['','']);
    headerInfoList.push(tableDisplayHeaders);   

    this.reportData.forEach((row: any) => {
      let temp: any = {};

      temp["date"] = row.readingIntervalDate;      
      temp["generationOwnerName"] = row.ownerName;
      temp["generationID"] = row.owner;
      temp["generationUnitNickname"] = row.nickName;
      temp["subscribedKWH"] = row.subscribeQuantity;
      modifiedlist.push(temp);
    });
    this._export.exportJsonToExcelFile(
      modifiedlist,
      "Subscriber Daily kWh Allocation  Report",
      false,
      tableKeyHeaders,
      tableDisplayHeaders,
      headerInfoList
    );
  }
}
