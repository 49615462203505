<div class="gen_unit_bill_account_subscription_report_container">
    <div class="gen_unit_bill_account_subscription_report_title">
        Generation Unit Bill Account Subscription Rate Report
    </div>


    <div class="text-center" *ngIf="!genOwners">
        <ameren-loading></ameren-loading>
        <p>Please wait..</p>
    </div>


    <div class="row" *ngIf="genOwners">
        <div class="col form-group">
            <label>Generation Owner Name:<span class="text-danger">*</span></label>
            <select class="form-select" [(ngModel)]="selectedOwner" (change)="onOwnerChange()">
                <option *ngFor="let o of genOwners" [ngValue]="o">{{o.name}}</option>
            </select>
        </div>
        <div class="col form-group">
            <label>Generation Unit:<span class="text-danger">*</span></label>
            <select class="form-select" [(ngModel)]="selectedUnit" [disabled]="!units" (change)="onUnitChange()">
                <option *ngFor="let u of units" [ngValue]="u">{{u.nickName}} - Unit# {{u.unitId}}</option>
            </select>
        </div>
    </div>

    <div class="row" *ngIf="genOwners">
        <div class="col form-group">
            <label>Bill Account:</label>
            <select class="form-select" [(ngModel)]="selectedAccount">
                <option   [value]="undefined">-- Select Bill Account --</option>
                <option *ngFor="let b of billAccounts" [ngValue]="b">{{b.billAccountNumber}} - Subscriber : {{b.subscriberName}}</option>
            </select>
        </div>
        <div class="col form-group pt-4">
            <button class="btn btn-outline-success" [disabled]="!selectedUnit || !selectedOwner"
                (click)="search()">Search</button>
            <button class="btn btn-outline-success m-2" [disabled]="!reportData || !reportData.length"
                (click)="exportReport()">
                <img src="assets/icon/excelicon.svg" alt="CalendarImage" width="22" height="19" />
                Export</button>
        </div>
    </div>

    <div class="text-center" id="loading_report_criteria" *ngIf="isReportLoading || isApiLoading ">
        <ameren-loading></ameren-loading>
        <p>Please wait..</p>
    </div>

    <div class="text-danger" id="loading_report_criteria" *ngIf="integrationErrorMsg || !isApiLoading ">
        {{integrationErrorMsg}}
    </div>

  
    <table class="table gen_unit_bill_account_subscription_report_grid" *ngIf="searchClicked">
        <caption class="sr-only">
            Generation Unit Subscriber Bill Account Report
        </caption>
        <thead>
            <tr>
                <th scope="col">
                    Bill Account
                </th>
                <th scope="col">
                    Subscriber Name
                </th>
                <th scope="col">
                    Subscription Rate
                </th>
                <th scope="col">
                    Effective Date
                </th>
                <th scope="col">
                    Termination Date
                </th>

            </tr>
        </thead>
        <tbody>
            <tr *ngIf="!isReportLoading && (!reportData || !reportData.length)">
                <td colspan="9" class="text-center">{{ integrationErrorMsg ? integrationErrorMsg : 'No Data' }} </td>
            </tr>
            <tr *ngIf="isReportLoading">
                <td colspan="9" class="text-center">
                    <p>Please wait..</p>
                    <ameren-loading></ameren-loading>
                </td>
            </tr>
            <ng-container *ngIf="!isReportLoading">
                <tr *ngFor="let rep of reportData">
                    <td>
                        {{ rep.billAccountNumber }}
                    </td>
                    <td>
                        {{ rep.subscriberName }}
                    </td>
                    <td>
                        {{ rep.subscriptionRate }}
                    </td>
                    <td>
                        {{ rep.effectiveDate  | date:'MM/dd/yyyy' }}
                    </td>
                    <td>
                        {{ rep.terminationDate | date:'MM/dd/yyyy' }}
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>