import { Component, OnInit } from '@angular/core';
import { AuthService } from "src/app/shared/services/auth.service";
import { FineGrainAuthorization } from "src/app/shared/models/fine-grain-authorization";

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
 
  isIPAUser:boolean =false;
  functionalityAccess: FineGrainAuthorization;

  constructor( private _authService: AuthService) { }

  ngOnInit(): void {
    let role = this._authService.getSelectedGenerationOwnerRole();
   
    if(role[0] == null )
    {
      role = this._authService.getCurrentUserRolesAccess();
      this.isIPAUser = (role[0] == 'anmIPAViewOnly')
    }
   
    let functionality = "REPORT"              
    this.functionalityAccess = this._authService.getPortalFunctioanalityAccesses(functionality, role); 

  }

}
