import { isUndefined } from "lodash";

export class GeneralUtilities{
    static formatPhone(phoneNumber: string){
        if (!isUndefined(phoneNumber) && phoneNumber != ''){
            let phoneChars = phoneNumber.split('-');
            phoneNumber = phoneChars[0] + '-' + phoneChars[1] + phoneChars[2];
            return phoneNumber;
        }
        return '';
    }
}