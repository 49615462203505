<div class="edit-subsriber-container">
  <div class="edit-subscriber-header">Edit Subscriber</div>

  <ng-container *ngIf="!subscriberLoading; else showLoadingIndicator">
    <ng-container *ngIf="!subscriberAPIFailure; else subscriberAPIError">
      <form
        [formGroup]="editSubscriberForm"
        class="needs-validation add-subscriber-form"
      >
        <div class="container">
          <div class="form-group">
            <div class="row">
              <div class="col-md-4">
                <label class="form-label fw-bold" for="subscriberName"
                  >Subscriber Name:</label
                >
              </div>
              <div class="col-md-4">
                <span id="subscriberName">{{
                  subscriptionDetails.subscriberName
                }}</span>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-md-4">
                <label class="form-label fw-bold" for="generationUnit"
                  >Generation Unit:</label
                >
              </div>
              <div class="col-md-4">
                <span id="generationUnit">{{
                  subscriptionDetails.unitName
                }}</span>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-md-4">
                <label class="form-label fw-bold" for="billAccount"
                  >Bill Account Number:</label
                >
              </div>
              <div class="col-md-4">
                <span id="billAccount">{{
                  subscriptionDetails.billAccountNumber
                }}</span>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-md-4">
                <label class="form-label fw-bold" for="meterNumber"
                  >Meter Number:</label
                >
              </div>
              <div class="col-md-4">
                <span id="meterNumber">{{ subscriptionDetails.meterNo }}</span>
              </div>
            </div>
          </div>

          <div
            class="form-group"
            *ngIf="subscriptionDetails.resUnitSubscriberRate != 0"
          >
            <div class="row">
              <div class="col-md-4">
                <label class="form-label fw-bold" for="resSubscriptionRate"
                  >Current Default Residential Subscription Rate:</label
                >
              </div>
              <div class="col-md-4">
                <span id="resSubscriptionRate"
                  >{{ subscriptionDetails.resUnitSubscriberRate }}%</span
                >
              </div>
            </div>
          </div>

          <div
            class="form-group"
            *ngIf="subscriptionDetails.comUnitSubscriberRate != 0"
          >
            <div class="row">
              <div class="col-md-4">
                <label class="form-label fw-bold" for="nonResSubscriptionRate"
                  >Current Default Non-Residential Subscription Rate:</label
                >
              </div>
              <div class="col-md-4">
                <span id="nonResSubscriptionRate"
                  >{{ subscriptionDetails.comUnitSubscriberRate }}%</span
                >
              </div>
            </div>
          </div>

          <div class="form-group w-50">
            <label class="form-label fw-bold" for="usageQuantity"
              >Subscribed KW:</label
            >

            <div class="input-group">
              <input
                type="text"
                class="form-control"
                id="usageQuantity"
                formControlName="usageQuantity"
              />

              <label
                class="input-group-text ameren-margin-top fw-bold"
                for="usageQuantity"
                >KW</label
              >
            </div>
          </div>
          <div class="form-group">
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="overrideSubscriptionRate"
                formControlName="overrideSubscription"
              /><label
                class="form-check-label fw-bold"
                for="overrideSubscription"
                >Override Subscription Rate</label
              >
            </div>
          </div>
          <div class="form-group" *ngIf="enableSubscriptionOverrideFields">
            <label class="form-label required fw-bold" for="subscriberRate"
              >Subscription Rate:</label
            >
            <div class="input-group w-50">
              <input
                type="text"
                class="form-control"
                id="subscriberRate"
                formControlName="subscriberRate"
              />
              <span
                class="input-group-text fw-bold ameren-margin-top"
                for="subscriberRate"
                >%</span
              >
            </div>
          </div>

          <div class="form-group w-50" *ngIf="enableSubscriptionOverrideFields">
            <label
              class="form-label required fw-bold"
              for="subscriberRateEffectiveDate"
              >Subscription Rate Date Effective:</label
            >
            <input
              type="date"
              class="form-control"
              id="subscriberRateEffectiveDate"
              formControlName="subscriberRateEffectiveDate"
            />
          </div>

          <div class="form-group">
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="savingsReleaseFlag"
                formControlName="savingsReleaseFlag"
              /><label class="form-check-label fw-bold" for="savingsReleaseFlag"
                >I have received authorization from this Subscriber for Ameren
                Illinois to provide me with their bill credit rate total kWh and
                total monetary credit value that is applied to their monthly
                bill for this generation unit. I understand that for certain 3rd
                party supplied customers. Ameren Illinois may be unable to
                provide me with the bill credit rate and the total monetary
                credit value.
              </label>
            </div>
          </div>

          <div
            class="text-center m-3 gap-2 d-md-flex d-lg-flex justify-content-center"
          >
            <button
              class="ameren-tertiary-green-btn"
              (click)="cancelUpdateSubscriber()"
            >
              Cancel
            </button>
            <button
              class="ameren-primary-green-btn"
              (click)="updateSubscriber()"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </ng-container>
  </ng-container>

  <ng-template #subscriberAPIError>
    <div class="mt-3 alert alert-danger text-center" role="alert">
      Sorry, Something went wrong. Please try again.
    </div>
  </ng-template>

  <ng-template #showLoadingIndicator>
    <ameren-loading class="d-flex justify-content-center"
      >Loading...</ameren-loading
    >
  </ng-template>
</div>

<div
  id="updateSubscriberModal"
  class="modal fade"
  aria-hidden="true"
  aria-labelledby="amerenModal"
  tabindex="-1"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header" *ngIf="!updateSubscriberLoading">
        <div
          class="ameren__modal__success-title"
          *ngIf="!updateSubscriberLoading && !updateSubscriberAPFailure"
        >
          Successfully Submitted!
        </div>
        <div
          class="ameren__modal__failure-title"
          *ngIf="!updateSubscriberLoading && updateSubscriberAPFailure"
        >
          Submission Error!
        </div>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div *ngIf="updateSubscriberLoading" class="text-center">
          <ameren-loading
            id="loadingIndicator"
            class="d-flex p-10 justify-content-center"
          ></ameren-loading>
          <span>Please wait...</span>
        </div>
        <div class="ameren__modal-image text-center justify-content-center">
          <img
            *ngIf="!updateSubscriberAPFailure && !updateSubscriberLoading"
            src="assets/images/success.svg"
            alt="Success Image"
          />
          <img
            *ngIf="updateSubscriberAPFailure && !updateSubscriberLoading"
            src="assets/images/failure.svg"
            alt="Failure Image"
          />
        </div>
        <div class="ameren__modal-footer" *ngIf="!updateSubscriberLoading">
          <div
            class="ameren__modal-message text-center justify-content-center"
            *ngIf="!updateSubscriberLoading && !updateSubscriberAPFailure"
          >
            Subscriber has been updated successfully.
          </div>

          <div
            class="ameren__modal-message text-center justify-content-center"
            *ngIf="!updateSubscriberLoading && updateSubscriberAPFailure"
          >
            Subscriber has not been updated successfully. {{errorMsg}}            
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="updateSubscriberCancelModal"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-black" id="updateSubscriberCancelLabel">
          Confirm Cancellation
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <p>Are you sure you want to cancel?</p>
      </div>
      <div class="modal-footer">
        <a
          [routerLink]="['/subscribers']"
          class="btn btn-success"
          data-bs-dismiss="modal"
          >Yes</a
        >

        <button
          type="button"
          data-bs-dismiss="modal"
          class="btn btn-danger"
          data-bs-dismiss="modal"
        >
          No
        </button>
      </div>
    </div>
  </div>
</div>
