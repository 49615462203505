import { NgModule } from "@angular/core";

import { EmulationRoutingModule } from "./emulation-routing.module";
import { SharedModule } from "src/app/shared/shared.module";
import { EmulationComponent } from "./emulation.component";

@NgModule({
  declarations: [EmulationComponent],
  imports: [SharedModule, EmulationRoutingModule],
})
export class EmulationModule {}
