<div class="edit-profile-container">
  <div class="edit-profile-header">Edit Profile</div>
  <!-- <div
    class="edit_profile_back_btn"
    (click)="onBackClick()"
    *ngIf="mode != 'menu'"
  >
    <svg
      width="22px"
      height="19px"
      viewBox="0 0 22 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>🎨 Icon Сolor</title>
      <g
        id="New-Login-/-Registration"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Register-Account---validation"
          transform="translate(-62.000000, -156.000000)"
          fill="#1B6CB5"
          fill-rule="nonzero"
        >
          <path
            d="M82.6428483,164.142787 L66.5402438,164.142787 L71.4707758,158.225605 C71.9512075,157.650173 71.8724927,156.79381 71.2970604,156.314736 C70.7202709,155.834304 69.8652654,155.913019 69.3861908,156.488451 L62.6004325,164.631361 C62.5475036,164.695147 62.5203605,164.769791 62.4810031,164.840363 C62.4484315,164.897363 62.4090741,164.94622 62.3846454,165.008649 C62.3235735,165.164722 62.2869304,165.328937 62.2869304,165.49451 C62.2869304,165.495867 62.2855733,165.498581 62.2855733,165.499938 C62.2855733,165.501295 62.2869304,165.50401 62.2869304,165.505367 C62.2869304,165.670939 62.3235735,165.835155 62.3846454,165.991227 C62.4090741,166.053656 62.4484315,166.102514 62.4810031,166.159514 C62.5203605,166.230086 62.5475036,166.304729 62.6004325,166.368515 L69.3861908,174.511425 C69.6549069,174.83307 70.0403379,175 70.4284833,175 C70.7351996,175 71.043273,174.896856 71.2970604,174.685141 C71.8724927,174.206066 71.9512075,173.349704 71.4707758,172.774271 L66.5402438,166.85709 L82.6428483,166.85709 C83.3919961,166.85709 84,166.249086 84,165.499938 C84,164.750791 83.3919961,164.142787 82.6428483,164.142787"
            id="🎨-Icon-Сolor"
          ></path>
        </g>
      </g>
    </svg>
    <span>Back</span>
  </div> -->
  <div class="edit_profile_form_container">
    <form [formGroup]="editProfileForm" class="edit_profile_form">
      <div id="edit-profile-current-info" class="edit_profile_form_sub_section">
        <h4 class="section-header">Update Personal Information</h4>
        <div class="row m-0 p-0">
          <div class="form-group col-md-6 col-sm-6 d-inline">
            <label class="edit_profile_form_label">First Name <span class="required-asterisk">*</span></label>
            <input
              formControlName="firstName"
              class="form-control"
              
              type="text"
            />
          </div>
          <div class="form-group col-md-6 col-sm-6 d-inline">
            <label class="edit_profile_form_label">Last Name <span class="required-asterisk">*</span></label>
            <input
              formControlName="lastName"
              class="form-control"              
              type="text"
            />
          </div>
        </div>
      </div>
      <div id="edit-contact-info" class="edit_profile_form_sub_section">
        <h4 class="section-header">Update Contact Information</h4>
        <div class="form-group">
          <label class="edit_profile_form_label"
            >Current Email Address / UserID <span class="required-asterisk">*</span></label
          >
          <input
            formControlName="currentEmail"
            class="form-control disabled"
            readonly        
            type="text"
          />
        </div>
        <div class="form-group">
          <label class="edit_profile_form_label"
            >New Email Address / UserID</label
          >
          <input formControlName="newEmail" class="form-control" type="text" />
          <div
            class="text-field-error ameren-font-sm"
            *ngIf="
              editProfileForm?.get('newEmail')?.dirty &&
              editProfileForm?.get('newEmail')?.invalid
            "
          >
            <p *ngIf="editProfileForm?.get('newEmail')?.errors?.['required']">
              New Email Address is required.
            </p>
            <p *ngIf="editProfileForm?.get('newEmail')?.errors?.['email']">
              Invalid Email Address.
            </p>
          </div>
        </div>
        <div class="form-group">
          <label class="edit_profile_form_label"
            >Confirm New Email Address / UserID</label
          >
          <input
            formControlName="confirmNewEmail"
            class="form-control"
            type="text"
          />
          <div
            class="text-field-error ameren-font-sm"
            *ngIf="
              editProfileForm?.get('confirmNewEmail')?.dirty &&
              editProfileForm?.get('confirmNewEmail')?.invalid
            "
          >
            <p
              *ngIf="editProfileForm?.get('confirmNewEmail')?.errors?.['required']"
            >
              Confirm New Email Address is required.
            </p>
            <p
              *ngIf="editProfileForm?.get('confirmNewEmail')?.errors?.['email']"
            >
              Invalid Email Address.
            </p>
            <p
              *ngIf="editProfileForm?.get('confirmNewEmail')?.errors?.['mustMatch']"
            >
              Emails must match
            </p>
          </div>
        </div>
        <div class="form-group">
          <label class="edit_profile_form_label">Phone Number <span class="required-asterisk">*</span></label>
          <input 
            formControlName="phoneNumber"
            class="form-control"
            required
            [class.is-invalid]="editProfileForm?.get('phoneNumber')?.invalid && (editProfileForm?.get('phoneNumber')?.dirty || editProfileForm?.get('phoneNumber')?.touched)"
            type="text" minlength="14" maxlength="14"
            (input)="validatePhoneNo($event.target)"
          />
          <div
            class="text-field-error ameren-font-sm"
            *ngIf="
              editProfileForm?.get('phoneNumber')?.dirty &&
              editProfileForm?.get('phoneNumber')?.invalid
            "
          >           
            <div *ngIf="editProfileForm?.get('phoneNumber')?.errors?.['required']" id="mpphoneNumReqd">
              Phone Number is required.
            </div>
            <div *ngIf="!isPhoneNumberValidFlg" id="mpphonenumberLength">
              Phone Number must have at least 10 numbers.
            </div>

          </div>
        </div>
        <ameren-loading
          *ngIf="isLoading"
          class="d-flex justify-content-center"
        ></ameren-loading>
        <div *ngIf="isAPIFailure" class="mt-3 alert alert-danger" role="alert">
          {{ integrationErrorMsg }}
        </div>
        <div
          *ngIf="isProfileUpdateSuccess"
          class="mt-3 alert alert-success"
          role="alert"
        >
          Profile has been updated successfully.
        </div>
        <div class="form-group text-center">
          <button
            type="button"
            (click)="openModal()"
            class="btn btn-success edit_profile_form_submit_btn"
            [disabled]="!editProfileForm.valid || !(editProfileForm?.get('firstName')?.touched || editProfileForm?.get('lastName')?.touched || editProfileForm?.get('newEmail')?.touched || editProfileForm?.get('confirmNewEmail')?.touched || editProfileForm?.get('phoneNumber')?.touched) "
          >
            Save Profile Information
          </button>
        </div>
      </div>
    </form>
  </div>
  <div
    class="modal fade"
    id="myModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="pop-header row">
          <p
            class="modal-title modal-edit text-right col-md-8 mb-0"
            id="exampleModalLabel"
          >
            Edit Confirmation
          </p>
          <p class="col-md-3 mb-0"></p>
          <button
            type="button"
            class="btn-close col-md-1 text-right"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body text-center">
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="50" cy="50" r="50" fill="#218737" fill-opacity="0.1" />
            <circle cx="50" cy="50" r="35" fill="#218737" fill-opacity="0.1" />
            <circle cx="49.5" cy="49.5" r="17.5" fill="#218737" />
            <path
              d="M56.8007 45.339C56.6644 45.0782 56.7657 44.756 57.0265 44.6196C57.2879 44.4833 57.6095 44.5846 57.7459 44.8454C58.1226 45.564 58.4163 46.3309 58.6135 47.1333C58.8057 47.9169 58.9085 48.7328 58.9085 49.568C58.9085 52.3802 57.7684 54.9261 55.9256 56.7697C54.0823 58.6131 51.5362 59.7526 48.7239 59.7526C45.9116 59.7526 43.3658 58.6125 41.5222 56.7697C39.6788 54.9264 38.5393 52.3804 38.5393 49.568C38.5393 46.7557 39.6794 44.2099 41.5222 42.3663C43.3655 40.523 45.9116 39.3834 48.7239 39.3834C49.6005 39.3834 50.4568 39.4961 51.2782 39.7084C52.1206 39.9258 52.9209 40.2489 53.6641 40.6619C53.922 40.8053 54.0143 41.1303 53.8711 41.3875C53.7277 41.6446 53.4027 41.7376 53.1455 41.5945C52.4807 41.2247 51.7642 40.936 51.0106 40.7409C50.2851 40.5529 49.5188 40.4536 48.7248 40.4536C46.2082 40.4536 43.9294 41.4735 42.2803 43.1232C40.6313 44.7722 39.6107 47.0511 39.6107 49.5677C39.6107 52.0843 40.6307 54.3632 42.2803 56.0122C43.9294 57.6612 46.2082 58.6818 48.7248 58.6818C51.2414 58.6818 53.5203 57.6618 55.1693 56.0122C56.8184 54.3631 57.8389 52.0843 57.8389 49.5677C57.8389 48.8127 57.748 48.0822 57.5776 47.3874C57.4 46.6646 57.1372 45.9775 56.8022 45.3379L56.8007 45.339ZM43.3833 49.3313C44.4976 50.4309 45.9439 51.9876 47.2854 53.8632C47.5945 54.2959 48.0648 54.5211 48.5954 54.491C49.1261 54.461 49.5678 54.184 49.8265 53.7199C52.0732 49.683 57.0041 41.3372 60.4615 39.2485C60.4615 39.2485 54.3545 40.1545 48.4498 50.5242L45.5732 47.2616C44.2102 45.716 41.9165 47.8845 43.3831 49.3322L43.3833 49.3313Z"
              fill="white"
            />
          </svg>
          <p class="mt-2 modal-edit">Are you sure, you want to make changes?</p>
          <button
            type="button"
            class="btn btn-yes-ok mt-2"
            (click)="updateProfile()"
          >
            Yes
          </button>
          <button
            type="button"
            class="btn btn-no-cancel mt-2"
            data-bs-dismiss="modal"
          >
            NO
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="conformationModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="pop-header row">
          <p
            class="modal-title modal-success text-center textColor col-md-11"
            id="exampleModalLabel"
          >
            Account Edit Successful!
          </p>
        </div>
        <div class="modal-body text-center">
          <p>Your account information has been successfully updated.</p>
          <p>You are being redirected to the login page.</p>
        </div>
      </div>
    </div>
  </div>
  <app-edit-password></app-edit-password>
</div>
