import { of } from 'rxjs';
import { map, catchError, throwError, switchMap, forkJoin } from 'rxjs';
import { AmerenError } from './../../../shared/models/amerenError';
import { IGlobalAPIResponse } from './../../../shared/interfaces/iglobal-api-response';
import { SubscriberApiServiceService } from './subscriber-api-service.service';
import { Injectable } from '@angular/core';
import { SubscriberModel } from '../models/subscriber.model';
import { CacheService } from 'src/app/shared/services/cache.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SubscriberListModel } from '../../generation-unit/models/subsriberlist.model';

@Injectable({
  providedIn: 'root',
})
export class SubscriberManagerServiceService {
  currentUser: any;
  loggedInUser: any;

  constructor(
    private subscriberSvc: SubscriberApiServiceService,
    private cache: CacheService,
    private authService: AuthService
  ) {
    this.loggedInUser = this.authService.getCurrentUser();

    if (this.loggedInUser != null) this.currentUser = this.loggedInUser.profile;
  }

  validateSubscriber(
    unitId: string,
    meter: string,
    billAccountNumber: string,
    audit: string
  ) {
    return this.subscriberSvc
      .validateSubscriber(meter, unitId, billAccountNumber, audit)
      .pipe(
        map((res: IGlobalAPIResponse) => {
          if (!res.success) {
            throw new AmerenError(
              'Validate subscriber failed',
              400,
              null,
              null,
              null
            );
          }

          return res.data;
        }),
        catchError((error: any) => {
          console.log('err', error);
          return throwError(() => error);
        })
      );
  }

  addSubscriber(subscriber: any, audit: string) {
    return this.subscriberSvc.addSubscriber(subscriber, audit).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Add Subscriber subscriber failed',
            400,
            null,
            null,
            null
          );
        }

        return res.data;
      }),
      catchError((error: any) => {
        console.log('err', error);
        return throwError(() => error);
      })
    );
  }

  getSubscribers(paginate: boolean = false) {
    let ownerAccess = this.authService.getCurrentUserGenerationOwnersAccess();

    if (!paginate) {
      return this.subscriberSvc.getSubscribers().pipe(
        map((res: IGlobalAPIResponse) => {
          if (!res.success) {
            throw new AmerenError(
              "Get Subscribers failed",
              400,
              null,
              null,
              null
            );
          }

         let subsList: any[] = [];

        res.data.forEach((res: any) => {
          subsList.push(new SubscriberListModel(res));
        });

        let ownerAccess =
          this.authService.getCurrentUserGenerationOwnersAccess();

        return ownerAccess.includes('00018')
          ? subsList
          : subsList?.filter((a: any) => ownerAccess?.includes(a.ownerId));
        }),
        catchError((error: any) => {
          console.log("err", error);
          return throwError(() => error);
        })
      );
    } else {
      return this.subscriberSvc.getSubscribers(0).pipe(
        switchMap((res: IGlobalAPIResponse) => {
          if (!res.success) {
            throw new AmerenError(
              "Get Subscribers failed",
              400,
              null,
              null,
              null
            );
          }

          if (res.data.totalPages > 1) {
            let pages = [...Array(res.data.totalPages - 1).keys()];
            const _observables = pages.map((pageNum) => {
              return this.subscriberSvc.getSubscribers(pageNum + 1).pipe(
                catchError((err) => {
                  return of(err);
                })
              );
            });
            return forkJoin(_observables).pipe(
              map((responses) => {
                let results: any[] = [];
                responses.forEach((response: any) => {
                  results.push(...response.data.subscribers);
                });
                results.push(...res.data.subscribers)
                if (!ownerAccess.includes("00018"))
                  results = results.filter((a: any) =>
                    ownerAccess?.includes(a.ownerId)
                  );

                  let subList: any[] =[];
                  results.forEach(result => {
                    subList.push(new SubscriberListModel(result));
                  });
                return subList;
              })
            );
          } else {
            return of(res.data.subscribers);
          }
        })
      );
    }
  }


  getSubscriberByBillAccount(ownerId: any, unitId: any, ba: any) {
    return this.subscriberSvc
      .getSubscriberByBillAccount(ownerId, unitId, ba)
      .pipe(
        map((res: IGlobalAPIResponse) => {
          if (!res.success) {
            throw new AmerenError(
              'Get Subscribers has been failed',
              400,
              null,
              null,
              null
            );
          }

          let ownerAccess =
            this.authService.getCurrentUserGenerationOwnersAccess();

          return ownerAccess.includes('00018')
            ? new SubscriberModel(res.data)
            : new SubscriberModel(
                res.data?.filter((a: any) => ownerAccess?.includes(a.ownerId))
              );
        }),
        catchError((error: any) => {
          console.log('error', error);
          return throwError(() => error);
        })
      );
  }

  updateSubscriber(body: any) {
    return this.subscriberSvc.updateSusbcriber(body).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Update Subscriber subscriber failed',
            400,
            null,
            null,
            null
          );
        }

        return res.data;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }
}
