import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuditTrailComponent } from "./audit-trail.component";
import { AuthGuardService } from "src/app/shared/services/auth-guard.service";


const routes: Routes = [
  { path: '', component: AuditTrailComponent },
    {
      path: 'audittrail',
      component: AuditTrailComponent,
      canActivate: [AuthGuardService],
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuditTrailRoutingModule {}
