import { AbstractControl } from '@angular/forms';
import * as moment from 'moment';
import 'moment-timezone';

export function InvalidDate(control: AbstractControl) {
  let controlValue = control.value;
  let currentDate = moment().tz('America/Chicago').format('YYYY-MM-DD');

  if (moment(moment(controlValue, 'YYYY-MM-DD')).isBefore(currentDate)) {
    return { InvalidDate: 'Date must be current or future' };
  } else {
    return null;
  }
}
