export enum RebateApprovalLevels {
   Level1 = '1',
   Level2 = '2',
   Level3 = '3',
   Level4 = '4', 
  }

  export const RebateApprovalLevelsMapping = {
    [RebateApprovalLevels.Level1] : 'Maximum Approval - $150,000', 
    [RebateApprovalLevels.Level2] : 'Maximum Approval - $1,000,000',
    [RebateApprovalLevels.Level3] : 'Maximum Approval - $3,000,000',
    [RebateApprovalLevels.Level4] : 'Maximum Approval - Above $3,000,000',
   }
 