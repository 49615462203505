import { isNumber } from 'lodash';
import { Observable } from 'rxjs';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';
import { CorrelationService } from 'src/app/shared/services/correlation.service';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RebateApiService {
  constructor(
    private correlationService: CorrelationService,
    private http: HttpClient
  ) {}

  getRebatesByUnits(
    unitId: string,
    billAccount: string,
    ownerId: string
  ): Observable<any> {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'ANM')
      .set('domain', 'private')
      .set('state', 'ILLINOIS')
      .set('unitId', unitId.toString())
      .set('billAccountNumber', billAccount.toString())
      .set('ownerId', ownerId.toString());

    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/rebate/rebatesbyunitid`,
      { headers }
    );
  }

  getRebateHistoryByUnit(unitId: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('x-modified-by-user-id', 'ANM')
      .set('state', 'ILLINOIS');
    return this.http.get<IGlobalAPIResponse>(
      `${environment.cloudHub}/metering-aggregated-reports-e/v1/rebates`,
      { headers }
    );
  }

  saveRebates(rebatesBody: any, audit: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'ANM')
      .set('domain', 'private')
      .set('Content-Type', 'application/json')
      .set('audit', audit);

    return this.http.post<IGlobalAPIResponse>(
      `${environment.apig}/rebate`,
      rebatesBody,
      { headers }
    );
  }

  getAllRebates(page?: number): Observable<any> {
    let headers = new HttpHeaders()
    .set('amrnenv', `${environment.cssEnv}`)
    .set('x-modified-by-user-id', 'ANM')
    .set("state", "IL")
    .set('page',isNumber(page)?page.toString():'')

    return this.http.get<IGlobalAPIResponse>(
      `${environment.cloudHub}/${isNumber(page) ? 'metering-aggregated-reports-e/v1/rebates/pagination':'metering-aggregated-reports-e/v1/rebates'}`,
      {
        headers,
      }
    );
  }
}
