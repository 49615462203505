import {
  EnumSplunkLevels,
  EnumSplunkSourceType,
  SplunkApiSuccessResponse,
  SplunkApiFailureResponse,
  SplunkManager,
  SplunkApiSuccessRequest,
} from 'oneuiframework';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, catchError, tap } from 'rxjs';
import { AmerenError } from '../models/amerenError';
import { environment } from 'src/environments/environment';
import { ProfileManagerService } from '../services/profilemanager.service';
import { Profile } from '../models/profile';

@Injectable({
  providedIn: 'root',
})
export class SplunkInterceptor implements HttpInterceptor {
  constructor(
    private splunkManager: SplunkManager,
    private profileManager: ProfileManagerService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const startTime = performance.now();
    if (this.requestCheck(req)) {
      return next.handle(req).pipe(
        tap((evt) => {
          if (evt instanceof HttpResponse) {
            const logRequests = new SplunkApiSuccessRequest();

            let requestDetails = JSON.stringify(req.body);

            logRequests.apiRequest = JSON.parse(requestDetails);
            logRequests.url = req.url;

            const logSuccessEvents = new SplunkApiSuccessResponse();
            logSuccessEvents.apiResponse = evt.body;
            logSuccessEvents.url = evt.url || '';
            logSuccessEvents.status = evt.status;
            logSuccessEvents.statusText = evt.statusText;
            const elapsed = Math.round(performance.now() - startTime);
            const method = req.method;
            const apiName = req.url.split('/')[3].split('?')[0];
            const splunkDetails = {
              env: `${environment.environmentName}`,
              portalName: 'ANM',
              splunkToken: '',
            };
            let userName = this.getUserName();

            let message = {
              request: logRequests || '',
              response: logSuccessEvents,
            };

            this.submitSplunk(
              EnumSplunkLevels.INFO,
              EnumSplunkSourceType.CLOUDHUBAPISUCCESSLOGS,
              message,
              elapsed,
              method,
              apiName,
              splunkDetails,
              userName
            );
          }
        }),
        catchError((err: AmerenError) => {
          let request = JSON.stringify(req.body);
          let requestDetails = JSON.parse(request);

          const logFailureEvents = new SplunkApiFailureResponse();
          if (err instanceof HttpErrorResponse) {
            logFailureEvents.apiResponse = err.message;
            logFailureEvents.url = err.url || '';
            logFailureEvents.status = err.status;
            logFailureEvents.statusText = err.statusText;
            logFailureEvents.error = err.error;
            logFailureEvents.apiRequest = requestDetails || '';
          } else {
            logFailureEvents.apiResponse = err;
          }
          const elapsed = Math.round(performance.now() - startTime);
          const method = req.method;
          const apiName = req.url.split('/')[3].split('?')[0];
          const splunkDetails = {
            env: `${environment.environmentName}`,
            portalName: 'ANM',
            splunkToken: '',
          };
          const userName = this.getUserName();

          if (err.errorCode === 401) {
            this.submitSplunk(
              EnumSplunkLevels.INFO,
              EnumSplunkSourceType.CLOUDHUBAPIFAILURELOGS,
              logFailureEvents,
              elapsed,
              method,
              apiName,
              splunkDetails,
              userName
            );
          } else {
            this.submitSplunk(
              EnumSplunkLevels.WARN,
              EnumSplunkSourceType.CLOUDHUBAPIFAILURELOGS,
              logFailureEvents,
              elapsed,
              method,
              apiName,
              splunkDetails,
              userName
            );
          }

          return throwError(() => err);
        })
      );
    }

    return next.handle(req);
  }

  /*istanbul ignore next*/
  private requestCheck(req: HttpRequest<any>): boolean {
    return (
      !/splunkcloudhec/.test(req.url) && window.location.hostname != 'localhost'
    );
  }

  submitSplunk(
    level: EnumSplunkLevels,
    sourceType: EnumSplunkSourceType,
    message: any,
    elapsed: number,
    method: string,
    apiName: string,
    splunkDetails?: any,
    userName?: any
  ) {
    this.splunkManager
      .logSplunk(
        level,
        sourceType,
        message,
        elapsed,
        method,
        apiName,
        splunkDetails,
        userName
      )
      .subscribe({
        next: (data: any) => {},
        error: (error: any) => {
          console.log('error', error);
        },
      });
  }

  getEnv(url: any) {
    if (url.indexOf('prd') > -1 || url.indexOf('prod') > -1) {
      return 'PROD';
    } else if (url.indexOf('qa') > -1) {
      return 'QA';
    } else {
      return 'DEV';
    }
  }

  getPortalName(portal: any) {
    if (portal.indexOf('eap') > -1) {
      return 'EAP';
    } else if (portal.indexOf('res') > -1) {
      return 'RES';
    } else if (portal.indexOf('anm') > -1) {
      return 'ANM';
    } else if (portal.indexOf('portal') > -1) {
      return 'PORTAL';
    } else {
      return 'ACOM';
    }
  }

  getUserName() {
    let email: any;
    this.profileManager.profile.forEach((a: Profile) => {
      email = a.email;
    });

    return email;
  }
}
