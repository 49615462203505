import * as _ from 'lodash';

export class RebatesByUnitIdModel {
  unitId: string;
  rebateSeq: string;
  billAccountNumber: string;
  premiseNumber: string;
  servicePointNumber: string;
  meterNumber: string;
  subscriberName: string;
  ownerId: string;
  ownerBillAccountNumber: string;
  rebateRecipient: string;
  rebateStatus: string;
  feinStatus: string;
  subscribedQuantity: string;
  rebateEligibleQuantity: string;
  rebateRequestQuantity: string;
  rebateAmount: string;
  checkIssueDate: string;
  comments: string;
  rebateResidentialFlg: boolean;
  rebateRateChangeCode: string;
  isApproved?: boolean;
  isDenied?: boolean;
  requestRebate?: boolean;
  cancelRebate?: boolean;
  rebateStatusDescription?: string;

  constructor(rebatesbyUnit?: any) {
    this.unitId = !_.isNil(rebatesbyUnit.unitId) ? rebatesbyUnit.unitId : '';
    this.rebateSeq = !_.isNil(rebatesbyUnit.rebateSeq)
      ? rebatesbyUnit.rebateSeq
      : '';
    this.billAccountNumber = !_.isNil(rebatesbyUnit.billAccountNumber)
      ? rebatesbyUnit.billAccountNumber
      : '';
    this.premiseNumber = !_.isNil(rebatesbyUnit.premiseNumber)
      ? rebatesbyUnit.premiseNumber
      : '';
    this.servicePointNumber = !_.isNil(rebatesbyUnit.servicePointNumber)
      ? rebatesbyUnit.servicePointNumber
      : '';
    this.meterNumber = !_.isNil(rebatesbyUnit.meterNumber)
      ? rebatesbyUnit.meterNumber
      : '';
    this.subscriberName = !_.isNil(rebatesbyUnit.subscriberName)
      ? rebatesbyUnit.subscriberName
      : '';
    this.ownerId = !_.isNil(rebatesbyUnit.ownerId) ? rebatesbyUnit.ownerId : '';
    this.ownerBillAccountNumber = !_.isNil(rebatesbyUnit.ownerBillAccountNumber)
      ? rebatesbyUnit.ownerBillAccountNumber
      : '';
    this.rebateRecipient = !_.isNil(rebatesbyUnit.rebateRecipient)
      ? rebatesbyUnit.rebateRecipient
      : '';
    this.rebateStatus = !_.isNil(rebatesbyUnit.rebateStatus)
      ? rebatesbyUnit.rebateStatus
      : '';
    this.rebateStatusDescription = this.getRebateStatusDesc(this.rebateStatus);
    this.feinStatus = !_.isNil(rebatesbyUnit.feinStatus)
      ? rebatesbyUnit.feinStatus
      : '';
    this.subscribedQuantity = !_.isNil(rebatesbyUnit.subscribedQuantity)
      ? rebatesbyUnit.subscribedQuantity
      : '';
    this.rebateEligibleQuantity = !_.isNil(rebatesbyUnit.rebateEligibleQuantity)
      ? rebatesbyUnit.rebateEligibleQuantity
      : '';
    this.rebateRequestQuantity = !_.isNil(rebatesbyUnit.rebateRequestQuantity)
      ? rebatesbyUnit.rebateRequestQuantity
      : '';
    this.rebateAmount = !_.isNil(rebatesbyUnit.rebateAmount)
      ? rebatesbyUnit.rebateAmount
      : '';
    this.checkIssueDate = !_.isNil(rebatesbyUnit.checkIssueDate)
      ? rebatesbyUnit.checkIssueDate
      : '';
    this.comments = !_.isNil(rebatesbyUnit.comments)
      ? rebatesbyUnit.comments
      : '';
    this.rebateResidentialFlg = !_.isNil(rebatesbyUnit.rebateResidentialFlg)
      ? rebatesbyUnit.rebateResidentialFlg
      : false;
    this.rebateRateChangeCode = !_.isNil(rebatesbyUnit.rebateRateChangeCode)
      ? rebatesbyUnit.rebateRateChangeCode
      : '';
  }

  getRebateStatusDesc(rebateStatus: any) {
    if (rebateStatus === '1') {
      return 'Requested';
    } else if (rebateStatus === '2') {
      return 'Awaiting Net Metering';
    } else if (rebateStatus === '3') {
      return 'Awaiting FEIN';
    } else if (rebateStatus === '4') {
      return 'Awaiting Approval';
    } else if (rebateStatus === '5') {
      return 'Approved';
    } else if (rebateStatus === '6') {
      return 'Check Issued';
    } else if (rebateStatus === '7') {
      return 'Approval Rejected';
    } else if (rebateStatus === '8') {
      return 'Cancelled';
    } else {
      return '';
    }
  }
}
