<div *ngIf="!isAuthenticated" class="container-fluid">
  <div class="navbar-header">
    <button
      id="header-logo-stateSelect"
      (click)="toggleStateSelector()"
      type="button"
      class="navbar-brand collapsed"
      data-toggle="collapse"
      data-target="#state-selector"
      aria-expanded="false"
    >
      <span class="sr-only">Ameren. Focused energy. For life.</span>
      <div class="logo-container" id="logo-container-default">
        <!-- Default Ameren Logo -->
        <svg
          *ngIf="!selectedState || selectedState == ''"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 186 71"
        >
          <title>logo_ameren_default</title>
          <g>
            <path
              class="logo-cls-1"
              d="M24,33H.19a29.25,29.25,0,0,1-.11-5.38L24,32.59V33Z"
            ></path>
            <path
              class="logo-cls-2"
              d="M40,30.35,60.2,22.73a29.32,29.32,0,0,1,.67,4.82L40.12,30.68a.41.41,0,0,0-.11-.34Z"
            ></path>
            <path
              class="logo-cls-1"
              d="M24.31,30.24.42,24.53a33.51,33.51,0,0,1,2.13-6.72l22,12a.58.58,0,0,0-.22.45Z"
            ></path>
            <path
              class="logo-cls-1"
              d="M37.54,26.43l13.35-19a25.39,25.39,0,0,1,4.37,4.82L37.88,26.77l-.34-.34Z"
            ></path>
            <path
              class="logo-cls-1"
              d="M26,27.32,4.57,13.89a30.76,30.76,0,0,1,6.17-6.94L26.21,27c-.11.11-.22.22-.22.34Z"
            ></path>
            <path
              class="logo-cls-1"
              d="M33.62,24.64,34.29,0a31.64,31.64,0,0,1,8.52,2.35l-8.75,22.4c-.11-.11-.22-.11-.45-.11Z"
            ></path>
            <path
              class="logo-cls-1"
              d="M29.24,25.09,15.56,3.7A29.2,29.2,0,0,1,26.44,0l3.25,24.86a.85.85,0,0,0-.45.22Z"
            ></path>
            <path
              class="logo-cls-1"
              d="M2.21,40.65s-.45-1.12-.9-2.46S.64,35.5.64,35.5c7.74-1,19.85-1.12,28.15-1.12,0,0-16,1.57-26.58,6.27Z"
            ></path>
            <path
              class="logo-cls-1"
              d="M19.37,57.45a22.22,22.22,0,0,1-4.26-1.9,28.9,28.9,0,0,1-4-2.91c4.71-5.94,19.85-17.47,34.09-18.25,0,0-14.69,5.15-25.8,23.07Z"
            ></path>
            <path
              class="logo-cls-1"
              d="M38.44,58.46a31.67,31.67,0,0,1-8,1,29.09,29.09,0,0,1-6.28-.67c.9-3,11.1-20.72,23.33-24.41,0,0-9.42,10-9.08,24.08Z"
            ></path>
            <path
              class="logo-cls-1"
              d="M7.82,49.61a29.31,29.31,0,0,1-2.47-3,21.72,21.72,0,0,1-2-3.47c3.81-2.46,17.38-8.74,36.56-8.74A80.44,80.44,0,0,0,7.82,49.61Z"
            ></path>
            <path
              class="logo-cls-3"
              d="M41.69,70.44l17-36.06h9.65L66.48,70.44H58.85l.67-7.28H52.46l-3,7.28ZM61.54,42.11,54.7,58H60l1.57-15.9Z"
            ></path>
            <path
              class="logo-cls-3"
              d="M68.27,70.44l7-27.55h7.51l-.56,2a11.68,11.68,0,0,1,6.73-2.58c3.36,0,4,2.24,4.26,2.91.78-.45,4.26-2.91,7.29-2.91,5.16,0,4.15,4,3.81,5.38L98.55,70.44H91l5.38-21.73c.22-.56,0-1.34-1.46-1.34a4.63,4.63,0,0,0-2.36,1l-5.5,22.06H79.6l5.5-21.73c0-.22.34-1.34-1.57-1.34a4.12,4.12,0,0,0-2.13.9L75.79,70.44Z"
            ></path>
            <path
              class="logo-cls-3"
              d="M110.89,71c-7.07,0-9.08-2.69-7.74-8.18l3.14-12.43c1-4.26,4.93-8.06,11.66-8.06,8.41,0,8.19,5.82,7.85,7.17l-2.13,8.4H111.56l-1.46,5.94c-.34,1.34,0,2.46,1.57,2.46a3.27,3.27,0,0,0,3.25-2.46l.67-2.69h7.18l-.67,2.69c-.9,2.8-3.93,7.17-11.22,7.17Zm2.92-21.84-1.12,4.14h4.82l1-4.14c.22-.56.56-2-1.68-2a2.83,2.83,0,0,0-3,2Z"
            ></path>
            <path
              class="logo-cls-3"
              d="M123.23,70.44l7-27.55h7.07l-.67,2.69c3-2.13,5.16-3.25,9.42-3.25l-1.23,5.26a27.66,27.66,0,0,0-2.92-.22c-5.61,0-6.5,3.47-7,5.26L130.4,70.44Z"
            ></path>
            <path
              class="logo-cls-3"
              d="M148.91,71c-7.07,0-9.08-2.69-7.74-8.18l3.14-12.43c1-4.26,4.93-8.06,11.55-8.06,8.52,0,8.3,5.82,8,7.17l-2.13,8.4H149.58l-1.46,5.94c-.34,1.34,0,2.46,1.57,2.46a3.27,3.27,0,0,0,3.25-2.46l.67-2.69h7.18L160,63.83c-.9,2.8-3.81,7.17-11.1,7.17Zm2.8-21.84-1,4.14h4.82l1-4.14c.22-.56.56-2-1.68-2a3.07,3.07,0,0,0-3.14,2Z"
            ></path>
            <path
              class="logo-cls-3"
              d="M161.36,70.44l7-27.55h7.4L175.15,45c.9-.67,3.25-2.69,6.5-2.69,5.5,0,4.49,4.59,3.81,7.28L180.2,70.44h-7.51L177.62,51c.56-2.35.79-3.47-.79-3.47a5.92,5.92,0,0,0-2.47.9l-5.5,22.06Z"
            ></path>
          </g>
        </svg>
        <svg
          *ngIf="selectedState == 'illinois'"
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 186 94"
        >
          <title>logo_ameren_illinois</title>
          <g>
            <g>
              <path
                class="logo-cls-1"
                d="M24,33H.19a29.19,29.19,0,0,1-.11-5.37L24,32.56V33Z"
              ></path>
              <path
                class="logo-cls-2"
                d="M40,30.32l20.19-7.61a29.26,29.26,0,0,1,.67,4.81L40.11,30.66a.41.41,0,0,0-.11-.34Z"
              ></path>
              <path
                class="logo-cls-1"
                d="M24.3,30.21.41,24.5a33.45,33.45,0,0,1,2.13-6.71l22,12a.58.58,0,0,0-.22.45Z"
              ></path>
              <path
                class="logo-cls-1"
                d="M37.53,26.4l13.35-19a25.37,25.37,0,0,1,4.37,4.81L37.87,26.74l-.34-.34Z"
              ></path>
              <path
                class="logo-cls-1"
                d="M26,27.3,4.56,13.87a30.74,30.74,0,0,1,6.17-6.94L26.2,27c-.11.11-.22.22-.22.34Z"
              ></path>
              <path
                class="logo-cls-1"
                d="M33.61,24.61,34.28,0A31.66,31.66,0,0,1,42.8,2.35L34.06,24.73c-.11-.11-.22-.11-.45-.11Z"
              ></path>
              <path
                class="logo-cls-1"
                d="M29.23,25.06,15.55,3.69A29.22,29.22,0,0,1,26.43,0l3.25,24.84a.85.85,0,0,0-.45.22Z"
              ></path>
              <path
                class="logo-cls-1"
                d="M2.2,40.61s-.45-1.12-.9-2.46S.63,35.47.63,35.47c7.74-1,19.85-1.12,28.15-1.12,0,0-16,1.57-26.58,6.27Z"
              ></path>
              <path
                class="logo-cls-1"
                d="M19.36,57.4a22.23,22.23,0,0,1-4.26-1.9,28.9,28.9,0,0,1-4-2.91c4.71-5.93,19.85-17.45,34.09-18.24,0,0-14.69,5.15-25.8,23Z"
              ></path>
              <path
                class="logo-cls-1"
                d="M38.43,58.4a31.7,31.7,0,0,1-8,1,29.12,29.12,0,0,1-6.28-.67c.9-3,11.1-20.7,23.33-24.39,0,0-9.42,10-9.08,24.05Z"
              ></path>
              <path
                class="logo-cls-1"
                d="M7.81,49.56a29.29,29.29,0,0,1-2.47-3,21.69,21.69,0,0,1-2-3.47c3.81-2.46,17.38-8.73,36.56-8.73A80.48,80.48,0,0,0,7.81,49.56Z"
              ></path>
              <path
                class="logo-cls-3"
                d="M41.68,70.37l17-36h9.65l-1.91,36H58.84l.67-7.27H52.45l-3,7.27ZM61.53,42.07,54.69,58H60l1.57-15.89Z"
              ></path>
              <path
                class="logo-cls-3"
                d="M68.26,70.37l7-27.52h7.51l-.56,2a11.69,11.69,0,0,1,6.73-2.57c3.36,0,4,2.24,4.26,2.91.78-.45,4.26-2.91,7.29-2.91,5.16,0,4.15,4,3.81,5.37L98.54,70.37H91l5.38-21.71c.22-.56,0-1.34-1.46-1.34a4.63,4.63,0,0,0-2.36,1l-5.5,22H79.59l5.5-21.71c0-.22.34-1.34-1.57-1.34a4.12,4.12,0,0,0-2.13.9L75.78,70.37Z"
              ></path>
              <path
                class="logo-cls-3"
                d="M110.88,70.93c-7.07,0-9.08-2.69-7.74-8.17l3.14-12.42c1-4.25,4.93-8.06,11.66-8.06,8.41,0,8.19,5.82,7.85,7.16l-2.13,8.39H111.55l-1.46,5.93c-.34,1.34,0,2.46,1.57,2.46a3.27,3.27,0,0,0,3.25-2.46l.67-2.69h7.18l-.67,2.69c-.9,2.8-3.93,7.16-11.22,7.16Zm2.92-21.82-1.12,4.14h4.82l1-4.14c.22-.56.56-2-1.68-2a2.83,2.83,0,0,0-3,2Z"
              ></path>
              <path
                class="logo-cls-3"
                d="M123.22,70.37l7-27.52h7.07l-.67,2.69c3-2.13,5.16-3.24,9.42-3.24l-1.23,5.26a27.68,27.68,0,0,0-2.92-.22c-5.61,0-6.5,3.47-7,5.26l-4.49,17.79Z"
              ></path>
              <path
                class="logo-cls-3"
                d="M148.9,70.93c-7.07,0-9.08-2.69-7.74-8.17l3.14-12.42c1-4.25,4.93-8.06,11.55-8.06,8.52,0,8.3,5.82,8,7.16l-2.13,8.39H149.57l-1.46,5.93c-.34,1.34,0,2.46,1.57,2.46a3.27,3.27,0,0,0,3.25-2.46l.67-2.69h7.18L160,63.77c-.9,2.8-3.81,7.16-11.1,7.16Zm2.8-21.82-1,4.14h4.82l1-4.14c.22-.56.56-2-1.68-2a3.07,3.07,0,0,0-3.14,2Z"
              ></path>
              <path
                class="logo-cls-3"
                d="M161.35,70.37l7-27.52h7.4L175.14,45c.9-.67,3.25-2.69,6.5-2.69,5.5,0,4.49,4.59,3.81,7.27l-5.27,20.81h-7.51l4.93-19.47c.56-2.35.79-3.47-.79-3.47a5.93,5.93,0,0,0-2.47.9l-5.5,22Z"
              ></path>
            </g>
            <g>
              <path
                class="logo-cls-4"
                d="M44.25,77h3.38L43.79,93.74H40.41Z"
              ></path>
              <path
                class="logo-cls-4"
                d="M51.07,77h3.38l-3.2,14h6l-.63,2.77H47.24Z"
              ></path>
              <path
                class="logo-cls-4"
                d="M62.88,77h3.38l-3.2,14h6l-.63,2.77H59Z"
              ></path>
              <path
                class="logo-cls-4"
                d="M74.84,77h3.38L74.39,93.74H71Z"
              ></path>
              <path
                class="logo-cls-4"
                d="M87,88.47h0L89.69,77h3.1L89,93.74H85.11L83.67,82h0L80.93,93.74h-3.1L81.67,77h3.9Z"
              ></path>
              <path
                class="logo-cls-4"
                d="M94.79,81.87a9.79,9.79,0,0,1,1.4-2.75,6,6,0,0,1,2.14-1.79,7,7,0,0,1,3.17-.64,5.27,5.27,0,0,1,2.88.64,3.13,3.13,0,0,1,1.31,1.79,6.94,6.94,0,0,1,.14,2.75,33.63,33.63,0,0,1-.63,3.5,36.17,36.17,0,0,1-1,3.52,9.76,9.76,0,0,1-1.4,2.74,5.74,5.74,0,0,1-2.13,1.76,7.29,7.29,0,0,1-3.17.61,5.55,5.55,0,0,1-2.89-.61,3,3,0,0,1-1.32-1.76,6.92,6.92,0,0,1-.15-2.74,33.48,33.48,0,0,1,.63-3.52A35.66,35.66,0,0,1,94.79,81.87Zm1.82,6.38a7.63,7.63,0,0,0-.11,1.92A1.62,1.62,0,0,0,97,91.25a1.65,1.65,0,0,0,1.13.34,2.35,2.35,0,0,0,1.29-.34,3,3,0,0,0,1-1.08,9.5,9.5,0,0,0,.77-1.92q.35-1.18.74-2.87t.57-2.86a7.74,7.74,0,0,0,.11-1.92,1.63,1.63,0,0,0-.45-1.09,1.65,1.65,0,0,0-1.13-.34,2.35,2.35,0,0,0-1.29.34,3,3,0,0,0-1,1.09,9.79,9.79,0,0,0-.77,1.92q-.35,1.17-.74,2.86T96.61,88.24Z"
              ></path>
              <path
                class="logo-cls-4"
                d="M110.22,77h3.38l-3.83,16.74h-3.38Z"
              ></path>
              <path
                class="logo-cls-4"
                d="M117,89a5.65,5.65,0,0,0-.15,1.07,1.51,1.51,0,0,0,.17.81,1.24,1.24,0,0,0,.57.52,2.45,2.45,0,0,0,1,.19,2.65,2.65,0,0,0,1.51-.5,2.46,2.46,0,0,0,1-1.56,2.7,2.7,0,0,0,.07-1,1.44,1.44,0,0,0-.33-.74,2.61,2.61,0,0,0-.79-.6,10.61,10.61,0,0,0-1.29-.55,9.34,9.34,0,0,1-1.76-.82,3.75,3.75,0,0,1-1.13-1,2.89,2.89,0,0,1-.49-1.38,5.69,5.69,0,0,1,.16-1.82,6.06,6.06,0,0,1,2.23-3.7,6.72,6.72,0,0,1,4.08-1.22,7.65,7.65,0,0,1,2,.25,3.31,3.31,0,0,1,1.46.8,2.77,2.77,0,0,1,.76,1.41,4.68,4.68,0,0,1-.08,2.05l-.11.47H122.7a3.08,3.08,0,0,0,0-1.84,1.21,1.21,0,0,0-1.26-.64,2.64,2.64,0,0,0-1,.16,2.17,2.17,0,0,0-.7.43,2,2,0,0,0-.45.62,3.65,3.65,0,0,0-.25.73,2.21,2.21,0,0,0,0,1.3,2.14,2.14,0,0,0,1.19,1l2.35,1.12a6,6,0,0,1,1.37.88,3.1,3.1,0,0,1,.75,1,2.69,2.69,0,0,1,.24,1.16,6.55,6.55,0,0,1-.18,1.41,6,6,0,0,1-2.42,3.86A8.07,8.07,0,0,1,117.83,94Q115,94,114,92.76a4.18,4.18,0,0,1-.42-3.56l.16-.68h3.38Z"
              ></path>
            </g>
          </g>
        </svg>
        <svg
          *ngIf="selectedState == 'missouri'"
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 186 94"
        >
          <title>logo_ameren_missouri</title>
          <g>
            <g>
              <path
                class="logo-cls-1"
                d="M24,33H.19a29.25,29.25,0,0,1-.11-5.38L24,32.59V33Z"
              ></path>
              <path
                class="logo-cls-2"
                d="M40,30.35,60.2,22.73a29.32,29.32,0,0,1,.67,4.82L40.12,30.68a.41.41,0,0,0-.11-.34Z"
              ></path>
              <path
                class="logo-cls-1"
                d="M24.31,30.24.42,24.53a33.51,33.51,0,0,1,2.13-6.72l22,12a.58.58,0,0,0-.22.45Z"
              ></path>
              <path
                class="logo-cls-1"
                d="M37.54,26.43l13.35-19a25.39,25.39,0,0,1,4.37,4.82L37.88,26.77l-.34-.34Z"
              ></path>
              <path
                class="logo-cls-1"
                d="M26,27.32,4.57,13.89a30.76,30.76,0,0,1,6.17-6.94L26.21,27c-.11.11-.22.22-.22.34Z"
              ></path>
              <path
                class="logo-cls-1"
                d="M33.62,24.64,34.29,0a31.64,31.64,0,0,1,8.52,2.35l-8.75,22.4c-.11-.11-.22-.11-.45-.11Z"
              ></path>
              <path
                class="logo-cls-1"
                d="M29.24,25.09,15.56,3.7A29.2,29.2,0,0,1,26.44,0l3.25,24.86a.85.85,0,0,0-.45.22Z"
              ></path>
              <path
                class="logo-cls-1"
                d="M2.21,40.65s-.45-1.12-.9-2.46S.64,35.5.64,35.5c7.74-1,19.85-1.12,28.15-1.12,0,0-16,1.57-26.58,6.27Z"
              ></path>
              <path
                class="logo-cls-1"
                d="M19.37,57.45a22.22,22.22,0,0,1-4.26-1.9,28.9,28.9,0,0,1-4-2.91c4.71-5.94,19.85-17.47,34.09-18.25,0,0-14.69,5.15-25.8,23.07Z"
              ></path>
              <path
                class="logo-cls-1"
                d="M38.44,58.46a31.67,31.67,0,0,1-8,1,29.09,29.09,0,0,1-6.28-.67c.9-3,11.1-20.72,23.33-24.41,0,0-9.42,10-9.08,24.08Z"
              ></path>
              <path
                class="logo-cls-1"
                d="M7.82,49.61a29.31,29.31,0,0,1-2.47-3,21.72,21.72,0,0,1-2-3.47c3.81-2.46,17.38-8.74,36.56-8.74A80.44,80.44,0,0,0,7.82,49.61Z"
              ></path>
              <path
                class="logo-cls-3"
                d="M41.69,70.44l17-36.06h9.65L66.48,70.44H58.85l.67-7.28H52.46l-3,7.28ZM61.54,42.11,54.7,58H60l1.57-15.9Z"
              ></path>
              <path
                class="logo-cls-3"
                d="M68.27,70.44l7-27.55h7.51l-.56,2a11.68,11.68,0,0,1,6.73-2.58c3.36,0,4,2.24,4.26,2.91.78-.45,4.26-2.91,7.29-2.91,5.16,0,4.15,4,3.81,5.38L98.55,70.44H91l5.38-21.73c.22-.56,0-1.34-1.46-1.34a4.63,4.63,0,0,0-2.36,1l-5.5,22.06H79.6l5.5-21.73c0-.22.34-1.34-1.57-1.34a4.12,4.12,0,0,0-2.13.9L75.79,70.44Z"
              ></path>
              <path
                class="logo-cls-3"
                d="M110.89,71c-7.07,0-9.08-2.69-7.74-8.18l3.14-12.43c1-4.26,4.93-8.06,11.66-8.06,8.41,0,8.19,5.82,7.85,7.17l-2.13,8.4H111.56l-1.46,5.94c-.34,1.34,0,2.46,1.57,2.46a3.27,3.27,0,0,0,3.25-2.46l.67-2.69h7.18l-.67,2.69c-.9,2.8-3.93,7.17-11.22,7.17Zm2.92-21.84-1.12,4.14h4.82l1-4.14c.22-.56.56-2-1.68-2a2.83,2.83,0,0,0-3,2Z"
              ></path>
              <path
                class="logo-cls-3"
                d="M123.23,70.44l7-27.55h7.07l-.67,2.69c3-2.13,5.16-3.25,9.42-3.25l-1.23,5.26a27.66,27.66,0,0,0-2.92-.22c-5.61,0-6.5,3.47-7,5.26L130.4,70.44Z"
              ></path>
              <path
                class="logo-cls-3"
                d="M148.91,71c-7.07,0-9.08-2.69-7.74-8.18l3.14-12.43c1-4.26,4.93-8.06,11.55-8.06,8.52,0,8.3,5.82,8,7.17l-2.13,8.4H149.58l-1.46,5.94c-.34,1.34,0,2.46,1.57,2.46a3.27,3.27,0,0,0,3.25-2.46l.67-2.69h7.18L160,63.83c-.9,2.8-3.81,7.17-11.1,7.17Zm2.8-21.84-1,4.14h4.82l1-4.14c.22-.56.56-2-1.68-2a3.07,3.07,0,0,0-3.14,2Z"
              ></path>
              <path
                class="logo-cls-3"
                d="M161.36,70.44l7-27.55h7.4L175.15,45c.9-.67,3.25-2.69,6.5-2.69,5.5,0,4.49,4.59,3.81,7.28L180.2,70.44h-7.51L177.62,51c.56-2.35.79-3.47-.79-3.47a5.92,5.92,0,0,0-2.47.9l-5.5,22.06Z"
              ></path>
            </g>
            <g>
              <path
                class="logo-cls-4"
                d="M49.23,77.09l-.31,11.75h0l5.09-11.75h5L55.21,93.74H52.13l3-13.29h0l-6,13.29H46.51l.08-13.29h0l-3,13.29H40.42l3.81-16.65Z"
              ></path>
              <path
                class="logo-cls-4"
                d="M62.52,77.09h3.36L62.07,93.74H58.71Z"
              ></path>
              <path
                class="logo-cls-4"
                d="M69.28,89a5.58,5.58,0,0,0-.15,1.06,1.5,1.5,0,0,0,.17.8,1.23,1.23,0,0,0,.57.51,2.43,2.43,0,0,0,1,.19,2.63,2.63,0,0,0,1.5-.5,2.45,2.45,0,0,0,1-1.55,2.7,2.7,0,0,0,.07-1,1.43,1.43,0,0,0-.33-.73,2.6,2.6,0,0,0-.79-.6,10.57,10.57,0,0,0-1.28-.55,9.3,9.3,0,0,1-1.75-.82,3.72,3.72,0,0,1-1.13-1,2.88,2.88,0,0,1-.49-1.38,5.69,5.69,0,0,1,.16-1.81A6,6,0,0,1,70.06,78a6.68,6.68,0,0,1,4.06-1.21,7.6,7.6,0,0,1,2,.24,3.29,3.29,0,0,1,1.45.79,2.74,2.74,0,0,1,.75,1.4,4.67,4.67,0,0,1-.08,2l-.11.47H74.92a3.07,3.07,0,0,0,0-1.83,1.21,1.21,0,0,0-1.25-.64,2.62,2.62,0,0,0-1,.16,2.17,2.17,0,0,0-.69.43,2,2,0,0,0-.44.62,3.67,3.67,0,0,0-.25.72,2.2,2.2,0,0,0,0,1.29,2.12,2.12,0,0,0,1.18,1l2.33,1.12a5.9,5.9,0,0,1,1.36.87,3.07,3.07,0,0,1,.74,1,2.69,2.69,0,0,1,.24,1.15A6.52,6.52,0,0,1,77,89a6,6,0,0,1-2.41,3.84A8,8,0,0,1,70.08,94q-2.85,0-3.79-1.24a4.16,4.16,0,0,1-.41-3.55l.15-.68h3.36Z"
              ></path>
              <path
                class="logo-cls-4"
                d="M82.32,89a5.58,5.58,0,0,0-.15,1.06,1.5,1.5,0,0,0,.17.8,1.23,1.23,0,0,0,.57.51,2.43,2.43,0,0,0,1,.19,2.63,2.63,0,0,0,1.5-.5,2.45,2.45,0,0,0,1-1.55,2.7,2.7,0,0,0,.07-1,1.43,1.43,0,0,0-.33-.73,2.6,2.6,0,0,0-.79-.6,10.57,10.57,0,0,0-1.28-.55,9.3,9.3,0,0,1-1.75-.82,3.72,3.72,0,0,1-1.13-1,2.88,2.88,0,0,1-.49-1.38,5.69,5.69,0,0,1,.16-1.81A6,6,0,0,1,83.1,78a6.68,6.68,0,0,1,4.06-1.21,7.6,7.6,0,0,1,2,.24,3.29,3.29,0,0,1,1.45.79,2.74,2.74,0,0,1,.75,1.4,4.67,4.67,0,0,1-.08,2l-.11.47H88a3.07,3.07,0,0,0,0-1.83,1.21,1.21,0,0,0-1.25-.64,2.62,2.62,0,0,0-1,.16,2.17,2.17,0,0,0-.69.43,2,2,0,0,0-.44.62,3.67,3.67,0,0,0-.25.72,2.2,2.2,0,0,0,0,1.29,2.12,2.12,0,0,0,1.18,1l2.33,1.12a5.9,5.9,0,0,1,1.36.87,3.07,3.07,0,0,1,.74,1,2.69,2.69,0,0,1,.24,1.15,6.52,6.52,0,0,1-.18,1.4,6,6,0,0,1-2.41,3.84A8,8,0,0,1,83.12,94q-2.85,0-3.79-1.24a4.16,4.16,0,0,1-.41-3.55l.15-.68h3.36Z"
              ></path>
              <path
                class="logo-cls-4"
                d="M94.11,81.93a9.75,9.75,0,0,1,1.4-2.74,6,6,0,0,1,2.12-1.78,7,7,0,0,1,3.16-.64,5.24,5.24,0,0,1,2.86.64A3.11,3.11,0,0,1,105,79.19a6.92,6.92,0,0,1,.14,2.74,33.5,33.5,0,0,1-.62,3.49,35.87,35.87,0,0,1-1,3.5,9.72,9.72,0,0,1-1.39,2.73A5.71,5.71,0,0,1,100,93.39a7.24,7.24,0,0,1-3.15.61A5.52,5.52,0,0,1,94,93.39a3,3,0,0,1-1.31-1.75,6.89,6.89,0,0,1-.15-2.73,33.45,33.45,0,0,1,.63-3.5A36,36,0,0,1,94.11,81.93Zm1.81,6.34a7.63,7.63,0,0,0-.11,1.91,1.61,1.61,0,0,0,.45,1.07,1.64,1.64,0,0,0,1.12.34,2.34,2.34,0,0,0,1.28-.34,3,3,0,0,0,.95-1.07,9.49,9.49,0,0,0,.76-1.91q.35-1.18.74-2.86t.57-2.85a7.75,7.75,0,0,0,.11-1.91,1.62,1.62,0,0,0-.45-1.08,1.64,1.64,0,0,0-1.12-.34,2.34,2.34,0,0,0-1.28.34A2.93,2.93,0,0,0,98,80.66a9.69,9.69,0,0,0-.76,1.91q-.35,1.17-.73,2.85T95.92,88.27Z"
              ></path>
              <path
                class="logo-cls-4"
                d="M109.78,88.71a7.74,7.74,0,0,0-.18,1.15,2.27,2.27,0,0,0,.09.91,1.17,1.17,0,0,0,.47.61,1.64,1.64,0,0,0,.92.22,2.19,2.19,0,0,0,1-.22,2.3,2.3,0,0,0,.73-.61,3.35,3.35,0,0,0,.51-.91,8.56,8.56,0,0,0,.35-1.15l2.66-11.61h3.36l-2.66,11.61a8,8,0,0,1-1,2.62A5.84,5.84,0,0,1,114.45,93a5.2,5.2,0,0,1-1.89.82,10,10,0,0,1-4,0,3.1,3.1,0,0,1-1.53-.76,2.89,2.89,0,0,1-.82-1.62,6.84,6.84,0,0,1,.18-2.73l2.66-11.61h3.36Z"
              ></path>
              <path
                class="logo-cls-4"
                d="M129,77.09a3.82,3.82,0,0,1,3,1.06,3.55,3.55,0,0,1,.43,3.23,6.24,6.24,0,0,1-1.3,2.74,4.19,4.19,0,0,1-2.41,1.36v0a2.36,2.36,0,0,1,1.66.82,3,3,0,0,1,.26,2q-.06.47-.16,1l-.23,1.23q-.26,1.33-.32,2a1,1,0,0,0,.39,1l0,.14h-3.64a1.51,1.51,0,0,1-.14-.8,8.87,8.87,0,0,1,.12-.95l.64-3.19a2.3,2.3,0,0,0-.14-1.54c-.21-.37-.67-.56-1.37-.56h-1.8l-1.61,7H119l3.81-16.65Zm-3.11,7.28a3.3,3.3,0,0,0,2-.57A3.14,3.14,0,0,0,129,81.87q.53-2.31-1.8-2.31h-1.59l-1.1,4.81Z"
              ></path>
              <path
                class="logo-cls-4"
                d="M136.84,77.09h3.36l-3.81,16.65H133Z"
              ></path>
            </g>
          </g>
        </svg>
      </div>
      <div class="nav-bar-u-icon">
        <svg
          *ngIf="!showStateSelector"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-chevron-down"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          *ngIf="showStateSelector"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-chevron-up"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
          />
        </svg>
      </div>
    </button>
  </div>
  <div
    id="main-menu"
    class="nav--subnav navbar-collapse collapse text-right"
    aria-expanded="false"
  >
    <ul class="nav nav--secondary navbar-nav navbar-left"></ul>
    <ul class="nav nav--secondary navbar-nav navbar-right">
      <li>
        <a
          *ngIf="!isAuthenticated"
          id="secondary-navigation-27113cfc-4c95-4fe3-b47a-459e9838a46e"
          (click)="login()"
          rel=""
          class="accountDashboard_subNav-Outages account-dashboard-subnav"
          target=""
        >
          <svg
            viewBox="0 0 50 50"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <title>Log In</title>
            <g
              id="New-Login-/-Registration"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <g
                id="Login"
                transform="translate(-1351.000000, -26.000000)"
                stroke="#020303"
              >
                <g
                  id="header/not-logged-in"
                  transform="translate(45.000000, 20.000000)"
                >
                  <g id="LogIN" transform="translate(1307.690395, 7.000000)">
                    <line
                      x1="3.9780295e-13"
                      y1="17.3"
                      x2="27.992894"
                      y2="17.3"
                      id="Path"
                      stroke-width="1.5"
                    ></line>
                    <polyline
                      id="Path"
                      stroke-width="1.5"
                      points="21.9945517 10.2857143 28.9928935 17.2857143 21.9945517 24.2857143"
                    ></polyline>
                    <path
                      d="M7.99786806,7.93333333 L7.99786806,2.26666667 C7.99786806,1.01433333 9.01467259,0 10.270057,0 L30.7197571,0 C31.9751415,0 32.991946,1.01433333 32.991946,2.26666667 L32.991946,31.7333333 C32.991946,32.9856667 31.9751415,34 30.7197571,34 L10.270057,34 C9.01467259,34 7.99786806,32.9856667 7.99786806,31.7333333 L7.99786806,26.0666667"
                      id="Path"
                      stroke-width="1.4"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          Log In
        </a>
      </li>
    </ul>
  </div>
</div>
<div *ngIf="isAuthenticated" class="container-fluid">
  <div class="desktop-display">
    <div class="navbar-header">
      <button
        id="header-logo-stateSelect"
        (click)="toggleStateSelector()"
        type="button"
        class="navbar-brand collapsed"
        data-toggle="collapse"
        data-target="#state-selector"
        aria-expanded="false"
      >
        <span class="sr-only">Ameren. Focused energy. For life.</span>
        <div class="logo-container" id="logo-container-default">
          <!-- Default Ameren Logo -->
          <svg
            *ngIf="!selectedState || selectedState == ''"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 186 71"
          >
            <title>logo_ameren_default</title>
            <g>
              <path
                class="logo-cls-1"
                d="M24,33H.19a29.25,29.25,0,0,1-.11-5.38L24,32.59V33Z"
              ></path>
              <path
                class="logo-cls-2"
                d="M40,30.35,60.2,22.73a29.32,29.32,0,0,1,.67,4.82L40.12,30.68a.41.41,0,0,0-.11-.34Z"
              ></path>
              <path
                class="logo-cls-1"
                d="M24.31,30.24.42,24.53a33.51,33.51,0,0,1,2.13-6.72l22,12a.58.58,0,0,0-.22.45Z"
              ></path>
              <path
                class="logo-cls-1"
                d="M37.54,26.43l13.35-19a25.39,25.39,0,0,1,4.37,4.82L37.88,26.77l-.34-.34Z"
              ></path>
              <path
                class="logo-cls-1"
                d="M26,27.32,4.57,13.89a30.76,30.76,0,0,1,6.17-6.94L26.21,27c-.11.11-.22.22-.22.34Z"
              ></path>
              <path
                class="logo-cls-1"
                d="M33.62,24.64,34.29,0a31.64,31.64,0,0,1,8.52,2.35l-8.75,22.4c-.11-.11-.22-.11-.45-.11Z"
              ></path>
              <path
                class="logo-cls-1"
                d="M29.24,25.09,15.56,3.7A29.2,29.2,0,0,1,26.44,0l3.25,24.86a.85.85,0,0,0-.45.22Z"
              ></path>
              <path
                class="logo-cls-1"
                d="M2.21,40.65s-.45-1.12-.9-2.46S.64,35.5.64,35.5c7.74-1,19.85-1.12,28.15-1.12,0,0-16,1.57-26.58,6.27Z"
              ></path>
              <path
                class="logo-cls-1"
                d="M19.37,57.45a22.22,22.22,0,0,1-4.26-1.9,28.9,28.9,0,0,1-4-2.91c4.71-5.94,19.85-17.47,34.09-18.25,0,0-14.69,5.15-25.8,23.07Z"
              ></path>
              <path
                class="logo-cls-1"
                d="M38.44,58.46a31.67,31.67,0,0,1-8,1,29.09,29.09,0,0,1-6.28-.67c.9-3,11.1-20.72,23.33-24.41,0,0-9.42,10-9.08,24.08Z"
              ></path>
              <path
                class="logo-cls-1"
                d="M7.82,49.61a29.31,29.31,0,0,1-2.47-3,21.72,21.72,0,0,1-2-3.47c3.81-2.46,17.38-8.74,36.56-8.74A80.44,80.44,0,0,0,7.82,49.61Z"
              ></path>
              <path
                class="logo-cls-3"
                d="M41.69,70.44l17-36.06h9.65L66.48,70.44H58.85l.67-7.28H52.46l-3,7.28ZM61.54,42.11,54.7,58H60l1.57-15.9Z"
              ></path>
              <path
                class="logo-cls-3"
                d="M68.27,70.44l7-27.55h7.51l-.56,2a11.68,11.68,0,0,1,6.73-2.58c3.36,0,4,2.24,4.26,2.91.78-.45,4.26-2.91,7.29-2.91,5.16,0,4.15,4,3.81,5.38L98.55,70.44H91l5.38-21.73c.22-.56,0-1.34-1.46-1.34a4.63,4.63,0,0,0-2.36,1l-5.5,22.06H79.6l5.5-21.73c0-.22.34-1.34-1.57-1.34a4.12,4.12,0,0,0-2.13.9L75.79,70.44Z"
              ></path>
              <path
                class="logo-cls-3"
                d="M110.89,71c-7.07,0-9.08-2.69-7.74-8.18l3.14-12.43c1-4.26,4.93-8.06,11.66-8.06,8.41,0,8.19,5.82,7.85,7.17l-2.13,8.4H111.56l-1.46,5.94c-.34,1.34,0,2.46,1.57,2.46a3.27,3.27,0,0,0,3.25-2.46l.67-2.69h7.18l-.67,2.69c-.9,2.8-3.93,7.17-11.22,7.17Zm2.92-21.84-1.12,4.14h4.82l1-4.14c.22-.56.56-2-1.68-2a2.83,2.83,0,0,0-3,2Z"
              ></path>
              <path
                class="logo-cls-3"
                d="M123.23,70.44l7-27.55h7.07l-.67,2.69c3-2.13,5.16-3.25,9.42-3.25l-1.23,5.26a27.66,27.66,0,0,0-2.92-.22c-5.61,0-6.5,3.47-7,5.26L130.4,70.44Z"
              ></path>
              <path
                class="logo-cls-3"
                d="M148.91,71c-7.07,0-9.08-2.69-7.74-8.18l3.14-12.43c1-4.26,4.93-8.06,11.55-8.06,8.52,0,8.3,5.82,8,7.17l-2.13,8.4H149.58l-1.46,5.94c-.34,1.34,0,2.46,1.57,2.46a3.27,3.27,0,0,0,3.25-2.46l.67-2.69h7.18L160,63.83c-.9,2.8-3.81,7.17-11.1,7.17Zm2.8-21.84-1,4.14h4.82l1-4.14c.22-.56.56-2-1.68-2a3.07,3.07,0,0,0-3.14,2Z"
              ></path>
              <path
                class="logo-cls-3"
                d="M161.36,70.44l7-27.55h7.4L175.15,45c.9-.67,3.25-2.69,6.5-2.69,5.5,0,4.49,4.59,3.81,7.28L180.2,70.44h-7.51L177.62,51c.56-2.35.79-3.47-.79-3.47a5.92,5.92,0,0,0-2.47.9l-5.5,22.06Z"
              ></path>
            </g>
          </svg>
          <svg
            *ngIf="selectedState == 'illinois'"
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 186 94"
          >
            <title>logo_ameren_illinois</title>
            <g>
              <g>
                <path
                  class="logo-cls-1"
                  d="M24,33H.19a29.19,29.19,0,0,1-.11-5.37L24,32.56V33Z"
                ></path>
                <path
                  class="logo-cls-2"
                  d="M40,30.32l20.19-7.61a29.26,29.26,0,0,1,.67,4.81L40.11,30.66a.41.41,0,0,0-.11-.34Z"
                ></path>
                <path
                  class="logo-cls-1"
                  d="M24.3,30.21.41,24.5a33.45,33.45,0,0,1,2.13-6.71l22,12a.58.58,0,0,0-.22.45Z"
                ></path>
                <path
                  class="logo-cls-1"
                  d="M37.53,26.4l13.35-19a25.37,25.37,0,0,1,4.37,4.81L37.87,26.74l-.34-.34Z"
                ></path>
                <path
                  class="logo-cls-1"
                  d="M26,27.3,4.56,13.87a30.74,30.74,0,0,1,6.17-6.94L26.2,27c-.11.11-.22.22-.22.34Z"
                ></path>
                <path
                  class="logo-cls-1"
                  d="M33.61,24.61,34.28,0A31.66,31.66,0,0,1,42.8,2.35L34.06,24.73c-.11-.11-.22-.11-.45-.11Z"
                ></path>
                <path
                  class="logo-cls-1"
                  d="M29.23,25.06,15.55,3.69A29.22,29.22,0,0,1,26.43,0l3.25,24.84a.85.85,0,0,0-.45.22Z"
                ></path>
                <path
                  class="logo-cls-1"
                  d="M2.2,40.61s-.45-1.12-.9-2.46S.63,35.47.63,35.47c7.74-1,19.85-1.12,28.15-1.12,0,0-16,1.57-26.58,6.27Z"
                ></path>
                <path
                  class="logo-cls-1"
                  d="M19.36,57.4a22.23,22.23,0,0,1-4.26-1.9,28.9,28.9,0,0,1-4-2.91c4.71-5.93,19.85-17.45,34.09-18.24,0,0-14.69,5.15-25.8,23Z"
                ></path>
                <path
                  class="logo-cls-1"
                  d="M38.43,58.4a31.7,31.7,0,0,1-8,1,29.12,29.12,0,0,1-6.28-.67c.9-3,11.1-20.7,23.33-24.39,0,0-9.42,10-9.08,24.05Z"
                ></path>
                <path
                  class="logo-cls-1"
                  d="M7.81,49.56a29.29,29.29,0,0,1-2.47-3,21.69,21.69,0,0,1-2-3.47c3.81-2.46,17.38-8.73,36.56-8.73A80.48,80.48,0,0,0,7.81,49.56Z"
                ></path>
                <path
                  class="logo-cls-3"
                  d="M41.68,70.37l17-36h9.65l-1.91,36H58.84l.67-7.27H52.45l-3,7.27ZM61.53,42.07,54.69,58H60l1.57-15.89Z"
                ></path>
                <path
                  class="logo-cls-3"
                  d="M68.26,70.37l7-27.52h7.51l-.56,2a11.69,11.69,0,0,1,6.73-2.57c3.36,0,4,2.24,4.26,2.91.78-.45,4.26-2.91,7.29-2.91,5.16,0,4.15,4,3.81,5.37L98.54,70.37H91l5.38-21.71c.22-.56,0-1.34-1.46-1.34a4.63,4.63,0,0,0-2.36,1l-5.5,22H79.59l5.5-21.71c0-.22.34-1.34-1.57-1.34a4.12,4.12,0,0,0-2.13.9L75.78,70.37Z"
                ></path>
                <path
                  class="logo-cls-3"
                  d="M110.88,70.93c-7.07,0-9.08-2.69-7.74-8.17l3.14-12.42c1-4.25,4.93-8.06,11.66-8.06,8.41,0,8.19,5.82,7.85,7.16l-2.13,8.39H111.55l-1.46,5.93c-.34,1.34,0,2.46,1.57,2.46a3.27,3.27,0,0,0,3.25-2.46l.67-2.69h7.18l-.67,2.69c-.9,2.8-3.93,7.16-11.22,7.16Zm2.92-21.82-1.12,4.14h4.82l1-4.14c.22-.56.56-2-1.68-2a2.83,2.83,0,0,0-3,2Z"
                ></path>
                <path
                  class="logo-cls-3"
                  d="M123.22,70.37l7-27.52h7.07l-.67,2.69c3-2.13,5.16-3.24,9.42-3.24l-1.23,5.26a27.68,27.68,0,0,0-2.92-.22c-5.61,0-6.5,3.47-7,5.26l-4.49,17.79Z"
                ></path>
                <path
                  class="logo-cls-3"
                  d="M148.9,70.93c-7.07,0-9.08-2.69-7.74-8.17l3.14-12.42c1-4.25,4.93-8.06,11.55-8.06,8.52,0,8.3,5.82,8,7.16l-2.13,8.39H149.57l-1.46,5.93c-.34,1.34,0,2.46,1.57,2.46a3.27,3.27,0,0,0,3.25-2.46l.67-2.69h7.18L160,63.77c-.9,2.8-3.81,7.16-11.1,7.16Zm2.8-21.82-1,4.14h4.82l1-4.14c.22-.56.56-2-1.68-2a3.07,3.07,0,0,0-3.14,2Z"
                ></path>
                <path
                  class="logo-cls-3"
                  d="M161.35,70.37l7-27.52h7.4L175.14,45c.9-.67,3.25-2.69,6.5-2.69,5.5,0,4.49,4.59,3.81,7.27l-5.27,20.81h-7.51l4.93-19.47c.56-2.35.79-3.47-.79-3.47a5.93,5.93,0,0,0-2.47.9l-5.5,22Z"
                ></path>
              </g>
              <g>
                <path
                  class="logo-cls-4"
                  d="M44.25,77h3.38L43.79,93.74H40.41Z"
                ></path>
                <path
                  class="logo-cls-4"
                  d="M51.07,77h3.38l-3.2,14h6l-.63,2.77H47.24Z"
                ></path>
                <path
                  class="logo-cls-4"
                  d="M62.88,77h3.38l-3.2,14h6l-.63,2.77H59Z"
                ></path>
                <path
                  class="logo-cls-4"
                  d="M74.84,77h3.38L74.39,93.74H71Z"
                ></path>
                <path
                  class="logo-cls-4"
                  d="M87,88.47h0L89.69,77h3.1L89,93.74H85.11L83.67,82h0L80.93,93.74h-3.1L81.67,77h3.9Z"
                ></path>
                <path
                  class="logo-cls-4"
                  d="M94.79,81.87a9.79,9.79,0,0,1,1.4-2.75,6,6,0,0,1,2.14-1.79,7,7,0,0,1,3.17-.64,5.27,5.27,0,0,1,2.88.64,3.13,3.13,0,0,1,1.31,1.79,6.94,6.94,0,0,1,.14,2.75,33.63,33.63,0,0,1-.63,3.5,36.17,36.17,0,0,1-1,3.52,9.76,9.76,0,0,1-1.4,2.74,5.74,5.74,0,0,1-2.13,1.76,7.29,7.29,0,0,1-3.17.61,5.55,5.55,0,0,1-2.89-.61,3,3,0,0,1-1.32-1.76,6.92,6.92,0,0,1-.15-2.74,33.48,33.48,0,0,1,.63-3.52A35.66,35.66,0,0,1,94.79,81.87Zm1.82,6.38a7.63,7.63,0,0,0-.11,1.92A1.62,1.62,0,0,0,97,91.25a1.65,1.65,0,0,0,1.13.34,2.35,2.35,0,0,0,1.29-.34,3,3,0,0,0,1-1.08,9.5,9.5,0,0,0,.77-1.92q.35-1.18.74-2.87t.57-2.86a7.74,7.74,0,0,0,.11-1.92,1.63,1.63,0,0,0-.45-1.09,1.65,1.65,0,0,0-1.13-.34,2.35,2.35,0,0,0-1.29.34,3,3,0,0,0-1,1.09,9.79,9.79,0,0,0-.77,1.92q-.35,1.17-.74,2.86T96.61,88.24Z"
                ></path>
                <path
                  class="logo-cls-4"
                  d="M110.22,77h3.38l-3.83,16.74h-3.38Z"
                ></path>
                <path
                  class="logo-cls-4"
                  d="M117,89a5.65,5.65,0,0,0-.15,1.07,1.51,1.51,0,0,0,.17.81,1.24,1.24,0,0,0,.57.52,2.45,2.45,0,0,0,1,.19,2.65,2.65,0,0,0,1.51-.5,2.46,2.46,0,0,0,1-1.56,2.7,2.7,0,0,0,.07-1,1.44,1.44,0,0,0-.33-.74,2.61,2.61,0,0,0-.79-.6,10.61,10.61,0,0,0-1.29-.55,9.34,9.34,0,0,1-1.76-.82,3.75,3.75,0,0,1-1.13-1,2.89,2.89,0,0,1-.49-1.38,5.69,5.69,0,0,1,.16-1.82,6.06,6.06,0,0,1,2.23-3.7,6.72,6.72,0,0,1,4.08-1.22,7.65,7.65,0,0,1,2,.25,3.31,3.31,0,0,1,1.46.8,2.77,2.77,0,0,1,.76,1.41,4.68,4.68,0,0,1-.08,2.05l-.11.47H122.7a3.08,3.08,0,0,0,0-1.84,1.21,1.21,0,0,0-1.26-.64,2.64,2.64,0,0,0-1,.16,2.17,2.17,0,0,0-.7.43,2,2,0,0,0-.45.62,3.65,3.65,0,0,0-.25.73,2.21,2.21,0,0,0,0,1.3,2.14,2.14,0,0,0,1.19,1l2.35,1.12a6,6,0,0,1,1.37.88,3.1,3.1,0,0,1,.75,1,2.69,2.69,0,0,1,.24,1.16,6.55,6.55,0,0,1-.18,1.41,6,6,0,0,1-2.42,3.86A8.07,8.07,0,0,1,117.83,94Q115,94,114,92.76a4.18,4.18,0,0,1-.42-3.56l.16-.68h3.38Z"
                ></path>
              </g>
            </g>
          </svg>
          <svg
            *ngIf="selectedState == 'missouri'"
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 186 94"
          >
            <title>logo_ameren_missouri</title>
            <g>
              <g>
                <path
                  class="logo-cls-1"
                  d="M24,33H.19a29.25,29.25,0,0,1-.11-5.38L24,32.59V33Z"
                ></path>
                <path
                  class="logo-cls-2"
                  d="M40,30.35,60.2,22.73a29.32,29.32,0,0,1,.67,4.82L40.12,30.68a.41.41,0,0,0-.11-.34Z"
                ></path>
                <path
                  class="logo-cls-1"
                  d="M24.31,30.24.42,24.53a33.51,33.51,0,0,1,2.13-6.72l22,12a.58.58,0,0,0-.22.45Z"
                ></path>
                <path
                  class="logo-cls-1"
                  d="M37.54,26.43l13.35-19a25.39,25.39,0,0,1,4.37,4.82L37.88,26.77l-.34-.34Z"
                ></path>
                <path
                  class="logo-cls-1"
                  d="M26,27.32,4.57,13.89a30.76,30.76,0,0,1,6.17-6.94L26.21,27c-.11.11-.22.22-.22.34Z"
                ></path>
                <path
                  class="logo-cls-1"
                  d="M33.62,24.64,34.29,0a31.64,31.64,0,0,1,8.52,2.35l-8.75,22.4c-.11-.11-.22-.11-.45-.11Z"
                ></path>
                <path
                  class="logo-cls-1"
                  d="M29.24,25.09,15.56,3.7A29.2,29.2,0,0,1,26.44,0l3.25,24.86a.85.85,0,0,0-.45.22Z"
                ></path>
                <path
                  class="logo-cls-1"
                  d="M2.21,40.65s-.45-1.12-.9-2.46S.64,35.5.64,35.5c7.74-1,19.85-1.12,28.15-1.12,0,0-16,1.57-26.58,6.27Z"
                ></path>
                <path
                  class="logo-cls-1"
                  d="M19.37,57.45a22.22,22.22,0,0,1-4.26-1.9,28.9,28.9,0,0,1-4-2.91c4.71-5.94,19.85-17.47,34.09-18.25,0,0-14.69,5.15-25.8,23.07Z"
                ></path>
                <path
                  class="logo-cls-1"
                  d="M38.44,58.46a31.67,31.67,0,0,1-8,1,29.09,29.09,0,0,1-6.28-.67c.9-3,11.1-20.72,23.33-24.41,0,0-9.42,10-9.08,24.08Z"
                ></path>
                <path
                  class="logo-cls-1"
                  d="M7.82,49.61a29.31,29.31,0,0,1-2.47-3,21.72,21.72,0,0,1-2-3.47c3.81-2.46,17.38-8.74,36.56-8.74A80.44,80.44,0,0,0,7.82,49.61Z"
                ></path>
                <path
                  class="logo-cls-3"
                  d="M41.69,70.44l17-36.06h9.65L66.48,70.44H58.85l.67-7.28H52.46l-3,7.28ZM61.54,42.11,54.7,58H60l1.57-15.9Z"
                ></path>
                <path
                  class="logo-cls-3"
                  d="M68.27,70.44l7-27.55h7.51l-.56,2a11.68,11.68,0,0,1,6.73-2.58c3.36,0,4,2.24,4.26,2.91.78-.45,4.26-2.91,7.29-2.91,5.16,0,4.15,4,3.81,5.38L98.55,70.44H91l5.38-21.73c.22-.56,0-1.34-1.46-1.34a4.63,4.63,0,0,0-2.36,1l-5.5,22.06H79.6l5.5-21.73c0-.22.34-1.34-1.57-1.34a4.12,4.12,0,0,0-2.13.9L75.79,70.44Z"
                ></path>
                <path
                  class="logo-cls-3"
                  d="M110.89,71c-7.07,0-9.08-2.69-7.74-8.18l3.14-12.43c1-4.26,4.93-8.06,11.66-8.06,8.41,0,8.19,5.82,7.85,7.17l-2.13,8.4H111.56l-1.46,5.94c-.34,1.34,0,2.46,1.57,2.46a3.27,3.27,0,0,0,3.25-2.46l.67-2.69h7.18l-.67,2.69c-.9,2.8-3.93,7.17-11.22,7.17Zm2.92-21.84-1.12,4.14h4.82l1-4.14c.22-.56.56-2-1.68-2a2.83,2.83,0,0,0-3,2Z"
                ></path>
                <path
                  class="logo-cls-3"
                  d="M123.23,70.44l7-27.55h7.07l-.67,2.69c3-2.13,5.16-3.25,9.42-3.25l-1.23,5.26a27.66,27.66,0,0,0-2.92-.22c-5.61,0-6.5,3.47-7,5.26L130.4,70.44Z"
                ></path>
                <path
                  class="logo-cls-3"
                  d="M148.91,71c-7.07,0-9.08-2.69-7.74-8.18l3.14-12.43c1-4.26,4.93-8.06,11.55-8.06,8.52,0,8.3,5.82,8,7.17l-2.13,8.4H149.58l-1.46,5.94c-.34,1.34,0,2.46,1.57,2.46a3.27,3.27,0,0,0,3.25-2.46l.67-2.69h7.18L160,63.83c-.9,2.8-3.81,7.17-11.1,7.17Zm2.8-21.84-1,4.14h4.82l1-4.14c.22-.56.56-2-1.68-2a3.07,3.07,0,0,0-3.14,2Z"
                ></path>
                <path
                  class="logo-cls-3"
                  d="M161.36,70.44l7-27.55h7.4L175.15,45c.9-.67,3.25-2.69,6.5-2.69,5.5,0,4.49,4.59,3.81,7.28L180.2,70.44h-7.51L177.62,51c.56-2.35.79-3.47-.79-3.47a5.92,5.92,0,0,0-2.47.9l-5.5,22.06Z"
                ></path>
              </g>
              <g>
                <path
                  class="logo-cls-4"
                  d="M49.23,77.09l-.31,11.75h0l5.09-11.75h5L55.21,93.74H52.13l3-13.29h0l-6,13.29H46.51l.08-13.29h0l-3,13.29H40.42l3.81-16.65Z"
                ></path>
                <path
                  class="logo-cls-4"
                  d="M62.52,77.09h3.36L62.07,93.74H58.71Z"
                ></path>
                <path
                  class="logo-cls-4"
                  d="M69.28,89a5.58,5.58,0,0,0-.15,1.06,1.5,1.5,0,0,0,.17.8,1.23,1.23,0,0,0,.57.51,2.43,2.43,0,0,0,1,.19,2.63,2.63,0,0,0,1.5-.5,2.45,2.45,0,0,0,1-1.55,2.7,2.7,0,0,0,.07-1,1.43,1.43,0,0,0-.33-.73,2.6,2.6,0,0,0-.79-.6,10.57,10.57,0,0,0-1.28-.55,9.3,9.3,0,0,1-1.75-.82,3.72,3.72,0,0,1-1.13-1,2.88,2.88,0,0,1-.49-1.38,5.69,5.69,0,0,1,.16-1.81A6,6,0,0,1,70.06,78a6.68,6.68,0,0,1,4.06-1.21,7.6,7.6,0,0,1,2,.24,3.29,3.29,0,0,1,1.45.79,2.74,2.74,0,0,1,.75,1.4,4.67,4.67,0,0,1-.08,2l-.11.47H74.92a3.07,3.07,0,0,0,0-1.83,1.21,1.21,0,0,0-1.25-.64,2.62,2.62,0,0,0-1,.16,2.17,2.17,0,0,0-.69.43,2,2,0,0,0-.44.62,3.67,3.67,0,0,0-.25.72,2.2,2.2,0,0,0,0,1.29,2.12,2.12,0,0,0,1.18,1l2.33,1.12a5.9,5.9,0,0,1,1.36.87,3.07,3.07,0,0,1,.74,1,2.69,2.69,0,0,1,.24,1.15A6.52,6.52,0,0,1,77,89a6,6,0,0,1-2.41,3.84A8,8,0,0,1,70.08,94q-2.85,0-3.79-1.24a4.16,4.16,0,0,1-.41-3.55l.15-.68h3.36Z"
                ></path>
                <path
                  class="logo-cls-4"
                  d="M82.32,89a5.58,5.58,0,0,0-.15,1.06,1.5,1.5,0,0,0,.17.8,1.23,1.23,0,0,0,.57.51,2.43,2.43,0,0,0,1,.19,2.63,2.63,0,0,0,1.5-.5,2.45,2.45,0,0,0,1-1.55,2.7,2.7,0,0,0,.07-1,1.43,1.43,0,0,0-.33-.73,2.6,2.6,0,0,0-.79-.6,10.57,10.57,0,0,0-1.28-.55,9.3,9.3,0,0,1-1.75-.82,3.72,3.72,0,0,1-1.13-1,2.88,2.88,0,0,1-.49-1.38,5.69,5.69,0,0,1,.16-1.81A6,6,0,0,1,83.1,78a6.68,6.68,0,0,1,4.06-1.21,7.6,7.6,0,0,1,2,.24,3.29,3.29,0,0,1,1.45.79,2.74,2.74,0,0,1,.75,1.4,4.67,4.67,0,0,1-.08,2l-.11.47H88a3.07,3.07,0,0,0,0-1.83,1.21,1.21,0,0,0-1.25-.64,2.62,2.62,0,0,0-1,.16,2.17,2.17,0,0,0-.69.43,2,2,0,0,0-.44.62,3.67,3.67,0,0,0-.25.72,2.2,2.2,0,0,0,0,1.29,2.12,2.12,0,0,0,1.18,1l2.33,1.12a5.9,5.9,0,0,1,1.36.87,3.07,3.07,0,0,1,.74,1,2.69,2.69,0,0,1,.24,1.15,6.52,6.52,0,0,1-.18,1.4,6,6,0,0,1-2.41,3.84A8,8,0,0,1,83.12,94q-2.85,0-3.79-1.24a4.16,4.16,0,0,1-.41-3.55l.15-.68h3.36Z"
                ></path>
                <path
                  class="logo-cls-4"
                  d="M94.11,81.93a9.75,9.75,0,0,1,1.4-2.74,6,6,0,0,1,2.12-1.78,7,7,0,0,1,3.16-.64,5.24,5.24,0,0,1,2.86.64A3.11,3.11,0,0,1,105,79.19a6.92,6.92,0,0,1,.14,2.74,33.5,33.5,0,0,1-.62,3.49,35.87,35.87,0,0,1-1,3.5,9.72,9.72,0,0,1-1.39,2.73A5.71,5.71,0,0,1,100,93.39a7.24,7.24,0,0,1-3.15.61A5.52,5.52,0,0,1,94,93.39a3,3,0,0,1-1.31-1.75,6.89,6.89,0,0,1-.15-2.73,33.45,33.45,0,0,1,.63-3.5A36,36,0,0,1,94.11,81.93Zm1.81,6.34a7.63,7.63,0,0,0-.11,1.91,1.61,1.61,0,0,0,.45,1.07,1.64,1.64,0,0,0,1.12.34,2.34,2.34,0,0,0,1.28-.34,3,3,0,0,0,.95-1.07,9.49,9.49,0,0,0,.76-1.91q.35-1.18.74-2.86t.57-2.85a7.75,7.75,0,0,0,.11-1.91,1.62,1.62,0,0,0-.45-1.08,1.64,1.64,0,0,0-1.12-.34,2.34,2.34,0,0,0-1.28.34A2.93,2.93,0,0,0,98,80.66a9.69,9.69,0,0,0-.76,1.91q-.35,1.17-.73,2.85T95.92,88.27Z"
                ></path>
                <path
                  class="logo-cls-4"
                  d="M109.78,88.71a7.74,7.74,0,0,0-.18,1.15,2.27,2.27,0,0,0,.09.91,1.17,1.17,0,0,0,.47.61,1.64,1.64,0,0,0,.92.22,2.19,2.19,0,0,0,1-.22,2.3,2.3,0,0,0,.73-.61,3.35,3.35,0,0,0,.51-.91,8.56,8.56,0,0,0,.35-1.15l2.66-11.61h3.36l-2.66,11.61a8,8,0,0,1-1,2.62A5.84,5.84,0,0,1,114.45,93a5.2,5.2,0,0,1-1.89.82,10,10,0,0,1-4,0,3.1,3.1,0,0,1-1.53-.76,2.89,2.89,0,0,1-.82-1.62,6.84,6.84,0,0,1,.18-2.73l2.66-11.61h3.36Z"
                ></path>
                <path
                  class="logo-cls-4"
                  d="M129,77.09a3.82,3.82,0,0,1,3,1.06,3.55,3.55,0,0,1,.43,3.23,6.24,6.24,0,0,1-1.3,2.74,4.19,4.19,0,0,1-2.41,1.36v0a2.36,2.36,0,0,1,1.66.82,3,3,0,0,1,.26,2q-.06.47-.16,1l-.23,1.23q-.26,1.33-.32,2a1,1,0,0,0,.39,1l0,.14h-3.64a1.51,1.51,0,0,1-.14-.8,8.87,8.87,0,0,1,.12-.95l.64-3.19a2.3,2.3,0,0,0-.14-1.54c-.21-.37-.67-.56-1.37-.56h-1.8l-1.61,7H119l3.81-16.65Zm-3.11,7.28a3.3,3.3,0,0,0,2-.57A3.14,3.14,0,0,0,129,81.87q.53-2.31-1.8-2.31h-1.59l-1.1,4.81Z"
                ></path>
                <path
                  class="logo-cls-4"
                  d="M136.84,77.09h3.36l-3.81,16.65H133Z"
                ></path>
              </g>
            </g>
          </svg>
        </div>
      </button>
     

    </div>
    <div
      id="main-menu"
      class="nav--subnav navbar-collapse collapse text-right"
      aria-expanded="false"
    >
      <ul class="nav nav--secondary navbar-nav navbar-left">
        <li class="sr-only">divider</li>
        <li *ngIf="hasSelectedGenerationOwner" class="mt-2 d-flex flex-row">
          <span class="selectedGenOwner_value">{{selectedGenerationOwner?.name}}
          <button
          *ngIf="hasSelectedGenerationOwner && isAmerenInternal"          
          (click)="ResetSelection()" class="ml-2 reset_link"
        > (Clear)</button>
      </span>
        </li>
      </ul>
      <ul class="nav nav--secondary navbar-nav navbar-right">
        <li>
          <a
            class="text-center log__in__out"
            id="secondary-navigation-27113cfc-4c95-4fe3-b47a-459e9838a46e"
            rel=""
            (click)="logout()"
            target=""
          >
            <svg
              width="52"
              height="50"
              viewBox="0 0 52 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Logout</title>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.96287 15.3504H30.9604C31.2854 15.3504 31.5487 15.0871 31.5487 14.7622C31.5487 14.4373 31.2854 14.174 30.9604 14.174H8.97828L17.8285 5.17547C18.0564 4.94383 18.0534 4.57135 17.8217 4.34359C17.5902 4.11594 17.2177 4.11888 16.9898 4.35053L7.1911 14.3135C7.08922 14.3989 7.01569 14.5167 6.99005 14.6527C6.98981 14.6548 6.98957 14.6568 6.98922 14.6589C6.98557 14.6771 6.98522 14.6957 6.98346 14.7142C6.98157 14.7331 6.97852 14.752 6.97852 14.771C6.97852 14.7896 6.98157 14.808 6.98334 14.8264C6.9851 14.8455 6.98557 14.8646 6.98934 14.8833C6.99287 14.9016 6.99946 14.919 7.00463 14.9368C7.01016 14.9555 7.0144 14.9742 7.02169 14.9923C7.02863 15.0096 7.03875 15.0255 7.04746 15.0422C7.05663 15.0597 7.0644 15.0778 7.07546 15.0944C7.08581 15.11 7.09899 15.1238 7.11099 15.1386C7.12299 15.1536 7.13334 15.1696 7.1471 15.1836L16.9901 25.1775C17.1052 25.2943 17.2572 25.3529 17.4092 25.3529C17.5583 25.3529 17.7074 25.2967 17.8221 25.1839C18.0535 24.9559 18.0563 24.5834 17.8283 24.352L8.96287 15.3504Z"
                fill="#565656"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M37.7366 3.26601V26.7341C37.7366 28.5349 36.2714 30.0001 34.4705 30.0001H25.1098C23.3089 30.0001 21.8438 28.5349 21.8438 26.7341V17.8917C21.8438 17.5667 22.107 17.3034 22.432 17.3034C22.7568 17.3034 23.0202 17.5667 23.0202 17.8917V26.7341C23.0202 27.8862 23.9576 28.8236 25.1098 28.8236H34.4705C35.6227 28.8236 36.5601 27.8862 36.5601 26.7341V3.26601C36.5601 2.11377 35.6227 1.17647 34.4705 1.17647H25.1098C23.9576 1.17647 23.0202 2.11377 23.0202 3.26601V10.6943C23.0202 11.0192 22.7568 11.2825 22.432 11.2825C22.107 11.2825 21.8438 11.0192 21.8438 10.6943V3.26601C21.8438 1.46518 23.3089 0 25.1098 0H34.4705C36.2714 0 37.7366 1.46518 37.7366 3.26601V3.26601Z"
                fill="#565656"
              />
            </svg>
            Log Out
          </a>
        </li>
      </ul>
     

      <ul class="nav nav--secondary navbar-nav navbar-right">
        <li (click)="editUserProfile()">
          <a
            id="secondary-navigation-27113cfc-4c95-4fe3-b47a-459e9838a46e"
            rel=""
            class="accountDashboard_subNav-Outages account-dashboard-subnav"
            target=""
          >
            <svg
              width="50px"
              height="50px"
              viewBox="0 0 30 50"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <title>Profile</title>
              <g
                id="Symbols"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="EAP/MO/Header/LoggedIn"
                  transform="translate(-1276.000000, -20.000000)"
                >
                  <g
                    id="Group-2-Copy"
                    transform="translate(1277.000000, 21.000000)"
                  >
                    <path
                      d="M17.1422267,5.94322836 C17.1718247,7.68132321 16.157402,9.2988984 14.4879241,10.1757184 C12.8184462,11.0525383 10.7529823,11.0525383 9.08350444,10.1757184 C7.41402654,9.2988984 6.39960382,7.68132321 6.42920187,5.94322836 L6.42920187,4.89010497 C6.39960382,3.15201012 7.41402654,1.53443493 9.08350444,0.657614971 C10.7529823,-0.21920499 12.8184462,-0.21920499 14.4879241,0.657614971 C16.157402,1.53443493 17.1718247,3.15201012 17.1422267,4.89010497 L17.1422267,5.94322836 Z"
                      id="Path_736"
                      stroke="#565656"
                      stroke-width="1.7"
                    ></path>
                    <path
                      d="M21.3418968,25.9997836 L0.916167741,25.9997836 C0.450322428,26.023127 0.0474989407,24.150839 0,23.6969496 C0,19.0865653 4.9760772,14.0833333 11.1290323,14.0833333 C17.2819873,14.0833333 22.5,18.9578587 22.5,23.6969496 C22.5,24.2088944 21.8652809,25.9997836 21.3418968,25.9997836 Z"
                      id="Path_737"
                      stroke="#565656"
                      stroke-width="1.7"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M28.384875,15.7263889 C28.249375,16.2241083 28.055375,16.6927583 27.8095,17.1324653 L28.951,18.286775 L26.65725,20.6061375 L25.5275,19.4637083 C25.09,19.7197722 24.621875,19.9195931 24.125,20.0614014 L24.125,21.6666667 L20.875,21.6666667 L20.875,20.0935042 C20.364875,19.9575097 19.886125,19.7555403 19.436625,19.4999819 L18.342625,20.6061375 L16.048875,18.286775 L17.1245,17.1991986 C16.86075,16.7410389 16.6555,16.2490069 16.51325,15.7263889 L15,15.7263889 L15,12.4402778 L16.5065,12.4402778 C16.6475,11.9147528 16.853,11.4205722 17.116875,10.9597583 L16.048875,9.87989167 L18.342625,7.56065556 L19.4225,8.65252917 C19.875875,8.39317917 20.359625,8.18868194 20.875,8.05117083 L20.875,6.5 L24.125,6.5 L24.125,8.08327361 C24.627125,8.226725 25.100375,8.42894722 25.541625,8.68867639 L26.65725,7.56065556 L28.951,9.87989167 L27.817,11.0264917 C28.063125,11.4687264 28.257375,11.9397778 28.391625,12.4402778 L30,12.4402778 L30,15.7263889 L28.384875,15.7263889 Z"
                      id="Path"
                      stroke="#565656"
                      stroke-width="1.4"
                      fill="#FFFFFF"
                    ></path>
                    <path
                      d="M22.5,11.5555556 C21.1192222,11.5555556 20,12.6872136 20,14.0833333 C20,15.4794531 21.1192222,16.6111111 22.5,16.6111111 C23.8807778,16.6111111 25,15.4794531 25,14.0833333 C25,12.6872136 23.8807778,11.5555556 22.5,11.5555556 L22.5,11.5555556 Z"
                      id="Path"
                      fill="#565656"
                    ></path>
                  </g>
                </g>
              </g>
            </svg>
            Profile
          </a>
        </li>
      </ul>

      <ul class="nav nav--secondary navbar-nav navbar-right" *ngIf="isAuditAllowed">
        <li [routerLink]="'audittrail'">
          <a
            id="secondary-navigation-27113cfc-4c95-4fe3-b47a-459e9838a46e"
            rel=""
            class="accountDashboard_subNav-Outages account-dashboard-subnav"
            target=""
          >
            <svg
              width="50px"
              height="50px"
              viewBox="0 0 30 50"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <title>Generation Unit</title>
              <g
                id="Symbols"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="ANM/MO/Header/LoggedIn"
                  transform="translate(-1075.000000, -18.000000)"
                  stroke="#565656"
                >
                  <g id="Group" transform="translate(1075.000000, 18.000000)">
                    <g id="clipboard" fill="#FFFFFF" stroke-width="1.7">
                      <rect
                        id="Rectangle"
                        x="0.85"
                        y="3.1462963"
                        width="22.3"
                        height="27.0037037"
                        rx="3"
                      ></rect>
                      <rect
                        id="Rectangle"
                        x="5.42142857"
                        y="0.85"
                        width="13.1571429"
                        height="5.18888889"
                        rx="1"
                      ></rect>
                    </g>
                    <line
                      x1="6.00000006"
                      y1="12.5"
                      x2="19.0000001"
                      y2="12.5"
                      id="Line"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    ></line>
                    <line
                      x1="6.00000006"
                      y1="17.5"
                      x2="19.0000001"
                      y2="17.5"
                      id="Line"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    ></line>
                    <line
                      x1="6.00000006"
                      y1="22.5"
                      x2="19.0000001"
                      y2="22.5"
                      id="Line"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    ></line>
                  </g>
                </g>
              </g>
            </svg>
            Audit Trail
          </a>
        </li>
      </ul>

      <ul class="nav nav--secondary navbar-nav navbar-right" *ngIf="(isAmerenInternal &&  isReportsAllowed) || showMenuOptions">
        <li [routerLink]="'reports'">
          <a
            id="secondary-navigation-27113cfc-4c95-4fe3-b47a-459e9838a46e"
            rel=""
            class="accountDashboard_subNav-Outages account-dashboard-subnav"
            target=""
          >
            <svg
              width="50"
              height="50"
              viewBox="0 0 30 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Reports</title>
              <circle
                cx="16"
                cy="16"
                r="15"
                fill="white"
                stroke="#565656"
                stroke-width="2"
              />
              <circle cx="9.14286" cy="16" r="2.28571" fill="#565656" />
              <circle cx="16" cy="16" r="2.28571" fill="#565656" />
              <circle cx="22.8572" cy="16" r="2.28571" fill="#565656" />
            </svg>
            <span class="nav-item-link-text"> Reports </span>
          </a>
        </li>
      </ul>

      <ul class="nav nav--secondary navbar-nav navbar-right" *ngIf="(isAmerenInternal &&  isUsersAllowed) || isAdmin">
        <li [routerLink]="'users'">
          <a
            id="secondary-navigation-27113cfc-4c95-4fe3-b47a-459e9838a46e"
            rel=""
            class="accountDashboard_subNav-Outages account-dashboard-subnav"
            target=""
          >
            <svg
              width="52"
              height="50"
              viewBox="0 0 52 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Users</title>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.0952 5.8003C22.1194 7.20415 21.2909 8.51065 19.9275 9.21885C18.5641 9.92705 16.8773 9.92705 15.5139 9.21885C14.1505 8.51065 13.322 7.20415 13.3462 5.8003V4.9497C13.322 3.54585 14.1505 2.23935 15.5139 1.53115C16.8773 0.82295 18.5641 0.82295 19.9275 1.53115C21.2909 2.23935 22.1194 3.54585 22.0952 4.9497V5.8003Z"
                fill="white"
                stroke="#565656"
                stroke-width="1.5"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M26.3549 22.8748H8.87954C8.48098 22.8954 8.13634 21.2457 8.0957 20.8457C8.0957 16.7834 12.353 12.375 17.6172 12.375C22.8814 12.375 27.3457 16.67 27.3457 20.8457C27.3457 21.2968 26.8027 22.8748 26.3549 22.8748Z"
                fill="white"
                stroke="#565656"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M37.8451 9.38536C37.8741 11.07 36.88 12.6378 35.2439 13.4876C33.6078 14.3375 31.5836 14.3375 29.9475 13.4876C28.3114 12.6378 27.3173 11.07 27.3463 9.38536V8.36464C27.3173 6.68003 28.3114 5.11222 29.9475 4.26238C31.5836 3.41254 33.6078 3.41254 35.2439 4.26238C36.88 5.11222 37.8741 6.68003 37.8451 8.36464V9.38536Z"
                fill="white"
                stroke="#565656"
                stroke-width="1.5"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M41.9698 27.2498H22.1114C21.6585 27.2704 21.2669 25.6207 21.2207 25.2207C21.2207 21.1584 26.0586 16.75 32.0406 16.75C38.0226 16.75 43.0957 21.045 43.0957 25.2207C43.0957 25.6718 42.4786 27.2498 41.9698 27.2498Z"
                fill="white"
                stroke="#565656"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span class="nav-item-link-text"> Users </span>
          </a>
        </li>
      </ul>

      <ul class="nav nav--secondary navbar-nav navbar-right" *ngIf="(isAmerenInternal && isRebatesAllowed) || showMenuOptions">
        <li [routerLink]="'rebate'">
          <a
            id="secondary-navigation-27113cfc-4c95-4fe3-b47a-459e9838a46e"
            rel=""
            class="accountDashboard_subNav-Outages account-dashboard-subnav"
            target=""
          >
            <svg
              width="52"
              height="50"
              viewBox="0 0 52 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Rebate</title>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.0952 5.8003C22.1194 7.20415 21.2909 8.51065 19.9275 9.21885C18.5641 9.92705 16.8773 9.92705 15.5139 9.21885C14.1505 8.51065 13.322 7.20415 13.3462 5.8003V4.9497C13.322 3.54585 14.1505 2.23935 15.5139 1.53115C16.8773 0.82295 18.5641 0.82295 19.9275 1.53115C21.2909 2.23935 22.1194 3.54585 22.0952 4.9497V5.8003Z"
                fill="white"
                stroke="#565656"
                stroke-width="1.5"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M26.3549 22.8748H8.87954C8.48098 22.8954 8.13634 21.2457 8.0957 20.8457C8.0957 16.7834 12.353 12.375 17.6172 12.375C22.8814 12.375 27.3457 16.67 27.3457 20.8457C27.3457 21.2968 26.8027 22.8748 26.3549 22.8748Z"
                fill="white"
                stroke="#565656"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M37.8451 9.38536C37.8741 11.07 36.88 12.6378 35.2439 13.4876C33.6078 14.3375 31.5836 14.3375 29.9475 13.4876C28.3114 12.6378 27.3173 11.07 27.3463 9.38536V8.36464C27.3173 6.68003 28.3114 5.11222 29.9475 4.26238C31.5836 3.41254 33.6078 3.41254 35.2439 4.26238C36.88 5.11222 37.8741 6.68003 37.8451 8.36464V9.38536Z"
                fill="white"
                stroke="#565656"
                stroke-width="1.5"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M41.9698 27.2498H22.1114C21.6585 27.2704 21.2669 25.6207 21.2207 25.2207C21.2207 21.1584 26.0586 16.75 32.0406 16.75C38.0226 16.75 43.0957 21.045 43.0957 25.2207C43.0957 25.6718 42.4786 27.2498 41.9698 27.2498Z"
                fill="white"
                stroke="#565656"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span class="nav-item-link-text"> Rebate </span>
          </a>
        </li>
      </ul>

      <ul class="nav nav--secondary navbar-nav navbar-right" *ngIf="(isAmerenInternal && isSubscribersAllowed) || showMenuOptions">
        <li [routerLink]="'subscribers'">
          <a
            id="secondary-navigation-27113cfc-4c95-4fe3-b47a-459e9838a46e"
            rel=""
            class="accountDashboard_subNav-Outages account-dashboard-subnav"
            target=""
          >
            <svg
              width="52"
              height="50"
              viewBox="0 0 52 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Subscribers</title>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.0952 5.8003C22.1194 7.20415 21.2909 8.51065 19.9275 9.21885C18.5641 9.92705 16.8773 9.92705 15.5139 9.21885C14.1505 8.51065 13.322 7.20415 13.3462 5.8003V4.9497C13.322 3.54585 14.1505 2.23935 15.5139 1.53115C16.8773 0.82295 18.5641 0.82295 19.9275 1.53115C21.2909 2.23935 22.1194 3.54585 22.0952 4.9497V5.8003Z"
                fill="white"
                stroke="#565656"
                stroke-width="1.5"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M26.3549 22.8748H8.87954C8.48098 22.8954 8.13634 21.2457 8.0957 20.8457C8.0957 16.7834 12.353 12.375 17.6172 12.375C22.8814 12.375 27.3457 16.67 27.3457 20.8457C27.3457 21.2968 26.8027 22.8748 26.3549 22.8748Z"
                fill="white"
                stroke="#565656"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M37.8451 9.38536C37.8741 11.07 36.88 12.6378 35.2439 13.4876C33.6078 14.3375 31.5836 14.3375 29.9475 13.4876C28.3114 12.6378 27.3173 11.07 27.3463 9.38536V8.36464C27.3173 6.68003 28.3114 5.11222 29.9475 4.26238C31.5836 3.41254 33.6078 3.41254 35.2439 4.26238C36.88 5.11222 37.8741 6.68003 37.8451 8.36464V9.38536Z"
                fill="white"
                stroke="#565656"
                stroke-width="1.5"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M41.9698 27.2498H22.1114C21.6585 27.2704 21.2669 25.6207 21.2207 25.2207C21.2207 21.1584 26.0586 16.75 32.0406 16.75C38.0226 16.75 43.0957 21.045 43.0957 25.2207C43.0957 25.6718 42.4786 27.2498 41.9698 27.2498Z"
                fill="white"
                stroke="#565656"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span class="nav-item-link-text"> Subscribers </span>
          </a>
        </li>
      </ul>
      <ul class="nav nav--secondary navbar-nav navbar-right" *ngIf="(isAmerenInternal && isGenerationUnitsAllowed) || showMenuOptions">
        <li [routerLink]="'generation-unit'">
          <a
            id="secondary-navigation-27113cfc-4c95-4fe3-b47a-459e9838a46e"
            rel=""
            class="accountDashboard_subNav-Outages account-dashboard-subnav"
            target=""
          >
            <svg
              width="50px"
              height="50px"
              viewBox="0 0 30 50"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <title>Generation Unit</title>
              <g
                id="Symbols"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="ANM/MO/Header/LoggedIn"
                  transform="translate(-1075.000000, -18.000000)"
                  stroke="#565656"
                >
                  <g id="Group" transform="translate(1075.000000, 18.000000)">
                    <g id="clipboard" fill="#FFFFFF" stroke-width="1.7">
                      <rect
                        id="Rectangle"
                        x="0.85"
                        y="3.1462963"
                        width="22.3"
                        height="27.0037037"
                        rx="3"
                      ></rect>
                      <rect
                        id="Rectangle"
                        x="5.42142857"
                        y="0.85"
                        width="13.1571429"
                        height="5.18888889"
                        rx="1"
                      ></rect>
                    </g>
                    <line
                      x1="6.00000006"
                      y1="12.5"
                      x2="19.0000001"
                      y2="12.5"
                      id="Line"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    ></line>
                    <line
                      x1="6.00000006"
                      y1="17.5"
                      x2="19.0000001"
                      y2="17.5"
                      id="Line"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    ></line>
                    <line
                      x1="6.00000006"
                      y1="22.5"
                      x2="19.0000001"
                      y2="22.5"
                      id="Line"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    ></line>
                  </g>
                </g>
              </g>
            </svg>
            Generation Unit
          </a>
        </li>
      </ul>

      <ul class="nav nav--secondary navbar-nav navbar-right" *ngIf="(isAmerenInternal && isGenerationOwnersAllowed) || (!isAmerenInternal && !(hasSelectedGenerationOwner || isUserWithMultiGenerationOwner)) || (!isAmerenInternal && !isUserWithMultiGenerationOwner)">
        <li [routerLink]="'generation-owner'">
          <a
            id="secondary-navigation-27113cfc-4c95-4fe3-b47a-459e9838a46e"
            rel=""
            class="accountDashboard_subNav-Outages account-dashboard-subnav"
            target=""
          >
            <svg
              width="50px"
              height="50px"
              viewBox="0 0 30 50"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <title>Generation Owner</title>
              <g
                id="Symbols"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="ANM/MO/Header/LoggedIn"
                  transform="translate(-1075.000000, -18.000000)"
                  stroke="#565656"
                >
                  <g id="Group" transform="translate(1075.000000, 18.000000)">
                    <g id="clipboard" fill="#FFFFFF" stroke-width="1.7">
                      <rect
                        id="Rectangle"
                        x="0.85"
                        y="3.1462963"
                        width="22.3"
                        height="27.0037037"
                        rx="3"
                      ></rect>
                      <rect
                        id="Rectangle"
                        x="5.42142857"
                        y="0.85"
                        width="13.1571429"
                        height="5.18888889"
                        rx="1"
                      ></rect>
                    </g>
                    <line
                      x1="6.00000006"
                      y1="12.5"
                      x2="19.0000001"
                      y2="12.5"
                      id="Line"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    ></line>
                    <line
                      x1="6.00000006"
                      y1="17.5"
                      x2="19.0000001"
                      y2="17.5"
                      id="Line"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    ></line>
                    <line
                      x1="6.00000006"
                      y1="22.5"
                      x2="19.0000001"
                      y2="22.5"
                      id="Line"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    ></line>
                  </g>
                </g>
              </g>
            </svg>
            Generation Owner
          </a>
        </li>
      </ul>

      <ul class="nav nav--secondary navbar-nav navbar-right" *ngIf="!isAmerenInternal && isUserWithMultiGenerationOwner">
        <li [routerLink]="'select-generation-owner'">
          <a
            id="secondary-navigation-27113cfc-4c95-4fe3-b47a-459e9838a46e"
            rel=""
            class="accountDashboard_subNav-Outages account-dashboard-subnav"
            target=""
          >
            <svg
              width="50px"
              height="50px"
              viewBox="0 0 30 50"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <title>Select Owners</title>
              <g
                id="Symbols"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="ANM/MO/Header/LoggedIn"
                  transform="translate(-1075.000000, -18.000000)"
                  stroke="#565656"
                >
                  <g id="Group" transform="translate(1075.000000, 18.000000)">
                    <g id="clipboard" fill="#FFFFFF" stroke-width="1.7">
                      <rect
                        id="Rectangle"
                        x="0.85"
                        y="3.1462963"
                        width="22.3"
                        height="27.0037037"
                        rx="3"
                      ></rect>
                      <rect
                        id="Rectangle"
                        x="5.42142857"
                        y="0.85"
                        width="13.1571429"
                        height="5.18888889"
                        rx="1"
                      ></rect>
                    </g>
                    <line
                      x1="6.00000006"
                      y1="12.5"
                      x2="19.0000001"
                      y2="12.5"
                      id="Line"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    ></line>
                    <line
                      x1="6.00000006"
                      y1="17.5"
                      x2="19.0000001"
                      y2="17.5"
                      id="Line"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    ></line>
                    <line
                      x1="6.00000006"
                      y1="22.5"
                      x2="19.0000001"
                      y2="22.5"
                      id="Line"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    ></line>
                  </g>
                </g>
              </g>
            </svg>
            Select Owners
          </a>
        </li>
      </ul>
      
      <ul class="nav nav--secondary navbar-nav navbar-right" *ngIf="isAdmin">
        <li [routerLink]="'manage-users-access'">
          <a
            id="secondary-navigation-27113cfc-4c95-4fe3-b47a-459e9838a46e"
            rel=""
            class="accountDashboard_subNav-Outages account-dashboard-subnav"
            target=""
          >
            <svg
              width="52"
              height="50"
              viewBox="0 0 52 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Manage Users Acess</title>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.0952 5.8003C22.1194 7.20415 21.2909 8.51065 19.9275 9.21885C18.5641 9.92705 16.8773 9.92705 15.5139 9.21885C14.1505 8.51065 13.322 7.20415 13.3462 5.8003V4.9497C13.322 3.54585 14.1505 2.23935 15.5139 1.53115C16.8773 0.82295 18.5641 0.82295 19.9275 1.53115C21.2909 2.23935 22.1194 3.54585 22.0952 4.9497V5.8003Z"
                fill="white"
                stroke="#565656"
                stroke-width="1.5"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M26.3549 22.8748H8.87954C8.48098 22.8954 8.13634 21.2457 8.0957 20.8457C8.0957 16.7834 12.353 12.375 17.6172 12.375C22.8814 12.375 27.3457 16.67 27.3457 20.8457C27.3457 21.2968 26.8027 22.8748 26.3549 22.8748Z"
                fill="white"
                stroke="#565656"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M37.8451 9.38536C37.8741 11.07 36.88 12.6378 35.2439 13.4876C33.6078 14.3375 31.5836 14.3375 29.9475 13.4876C28.3114 12.6378 27.3173 11.07 27.3463 9.38536V8.36464C27.3173 6.68003 28.3114 5.11222 29.9475 4.26238C31.5836 3.41254 33.6078 3.41254 35.2439 4.26238C36.88 5.11222 37.8741 6.68003 37.8451 8.36464V9.38536Z"
                fill="white"
                stroke="#565656"
                stroke-width="1.5"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M41.9698 27.2498H22.1114C21.6585 27.2704 21.2669 25.6207 21.2207 25.2207C21.2207 21.1584 26.0586 16.75 32.0406 16.75C38.0226 16.75 43.0957 21.045 43.0957 25.2207C43.0957 25.6718 42.4786 27.2498 41.9698 27.2498Z"
                fill="white"
                stroke="#565656"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span class="nav-item-link-text"> Manage Users Access </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="desktop-hide">
    <div id="wrapper">
      <div class="d-flex flex-row">
        <div class="navbar-top-content">
          <button
            id="open-menu"
            class="mobile-only"
            (click)="showMenu($event)"
            aria-label="open menu"
            [attr.aria-hidden]="isMobile ? false : true"
          >
            <div id="navbar" class="desktop-only">
              <nav
                aria-labelledby="main-menu-label"
                style="
                  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
                    0 6px 20px 0 rgba(0, 0, 0, 0.19);
                "
              >
                <div class="d-flex flex-row">
                  <button
                    type="button"
                    class="navbar-brand collapsed mob-menu-logo"
                  >
                    <span class="sr-only"
                      >Ameren. Focused energy. For life.</span
                    >
                    <div class="logo-container" id="logo-container-default">
                      <!-- Default Ameren Logo -->
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 186 71"
                      >
                        <title>logo_ameren_default</title>
                        <g>
                          <path
                            class="logo-cls-1"
                            d="M24,33H.19a29.25,29.25,0,0,1-.11-5.38L24,32.59V33Z"
                          ></path>
                          <path
                            class="logo-cls-2"
                            d="M40,30.35,60.2,22.73a29.32,29.32,0,0,1,.67,4.82L40.12,30.68a.41.41,0,0,0-.11-.34Z"
                          ></path>
                          <path
                            class="logo-cls-1"
                            d="M24.31,30.24.42,24.53a33.51,33.51,0,0,1,2.13-6.72l22,12a.58.58,0,0,0-.22.45Z"
                          ></path>
                          <path
                            class="logo-cls-1"
                            d="M37.54,26.43l13.35-19a25.39,25.39,0,0,1,4.37,4.82L37.88,26.77l-.34-.34Z"
                          ></path>
                          <path
                            class="logo-cls-1"
                            d="M26,27.32,4.57,13.89a30.76,30.76,0,0,1,6.17-6.94L26.21,27c-.11.11-.22.22-.22.34Z"
                          ></path>
                          <path
                            class="logo-cls-1"
                            d="M33.62,24.64,34.29,0a31.64,31.64,0,0,1,8.52,2.35l-8.75,22.4c-.11-.11-.22-.11-.45-.11Z"
                          ></path>
                          <path
                            class="logo-cls-1"
                            d="M29.24,25.09,15.56,3.7A29.2,29.2,0,0,1,26.44,0l3.25,24.86a.85.85,0,0,0-.45.22Z"
                          ></path>
                          <path
                            class="logo-cls-1"
                            d="M2.21,40.65s-.45-1.12-.9-2.46S.64,35.5.64,35.5c7.74-1,19.85-1.12,28.15-1.12,0,0-16,1.57-26.58,6.27Z"
                          ></path>
                          <path
                            class="logo-cls-1"
                            d="M19.37,57.45a22.22,22.22,0,0,1-4.26-1.9,28.9,28.9,0,0,1-4-2.91c4.71-5.94,19.85-17.47,34.09-18.25,0,0-14.69,5.15-25.8,23.07Z"
                          ></path>
                          <path
                            class="logo-cls-1"
                            d="M38.44,58.46a31.67,31.67,0,0,1-8,1,29.09,29.09,0,0,1-6.28-.67c.9-3,11.1-20.72,23.33-24.41,0,0-9.42,10-9.08,24.08Z"
                          ></path>
                          <path
                            class="logo-cls-1"
                            d="M7.82,49.61a29.31,29.31,0,0,1-2.47-3,21.72,21.72,0,0,1-2-3.47c3.81-2.46,17.38-8.74,36.56-8.74A80.44,80.44,0,0,0,7.82,49.61Z"
                          ></path>
                          <path
                            class="logo-cls-3"
                            d="M41.69,70.44l17-36.06h9.65L66.48,70.44H58.85l.67-7.28H52.46l-3,7.28ZM61.54,42.11,54.7,58H60l1.57-15.9Z"
                          ></path>
                          <path
                            class="logo-cls-3"
                            d="M68.27,70.44l7-27.55h7.51l-.56,2a11.68,11.68,0,0,1,6.73-2.58c3.36,0,4,2.24,4.26,2.91.78-.45,4.26-2.91,7.29-2.91,5.16,0,4.15,4,3.81,5.38L98.55,70.44H91l5.38-21.73c.22-.56,0-1.34-1.46-1.34a4.63,4.63,0,0,0-2.36,1l-5.5,22.06H79.6l5.5-21.73c0-.22.34-1.34-1.57-1.34a4.12,4.12,0,0,0-2.13.9L75.79,70.44Z"
                          ></path>
                          <path
                            class="logo-cls-3"
                            d="M110.89,71c-7.07,0-9.08-2.69-7.74-8.18l3.14-12.43c1-4.26,4.93-8.06,11.66-8.06,8.41,0,8.19,5.82,7.85,7.17l-2.13,8.4H111.56l-1.46,5.94c-.34,1.34,0,2.46,1.57,2.46a3.27,3.27,0,0,0,3.25-2.46l.67-2.69h7.18l-.67,2.69c-.9,2.8-3.93,7.17-11.22,7.17Zm2.92-21.84-1.12,4.14h4.82l1-4.14c.22-.56.56-2-1.68-2a2.83,2.83,0,0,0-3,2Z"
                          ></path>
                          <path
                            class="logo-cls-3"
                            d="M123.23,70.44l7-27.55h7.07l-.67,2.69c3-2.13,5.16-3.25,9.42-3.25l-1.23,5.26a27.66,27.66,0,0,0-2.92-.22c-5.61,0-6.5,3.47-7,5.26L130.4,70.44Z"
                          ></path>
                          <path
                            class="logo-cls-3"
                            d="M148.91,71c-7.07,0-9.08-2.69-7.74-8.18l3.14-12.43c1-4.26,4.93-8.06,11.55-8.06,8.52,0,8.3,5.82,8,7.17l-2.13,8.4H149.58l-1.46,5.94c-.34,1.34,0,2.46,1.57,2.46a3.27,3.27,0,0,0,3.25-2.46l.67-2.69h7.18L160,63.83c-.9,2.8-3.81,7.17-11.1,7.17Zm2.8-21.84-1,4.14h4.82l1-4.14c.22-.56.56-2-1.68-2a3.07,3.07,0,0,0-3.14,2Z"
                          ></path>
                          <path
                            class="logo-cls-3"
                            d="M161.36,70.44l7-27.55h7.4L175.15,45c.9-.67,3.25-2.69,6.5-2.69,5.5,0,4.49,4.59,3.81,7.28L180.2,70.44h-7.51L177.62,51c.56-2.35.79-3.47-.79-3.47a5.92,5.92,0,0,0-2.47.9l-5.5,22.06Z"
                          ></path>
                        </g>
                      </svg>
                    </div>
                  </button>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="menu"
                    aria-label="Close"
                    (click)="hideMenu($event)"
                  ></button>
                </div>
                <hr class="" />

                <ul>
                  <li>
                    <a
                      class="text-center log__in__out"
                      id="secondary-navigation-27113cfc-4c95-4fe3-b47a-459e9838a46e"
                      rel=""
                      (click)="logout()"
                      target=""
                    >
                      <svg
                        width="21"
                        height="18"
                        viewBox="0 0 21 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title>Logout</title>
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M2.10583 9.1869H13.9964C14.1721 9.1869 14.3144 9.04647 14.3144 8.87317C14.3144 8.69987 14.1721 8.55945 13.9964 8.55945H2.11416L6.89808 3.76025C7.02126 3.63671 7.01961 3.43806 6.89439 3.31658C6.76924 3.19517 6.5679 3.19674 6.44472 3.32028L1.14812 8.63386C1.09304 8.67941 1.0533 8.74222 1.03944 8.81476C1.03931 8.81589 1.03918 8.81695 1.03899 8.81808C1.03702 8.82781 1.03683 8.83772 1.03587 8.84757C1.03486 8.85767 1.0332 8.86771 1.0332 8.87788C1.0332 8.88779 1.03486 8.89758 1.03581 8.90743C1.03676 8.9176 1.03702 8.92776 1.03905 8.93774C1.04096 8.94753 1.04452 8.95681 1.04732 8.96629C1.05031 8.97626 1.0526 8.98624 1.05654 8.9959C1.06029 9.00512 1.06576 9.0136 1.07047 9.02251C1.07543 9.03185 1.07963 9.04152 1.0856 9.05036C1.0912 9.05865 1.09832 9.06605 1.10481 9.07389C1.1113 9.08193 1.11689 9.09046 1.12433 9.09793L6.44485 14.428C6.50711 14.4903 6.58927 14.5216 6.67143 14.5216C6.75201 14.5216 6.83258 14.4916 6.89458 14.4314C7.01967 14.3098 7.0212 14.1111 6.89795 13.9877L2.10583 9.1869Z"
                          fill="#565656"
                          stroke="#565656"
                          stroke-width="0.4"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M17.6571 2.74187V15.2582C17.6571 16.2186 16.8652 17 15.8917 17H10.8318C9.8584 17 9.06641 16.2186 9.06641 15.2582V10.5422C9.06641 10.3689 9.20873 10.2285 9.38437 10.2285C9.55995 10.2285 9.70234 10.3689 9.70234 10.5422V15.2582C9.70234 15.8726 10.209 16.3726 10.8318 16.3726H15.8917C16.5145 16.3726 17.0212 15.8726 17.0212 15.2582V2.74187C17.0212 2.12734 16.5145 1.62745 15.8917 1.62745H10.8318C10.209 1.62745 9.70234 2.12734 9.70234 2.74187V6.7036C9.70234 6.87691 9.55995 7.01733 9.38437 7.01733C9.20873 7.01733 9.06641 6.87691 9.06641 6.7036V2.74187C9.06641 1.78143 9.8584 1 10.8318 1H15.8917C16.8652 1 17.6571 1.78143 17.6571 2.74187V2.74187Z"
                          fill="#565656"
                          stroke="#565656"
                          stroke-width="0.4"
                        />
                      </svg>
                      Log Out
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <svg
              width="37px"
              height="26px"
              viewBox="0 0 37 26"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <title>Group 3</title>
              <g
                id="Master-Views"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
                stroke-linecap="round"
              >
                <g
                  id="Mobile---My-Account"
                  transform="translate(-374.000000, -24.000000)"
                  stroke="#6F6F6F"
                  stroke-width="2"
                >
                  <g id="Group-3" transform="translate(375.200000, 25.000000)">
                    <line
                      x1="0.397727273"
                      y1="12"
                      x2="34.6022727"
                      y2="12"
                      id="Line-10"
                    ></line>
                    <line
                      x1="0.397727273"
                      y1="0.363636364"
                      x2="34.6022727"
                      y2="0.363636364"
                      id="Line-10"
                    ></line>
                    <line
                      x1="0.397727273"
                      y1="23.6363636"
                      x2="34.6022727"
                      y2="23.6363636"
                      id="Line-10"
                    ></line>
                  </g>
                </g>
              </g>
            </svg>
          </button>
        </div>
        <div>
          <button type="button" class="navbar-brand collapsed">
            <span class="sr-only">Ameren. Focused energy. For life.</span>
            <div class="logo-container" id="logo-container-default">
              <!-- Default Ameren Logo -->
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 186 71">
                <title>logo_ameren_default</title>
                <g>
                  <path
                    class="logo-cls-1"
                    d="M24,33H.19a29.25,29.25,0,0,1-.11-5.38L24,32.59V33Z"
                  ></path>
                  <path
                    class="logo-cls-2"
                    d="M40,30.35,60.2,22.73a29.32,29.32,0,0,1,.67,4.82L40.12,30.68a.41.41,0,0,0-.11-.34Z"
                  ></path>
                  <path
                    class="logo-cls-1"
                    d="M24.31,30.24.42,24.53a33.51,33.51,0,0,1,2.13-6.72l22,12a.58.58,0,0,0-.22.45Z"
                  ></path>
                  <path
                    class="logo-cls-1"
                    d="M37.54,26.43l13.35-19a25.39,25.39,0,0,1,4.37,4.82L37.88,26.77l-.34-.34Z"
                  ></path>
                  <path
                    class="logo-cls-1"
                    d="M26,27.32,4.57,13.89a30.76,30.76,0,0,1,6.17-6.94L26.21,27c-.11.11-.22.22-.22.34Z"
                  ></path>
                  <path
                    class="logo-cls-1"
                    d="M33.62,24.64,34.29,0a31.64,31.64,0,0,1,8.52,2.35l-8.75,22.4c-.11-.11-.22-.11-.45-.11Z"
                  ></path>
                  <path
                    class="logo-cls-1"
                    d="M29.24,25.09,15.56,3.7A29.2,29.2,0,0,1,26.44,0l3.25,24.86a.85.85,0,0,0-.45.22Z"
                  ></path>
                  <path
                    class="logo-cls-1"
                    d="M2.21,40.65s-.45-1.12-.9-2.46S.64,35.5.64,35.5c7.74-1,19.85-1.12,28.15-1.12,0,0-16,1.57-26.58,6.27Z"
                  ></path>
                  <path
                    class="logo-cls-1"
                    d="M19.37,57.45a22.22,22.22,0,0,1-4.26-1.9,28.9,28.9,0,0,1-4-2.91c4.71-5.94,19.85-17.47,34.09-18.25,0,0-14.69,5.15-25.8,23.07Z"
                  ></path>
                  <path
                    class="logo-cls-1"
                    d="M38.44,58.46a31.67,31.67,0,0,1-8,1,29.09,29.09,0,0,1-6.28-.67c.9-3,11.1-20.72,23.33-24.41,0,0-9.42,10-9.08,24.08Z"
                  ></path>
                  <path
                    class="logo-cls-1"
                    d="M7.82,49.61a29.31,29.31,0,0,1-2.47-3,21.72,21.72,0,0,1-2-3.47c3.81-2.46,17.38-8.74,36.56-8.74A80.44,80.44,0,0,0,7.82,49.61Z"
                  ></path>
                  <path
                    class="logo-cls-3"
                    d="M41.69,70.44l17-36.06h9.65L66.48,70.44H58.85l.67-7.28H52.46l-3,7.28ZM61.54,42.11,54.7,58H60l1.57-15.9Z"
                  ></path>
                  <path
                    class="logo-cls-3"
                    d="M68.27,70.44l7-27.55h7.51l-.56,2a11.68,11.68,0,0,1,6.73-2.58c3.36,0,4,2.24,4.26,2.91.78-.45,4.26-2.91,7.29-2.91,5.16,0,4.15,4,3.81,5.38L98.55,70.44H91l5.38-21.73c.22-.56,0-1.34-1.46-1.34a4.63,4.63,0,0,0-2.36,1l-5.5,22.06H79.6l5.5-21.73c0-.22.34-1.34-1.57-1.34a4.12,4.12,0,0,0-2.13.9L75.79,70.44Z"
                  ></path>
                  <path
                    class="logo-cls-3"
                    d="M110.89,71c-7.07,0-9.08-2.69-7.74-8.18l3.14-12.43c1-4.26,4.93-8.06,11.66-8.06,8.41,0,8.19,5.82,7.85,7.17l-2.13,8.4H111.56l-1.46,5.94c-.34,1.34,0,2.46,1.57,2.46a3.27,3.27,0,0,0,3.25-2.46l.67-2.69h7.18l-.67,2.69c-.9,2.8-3.93,7.17-11.22,7.17Zm2.92-21.84-1.12,4.14h4.82l1-4.14c.22-.56.56-2-1.68-2a2.83,2.83,0,0,0-3,2Z"
                  ></path>
                  <path
                    class="logo-cls-3"
                    d="M123.23,70.44l7-27.55h7.07l-.67,2.69c3-2.13,5.16-3.25,9.42-3.25l-1.23,5.26a27.66,27.66,0,0,0-2.92-.22c-5.61,0-6.5,3.47-7,5.26L130.4,70.44Z"
                  ></path>
                  <path
                    class="logo-cls-3"
                    d="M148.91,71c-7.07,0-9.08-2.69-7.74-8.18l3.14-12.43c1-4.26,4.93-8.06,11.55-8.06,8.52,0,8.3,5.82,8,7.17l-2.13,8.4H149.58l-1.46,5.94c-.34,1.34,0,2.46,1.57,2.46a3.27,3.27,0,0,0,3.25-2.46l.67-2.69h7.18L160,63.83c-.9,2.8-3.81,7.17-11.1,7.17Zm2.8-21.84-1,4.14h4.82l1-4.14c.22-.56.56-2-1.68-2a3.07,3.07,0,0,0-3.14,2Z"
                  ></path>
                  <path
                    class="logo-cls-3"
                    d="M161.36,70.44l7-27.55h7.4L175.15,45c.9-.67,3.25-2.69,6.5-2.69,5.5,0,4.49,4.59,3.81,7.28L180.2,70.44h-7.51L177.62,51c.56-2.35.79-3.47-.79-3.47a5.92,5.92,0,0,0-2.47.9l-5.5,22.06Z"
                  ></path>
                </g>
              </svg>
            </div>
          </button>
        </div>
        <div class="ms-auto">
          <a
            class="text-center log__in__out"
            id="secondary-navigation-27113cfc-4c95-4fe3-b47a-459e9838a46e"
            rel=""
            (click)="logout()"
            target=""
            style="font-size: initial"
          >
            <svg
              width="21"
              height="30"
              viewBox="0 0 21 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Logout</title>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.10583 9.1869H13.9964C14.1721 9.1869 14.3144 9.04647 14.3144 8.87317C14.3144 8.69987 14.1721 8.55945 13.9964 8.55945H2.11416L6.89808 3.76025C7.02126 3.63671 7.01961 3.43806 6.89439 3.31658C6.76924 3.19517 6.5679 3.19674 6.44472 3.32028L1.14812 8.63386C1.09304 8.67941 1.0533 8.74222 1.03944 8.81476C1.03931 8.81589 1.03918 8.81695 1.03899 8.81808C1.03702 8.82781 1.03683 8.83772 1.03587 8.84757C1.03486 8.85767 1.0332 8.86771 1.0332 8.87788C1.0332 8.88779 1.03486 8.89758 1.03581 8.90743C1.03676 8.9176 1.03702 8.92776 1.03905 8.93774C1.04096 8.94753 1.04452 8.95681 1.04732 8.96629C1.05031 8.97626 1.0526 8.98624 1.05654 8.9959C1.06029 9.00512 1.06576 9.0136 1.07047 9.02251C1.07543 9.03185 1.07963 9.04152 1.0856 9.05036C1.0912 9.05865 1.09832 9.06605 1.10481 9.07389C1.1113 9.08193 1.11689 9.09046 1.12433 9.09793L6.44485 14.428C6.50711 14.4903 6.58927 14.5216 6.67143 14.5216C6.75201 14.5216 6.83258 14.4916 6.89458 14.4314C7.01967 14.3098 7.0212 14.1111 6.89795 13.9877L2.10583 9.1869Z"
                fill="#565656"
                stroke="#565656"
                stroke-width="0.4"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.6571 2.74187V15.2582C17.6571 16.2186 16.8652 17 15.8917 17H10.8318C9.8584 17 9.06641 16.2186 9.06641 15.2582V10.5422C9.06641 10.3689 9.20873 10.2285 9.38437 10.2285C9.55995 10.2285 9.70234 10.3689 9.70234 10.5422V15.2582C9.70234 15.8726 10.209 16.3726 10.8318 16.3726H15.8917C16.5145 16.3726 17.0212 15.8726 17.0212 15.2582V2.74187C17.0212 2.12734 16.5145 1.62745 15.8917 1.62745H10.8318C10.209 1.62745 9.70234 2.12734 9.70234 2.74187V6.7036C9.70234 6.87691 9.55995 7.01733 9.38437 7.01733C9.20873 7.01733 9.06641 6.87691 9.06641 6.7036V2.74187C9.06641 1.78143 9.8584 1 10.8318 1H15.8917C16.8652 1 17.6571 1.78143 17.6571 2.74187V2.74187Z"
                fill="#565656"
                stroke="#565656"
                stroke-width="0.4"
              />
            </svg>
          </a>
          <span style="font-size: smaller; font-weight: 600">Logout</span>
        </div>
      </div>
    </div>
  </div>
</div>

<app-state-selector
  [showStateSelector]="showStateSelector && !isAuthenticated"
  (ondismiss)="onStateSelected($event)"
></app-state-selector>
