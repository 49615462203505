<!-- <div class="select-generation-owner-container">
  <div class="select-generation-owner-header">Select Generation Owner</div>
</div> -->
<div class="select-generation-owner-container justify-content-center">
 
  <div class="select-generation-owner-header mb-2">Select Generation Owner</div>
  <div class="col-md-12">
    <em>
      Disclaimer: COGF Participating Customers will be referred to throughout this portal as Subscribers.
    </em>
  </div>
  <div class="select-generation-owner-content mt-5">          
  <p>
    Please select an owner before you proceed.
  </p>
 </div>

  <div class="col-md-12 mt-2">
  
    <table class="table gen_owner_grid" aria-describedby="Generation owner">
      <thead>
        <tr>
          <th scope="col">Owner ID</th>
          <th scope="col">Name</th>         
          <th scope="col">Number of Generation Units</th>
          <th scope="col"> Status</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td id="gen_owner_loading" colspan="6" *ngIf="isApiLoading">
            <div class="text-center">
              <p>Please wait..</p>
              <ameren-loading></ameren-loading>
            </div>
          </td>
          <td
            colspan="6"
            id="gen_owner_no_data"
            *ngIf="!isApiLoading && !apiError && (!owners || !owners.length)"
          >
            <div class="text-center">No data</div>
          </td>
          <td
            colspan="6"
            id="gen_owner_api_error"
            *ngIf="!isApiLoading && apiError"
          >
            <div class="text-center text-danger">
              {{integrationErrorMsg ? integrationErrorMsg : 'Something went wrong. Please try again!'}}
              
            </div>
          </td>
        </tr>
        <tr *ngFor="let owner of owners">
          <td>{{ owner.ownerId }}</td>
          <td>
            <a
              class="gen_owner_link"
              [routerLink]="['/generation-owner/owner-dashboard']"
              [queryParams]="{ ownerId: owner.ownerId }"
              >{{ owner.name }}</a
            >
          </td> 
          <td>
            {{ owner.generatingUnitsQuantity }}
          </td>
          <td> 
            <span>
              <h5 
                [ngStyle]="{
                color: owner.status ==='Active'? 'Green' : 'Red'
                }"
              >
              {{owner.status}} 
              </h5>
              </span>
            </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
