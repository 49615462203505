import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ManageprofileComponent } from "./manageprofile.component";
import { EditProfileComponent } from "./edit-profile/edit-profile.component";
import { EditPasswordComponent } from "./edit-password/edit-password.component";

const routes: Routes = [
  { path: "", component: ManageprofileComponent },
  { path: "edit-profile", component: EditProfileComponent },
  { path: "edit-password", component: EditPasswordComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ManageProfileRoutingModule {}
