import { NgModule } from "@angular/core";
import { GenerationOwnerRoutingModule } from "./generation-owner-routing.module";
import { GenerationOwnerComponent } from "./generation-owner.component";
import { ExportUtility } from "src/app/shared/utilities/utility.export";
import { GenerationOwnerManagerService } from "./services/generation-owner-manager.service";
import { GenerationOwnerApiService } from "./services/generation-owner-api.service";
import { AddGenerationOwnerComponent } from "./add-generation-owner/add-generation-owner.component";
import { EditGenerationOwnerComponent } from "./edit-generation-owner/edit-generation-owner.component";
import { OwnerDashboardComponent } from "./owner-dashboard/owner-dashboard.component";
import { SharedModule } from "src/app/shared/shared.module";
import { SelectGenerationOwnerComponent } from './select-generation-owner/select-generation-owner.component';

@NgModule({
  declarations: [
    GenerationOwnerComponent,
    OwnerDashboardComponent,
    EditGenerationOwnerComponent,
    AddGenerationOwnerComponent,
    SelectGenerationOwnerComponent,
  ],
  imports: [SharedModule, GenerationOwnerRoutingModule],
  providers: [
    ExportUtility,
    GenerationOwnerManagerService,
    GenerationOwnerApiService,
  ],
})
export class GenerationOwnerModule {}
