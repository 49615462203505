import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EnumRole } from 'src/app/shared/enums/role';
import { Profile } from 'src/app/shared/models/profile';
import { ProfileManagerService } from 'src/app/shared/services/profilemanager.service';
import { AuthService } from '../../../../src/app/shared/services/auth.service';

@Component({
  selector: 'app-signin-redirect-callback',
  templateUrl: './signin-redirect-callback.component.html',
  styleUrls: ['./signin-redirect-callback.component.scss'],
})
export class SigninRedirectCallbackComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private profileManager: ProfileManagerService
  ) {}

  ngOnInit() {
    this.authService.LoginComplete().then(
      (user) => {
        this.profileManager.profile.forEach((a: Profile) => {
          if (a.anmRoleMap && a.anmRoleMap[0]?.role == 'anmIPAViewOnly') {
            this.router.navigateByUrl('/reports');
          } else if (
            a.anmRoleMap &&
            a.anmRoleMap[0]?.role == 'anmRebateApprover'
          ) {
            this.router.navigateByUrl('/rebate');
          } else if (a.anmRoleMap && !a.isUserWithMultiGenerationOwner) {
            this.router.navigateByUrl('/generation-owner');
          } else if (a.isUserWithMultiGenerationOwner) {
            this.router.navigateByUrl('/select-generation-owner');
          } else {
            this.router.navigateByUrl('/ineligible-user');
          }
        });
      },
      (error: any) => {
        this.authService.Login();
      }
    );
  }
}
