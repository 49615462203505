import { ExportUtility } from 'src/app/shared/utilities/utility.export';
import { AuthService } from 'src/app/shared/services/auth.service';
import { GenerationUnitManagerService } from './../services/generation-unit-manager.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { GenerationOwnerManagerService } from '../../generation-owner/services/generation-owner-manager.service';
import { FineGrainAuthorization } from 'src/app/shared/models/fine-grain-authorization';
import 'moment-timezone';
import { SubscriberListModel } from '../models/subsriberlist.model';
import { Banking } from 'src/app/shared/models/banking';

@Component({
  selector: 'app-generation-unit-subscription-detail',
  templateUrl: './generation-unit-subscription-detail.component.html',
  styleUrls: ['./generation-unit-subscription-detail.component.scss'],
})
export class GenerationUnitSubscriptionDetailComponent implements OnInit {
  unitId: any;
  billAccountNumber: string;
  ownerId: string;
  subscribers: SubscriberListModel[];
  allSubscribers: SubscriberListModel[];
  searchString: string;
  isSubscriberListLoading: boolean;
  isSubscriberListApiError: boolean = false;
  showInactiveSubscribers: boolean;
  selectedSubscribersToRemove: any[] = [];
  currentUser: any;
  isRemoveSuccess: boolean;
  isRemoveError: boolean;
  isRemoveLoading: boolean;
  removeEffectiveDt: any;
  removeErrorMsg: string;
  subscriberListErrorMsg: string;
  unit: any;
  isGetUnitApiLoading: boolean;
  isGetUnitApiError: boolean;

  pageSize = 10;
  currentPage = 0;
  totalSize = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pagedSubscribers: any[];

  isBankingStatusAPIFailure: boolean;
  isBankingStatusLoading: boolean;
  bankingActiveStatus: boolean;
  noBankInfoMsg: boolean;
  pennytestInprogressMsg: boolean;
  genunitsTerminatedMsg: boolean;

  functionalityAccess: FineGrainAuthorization;
  selectedGenerationOwner: any;
  hasSelectedGenerationOwner: boolean = false;

  constructor(
    private _route: ActivatedRoute,
    private genUnitMgr: GenerationUnitManagerService,
    private _authService: AuthService,
    private _router: Router,
    private _export: ExportUtility,
    private generationOwnerManager: GenerationOwnerManagerService
  ) {
    this._route.queryParams.subscribe((data) => {
      this.unitId = data['unitId'];
      this.billAccountNumber = data['acct'];
      this.ownerId = data['ownerId'];
    });
    this._authService.getUser().then((res) => {
      this.currentUser = res?.profile;
    });
  }

  ngOnInit(): void {
    this.selectedGenerationOwner =
      this._authService.getSelectedGenerationOwner();
    this.hasSelectedGenerationOwner = this.selectedGenerationOwner
      ? true
      : false;

    let role = this._authService.getSelectedGenerationOwnerRole();
    let functionality = '';
    this.functionalityAccess =
      this._authService.getPortalFunctioanalityAccesses(functionality, role);

    this.getSubscribersByUnitId();
    this.getUnitByUnitId();
  }

  getSubscribersByUnitId() {
    this.isSubscriberListLoading = true;
    
    this.genUnitMgr.getSubscribers('0', this.unitId, this.ownerId).subscribe({
      next: (resp) => {
        this.isSubscriberListLoading = false;
        this.isSubscriberListApiError = false;
        this.allSubscribers = resp;
        this.allSubscribers.forEach((sub: any, i) => {
          if (sub.statusCode == '01') sub.statusDescription = 'Inactive';
          else if (sub.statusCode == '02') sub.statusDescription = 'Active';
          else if (sub.statusCode == '03') sub.statusDescription = 'Pending';
          else sub.statusDescription = 'Finaled';

          sub['selected'] = false;
          sub['index'] = i;
        });
        this.subscribers = this.allSubscribers.filter(
          (s) => s.statusCode == '02'
        );

        if (this.hasSelectedGenerationOwner)
          this.subscribers = this.subscribers.filter(
            (x) => x.ownerId == this.selectedGenerationOwner?.ownerId
          );

        this.iterator();
      },
      error: (err) => {
        this.isSubscriberListLoading = false;
        if (
          err?.status == 400 &&
          err?.error?.errorDetails?.message?.indexOf('not found')
        ) {
          this.isSubscriberListApiError = false;
          this.allSubscribers = [];
          this.subscribers = [];
        } else this.isSubscriberListApiError = true;
      },
    });
  }
  exportSubscribers() {
    let keyHeaders: string[] = [];
    let modifiedlist: any = [];
    let displayHeaders: string[] = [
      'Subscriber Bill Account Number',
      'Subscriber Name',
      'Meter Number',
      'Subscribed KW',
      'Active Date',
      'Address',
      'Termination Date',
      'Annual Period',
      'Status',
    ];

    keyHeaders = [
      'billAccountNumber',
      'subscriberName',
      'meterNo',
      'usageQuantity',
      'effectiveDate',
      'subscriberAddress',
      'terminationDate',
      'annualMonth',
      'statusDescription',
    ];

    this.subscribers.forEach((row: any) => {
      let temp: any = {};

      temp['billAccountNumber'] = row.billAccountNumber;
      temp['subscriberName'] = row.subscriberName;
      temp['meterNo'] = row.meterNo;
      temp['usageQuantity'] = row.usageQuantity;
      temp['effectiveDate'] = row.effectiveDate;
      if (this.showInactiveSubscribers) {
        temp['terminationDate'] = row.terminationDate;
      }
      temp['subscriberAddress'] = row.subscriberAddress;
      temp['annualMonth'] = row.annualMonth;
      temp['statusDescription'] = row.statusDescription;
      if (this.showInactiveSubscribers) {
        temp['terminationDate'] = row.terminationDate;
      } else {
        temp['terminationDate'] = row.showTerminationDate
          ? row.terminationDate
          : '';
      }

      modifiedlist.push(temp);
    });
    this._export.exportJsonToExcelFile(
      modifiedlist,
      'subscribers',
      false,
      keyHeaders,
      displayHeaders
    );
  }

  onToggleActiveInactive() {
    if (this.hasSelectedGenerationOwner)
      this.subscribers = this.allSubscribers.filter(
        (x) => x.ownerId == this.selectedGenerationOwner?.ownerId
      );
    else this.subscribers = this.allSubscribers;

    this.showInactiveSubscribers = !this.showInactiveSubscribers;
    if (this.showInactiveSubscribers) {
      this.subscribers = this.subscribers.filter((a) => a.statusCode != '02');
    } else {
      this.subscribers = this.subscribers.filter((x) => x.statusCode == '02');
    }
    this.iterator();
  }

  selectSubscriber(sub: any) {
    sub.selected = !sub.selected;
    if (sub.selected) this.selectedSubscribersToRemove.push(sub);
    else
      this.selectedSubscribersToRemove =
        this.selectedSubscribersToRemove.filter(
          (s: any) => s.index != sub.index
        );
  }

  removeSubscribers() {
    this.isRemoveLoading = true;
    let removeSubsApiBody: any[] = [];
    this.selectedSubscribersToRemove.forEach((elm: any) => {
      removeSubsApiBody.push({
        unitId: elm.unitId,
        owner: elm.ownerId,
        billAccountNumber: elm.billAccountNumber,
        subscriberName: elm.subscriberName,
        meterNo: elm.meterNo,
        effectiveDate: this.removeEffectiveDt || elm.effectiveDate,
        usageQuantity: elm.usageQuantity,
        premiseNo: elm.premiseNo,
        servicePt: elm.servicePt,
      });
    });

    this.genUnitMgr
      .removeSubscribers(removeSubsApiBody, this.currentUser.email)
      .subscribe({
        next: (resp) => {
          this.isRemoveLoading = false;
          this.isRemoveError = false;
          this.isRemoveSuccess = true;
        },
        error: (error) => {
          console.log(error);
          this.isRemoveLoading = false;
          this.isRemoveError = true;
          this.isRemoveSuccess = false;
          if (error?.status == 400) {
            this.removeErrorMsg = error?.error?.errorDetails?.message;
          } else {
            this.removeErrorMsg = 'Something went wrong. Please try again..';
          }
        },
      });
  }

  closeRemoveModal() {
    this.removeEffectiveDt = undefined;
    this.removeErrorMsg = '';
    this.isRemoveError = false;
    this.isRemoveSuccess = false;
    this.selectedSubscribersToRemove = [];
    this.subscribers = [];
    this.allSubscribers = [];
    this.getSubscribersByUnitId();
  }

  searchFilter() {
    if (this.showInactiveSubscribers)
      this.subscribers = this.allSubscribers.filter(
        (a) => a.statusCode != '02'
      );
    else
      this.subscribers = this.allSubscribers.filter(
        (x) => x.statusCode == '02'
      );

    if (this.hasSelectedGenerationOwner)
      this.subscribers = this.subscribers.filter(
        (x) => x.ownerId == this.selectedGenerationOwner?.ownerId
      );
    else {
      let subsList = this.subscribers;
      this.subscribers = subsList;
    }

    let originalSubs: any[] = this.subscribers;

    if (this.searchString && this.searchString.trim() != '') {
      let adjustedSearch = this.searchString.trim().toLocaleLowerCase();
      this.subscribers = originalSubs.filter(
        (data) =>
          data.billAccountNumber?.toLocaleLowerCase()?.match(adjustedSearch) ||
          data.subscriberName?.toLocaleLowerCase()?.match(adjustedSearch) ||
          data.meterNo?.toLocaleLowerCase()?.match(adjustedSearch) ||
          data.usageQuantity?.toLocaleLowerCase()?.match(adjustedSearch) ||
          data.effectiveDate?.toLocaleLowerCase()?.match(adjustedSearch) ||
          data.terminationDate?.match(adjustedSearch) ||
          data.annualMonth?.toLocaleLowerCase()?.match(adjustedSearch) ||
          data.statusDescription?.toLocaleLowerCase()?.match(adjustedSearch)
      );
    } else {
      this.subscribers = originalSubs;
    }

    this.iterator();
  }

  closeRemoveUnitModal() {
    if (this.isRemoveSuccess) {
      this._router.navigate(['/generation-unit']);
    }
    this.isRemoveSuccess = false;
    this.removeErrorMsg = '';
    this.isRemoveError = false;
  }
  confirmRemoveUnit() {
    const unit = {
      subscribedAllocation: this.unit.subscribedAllocation
        ? +this.unit.subscribedAllocation
        : 0,
      rebateCapacityQuantity: +this.unit.rebateCapacityQuantity,
      rebateRequestQuantity: +this.unit.rebateRequestQuantity,
      requestRebate: this.unit.requestRebate,
      capacity: +this.unit.capacity,
      owner: this.unit.owner,
      meterNo: this.unit.meterNo,
      effectiveDate: this.unit.effectiveDate,
      billAccountNumber: this.unit.billAccountNumber,
      powerClerk: this.unit.powerClerk,
      fuelSource: this.unit.fuelSource,
      rebateRateChangeCode: this.unit.rebateRateChangeCode || '',
      nickName: this.unit.nickName,
      unitId: this.unit.unitId,
      type: this.unit.type,
      status: this.unit.statusCode,
      audit: this.currentUser?.email,
      removeUnitFlg: true,
    };

    this.isRemoveLoading = true;
    this.genUnitMgr.updateGenUnit(unit).subscribe({
      next: (resp) => {
        this.isRemoveLoading = false;
        this.isRemoveError = false;
        this.isRemoveSuccess = true;
      },
      error: (err) => {
        this.isRemoveLoading = false;
        this.isRemoveError = true;
        this.isRemoveSuccess = false;
        if (err?.status == 400) {
          this.removeErrorMsg = err?.error?.errorDetails?.message;
        } else this.removeErrorMsg = 'Something went wrong. Please try again..';
      },
    });
  }

  getUnitByUnitId() {
    this.isGetUnitApiLoading = true;
    this.isBankingStatusLoading = true;
    this.genUnitMgr.getGenerationUnitByUnitId(this.unitId).subscribe({
      next: (resp) => {
        this.isGetUnitApiLoading = false;
        this.isGetUnitApiError = false;
        this.isBankingStatusLoading = false;
        if (+resp.rebateCapacityQuantity <= +resp.rebateRequestQuantity)
          resp.rebateEligibleFlag = false;
        this.unit = resp;
        this.getBankingStatus(this.ownerId, this.unit?.type);
      },
      error: (err) => {
        this.isGetUnitApiLoading = false;
        this.isGetUnitApiError = true;
        this.isBankingStatusLoading = false;
      },
    });
  }

  getBankingStatus(owner: any, type: string) {
    if (type === 'COGF') {
      this.noBankInfoMsg = false;
      this.pennytestInprogressMsg = false;
      this.genunitsTerminatedMsg = false;
      this.bankingActiveStatus = false;
    } else {
      this.isBankingStatusAPIFailure = false;
      this.isBankingStatusLoading = true;
      this.noBankInfoMsg = false;
      this.pennytestInprogressMsg = false;
      this.genunitsTerminatedMsg = false;
      this.bankingActiveStatus = false;
      this.isGetUnitApiLoading = true;
      this.generationOwnerManager.getOwnerBankingInfo(owner).subscribe({
        next: (banking: Banking) => {
          this.isBankingStatusAPIFailure = false;
          this.isBankingStatusLoading = false;
          this.isGetUnitApiLoading = false;
          if (
            banking.status.toLocaleLowerCase() === '' ||
            banking.status.toLocaleLowerCase() === 'cancelled' ||
            banking.status.toLocaleLowerCase() === 'unknown'
          ) {
            this.noBankInfoMsg = true;
            this.pennytestInprogressMsg = false;
            this.genunitsTerminatedMsg = false;
            this.bankingActiveStatus = false;
          }

          if (
            banking.status.toLocaleLowerCase() === 'active' &&
            banking.termsAndConditions.toLocaleLowerCase() === 'y'
          ) {
            this.noBankInfoMsg = false;
            this.pennytestInprogressMsg = false;
            this.genunitsTerminatedMsg = false;
            this.bankingActiveStatus = true;
          }

          if (
            banking.status.toLocaleLowerCase() === 'active' &&
            banking.termsAndConditions.toLocaleLowerCase() != 'y'
          ) {
            this.noBankInfoMsg = false;
            this.pennytestInprogressMsg = false;
            this.genunitsTerminatedMsg = true;
            this.bankingActiveStatus = false;
          }

          if (
            banking.status.toLocaleLowerCase() === 'pending active' ||
            banking.status.toLocaleLowerCase() === 'inactive'
          ) {
            this.noBankInfoMsg = false;
            this.pennytestInprogressMsg = true;
            this.genunitsTerminatedMsg = false;
            this.bankingActiveStatus = false;
          }

          if (banking.status.toLocaleLowerCase() === 'failed') {
            this.noBankInfoMsg = false;
            this.pennytestInprogressMsg = true;
            this.genunitsTerminatedMsg = false;
            this.bankingActiveStatus = false;
          }
        },
        error: (error: any) => {
          console.log('error', error);
          this.isBankingStatusAPIFailure = true;
          this.isBankingStatusLoading = false;
          this.isGetUnitApiLoading = false;
        },
      });
    }
  }

  handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();
  }
  private iterator() {
    this.totalSize = this.subscribers.length;
    if (this.totalSize > this.pageSize)
    {
      const end = (this.currentPage + 1) * this.pageSize;
      const start = this.currentPage * this.pageSize;
      this.pagedSubscribers = this.subscribers.slice(start, end);
    }
    else{
      this.pagedSubscribers = this.subscribers;
    }
  }
}
