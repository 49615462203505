<div class="gen-unit-sub-daily-usage-container">
  <div class="gen-unit-sub-daily-usage-title">
    Generation Unit Subscriber Daily kWh Allocation Report
  </div>

  <div class="text-center" *ngIf="!genOwners">
    <ameren-loading></ameren-loading>
    <p>Please wait..</p>
  </div>

  <div class="row" *ngIf="genOwners">
    <div class="col form-group">
      <label>Generation Owner Name:<span class="text-danger">*</span></label>
      <ng-select [(ngModel)]="selectedOwner" (change)="onOwnerChange()">
        <ng-option *ngFor="let o of genOwners" [value]="o">{{
          o.name
        }}</ng-option>
      </ng-select>
    </div>
    <div class="col form-group">
      <label>Generation Unit:<span class="text-danger">*</span></label>
      <ng-select [(ngModel)]="selectedUnit" [disabled]="!units">
        <ng-option *ngFor="let u of units" [value]="u">
          {{ u.nickName }} - Acct# {{ u.billAccountNumber }}
        </ng-option>
      </ng-select>
    </div>
  </div>

  <div class="row" *ngIf="genOwners">
    <div class="col form-group">
      <label> Service From Date:<span class="text-danger">*</span> </label>
      <input type="date" class="form-control" [(ngModel)]="dtBegin" />
    </div>
    <div class="col form-group">
      <label> Service To Date:<span class="text-danger">*</span> </label>
      <input type="date" class="form-control" [(ngModel)]="dtEnd" />
    </div>
  </div>
  <div class="row mb-3" *ngIf="genOwners">
    <div class="d-flex justify-content-center">
      <button
        class="btn ameren-tertiary-green-btn m-2"
        [disabled]="!dtBegin || !dtEnd || !selectedOwner || !selectedUnit"
        (click)="search()"
      >
        Search
      </button>
      <button
        class="btn ameren-tertiary-green-btn m-2"
        [disabled]="!reportData || !reportData.length"
        (click)="exportReport()"
      >
        <img
          src="assets/icon/excelicon.svg"
          alt="CalendarImage"
          width="22"
          height="19"
        />
        Export
      </button>
    </div>
  </div>

  <div class="text-center" id="loading_report_criteria" *ngIf="isApiLoading">
    <ameren-loading></ameren-loading>
    <p>Please wait..</p>
  </div>

  <div
    class="row mt-3 mb-5 gen_unit_subscriber_daily_usage_report_selections"
    *ngIf="
      dtBegin &&
      dtBegin &&
      dtEnd &&
      dtBegin &&
      selectedOwner &&
      selectedUnit &&
      reportData
    "
  >
    <div class="col">
      <div class="row">
        <div class="col">
          <label> Generation Owner Name: </label>
        </div>
        <div class="col">{{ selectedUnit?.ownerName }}</div>
      </div>

      <div class="row">
        <div class="col">
          <label> Generation ID: </label>
        </div>
        <div class="col">{{ selectedUnit?.unitId }}</div>
      </div>

      <div class="row">
        <div class="col">
          <label> Service From: </label>
        </div>
        <div class="col">{{ dtBegin | date : 'MM/dd/yyyy' }}</div>
      </div>
    </div>

    <div class="col">
      <div class="row">
        <div class="col"><label> Generation Unit Account Number: </label></div>
        <div class="col">{{ selectedUnit?.billAccountNumber }}</div>
      </div>

      <div class="row">
        <div class="col"><label> Generation Unit Nickname: </label></div>
        <div class="col">{{ selectedUnit?.nickName }}</div>
      </div>

      <div class="row">
        <div class="col"><label> Service To: </label></div>
        <div class="col">{{ dtEnd | date : 'MM/dd/yyyy' }}</div>
      </div>
    </div>
  </div>

  <table
    class="table gen_unit_subscriber_daily_usage_report_grid mt-3"
    *ngIf="searchClicked"
  >
    <caption class="sr-only">
      Generation Unit Subscriber Bill Account Report
    </caption>
    <thead>
      <tr>
        <th scope="col">Date</th>
        <th scope="col">Subscriber Name</th>
        <th scope="col">Subscriber Account Number</th>
        <th scope="col">Meter Number</th>
        <th scope="col">kWh to Subscriber</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngIf="
          !isGenUnitSubDailyUsageAPIFailure &&
          !isReportLoading &&
          (!reportData || !reportData.length)
        "
      >
        <td colspan="9" class="text-center">No data</td>
      </tr>
      <tr *ngIf="isReportLoading">
        <td colspan="9" class="text-center">
          <p>Please wait..</p>
          <ameren-loading></ameren-loading>
        </td>
      </tr>

      <tr *ngIf="isGenUnitSubDailyUsageAPIFailure">
        <td colspan="9" class="text-center">
          <div class="mt-3 alert alert-danger" role="alert">
            Sorry, Something went wrong. Please try again.
          </div>
        </td>
      </tr>
      <ng-container *ngIf="!isReportLoading">
        <tr *ngFor="let rep of reportData">
          <td>
            {{ rep.intervalReadingDate | date : 'MM/dd/yyyy' }}
          </td>
          <td>
            {{ rep.subscriberName }}
          </td>
          <td>
            {{ rep.billAccountNumber }}
          </td>
          <td>
            {{ rep.meterNo }}
          </td>
          <td>
            {{ rep.subscribeQuantity }}
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
