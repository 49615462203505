<div class="sub_daily_kwh_allocation_report_container">
  <div class="sub_daily_kwh_allocation_report_title">
    Subscriber Daily kWh Allocation Report
  </div>
  <div class="row col-md-3">
    <div class="col form-group">
      <label
        >Subscriber Account Number:<span class="text-danger">*</span></label
      >
      <input
        type="text"
        class="form-control"
        [(ngModel)]="subAcctNumber"
        required
        maxlength="10"
        pattern="^[0-9]\d*$"
      />
    </div>
  </div>
  <div class="row col-md-6">
    <div class="col form-group">
      <label>Service From Date:<span class="text-danger">*</span></label>
      <input
        type="date"
        class="form-control"
        required
        [value]="dtBegin"
        [min]="minDate"
        [max]="maxDate"
        [(ngModel)]="dtBegin"
        (ngModelChange)="onChangeFromDate()"
      />
    </div>
    <div class="col form-group">
      <label>Service To Date:<span class="text-danger">*</span></label>
      <input
        type="date"
        class="form-control"
        required
        [value]="dtEnd"
        [max]="dtEndMaxDate"
        [(ngModel)]="dtEnd"
        (ngModelChange)="onChangeToDate()"
      />
    </div>
  </div>
  <div class="sub_daily_kwh_allocation_search_btn">
    <div class="row col-md-3">
      <div class="col">
        <button
          class="btn btn-outline-success"
          [disabled]="!dtBegin || !dtEnd || !subAcctNumber"
          (click)="search()"
        >
          Search
        </button>
        <button
          class="btn btn-outline-success m-2"
          [disabled]="!reportData || !reportData.length"
          (click)="exportReport()"
        >
          <img
            src="assets/icon/excelicon.svg"
            alt="CalendarImage"
            width="22"
            height="19"
          />
          Export
        </button>
      </div>
    </div>
  </div>

  <table
    class="gen_unit_sub_bill_account_report_selections"
    aria-describedby="gen unit subscriber billaccount report"
    *ngIf="searchClicked && reportData && reportData.length > 0"
  >
    <th class="sr-only"></th>
    <caption class="sr-only">
      Selected Report Creteria - Subscriber Daily kWh Allocation Report
    </caption>
    <tbody>
      <tr>
        <td><label>Subscriber Account Number:&nbsp;</label></td>
        <td>{{ selectedSubscriber.billAccountNumber }}</td>
        <td><label>Subscriber Name:&nbsp;</label></td>
        <td>{{ selectedSubscriber.subscriberName }}</td>
      </tr>
      <tr>
        <td><label>Service From:&nbsp;</label></td>
        <td>{{ dtBegin | date : 'MM/dd/yyyy' }}</td>
        <td><label>Service To:&nbsp;</label></td>
        <td>{{ dtEnd | date : 'MM/dd/yyyy' }}</td>
      </tr>
      <tr>
        <td><label>Subscriber Premise Address:&nbsp;</label></td>
        <td>{{ selectedSubscriber.subscriberAddress }}</td>
      </tr>
    </tbody>
  </table>

  <table
    class="table sub_daily_kwh_allocation_report_grid"
    *ngIf="searchClicked"
  >
    <caption class="sr-only">
      Subscriber Daily kWh Allocation Report
    </caption>
    <thead>
      <tr>
        <th scope="col">Date</th>
        <th scope="col">Generation Owner</th>
        <th scope="col">Generation ID</th>
        <th scope="col">Nickname</th>
        <th scope="col">KWh to Subscriber</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="!isReportLoading && (!reportData || !reportData.length)">
        <td colspan="9" class="text-center">No data</td>
      </tr>
      <tr *ngIf="isReportLoading">
        <td colspan="9" class="text-center">
          <p>Please wait..</p>
          <ameren-loading></ameren-loading>
        </td>
      </tr>
      <ng-container *ngIf="!isReportLoading">
        <tr *ngFor="let rep of reportData">
          <td>
            {{ rep.readingIntervalDate }}
          </td>
          <td>
            {{ rep.ownerName }}
          </td>
          <td>
            {{ rep.owner }}
          </td>
          <td>
            {{ rep.nickName }}
          </td>
          <td>
            {{ rep.subscribeQuantity }}
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
