import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { ManageUsersAccessComponent } from './manage-users-access.component';


const routes: Routes = [
  { path: "", component: ManageUsersAccessComponent }
 
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ManageUsersAccessRoutingModule { }
