import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CorrelationService } from 'src/app/shared/services/correlation.service';
import { environment } from 'src/environments/environment';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';

@Injectable({
  providedIn: 'root'
})
export class UsersApiService {
  constructor(
    private http: HttpClient,
    private correlationService: CorrelationService
  ) {}
  
  private buildHttpHeaders() {
    let httpOptions: HttpHeaders = new HttpHeaders();
    httpOptions = httpOptions.append('x-modified-by-user-id', 'ANM');
    httpOptions = httpOptions.append('domain', 'private');
    httpOptions = httpOptions.append('vpcenv',`${environment.environmentName}`);
    httpOptions = httpOptions.append('amrnenv',`${environment.cssEnv}`);
    httpOptions = httpOptions.append('X-Correlation-Id',`${this.correlationService.getCorrelationId()}`);
    return httpOptions;
  }

  getAllUsers(includeInactive: boolean, genOwners:string): Observable<any> {
    let headers = this.buildHttpHeaders(); 
       
    headers = headers.append('includeinactiveusers',`${includeInactive}`); 
    if(genOwners)
      headers = headers.append('generationowners',`${genOwners}`); 
    return this.http.get<IGlobalAPIResponse>(`${environment.apig}/users`, {
      headers,
    });
  }

  getUserbyEmail(email:string): Observable<any> {
    let headers = this.buildHttpHeaders(); 
       
    headers = headers.append('includeinactiveusers',`false`); 
    headers = headers.append('email',`${email}`); 

    return this.http.get<IGlobalAPIResponse>(`${environment.apig}/users`, {
      headers,
    });
  }

  updateUserStatus(data: any) {  
   
    let headers = this.buildHttpHeaders();      
    headers = headers.append('Content-Type', 'application/json');   
        
    const body = data;
  
     return this.http.patch<IGlobalAPIResponse>(`${environment.apig}/users/accountStatus`, body, {headers});
   }

   updateUserRoleStatus(data: any) {  
   
    let headers = this.buildHttpHeaders();      
    headers = headers.append('Content-Type', 'application/json');   
        
    const body = data;
  
     return this.http.patch<IGlobalAPIResponse>(`${environment.apig}/users/genowners`, body, {headers});
   }

   updateUserRoles(data: any) {  
   
    let headers = this.buildHttpHeaders();      
    headers = headers.append('Content-Type', 'application/json');   
        
    const body = data;
  
     return this.http.patch<IGlobalAPIResponse>(`${environment.apig}/users/roles`, body, {headers});
   }
  
}
