import { RebateDetailComponent } from './rebate-detail/rebate-detail.component';
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ManageRebateComponent } from "./manage-rebate/manage-rebate.component";

const routes: Routes = [{ path: "", component: ManageRebateComponent },
{
  path:"rebate/rebate-detail", component: RebateDetailComponent
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RebateRoutingModule {}
