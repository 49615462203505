import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { GenerationUnitManagerService } from '../services/generation-unit-manager.service';
import { GenerationOwnerManagerService } from '../../generation-owner/services/generation-owner-manager.service';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { Subject, delay, takeUntil, tap } from 'rxjs';

declare var window: any;

@Component({
  selector: 'app-add-generation-unit',
  templateUrl: './add-generation-unit.component.html',
  styleUrls: ['./add-generation-unit.component.scss'],
})
export class AddGenerationUnitComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  addGenerationUnitForm: FormGroup;
  unitTypes: any[];
  isGetUnitTypesError: boolean;
  isGetUnitTypesLoading: boolean = true;

  fuelSources: any[];
  isGetFuelSourceLoading: boolean = true;
  isGetFuelSourceError: boolean;

  generationOwnerListAPIFailure: boolean;
  generationOwnerListLoading: boolean = true;
  ownerList: any[];

  ownerListTemp: any[];

  CSSDateAPIFailure: boolean;
  CSSDateLoading: boolean;

  showMeterNumber: boolean = false;
  ownerType: string;

  addGenerationUnitAPIFailure: boolean;
  addGenerationUnitLoading: boolean;
  responseMsg: string;

  CSSDate: string = '';

  addGenerationUnitModal: any;
  addGenerationUnitCancelModal: any;

  locationDetails: any = [];
  locationAPIFailure: boolean;
  locationLoading: boolean;
  showGenerationOwnerLocation: boolean;

  viewLoaded: Subject<null>;
  destroyed$: Subject<boolean>;

  selectLocation: any;
  showOtherLocationDescription: boolean;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private generationUnitManager: GenerationUnitManagerService,
    private generationOwnerManager: GenerationOwnerManagerService
  ) {
    this.viewLoaded = new Subject();
    this.destroyed$ = new Subject();
  }

  ngOnInit(): void {
    this.addGenerationUnitModal = new window.bootstrap.Modal(
      document.getElementById('addGenerationUnitModal'),
      {
        backdrop: true,
        keyboard: true,
      }
    );

    this.addGenerationUnitCancelModal = new window.bootstrap.Modal(
      document.getElementById('addGenerationUnitCancelModal'),
      {
        backdrop: true,
        keyboard: true,
      }
    );

    this.getCSSDate();
    this.getUnitTypes();
    this.getFuelSources();
    this.getGenerationOwnerList();
    this.initAddGenerationUnitForm();

    this.viewLoaded
      .pipe(
        delay(0),
        tap(() => {
          this.listenForGenerationTypeChanges();
          this.listenForGenerationOwnerChange();
          this.listenForLocationChanges();
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe();
  }

  listenForGenerationOwnerChange() {
    this.addGenerationUnitForm
      .get('generationOwnerName')
      ?.valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe((data: any) => {
        this.addGenerationUnitForm.get('locationDesc')?.setValue('');
        this.getLocationByGenerationOwner(data);
      });
  }

  listenForLocationChanges() {
    this.addGenerationUnitForm
      .get('locationID')
      ?.valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe((data: any) => {
        this.showOtherLocationDescription = false;
        if (
          data.$ngOptionLabel != 'Other Location' &&
          this.showGenerationOwnerLocation
        ) {
          let locationData, locationDescription;
          locationData = this.locationDetails.filter(
            (loc: any) => loc.locationID === data
          );
          locationDescription =
            locationData[0] && locationData[0].locationDesc
              ? locationData[0].locationDesc
              : '';
          this.addGenerationUnitForm
            .get('locationDesc')
            ?.setValue(locationDescription);
        } else {
          this.addGenerationUnitForm.get('locationDesc')?.setValue('');
          this.showOtherLocationDescription = true;
        }
      });
  }

  listenForGenerationTypeChanges() {
    this.addGenerationUnitForm
      .get('generationType')
      ?.valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe((data: any) => {
        if (data === 'ESS' || data === 'POSG') {
          this.showMeterNumber = true;
          this.ownerType = '3';
          let genOName = document.getElementById('generationOwnerName');
          genOName?.classList.remove('required');
          this.addGenerationUnitForm
            .get('generationOwnerName')
            ?.removeValidators(Validators.required);
          this.addGenerationUnitForm
            .get('generationOwnerName')
            ?.clearValidators();

          this.addGenerationUnitForm
            .get('generationOwnerName')
            ?.setErrors(null);

          this.ownerList = this.ownerListTemp.filter(
            (type) => type.ownerType === '3'
          );
        } else if (data === 'CRGP' || data === 'LICS' || data === 'COGF') {
          this.showMeterNumber = false;
          this.ownerType = 'O';
          this.addGenerationUnitForm
            .get('generationOwnerName')
            ?.addValidators(Validators.required);
          let genOName = document.getElementById('generationOwnerName');
          genOName?.classList.add('required');
          this.ownerList = this.ownerListTemp.filter(
            (type) => type.ownerType === 'O'
          );
        } else {
          this.showMeterNumber = false;
          this.ownerType = '';
          this.ownerList = this.ownerListTemp;
        }
      });
  }

  ngAfterViewInit() {
    this.viewLoaded.next(null);
    this.showMeterNumber = false;
  }

  toggleRequestRebate(event: any) {
    if (event.target.value) {
      this.addGenerationUnitForm.get('requestRebate')?.enable();
      this.addGenerationUnitForm.get('effectiveDate')?.setValue(this.CSSDate);
    } else {
      this.addGenerationUnitForm.get('requestRebate')?.disable();
    }
  }

  initAddGenerationUnitForm() {
    this.addGenerationUnitForm = this.fb.group({
      generationType: new FormControl('Select Generation Type', [
        Validators.required,
      ]),
      generationOwnerName: new FormControl('Select Generation Owner Name'),
      billAccountNumber: new FormControl(null, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern(/^[0-9]\d*$/),
      ]),
      meterNumber: new FormControl(null),
      capacity: new FormControl(null, [Validators.required]),
      nickName: new FormControl(null, [Validators.required]),
      generationFuelSource: new FormControl('Select Fuel Source', [
        Validators.required,
      ]),
      powerClerkNumber: new FormControl(null),
      capacityForRebate: new FormControl(null),
      requestRebate: new FormControl({
        value: null,
        disabled: true,
      }),
      effectiveDate: new FormControl({ value: this.CSSDate, disabled: true }),
      locationID: new FormControl('Select Location', [Validators.required]),
      locationDesc: new FormControl(null),
    });
  }

  getUnitTypes() {
    this.isGetUnitTypesLoading = true;
    this.isGetUnitTypesError = false;
    this.generationUnitManager
      .getDecodesByTableName('Generation Unit Type')
      .subscribe({
        next: (data) => {
          this.isGetUnitTypesLoading = false;
          this.isGetUnitTypesError = false;
          this.unitTypes = data;
        },
        error: (err) => {
          this.isGetUnitTypesLoading = false;
          this.isGetUnitTypesError = true;
        },
      });
  }

  getFuelSources() {
    this.isGetFuelSourceLoading = true;
    this.isGetFuelSourceError = false;
    this.generationUnitManager.getDecodesByTableName('Fuel Source').subscribe({
      next: (data) => {
        this.isGetFuelSourceError = false;
        this.isGetFuelSourceLoading = false;

        this.fuelSources = data;
      },
      error: () => {
        this.isGetFuelSourceError = true;
        this.isGetFuelSourceLoading = false;
      },
    });
  }

  getGenerationOwnerList() {
    this.ownerList = [];
    this.ownerListTemp = [];
    this.generationOwnerListAPIFailure = false;
    this.generationOwnerListLoading = true;
    this.generationOwnerManager.getOwnersList().subscribe({
      next: (data: any) => {
        this.generationOwnerListAPIFailure = false;
        this.generationOwnerListLoading = false;
        this.ownerList = data.sort((a: any, b: any) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
        this.ownerListTemp = data.sort((a: any, b: any) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
      },
      error: (error: any) => {
        console.log('error', error);
        this.generationOwnerListAPIFailure = true;
        this.generationOwnerListLoading = false;
      },
    });
  }

  getCSSDate() {
    this.CSSDateAPIFailure = false;
    this.CSSDateLoading = true;
    this.generationUnitManager.getCSSDate().subscribe({
      next: (data: any) => {
        this.CSSDateAPIFailure = false;
        this.CSSDateLoading = false;
        let dateValue = data.split('-');
        this.CSSDate = (
          dateValue[0] +
          '-' +
          dateValue[1] +
          '-' +
          dateValue[2]
        ).toString();
      },
      error: (error: any) => {
        console.log('error', error);
        this.CSSDateAPIFailure = true;
        this.CSSDateLoading = false;
      },
    });
  }

  submitGenerationUnit() {
    let addGenerationUnitBody;
    addGenerationUnitBody = {
      nickName: this.addGenerationUnitForm.controls['nickName'].value || '',
      owner:
        this.addGenerationUnitForm.controls['generationOwnerName'].value || '',
      billAccountNumber:
        this.addGenerationUnitForm.controls['billAccountNumber'].value || '',
      powerClerk: !_.isNil(
        this.addGenerationUnitForm.controls['powerClerkNumber'].value
      )
        ? this.addGenerationUnitForm.controls['powerClerkNumber'].value
        : '',
      fuelSource:
        this.addGenerationUnitForm.controls['generationFuelSource'].value || '',
      capacity: this.addGenerationUnitForm.controls['capacity'].value || '',
      type: this.addGenerationUnitForm.controls['generationType'].value || '',
      meterNo: !_.isNil(
        this.addGenerationUnitForm.controls['meterNumber'].value
      )
        ? this.addGenerationUnitForm.controls['meterNumber'].value
        : '',
      effectiveDate: !_.isNil(
        this.addGenerationUnitForm.controls['effectiveDate'].value
      )
        ? this.addGenerationUnitForm.controls['effectiveDate'].value
        : '',
      rebateCapacityQuantity: !_.isNil(
        this.addGenerationUnitForm.controls['capacityForRebate'].value
      )
        ? this.addGenerationUnitForm.controls['capacityForRebate'].value
        : 0,
      rebateRateChangeCode: '1',
      requestRebate:
        this.addGenerationUnitForm.controls['requestRebate'].value === true
          ? true
          : false,
      locationID:
        !_.isNil(this.addGenerationUnitForm.controls['locationID'].value) &&
        this.addGenerationUnitForm.controls['locationID'].value
          .$ngOptionLabel != 'Other Location'
          ? this.addGenerationUnitForm.controls['locationID'].value
          : '0',
      locationDesc:
        this.addGenerationUnitForm.controls['locationDesc'].value || '',
      status: 'active',
    };

    if (addGenerationUnitBody.type === 'Select Generation Type') {
      this.addGenerationUnitForm.controls['generationType']?.setErrors({
        required: true,
      });
      this.addGenerationUnitForm.get('generationType')?.markAsDirty();
      return;
    }

    if (addGenerationUnitBody.owner === 'Select Generation Owner Name') {
      if (
        addGenerationUnitBody.type === 'ESS' ||
        addGenerationUnitBody.type === 'POSG'
      ) {
        addGenerationUnitBody.owner = '';
      } else {
        this.addGenerationUnitForm.controls['generationOwnerName']?.setErrors({
          required: true,
        });
        this.addGenerationUnitForm.get('generationOwnerName')?.markAsDirty();

        return;
      }
    }

    if (
      this.showGenerationOwnerLocation &&
      addGenerationUnitBody.locationID === 'Select Location'
    ) {
      this.addGenerationUnitForm.controls['locationID']?.setErrors({
        required: true,
      });
      this.addGenerationUnitForm.get('locationID')?.markAsDirty();
      return;
    } else {
      addGenerationUnitBody.locationID = '0';
    }

    if (addGenerationUnitBody.fuelSource === 'Select Fuel Source') {
      this.addGenerationUnitForm.controls['generationFuelSource']?.setErrors({
        required: true,
      });
      this.addGenerationUnitForm.get('generationFuelSource')?.markAsDirty();
      return;
    }

    this.addGenerationUnitModal.show();
    this.addGenerationUnitLoading = true;
    this.addGenerationUnitAPIFailure = false;
    this.responseMsg = '';
    this.generationUnitManager
      .addGenerationUnit(addGenerationUnitBody)
      .subscribe({
        next: (res: any) => {
          this.addGenerationUnitLoading = false;
          this.addGenerationUnitAPIFailure = false;
          this.responseMsg = 'Generation Unit added successfully. ' + res.successMessage;
          this.addGenerationUnitModal.show();
        },
        error: (error: any) => {
          this.addGenerationUnitLoading = false;
          this.addGenerationUnitAPIFailure = true;
          this.responseMsg = "Failed to add Generation Unit. " + error.error.errorDetails.message;          
          this.addGenerationUnitModal.show();
        },
      });
  }

  closeAddGenerationModal() {
    if (!this.addGenerationUnitAPIFailure) {
      this.addGenerationUnitForm.reset();
      this.router.navigate(['generation-unit']);
    }
  }

  getLocationByGenerationOwner(owner: any) {
    this.locationDetails = [];
    this.locationAPIFailure = false;
    this.locationLoading = true;
    this.showGenerationOwnerLocation = false;
    this.generationOwnerManager.getLocationByGenerationOwner(owner).subscribe({
      next: (res: any) => {
        this.locationAPIFailure = false;
        this.locationLoading = true;
        if (
          this.addGenerationUnitForm.controls['generationType'].value === 'COGF'
        ) {
          this.showGenerationOwnerLocation = true;
          this.locationDetails = res;
        } else {
          this.showGenerationOwnerLocation = false;
          this.locationDetails = '';
          this.addGenerationUnitForm.get('locationDesc')?.setValue('');
        }
      },
      error: (error: any) => {
        if (
          this.addGenerationUnitForm.controls['generationType'].value === 'COGF'
        ) {
          this.locationDetails = '';
          this.showGenerationOwnerLocation = true;
          this.addGenerationUnitForm.get('locationDesc')?.setValue('');
        } else {
          this.showGenerationOwnerLocation = false;
          this.locationDetails = '';
          this.addGenerationUnitForm.get('locationDesc')?.setValue('');
        }

        console.log('error', error);
      },
    });
  }

  findLocation(event: any) {
    let ownerid =
      this.addGenerationUnitForm.controls['generationOwnerName'].value;
    this.getLocationByGenerationOwner(ownerid);
  }

  cancelAddGenerationUnit() {
    this.addGenerationUnitCancelModal.show();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
