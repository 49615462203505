export class FineGrainAuthorization {
//Generic
isAmerenAdmin:boolean = false;
isAmerenInternal: boolean = false;
isExternalAdmin:boolean = false;
isExternal:boolean = false;
isRebateApprover:boolean = false;
isIPAUser:boolean = false;
isAdmin:boolean = false;


//functioanlities
allowUsers:boolean = false;
allowUsersList:boolean = false;
allowEditUser:boolean = false;
allowEnableDisableUsers:boolean = false;
allowLockUnlockUsers:boolean = false;

allowGenerationOwners:boolean = false;
allowGenerationOwnersList:boolean = false;
allowCreateGenerationOwner:boolean = false;
allowEditGenerationOwner:boolean = false;
allowReadGenerationOwnerBankingInfo:boolean = false;
allowEditGenerationOwnerBankingInfo:boolean = false;

allowGenerationUnits:boolean = false;
allowGenerationUnitsList:boolean = false;
allowCreateGenerationUnit:boolean = false;
allowEditGenerationUnit:boolean = false;
allowRemoveGenerationUnit:boolean = false;

allowSubscribers:boolean = false;
allowSubscriberList:boolean = false;
allowCreateSubscriber:boolean = false;
allowEditSubscriber:boolean = false;
allowEnableDisableSubscribers:boolean = false;


allowRebates:boolean = false;
allowRebateList:boolean = false;
allowRebateDetail:boolean = false;
allowEditRebate:boolean = false;

//Reports

allowReports:boolean = false;
allowReportsList:boolean = false;
allowGenerationOwnerReport:boolean = false;
allowGenerationUnitSubscriberBillAccountReport:boolean = false;
allowGenerationUnitDailyOutputReport:boolean = false;
allowGenerationUnitSubscriberContractReport:boolean = false;
allowGenerationUnitSubscriberDailyAllocationReport:boolean = false;
allowSubscriberDailyAllocationReport:boolean = false;
allowGenerationUnitAllSubscriberBillAccountReport:boolean = false;
allowIPAGenerationUnitSubscriberContractReport:boolean = false;
allowRegulatoryComplianceReport:boolean = false;
allowGenerationUnitBillAccountSubscriptionRateReport:boolean = false;
allowGenerationUnitBillAccountSubscriptionReport:boolean = false;

allowReadAudits:boolean = false;
allowCreateAudit:boolean = false;

constructor(){}
}

