import * as _ from 'lodash';
import { SubscriberStatusType } from '../enums/subscriberstatus';

export class SubscriberModel {
  annualMonth: string;
  annualMonthReq: boolean;
  billAccountNumber: string;
  effectiveDate: string;
  meterNo: string;
  ownerId: string;
  ownerName: string;
  premiseNo: string;
  rebateRecipient: string;
  savingsReleaseFlag: boolean;
  servicePt: string;
  statusCode: string;
  statusDescription: string;
  subscriberAddress: string;
  subscriberName: string;
  terminationDate: string;
  unitId: string;
  unitName: string;
  usageQuantity: any;
  disclaimerFlag: boolean;
  totalRebateRequested: any;
  subscriberRate: any;
  subscriberRateEffectiveDate: string;
  resUnitSubscriberRate: any;
  comUnitSubscriberRate: any;

  constructor(subsriber?: any) {
    if (subsriber.length > 1) {
      let subsDetails = subsriber.filter(
        (a: any) => a.statusCode === SubscriberStatusType.Active
      );
      this.annualMonth = !_.isNil(subsDetails[0].annualMonth)
        ? subsDetails[0].annualMonth
        : '';
      this.annualMonthReq = !_.isNil(subsDetails[0].annualMonthReq)
        ? subsDetails[0].annualMonthReq
        : false;
      this.billAccountNumber = !_.isNil(subsDetails[0].billAccountNumber)
        ? subsDetails[0].billAccountNumber
        : '';
      this.effectiveDate = !_.isNil(subsDetails[0].effectiveDate)
        ? subsDetails[0].effectiveDate
        : '';
      this.meterNo = !_.isNil(subsDetails[0].meterNo)
        ? subsDetails[0].meterNo
        : '';
      this.ownerId = !_.isNil(subsDetails[0].ownerId)
        ? subsDetails[0].ownerId
        : '';
      this.ownerName = !_.isNil(subsDetails[0].ownerName)
        ? subsDetails[0].ownerName
        : '';
      this.premiseNo = !_.isNil(subsDetails[0].premiseNo)
        ? subsDetails[0].premiseNo
        : '';
      this.rebateRecipient = !_.isNil(subsDetails[0].rebateRecipient)
        ? subsDetails[0].rebateRecipient
        : '';
      this.savingsReleaseFlag = !_.isNil(subsDetails[0].savingsReleaseFlag)
        ? subsDetails[0].savingsReleaseFlag
        : false;
      this.servicePt = !_.isNil(subsDetails[0].servicePt)
        ? subsDetails[0].servicePt
        : '';
      this.statusCode = !_.isNil(subsDetails[0].statusCode)
        ? subsDetails[0].statusCode
        : '';
      this.statusDescription = !_.isNil(subsDetails[0].statusDescription)
        ? subsDetails[0].statusDescription
        : '';
      this.subscriberAddress = !_.isNil(subsDetails[0].subscriberAddress)
        ? subsDetails[0].subscriberAddress
        : '';
      this.subscriberName = !_.isNil(subsDetails[0].subscriberName)
        ? subsDetails[0].subscriberName
        : '';
      this.terminationDate = !_.isNil(subsDetails[0].terminationDate)
        ? subsDetails[0].terminationDate
        : '';
      this.unitId = !_.isNil(subsDetails[0].unitId)
        ? subsDetails[0].unitId
        : '';
      this.unitName = !_.isNil(subsDetails[0].unitName)
        ? subsDetails[0].unitName
        : '';
      this.usageQuantity = !_.isNil(subsDetails[0].usageQuantity)
        ? Number(subsDetails[0].usageQuantity).toFixed(3).toString()
        : '';
      this.disclaimerFlag = !_.isNil(subsDetails[0].disclaimerFlag)
        ? subsDetails[0].disclaimerFlag
        : false;
      this.totalRebateRequested = !_.isNil(subsDetails[0].totalRebateRequested)
        ? subsDetails[0].totalRebateRequested
        : '';
      this.subscriberRate = !_.isNil(subsDetails[0].subscriberRate)
        ? subsDetails[0].subscriberRate
        : '';
      this.subscriberRateEffectiveDate = !_.isNil(
        subsDetails[0].subscriberRateEffectiveDate
      )
        ? subsDetails[0].subscriberRateEffectiveDate
        : '';
      this.resUnitSubscriberRate = !_.isNil(
        subsDetails[0].resUnitSubscriberRate
      )
        ? subsDetails[0].resUnitSubscriberRate
        : '';
      this.comUnitSubscriberRate = !_.isNil(
        subsDetails[0].comUnitSubscriberRate
      )
        ? subsDetails[0].comUnitSubscriberRate
        : '';
    } else {
      this.annualMonth = !_.isNil(subsriber[0].annualMonth)
        ? subsriber[0].annualMonth
        : '';
      this.annualMonthReq = !_.isNil(subsriber[0].annualMonthReq)
        ? subsriber[0].annualMonthReq
        : false;
      this.billAccountNumber = !_.isNil(subsriber[0].billAccountNumber)
        ? subsriber[0].billAccountNumber
        : '';
      this.effectiveDate = !_.isNil(subsriber[0].effectiveDate)
        ? subsriber[0].effectiveDate
        : '';
      this.meterNo = !_.isNil(subsriber[0].meterNo) ? subsriber[0].meterNo : '';
      this.ownerId = !_.isNil(subsriber[0].ownerId) ? subsriber[0].ownerId : '';
      this.ownerName = !_.isNil(subsriber[0].ownerName)
        ? subsriber[0].ownerName
        : '';
      this.premiseNo = !_.isNil(subsriber[0].premiseNo)
        ? subsriber[0].premiseNo
        : '';
      this.rebateRecipient = !_.isNil(subsriber[0].rebateRecipient)
        ? subsriber[0].rebateRecipient
        : '';
      this.savingsReleaseFlag = !_.isNil(subsriber[0].savingsReleaseFlag)
        ? subsriber[0].savingsReleaseFlag
        : false;
      this.servicePt = !_.isNil(subsriber[0].servicePt)
        ? subsriber[0].servicePt
        : '';
      this.statusCode = !_.isNil(subsriber[0].statusCode)
        ? subsriber[0].statusCode
        : '';
      this.statusDescription = !_.isNil(subsriber[0].statusDescription)
        ? subsriber[0].statusDescription
        : '';
      this.subscriberAddress = !_.isNil(subsriber[0].subscriberAddress)
        ? subsriber[0].subscriberAddress
        : '';
      this.subscriberName = !_.isNil(subsriber[0].subscriberName)
        ? subsriber[0].subscriberName
        : '';
      this.terminationDate = !_.isNil(subsriber[0].terminationDate)
        ? subsriber[0].terminationDate
        : '';
      this.unitId = !_.isNil(subsriber[0].unitId) ? subsriber[0].unitId : '';
      this.unitName = !_.isNil(subsriber[0].unitName)
        ? subsriber[0].unitName
        : '';
      this.usageQuantity = !_.isNil(subsriber[0].usageQuantity)
        ? subsriber[0].usageQuantity
        : '';
      this.disclaimerFlag = !_.isNil(subsriber[0].disclaimerFlag)
        ? subsriber[0].disclaimerFlag
        : false;
      this.totalRebateRequested = !_.isNil(subsriber[0].totalRebateRequested)
        ? subsriber[0].totalRebateRequested
        : '';
      this.subscriberRate = !_.isNil(subsriber[0].subscriberRate)
        ? subsriber[0].subscriberRate
        : '';
      this.subscriberRateEffectiveDate = !_.isNil(
        subsriber[0].subscriberRateEffectiveDate
      )
        ? subsriber[0].subscriberRateEffectiveDate
        : '';
      this.resUnitSubscriberRate = !_.isNil(subsriber[0].resUnitSubscriberRate)
        ? subsriber[0].resUnitSubscriberRate
        : '';
      this.comUnitSubscriberRate = !_.isNil(subsriber[0].comUnitSubscriberRate)
        ? subsriber[0].comUnitSubscriberRate
        : '';
    }
  }
}
