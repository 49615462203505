<div class="owner-dashboard-container">
  <div class="col-md-12 mt-3 text-center" *ngIf="isApiLoading">
    <ameren-loading></ameren-loading>
    <p>Please wait..</p>
  </div>
  
  <div class="row" *ngIf="!isApiLoading">
    <div class="col-md-4">
      <div
        class="col-md-12 owner_dashboard_profile_container text-center p-4"
        *ngIf="currentUser"
      >
        <ul class="list-unstyled">
          <li class="text-center d-flex justify-content-center">
            <div class="circle">
              <span class="characters"
                >{{ currentUser?.firstname?.toString().substring(0, 1)
                }}{{ currentUser?.lastname?.toString().substring(0, 1) }}</span
              >
            </div>
          </li>
          <li class="text-center">
            <strong
              >{{ currentUser?.firstname }} {{ currentUser?.lastname }}</strong
            >
          </li>
          <li class="text-center">
            <span>{{ currentUser?.email }}</span>
          </li>
          <li class="text-center mt-4">
            <button
              class="owner_dashboard_edit_btn btn"
              [routerLink]="['/edit-profile']"
            >
              Edit Profile
            </button>
          </li>
        </ul>
      </div>
      <div
        class="col-md-12 owner_dashboard_info_container mt-3 p-4"
        *ngIf="owner"
      >
        <h5 class="owner_info_header">Generation Owner Information</h5>
        <div class="col-md-12 mb-2 mt-2 owner_dashboard_owner_name">
          <ul class="list-unstyled">
            <li>OwnerID: {{ owner.ownerId }}</li>
            <li>Name: {{ owner.name }}</li>
          </ul>
        </div>
        <div class="col-md-12 mb-2 owner_dashboard_owner_contact">
          <ul class="list-unstyled">
            <li>Contact Person: {{ ownerContact.contactPerson }}</li>
            <li>Phone: {{ ownerContact.contactPhone }}</li>
            <li>
              Address: {{ ownerContact.mailingAddress.addressLine1 }}
              {{ ownerContact.mailingAddress.addressLine2 }}
              {{ ownerContact.mailingAddress.city }}
              {{ ownerContact.mailingAddress.postalCode }}
            </li>
            <li>Email: {{ ownerContact.email }}</li>
          </ul>
        </div>
        <div class="col-md-12 owner_dashboard_owner_type">
          <ul class="list-unstyled">
            <li>
              Owner Type:
              {{ owner.ownerType == "O" ? "Off-site" : "3rd Party" }}
            </li>
            <li>Bill Account: {{ owner.ownerBillAccount }}</li>
          </ul>
        </div>
        <div class="col-md-12 owner_dashboard_btn_container text-center">
          <a
            class="btn owner_dashboard_btn"
            [routerLink]="['/generation-owner/edit']"
            [queryParams]="{ ownerId: owner.ownerId }"
            *ngIf="functionalityAccess.allowEditGenerationOwner"
            >Edit Generation Owner</a
          >
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="mb-5">
        <h3 class="gen_owner_dashboard_page_header">
          Generation Owner Dashboard
        </h3>
        <div class="col-md-12">
          <em>
            Disclaimer: COGF Participating Customers will be referred to throughout this portal as Subscribers.
          </em>
        </div>
      </div>
      <div class="row gen_unit_search_container mb-3">
        <div class="col-md-2"></div>
        <div class="col-md-3">
          <select
            [(ngModel)]="unitStatus"
            (change)="onStatusChange()"
            class="form-select"
            aria-label="Default select select-status"
          >
            <option value="-1"></option>
            <option value="2">Active</option>
            <option value="1">Inactive</option>
            <option value="3">Pending</option>
          </select>
        </div>
        <div class="col-md-5">
          <div class="d-flex flex-row gen_owner__search">
            <div class="input-group search_button searchuser mb-3 ms-auto">
              <input
                type="search"
                class="form-control mt-0"
                placeholder="Search"
                aria-label="searchAmerenUser"
                [(ngModel)]="searchString"
                aria-describedby="searchAmerenUser"
              />
              <span
                class="input-group-text"
                id="searchAmerenUser"
                (click)="searchFilter()"
                ><em class="fa fa-search"></em
              ></span>
            </div>
          </div>
        </div>
        <div class="col-md-2 text-right">
          <button
            type="button"
            class="btn btn-success gen_owner_export"
            (click)="exportgenUnits()"
          >
            <img
              src="assets/icon/excelicon.svg"
              alt="CalendarImage"
              width="22"
              height="19"
            />
            Export
          </button>
        </div>
      </div>
      <table class="table gen_unit_grid" aria-describedby="Owner Dashboard">
        <thead>
          <tr>
            <th scope="col">Unit ID</th>
            <th scope="col">Nickname</th>
            <th scope="col">Number Of Subscribers</th>
            <th scope="col">Bill AccountNumber</th>
            <th scope="col">Capacity</th>
            <th scope="col">Subscribed Allocation</th>
            <th scope="col">Type</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="!units?.length">
            <td colspan="8" class="text-center">No data</td>
          </tr>
          <tr *ngFor="let unit of units">
            <td>
              {{ unit.unitId }}
            </td>
            <td>
              <a
              [routerLink]="['/subscription-detail']"
              [queryParams]="{
                unitId: unit.unitId,
                acct: unit.billAccountNumber,
                ownerId: unit.owner
              }"
              *ngIf="functionalityAccess.allowEditGenerationUnit"
            >
              {{ unit.nickName }}</a
            >

             <span  *ngIf="!functionalityAccess.allowEditGenerationUnit">
            {{ unit.nickName }}
          </span>
        </td>
            <td>
              {{ unit.numOfSubscriptions }}
            </td>
            <td>
              {{ unit.billAccountNumber }}
            </td>
            <td>
              {{ unit.capacity }}
            </td>
            <td>{{ unit.totalSubscriberUsage }}</td>
            <td>{{ unit.type }}</td>
            <td>
              {{
                unit.status == "1"
                  ? "Inactive"
                  : unit.status == "2"
                  ? "Active"
                  : "Pending"
              }}
            </td>
          </tr>
        </tbody>
      </table>
    
      <div class="col-md-12 text-center text-danger" *ngIf="isApiError && integrationErrorMsg">
        Something went wrong. Please try again.    
      </div>
    </div>
  </div>
  

  <div
  class="modal fade"
  id="genOwnerMsgPopup"
  tabindex="-1"
  aria-labelledby="genOwnerMsgModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" id="genOwnerMsgModalDialog">
    <div class="modal-content" id="genOwnerMsgModalContent">
      <div class="pop-header ml-2" id="genOwnerMsgModalHeader">
        <p
          class="modal-title text-center alert-danger"
          id="genOwnerMsgModalLabel"
        >
          Not Authorized!
        </p>
      </div>
      <div class="modal-body text-center textbold" id="genOwnerMsgModalText">
        <p>You are not authorized to access requested Generation Owner information. </p>
        <p>You are being redirected to the previous page. </p>

      </div>
    </div>
  </div>
</div>

</div>
