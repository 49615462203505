// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environmentName: 'DEV',
  cssEnv: 'css.px7',
  client_id: '7be5a825-f335-411f-82b0-0aa488db3a60',
  authority:
    'https://login.ciam-dev.ece.ameren.com/oauth2/realms/root/realms/partner/authorize',
  scope: 'openid profile ismemberof anmuseraccess imp_anmRoleMap imp_username imp_email',
  amerenUrl: 'https://www.dev.ameren.com',
  authenticateUrl:
    'https://login.ciam-dev.ece.ameren.com/json/realms/root/realms/ameren/authenticate',
  apiUrl: 'https://custcorpd.ameren.com:7443/proxy/metering-aggregated-e/api',
  cloudHub: 'https://dev.ameren.io',
  openIGurl: 'https://openig.ciam-dev.ameren.com',
  apig: 'https://anm-dev-api.np.ce.ameren.com',
  changePasswordUrl:
    'https://login.ciam-dev.ece.ameren.com/json/realms/partner/users',
  frXUI: 'https://login.ciam-dev.ece.ameren.com/XUI',
  adfs: 'https://login.ciam-dev.ece.ameren.com:443/partneradfs',
  fed: 'https://fed.ameren.com/adfs/ls/idpinitiatedsignon.aspx',
  recaptchaSiteKey: '6LckZkofAAAAANMCMFu1ZYvFqYS3wEt_R9bDlYpx',
  testEmail: 'star@ameren.com', //Leave it blank for prod
  splunkToken: 'e130d306-df3a-4e00-92b4-75cc623854e1',
  splunkUrl: 'https://splunkcloudhec.ameren.com:443/services/collector',
  publicVPCUrl:'https://dev.ameren.io'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
