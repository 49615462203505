import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { IGlobalAPIResponse } from '../../../../../src/app/shared/interfaces/iglobal-api-response';
import { AmerenError } from '../../../../../src/app/shared/models/amerenError';
import { RegistrationApiService } from './registration-api.service';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class RegistrationManagerService {
  constructor(private registrationService: RegistrationApiService) {}

  submitRegistration(data: any) {
    return this.registrationService.submitRegistration(data).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          if (res.errorDetails?.message != undefined)
            throw new AmerenError(
              res.errorDetails?.message,
              res.errorDetails.code,
              null,
              null,
              null
            );
          else
            throw new AmerenError(
              'User Submission has failed',
              400,
              null,
              null,
              null
            );
        }

        return res;
      }),
      catchError((error: any) => {
        return throwError(
          () =>
            new AmerenError(
              error.error?.errorDetails.message,
              error.error?.errorDetails.code,
              error.error?.errorDetails.code
            )
        );
      })
    );
  }
 
  updateProfile(data: any) {
    return this.registrationService.updateProfile(data).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'User Submission has failed',
            400,
            null,
            null,
            null
          );
        }

        return res;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  changePassword(email: string, currentPass: string, newPass: string) {
    return this.registrationService
      .changePassword(email, currentPass, newPass)
      .pipe(
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return throwError(
            () => new AmerenError(error.error.message, error.status)
          );
        })
      );
  }

  recaptchaVerification(data: any) {
    return this.registrationService.recaptchaVerification(data).pipe(
    map((res: any) => {
      if (!res.success) {
        throw new AmerenError(
          'Recaptcha verification is failed',
          500,
          null,
          null,
          null
        );
      }

      return res;
    }),
    catchError((error: any) => {
      return throwError(() => error);
    })
    );
  }
  
 
}
