<div class="gen_unit_subscriber_contract_report_container">
  <div class="gen_unit_subscriber_contract_report_title">
    Generation Unit Subscriber Contract Report
  </div>

  
 
  <div class="row" *ngIf="genOwners">
    <div class="col form-group">
      <label>Generation Owner Name:<span class="text-danger">*</span></label>
      <select
        class="form-select"
        [(ngModel)]="selectedOwner"
        (change)="onOwnerChange()"
      >
        <option *ngFor="let o of genOwners" [ngValue]="o">{{ o.name }}</option>
      </select>
    </div>
    <div class="col form-group">
      <label>Generation Unit:<span class="text-danger">*</span></label>
      <select
        class="form-select"
        [(ngModel)]="selectedUnit"
        [disabled]="!units"
      >
        <option *ngFor="let u of units" [ngValue]="u">
          {{ u.nickName }} - Acct# {{ u.billAccountNumber }}
        </option>
      </select>
    </div>
  </div>

  <div class="row" *ngIf="genOwners">
    <div class="col form-group">
      <label> Service From Date:<span class="text-danger">*</span> </label>
      <input type="date" class="form-control" [(ngModel)]="dtBegin" />
    </div>
    <div class="col form-group">
      <label> Service To Date:<span class="text-danger">*</span> </label>
      <input type="date" class="form-control" [(ngModel)]="dtEnd" />
    </div>
  </div>
  <div class="row" *ngIf="genOwners">
    <div class="col-md-3">
      <button
        class="btn btn-outline-success"
        [disabled]="!dtBegin || !dtEnd || !selectedOwner || !selectedUnit"
        (click)="search()"
      >
        Search
      </button>
      <button
        class="btn btn-outline-success m-2"
        [disabled]="!reportData || !reportData.length"
        (click)="exportReport()"
      >
        <img
          src="assets/icon/excelicon.svg"
          alt="CalendarImage"
          width="22"
          height="19"
        />
        Export
      </button>
    </div>
  </div>
  <div class="text-center" *ngIf="!genOwners">
    <ameren-loading></ameren-loading>
    <p>Please wait..</p>
  </div>
  <div class="text-danger" id="loading_report_criteria" *ngIf="integrationErrorMsg || !isApiLoading" >
    {{integrationErrorMsg}}
  </div>
  <div class="text-center" id="loading_report_criteria" *ngIf="isApiLoading">
    <ameren-loading></ameren-loading>
    <p>Please wait..</p>
  </div>

  <table
    class="gen_unit_subscriber_contract_report_selections"
    aria-describedby="gen unit subscriber contract report"
    *ngIf="
      dtBegin &&
      dtBegin &&
      dtEnd &&
      dtBegin &&
      selectedOwner &&
      selectedUnit &&
      reportData
    "
  >
    <th class="sr-only"></th>
    <caption class="sr-only">
      Selected Report Creteria - Generation Unit Subscriber Contract Report
    </caption>
    <tbody>
      <tr>
        <td><label> Generation Owner Name: </label></td>
        <td>{{ selectedUnit?.ownerName }}</td>
        <td><label> Generation Unit Account Number: </label></td>
        <td>{{ selectedUnit?.billAccountNumber }}</td>
      </tr>
      <tr>
        <td><label> Generation ID: </label></td>
        <td>{{ selectedUnit?.unitId }}</td>
        <td><label> Generation Unit Nickname: </label></td>
        <td>{{ selectedUnit?.nickName }}</td>
      </tr>
      <tr>
        <td><label> Service From: </label></td>
        <td>{{ dtBegin | date : 'MM/dd/yyyy' }}</td>
        <td><label> Service To: </label></td>
        <td>{{ dtEnd | date : 'MM/dd/yyyy' }}</td>
      </tr>
      <tr>
        <td><label> Date Unit Energized: </label></td>
        <td>{{ selectedUnit?.effectiveDate | date : 'MM/dd/yyyy' }}</td>
        <td><label> Date Unit Removed: </label></td>
        <td>{{ selectedUnit?.terminationDate | date : 'MM/dd/yyyy' }}</td>
      </tr>
      <tr>
        <td><label> GUnit Capacity KW: </label></td>
        <td>{{ selectedUnit?.capacity }}</td>
        <td><label> Total Allocation as of Service To Date KW: </label></td>
        <td>{{ selectedUnit?.totalSubscriberUsage }}</td>
      </tr>
    </tbody>
  </table>

  <table
    class="table gen_unit_subscriber_contract_report_grid"
    *ngIf="searchClicked"
  >
    <caption class="sr-only">
      Generation Unit Subscriber Bill Account Report
    </caption>
    <thead>
      <tr>
        <th scope="col">Subscriber Name</th>
        <th scope="col">Subscriber Account Number</th>
        <th scope="col">Subscriber Identification Number</th>
        <th scope="col">Subscribed KW</th>
        <th scope="col">Active Date</th>
        <th scope="col">Termination Date</th>
        <th scope="col">DS Rate Class</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="!isReportLoading && (!reportData || !reportData.length)">
        <td colspan="9" class="text-center">No data</td>
      </tr>
      <tr *ngIf="isReportLoading">
        <td colspan="9" class="text-center">
          <p>Please wait..</p>
          <ameren-loading></ameren-loading>
        </td>
      </tr>
      <ng-container *ngIf="!isReportLoading">
        <tr *ngFor="let rep of reportData">
          <td>
            {{ rep.subscriberName }}
          </td>
          <td>
            {{ rep.billAccountNumber }}
          </td>
          <td>
            {{ rep.customerIdentifier }}
          </td>
          <td>
            {{ rep.subscribeQuantity }}
          </td>
          <td>
            {{ rep.effectiveDate | date : 'MM/dd/yyyy' }}
          </td>
          <td>
            {{ rep.terminationDate | date : 'MM/dd/yyyy' }}
          </td>
          <td>
            {{ rep.rateClassDesc }}
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
