import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CorrelationService } from 'src/app/shared/services/correlation.service';
import { environment } from 'src/environments/environment';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';

@Injectable({
  providedIn: 'root',
})
export class GenerationOwnerApiService {
  constructor(
    private http: HttpClient,
    private correlationService: CorrelationService
  ) {}
  getAllOwners(): Observable<any> {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'ANM')
      .set('domain', 'private')
      .set('ownertype', 'O');

    return this.http.get<IGlobalAPIResponse>(`${environment.apig}/genowners`, {
      headers,
    });
  }

  get3rdPartyOwners(): Observable<any> {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'ANM')
      .set('domain', 'private')
      .set('ownertype', '3');

    return this.http.get<IGlobalAPIResponse>(`${environment.apig}/genowners`, {
      headers,
    });
  }

  getOwnersByOwnerId(ownerid: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'ANM')
      .set('domain', 'private')
      .set('ownerid', ownerid.toString());

    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/genowners/ownerid`,
      { headers }
    );
  }

  getOwnersByOwnerType(ownerType: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'ANM')
      .set('domain', 'private')
      .set('ownertype', ownerType.toString());

    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/genowners/ownertype`,
      { headers }
    );
  }

  getGenerationUnitsByOwnerId(ownerId: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'ANM')
      .set('domain', 'private');

    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/genunits/byowner/${ownerId.toString()}`,
      { headers }
    );
  }

  getLocationByGenerationOwner(ownerid: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'ANM')
      .set('domain', 'private')
      .set('ownerid', ownerid);

    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/genowners/locations`,
      { headers }
    );
  }

  gtOwnerBankingInfo(ownerid: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'ANM')
      .set('domain', 'private')
      .set('ownerid', ownerid);

    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/genowners/banking`,
      { headers }
    );
  }

  updateBankingInfo(
    banking: any,
    ownerid: string,
    audit: string
  ): Observable<any> {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'ANM')
      .set('domain', 'private')
      .set('audit', audit)
      .set('ownerid', ownerid);

    return this.http.put<IGlobalAPIResponse>(
      `${environment.apig}/genowners/banking`,
      banking,
      { headers }
    );
  }

  updateGenOwner(owner: any, audit: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'ANM')
      .set('domain', 'private')
      .set('state', 'ILLINOIS')
      .set('Content-Type', 'application/json')
      .set('audit', audit)
      .set('ownerid', owner.ownerId.toString());

    return this.http.put<IGlobalAPIResponse>(
      `${environment.apig}/genowners`,
      owner,
      { headers }
    );
  }

  addGenOwner(owner: any, audit: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'ANM')
      .set('domain', 'private')
      .set('state', 'ILLINOIS')
      .set('Content-Type', 'application/json')
      .set('audit', audit);

    return this.http.post<IGlobalAPIResponse>(
      `${environment.apig}/genowners`,
      owner,
      { headers }
    );
  }
}
