<div class="reg_compliance_report_container">
  <div class="reg_compliance_report_title">Regulatory Compliance Report</div>
  <div class="row col-md-6">
    <div class="col form-group">
      <label>Service From Date:<span class="text-danger">*</span></label>
      <input
        type="date"
        class="form-control"
        required
        [value]="dtBegin"
        [min]="minDate"
        [max]="maxDate"
        [(ngModel)]="dtBegin"
        (ngModelChange)="onChangeFromDate()"
      />
    </div>
    <div class="col form-group">
      <label>Service To Date:<span class="text-danger">*</span></label>
      <input
        type="date"
        class="form-control"
        required
        [value]="dtEnd"
        [max]="dtEndMaxDate"
        [(ngModel)]="dtEnd"
        (ngModelChange)="onChangeToDate()"
      />
    </div>
  </div>
  <div class="reg_compliance_report_search_btn">
    <div class="row col-md-3">
      <div class="col">
        <button
          class="btn btn-outline-success"
          [disabled]="!dtBegin || !dtEnd"
          (click)="search()"
        >
          Search
        </button>
        <button
          class="btn btn-outline-success m-2"
          [disabled]="!reportData || !reportData.length"
          (click)="exportReport()"
        >
          <img
            src="assets/icon/excelicon.svg"
            alt="CalendarImage"
            width="22"
            height="19"
          />
          Export
        </button>
      </div>
    </div>
  </div>

  <table class="table reg_compliance_report_grid" *ngIf="searchClicked">
    <caption class="sr-only">
      Regulatory Compliance Report
    </caption>
    <thead>
      <tr>
        <th scope="col" class="gen-o-name">Generation Owner Name</th>
        <th scope="col" class="gen-id">Generation ID</th>
        <th scope="col" class="date-energized">Date Energized</th>
        <th scope="col" class="subs-name">Subscriber Name</th>
        <th scope="col" class="subs-fein">Subscriber FEIN/Customer SSN</th>
        <th scope="col" class="subs-id-no">Subscriber Identification Number</th>
        <th scope="col" class="subs-kw">Subscribed KW</th>
        <th scope="col" class="active-date">Active Date</th>
        <th scope="col" class="termination-date">Termination Date</th>
        <th scope="col" class="ds-rate">DS Rate Class</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="!isReportLoading && (!reportData || !reportData.length)">
        <td colspan="9" class="text-center">No data</td>
      </tr>
      <tr *ngIf="isReportLoading">
        <td colspan="9" class="text-center">
          <p>Please wait..</p>
          <ameren-loading></ameren-loading>
        </td>
      </tr>
      <ng-container *ngIf="!isReportLoading">
        <tr *ngFor="let rep of reportData">
          <td>
            {{ rep.ownerName }}
          </td>
          <td>
            {{ rep.owner }}
          </td>
          <td>
            {{ rep.genUnitactiveDate }}
          </td>
          <td>
            {{ rep.subscriberName }}
          </td>
          <td>
            {{ rep.ssnFein }}
          </td>
          <td>
            {{ rep.customerIdentifier }}
          </td>
          <td>
            {{ rep.subscribeQuantity }}
          </td>
          <td>
            {{ rep.effectiveDate }}
          </td>
          <td>
            {{ rep.terminationDate == '9999-12-31' ? '' : rep.terminationDate }}
          </td>
          <td>
            {{ rep.rateClassDesc }}
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
