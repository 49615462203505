import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../../../src/app/shared/services/auth.service";

@Component({
  selector: "app-signout-redirect-callback",
  templateUrl: "./signout-redirect-callback.component.html",
  styleUrls: ["./signout-redirect-callback.component.scss"],
})
export class SignoutRedirectCallbackComponent implements OnInit {
  constructor(private authService: AuthService, private router: Router ) {}

  ngOnInit() {   
    this.authService.LogOutComplete().then(() => {   
      this.authService.setSelectedGenerationOwner(); 
      this.router.navigate(["/"]);
    });
  }
}
