<div class="gen_unit_owner_sub_fee_report_container">
    <div class="gen_unit_owner_sub_fee_report_title">
        Generation Unit Owner Subscription Fee Report
    </div>
    <div class="text-center" *ngIf="!genOwners">
        <ameren-loading></ameren-loading>
        <p>Please wait..</p>
    </div>
    <div class="row" *ngIf="genOwners">
        <div class="col form-group">
            <label>Generation Owner Name:<span class="text-danger">*</span></label>
            <select class="form-select" [(ngModel)]="selectedOwner" (change)="onOwnerChange()">
                <option *ngFor="let o of genOwners" [ngValue]="o">{{o.name}}</option>
            </select>
        </div>
        <div class="col form-group">
            <label>Generation Unit:<span class="text-danger">*</span></label>
            <select class="form-select" [(ngModel)]="selectedUnit" [disabled]="!units">
                <option *ngFor="let u of units" [ngValue]="u">{{u.nickName}} - Acct# {{u.billAccountNumber}}</option>
            </select>
        </div>
    </div>
    <div class="row" *ngIf="genOwners">
        <div class="col form-group">
            <label>
                Service From Date:
            </label>
            <input type="date" class="form-control" [min]="minDate" [max]="maxDate" [(ngModel)]="dtBegin" />
        </div>
        <div class="col form-group">
            <label>
                Service To Date:
            </label>
            <input type="date" class="form-control" [min]="minDate" [max]="maxDate" [(ngModel)]="dtEnd"  />
        </div>
    </div>

    <div class="row" *ngIf="genOwners">
        <div class="col-md-3">
            <button class="btn btn-outline-success" [disabled]="!dtBegin || !dtEnd || !selectedOwner" (click)="search()">Search</button>
            <button class="btn btn-outline-success m-2" [disabled]="!reportData || !reportData.length" (click)="exportReport()">
                <img src="assets/icon/excelicon.svg" alt="CalendarImage" width="22" height="19" />
                Export</button>
        </div>
    </div>

    <div class="text-center" *ngIf="isApiLoading">
        <ameren-loading></ameren-loading>
        <p>Please wait..</p>
    </div>
    <div class="text-danger" id="loading_report_criteria" *ngIf="integrationErrorMsg || !isApiLoading" >
        {{integrationErrorMsg}}
    </div>
    
    <table class="table gen_unit_owner_sub_fee_report_grid" *ngIf="searchClicked">
        <caption class="sr-only">
            Generation Owner Unit Subscription Fee Report
        </caption>
        <thead>
            <tr>
                <th scope="col">
                    Bill Account
                </th>
                <th scope="col">
                    Date Processed
                </th>
                <th scope="col">
                    Subscriber Name
                </th>
                <th scope="col">
                    Meter Number
                </th>
                <th scope="col">
                    Unit Nick Name
                </th>
                <th scope="col">
                    Subscriber Fee
                </th>
                <th scope="col">
                    Admin Fee
                </th>
                <th scope="col">
                    Transaction Amount
                </th>
                <th scope="col">
                    Transaction Status
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="!isReportLoading && (!reportData || !reportData.length)">
                <td colspan="9" class="text-center">No data</td>
            </tr>
            <tr *ngIf="isReportLoading">
                <td colspan="9" class="text-center">
                    <p>Please wait..</p>
                    <ameren-loading></ameren-loading>
                </td>
            </tr>
            <ng-container *ngIf="!isReportLoading">
                <tr *ngFor="let rep of reportData">
                    <td>
                        {{ rep.billAccountNumber }}
                    </td>
                    <td>
                        {{ rep.dateProcessed }}
                    </td>
                    <td>
                        {{ rep.subscriberName }}
                    </td>
                    <td>
                        {{ rep.meterNumber }}
                    </td>
                    <td>
                        {{ rep.unitNickName }}
                    </td>
                    <td>
                        {{ rep.subscriberFee}}
                    </td>
                    <td>{{ rep.adminFee}}</td>
                    <td>
                        {{rep.transactionAmount}}
                    </td>
                    <td>
                        {{rep.transactionStatus}}
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>