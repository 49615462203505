import { ExportUtility } from './../../../shared/utilities/utility.export';
import { AuthService } from './../../../shared/services/auth.service';
import { ProfileManagerService } from './../../../shared/services/profilemanager.service';
import { GenerationUnitManagerService } from './../../generation-unit/services/generation-unit-manager.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { RebateManagerService } from '../services/rebate-manager.service';
import { FineGrainAuthorization } from 'src/app/shared/models/fine-grain-authorization';
import { RebatesByUnitIdModel } from '../models/rebatesbyunitid.model';

declare let window: any;

export enum EnumRebateApprovalLevels {
  Level1 = '1',
  Level2 = '2',
  Level3 = '3',
  Level4 = '4',
}

@Component({
  selector: 'app-rebate-detail',
  templateUrl: './rebate-detail.component.html',
  styleUrls: ['./rebate-detail.component.scss'],
})
export class RebateDetailComponent implements OnInit {
  currentUser: any;
  isInternalAdmin: boolean;
  isRebateApprover: boolean;
  unitId: string;
  ownerId: string;
  billAccountNumber: string;
  rebates: any[] = [];
  unitRebates: RebatesByUnitIdModel[];
  unit: any;
  isUnitLoading: boolean;
  selectedRebates: any[] = [];
  showAllRebates: boolean;
  isRebateListLoading: boolean;
  rebatesAPIFailure: boolean = false;
  roleMap: any[];
  saveLoading: boolean;
  saveError: boolean;
  saveSuccess: boolean;
  functionalityAccess: FineGrainAuthorization;
  selectedGenerationOwner: any;
  hasSelectedGenerationOwner: boolean = false;
  rebatebyUnitIdAPIFailureMessage = '';
  rebateSuccessMessage: string = '';
  rebateApproverLevel: string = '';
  isRebateApproverEligible: boolean = false;
  rebateInEligibilityMessage: string = '';
  rebateInEligibilityModal: any;
  saveRebatesModal: any;

  constructor(
    private route: ActivatedRoute,
    private _genUnitMgr: GenerationUnitManagerService,
    private rebateMgr: RebateManagerService,
    private authSvc: AuthService,
    private _export: ExportUtility,
    private profileManager: ProfileManagerService
  ) {
    this.route.queryParams.subscribe((data) => {
      this.ownerId = data['oId'];
      this.unitId = data['uId'];
      this.billAccountNumber = data['ba'];
    });

    this.authSvc.getUser().then((user) => {
      this.currentUser = user?.profile;
      this.roleMap = user?.profile?.['anmRoleMap'];
      this.isRebateApprover = this.roleMap.some(
        (x: any) => x.role == 'anmRebateApprover' && x.status == 'Active'
      );
      this.isInternalAdmin = this.roleMap.some(
        (x: any) => x.role == 'anmInternalAdmin'
      );
    });
  }

  ngOnInit(): void {
    this.rebateApproverLevel =
      this.profileManager.profile[0].anmRebateApprovalLevel || '';

    this.rebateInEligibilityModal = new window.bootstrap.Modal(
      document.getElementById('rebateInEligibilityModal')
    );
    this.saveRebatesModal = new window.bootstrap.Modal(
      document.getElementById('saveRebatesModal')
    );

    this.selectedGenerationOwner = this.authSvc.getSelectedGenerationOwner();
    this.hasSelectedGenerationOwner = this.selectedGenerationOwner
      ? true
      : false;

    let role = this.authSvc.getSelectedGenerationOwnerRole();
    let functionality = 'REBATE';
    this.functionalityAccess = this.authSvc.getPortalFunctioanalityAccesses(
      functionality,
      role
    );

    this.getGenerationUnit();
    this.getRebatesByUnitId();
  }

  getGenerationUnit() {
    this.isUnitLoading = true;
    this._genUnitMgr.getGenerationUnitByUnitId(this.unitId).subscribe({
      next: (resp) => {
        this.isUnitLoading = false;
        this.unit = resp;
        this.rebatesAPIFailure = false;
      },
      error: (error: any) => {
        this.isUnitLoading = false;
        this.rebatesAPIFailure = true;
        console.log('err', error);
      },
    });
  }

  getRebatesByUnitId() {
    this.isRebateListLoading = true;
    this.rebateMgr
      .getRebatesByUnitId(
        this.unitId,
        this.billAccountNumber,
        this.ownerId || '0000'
      )
      .subscribe({
        next: (resp: RebatesByUnitIdModel[]) => {
          this.isRebateListLoading = false;
          this.rebatesAPIFailure = false;
          if (this.functionalityAccess.isRebateApprover) {
            this.rebates = resp.filter((data) => {
              return data.rebateStatus === '4';
            });
          } else if (this.functionalityAccess.isAmerenAdmin) {
            this.rebates = resp.filter((data) => {
              return (
                data.rebateStatus === '4' ||
                data.rebateStatus === '1' ||
                data.rebateStatus === ''
              );
            });
          } else {
            this.rebates = resp;
          }
          this.unitRebates = resp;
          this.rebates.forEach((rebate: any) => {
            rebate['comments'] = '';
            rebate['isApproved'] = false;
            rebate['isDenied'] = false;
            rebate['requestRebate'] = false;
            rebate['cancelRebate'] = false;

            if (rebate.feinStatus === '1') {
              rebate['feinStatus'] = 'Pending W9 Receipt';
            } else if (rebate.feinStatus === '2') {
              rebate['feinStatus'] = 'W9 On File';
            } else if (rebate.feinStatus === '3') {
              rebate['feinStatus'] = 'Pending IRS Validation';
            } else if (rebate.feinStatus === '4') {
              rebate['feinStatus'] = 'IRS Validation Successful';
            } else if (rebate.feinStatus === '5') {
              rebate['feinStatus'] = 'IRS Validation Failed';
            } else {
              rebate['feinStatus'] = '';
            }
          });
        },
        error: (error: any) => {
          this.isRebateListLoading = false;
          this.rebatesAPIFailure = true;
          if (error.error.errorDetails.code === 400) {
            this.rebatebyUnitIdAPIFailureMessage =
              error.error.errorDetails.message;
          }
          console.log('err', error);
        },
      });
  }

  approveRejectRequestCancelRebate(rebate: any, comment?: string) {
    this.selectedRebates = [];

    if (this.selectedRebates.length > 1) {
      this.selectedRebates = this.selectedRebates.filter(
        (x) => x.billAccountNumber != rebate.billAccountNumber
      );
    }

    if (this.functionalityAccess.isRebateApprover) {
      if (rebate.isDenied || rebate.isApproved)
        this.selectedRebates.push(rebate);
    }

    if (this.functionalityAccess.isAmerenAdmin) {
      if (rebate.requestRebate || rebate.cancelRebate)
        this.selectedRebates.push(rebate);
    }
  }

  onSaveClick() {
    let rebateUpdates: any[] = [];
    if (this.functionalityAccess.isRebateApprover) {
      this.selectedRebates.forEach((re) => {
        rebateUpdates.push({
          ownerId: re.ownerId,
          unitId: re.unitId,
          billAccountNumber: re.billAccountNumber,
          premiseNumber: re.premiseNumber,
          servicePointNumber: re.servicePointNumber,
          rebateSeq: re.rebateSeq,
          rebateStatus: re.isApproved ? '5' : '7',
          comments: re.comments,
          recipientUpdateFlg: false,
          rebateMaintainFlg: true,
        });
      });
    }

    if (this.functionalityAccess.isAmerenAdmin) {
      this.selectedRebates.forEach((re) => {
        rebateUpdates.push({
          ownerId: re.ownerId,
          unitId: re.unitId,
          billAccountNumber: re.billAccountNumber,
          premiseNumber: re.premiseNumber,
          servicePointNumber: re.servicePointNumber,
          rebateSeq: re.rebateSeq,
          rebateStatus: re.requestRebate ? '1' : '8',
          comments: re.comments,
          recipientUpdateFlg: false,
          rebateMaintainFlg: true,
        });
      });
    }

    let rebateApprovalLimitAmount: any;
    if (this.rebateApproverLevel) {
      rebateApprovalLimitAmount = this.getRebateApprovalLimit(
        this.rebateApproverLevel
      );

      this.selectedRebates.forEach((data: any) => {
        if (
          +data.rebateAmount <= rebateApprovalLimitAmount &&
          this.rebateApproverLevel != '4'
        ) {
          this.isRebateApproverEligible = true;
        } else if (this.rebateApproverLevel === '4') {
          this.isRebateApproverEligible = true;
        } else {
          this.isRebateApproverEligible = false;
        }
      });
    }

    if (!this.isRebateApproverEligible && this.rebateApproverLevel) {
      this.rebateInEligibilityMessage =
        'Some Rebate(s) Are Over Approval Level';
      this.rebateInEligibilityModal.show();
      return '';
    } else {
      this.submitRebateApprover(rebateUpdates);
    }
  }

  submitRebateApprover(rebateUpdates: any) {
    this.saveLoading = true;
    this.saveRebatesModal.show();
    this.rebateMgr
      .saveRebates({ RebateUpdates: rebateUpdates }, this.currentUser.email)
      .subscribe({
        next: (resp) => {
          this.saveLoading = false;
          this.saveError = false;
          this.saveSuccess = true;

          if (resp.successMessage) {
            this.rebateSuccessMessage = resp.successMessage;
          } else {
            this.rebateSuccessMessage = 'Rebate(s) submitted successfully';
          }

          this.getRebatesByUnitId();
        },
        error: (error) => {
          this.saveLoading = false;
          this.saveError = true;
          this.saveSuccess = false;
        },
      });
  }

  toggleAllRebates() {
    this.showAllRebates = !this.showAllRebates;
    if (this.showAllRebates) {
      this.isRebateListLoading = true;
      this.rebates = [];
      this.rebateMgr.getRebateHistoryByUnit(this.unitId).subscribe({
        next: (resp) => {
          this.isRebateListLoading = false;
          this.rebates = resp;

          this.rebates.forEach((rebate: any) => {
            if (rebate.feinStatus === '1') {
              rebate['feinStatus'] = 'Pending W9 Receipt';
            } else if (rebate.feinStatus === '2') {
              rebate['feinStatus'] = 'W9 On File';
            } else if (rebate.feinStatus === '3') {
              rebate['feinStatus'] = 'Pending IRS Validation';
            } else if (rebate.feinStatus === '4') {
              rebate['feinStatus'] = 'IRS Validation Successful';
            } else if (rebate.feinStatus === '5') {
              rebate['feinStatus'] = 'IRS Validation Failed';
            } else {
              rebate['feinStatus'] = '';
            }
          });

          if (this.hasSelectedGenerationOwner)
            this.rebates = this.rebates.filter(
              (x: any) => x.ownerId == this.ownerId
            );
        },
      });
    } else {
      this.rebates = this.unitRebates;
    }
  }

  exportRebates() {
    let keyHeaders: string[] = [];
    let modifiedlist: any = [];
    let displayHeaders: string[] = [
      'Bill Account Number',
      'Owner Bill Account Number',
      'FEIN Stauts',
      'Rebate Amount',
    ];

    keyHeaders = [
      'billAccountNumber',
      'ownerBillAccountNumber',
      'feinStatus',
      'rebateAmount',
    ];

    this.rebates.forEach((row: any) => {
      let temp: any = {};

      temp['billAccountNumber'] = row.billAccountNumber;
      temp['ownerBillAccountNumber'] = row.ownerBillAccountNumber;
      temp['feinStatus'] = row.feinStatus;
      temp['rebateAmount'] = row.rebateAmount;

      modifiedlist.push(temp);
    });
    this._export.exportJsonToExcelFile(
      modifiedlist,
      'rebates',
      false,
      keyHeaders,
      displayHeaders
    );
  }

  getRebateApprovalLimit(level: any) {
    switch (level) {
      case EnumRebateApprovalLevels.Level1:
        return 150000;
      case EnumRebateApprovalLevels.Level2:
        return 1000000;
      case EnumRebateApprovalLevels.Level3:
        return 3000000;
      case EnumRebateApprovalLevels.Level4:
        return 3000001;
    }
  }

  closeSaveModal() {
    this.saveRebatesModal.hide();
  }

  closeInEligibilityModal() {
    this.rebateInEligibilityModal.hide();
    this.getRebatesByUnitId();
  }
}
