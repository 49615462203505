<div class="add-generation-unit-container">
  <div class="add-generation-unit-header">Add Generation Unit</div>
  <div class="d-flex align-items-center justify-content-center">
    <ng-container
      *ngIf="
        !generationOwnerListLoading &&
          !isGetFuelSourceLoading &&
          !isGetUnitTypesLoading;
        else showLoadingIndicator
      "
    >
      <ng-container
        *ngIf="
          !generationOwnerListAPIFailure &&
            !isGetFuelSourceError &&
            !isGetUnitTypesError;
          else apiFailureMessage
        "
      >
        <!-- Add Generation Owner Form -->

        <form [formGroup]="addGenerationUnitForm" class="needs-validation">
          <div class="form-group">
            <label class="form-label required fw-bold" for="generationType"
              >Type:</label
            >

            <ng-select formControlName="generationType">
              <ng-option *ngFor="let type of unitTypes" [value]="type.code">
                {{ type.decode }} ({{ type.code }})</ng-option
              >
              <ng-option [value]="">Select Generation Type</ng-option>
            </ng-select>
            <div
              class="text-field-error mt-3 ameren-font-sm"
              *ngIf="addGenerationUnitForm?.get('generationType')?.invalid"
            >
              <p
                *ngIf="addGenerationUnitForm?.get('generationType')?.errors?.['required']"
              >
                Generation Type is required.
              </p>
            </div>
          </div>

          <div class="form-group">
            <label
              class="form-label fw-bold"
              for="generationOwnerName"
              id="generationOwnerName"
            >
              Generation Owner Name:
            </label>

            <ng-select formControlName="generationOwnerName">
              <ng-option
                *ngFor="let owners of ownerList"
                [value]="owners.ownerId"
              >
                {{ owners.name }} ({{ owners.ownerId }})
              </ng-option>
              <option [value]="">Select Generation Owner Name</option>
            </ng-select>

            <div
              class="text-field-error mt-3 ameren-font-sm"
              *ngIf="addGenerationUnitForm?.get('generationOwnerName')?.invalid"
            >
              <p
                *ngIf="addGenerationUnitForm?.get('generationOwnerName')?.errors?.['required']"
              >
                Generation Owner is required.
              </p>
            </div>
          </div>

          <div class="form-group" *ngIf="showGenerationOwnerLocation">
            <label class="form-label required fw-bold" for="locationID">
              Location:
            </label>

            <ng-select formControlName="locationID">
              <ng-option
                *ngFor="let location of locationDetails"
                [value]="location.locationID"
              >
                {{ location.locationDesc }}
              </ng-option>
              <ng-option>Other Location</ng-option>

              <ng-option [value]="">Select Location</ng-option>
            </ng-select>

            <input
              *ngIf="showOtherLocationDescription"
              class="mt-2 form-control"
              type="text"
              name="locationDesc"
              id="locationDesc"
              formControlName="locationDesc"
            />
            <div
              class="text-field-error mt-3 ameren-font-sm"
              *ngIf="addGenerationUnitForm?.get('locationID')?.invalid"
            >
              <p
                *ngIf="addGenerationUnitForm?.get('locationID')?.errors?.['required']"
              >
                location Id is required.
              </p>
            </div>
          </div>

          <div class="form-group">
            <label
              class="billaccount-number form-label required fw-bold"
              for="billAccountNumber"
              >Bill Account Number:</label
            >
            <input
              class="form-control"
              type="text"
              name="billAccountNumber"
              id="billAccountNumber"
              formControlName="billAccountNumber"
            />
            <div
              class="text-field-error mt-3 ameren-font-sm"
              *ngIf="
                addGenerationUnitForm?.get('billAccountNumber')?.dirty &&
                addGenerationUnitForm?.get('billAccountNumber')?.invalid
              "
            >
              <p
                *ngIf="addGenerationUnitForm?.get('billAccountNumber')?.errors?.['required']"
              >
                Bill Account Number is required.
              </p>
              <p
                *ngIf="
                  addGenerationUnitForm
                    ?.get('billAccountNumber')
                    ?.errors?.['maxlength'] || addGenerationUnitForm
                    ?.get('billAccountNumber')
                    ?.errors?.['minlength']"
              >
                Bill Account Number should be 10 digits.
              </p>

              <p
                *ngIf="
                addGenerationUnitForm
                  ?.get('billAccountNumber')
                  ?.errors?.['pattern']"
              >
                Bill Account Number is invalid.
              </p>
            </div>
          </div>

          <div class="form-group" *ngIf="showMeterNumber">
            <label
              class="meter-number form-label required fw-bold"
              for="meterNumber"
              >Meter Number:</label
            >
            <input
              class="form-control"
              type="text"
              name="meterNumber"
              id="meterNumber"
              formControlName="meterNumber"
            />
            <div
              class="text-field-error mt-3 ameren-font-sm"
              *ngIf="
                addGenerationUnitForm?.get('meterNumber')?.dirty &&
                addGenerationUnitForm?.get('meterNumber')?.invalid
              "
            >
              <p
                *ngIf="addGenerationUnitForm?.get('meterNumber')?.errors?.['required']"
              >
                Meter Number is required.
              </p>
            </div>
          </div>

          <div class="form-group">
            <label for="capacity" class="form-label required fw-bold"
              >Capacity:</label
            >
            <div class="input-group">
              <input
                type="number"
                class="form-control"
                id="capacity"
                name="capacity"
                formControlName="capacity"
                placeholder="0.000"
              />
              <div class="input-group-append">
                <span class="input-group-text ameren-margin-top">KW</span>
              </div>
            </div>

            <div
              class="text-field-error mt-3 ameren-font-sm"
              *ngIf="
                addGenerationUnitForm?.get('capacity')?.dirty &&
                addGenerationUnitForm?.get('capacity')?.invalid
              "
            >
              <p
                *ngIf="addGenerationUnitForm?.get('capacity')?.errors?.['required']"
              >
                Capacity is required
              </p>
            </div>
          </div>

          <div class="form-group">
            <label for="nickName" class="form-label required fw-bold"
              >Nickname:</label
            >
            <input
              type="text"
              class="form-control"
              id="nickName"
              name="nickName"
              formControlName="nickName"
            />
            <div
              class="text-field-error mt-3 ameren-font-sm"
              *ngIf="
                addGenerationUnitForm?.get('nickName')?.dirty &&
                addGenerationUnitForm?.get('nickName')?.invalid
              "
            >
              <p
                *ngIf="addGenerationUnitForm?.get('nickName')?.errors?.['required']"
              >
                NickName is required
              </p>
            </div>
          </div>

          <div class="form-group">
            <label
              for="generationFuelSource"
              class="form-label required fw-bold"
              >Generation Fuel Source:</label
            >

            <ng-select formControlName="generationFuelSource">
              <ng-option
                *ngFor="let source of fuelSources"
                [value]="source.code"
              >
                {{ source.decode }}
              </ng-option>
              <ng-option [value]="">Select Fuel Source</ng-option>
            </ng-select>

            <div
              class="text-field-error mt-3 ameren-font-sm"
              *ngIf="
                addGenerationUnitForm?.get('generationFuelSource')?.invalid
              "
            >
              <p
                *ngIf="addGenerationUnitForm?.get('generationFuelSource')?.errors?.['required']"
              >
                Generation Fuel Source is required
              </p>
            </div>
          </div>

          <div class="form-group">
            <label for="powerClerkNumber" class="form-label fw-bold"
              >PowerClerk Number:</label
            >
            <input
              type="text"
              class="form-control"
              id="powerClerkNumber"
              name="powerClerkNumber"
              formControlName="powerClerkNumber"
            />
            <div
              class="text-field-error mt-3 ameren-font-sm"
              *ngIf="
                addGenerationUnitForm?.get('powerClerkNumber')?.dirty &&
                addGenerationUnitForm?.get('powerClerkNumber')?.invalid
              "
            >
              <p>Power Clerk Number is Invalid</p>
            </div>
          </div>

          <div class="form-group">
            <label for="capacityForRebate" class="form-label fw-bold"
              >Capacity For Rebate:</label
            >
            <div class="input-group">
              <input
                type="number"
                class="form-control"
                id="capacityForRebate"
                name="capacityForRebate"
                formControlName="capacityForRebate"
                placeholder="0.000"
                (keyup)="toggleRequestRebate($event)"
              />
              <div class="input-group-append">
                <ng-template #otherUnitType
                  ><span class="input-group-text ameren-margin-top">
                    KW</span
                  ></ng-template
                >
                <span
                  class="input-group-text ameren-margin-top"
                  *ngIf="
                    addGenerationUnitForm.get('generationType')?.value ===
                      'ESS';
                    else otherUnitType
                  "
                  >KWh</span
                >
              </div>
            </div>

            <div
              class="text-field-error mt-3 ameren-font-sm"
              *ngIf="
                addGenerationUnitForm?.get('capacityForRebate')?.dirty &&
                addGenerationUnitForm?.get('capacityForRebate')?.invalid
              "
            >
              <p>Capacity for Rebate is Invalid</p>
            </div>
          </div>

          <div class="form-group">
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="requestRebate"
                name="requestRebate"
                formControlName="requestRebate"
              />
              <label for="requestRebate" class="form-check-label fw-bold"
                >Request Rebate:</label
              >
            </div>

            <div
              class="text-field-error mt-3 ameren-font-sm"
              *ngIf="
                addGenerationUnitForm?.get('requestRebate')?.dirty &&
                addGenerationUnitForm?.get('requestRebate')?.invalid
              "
            >
              <p>Rebate Request is Invalid</p>
            </div>
          </div>

          <div
            class="form-group"
            *ngIf="addGenerationUnitForm?.get('requestRebate')?.value"
          >
            <label for="effectiveDate" class="form-label fw-bold"
              >Effective Date:</label
            >

            <input
              type="text"
              class="form-control"
              id="effectiveDate"
              name="effectiveDate"
              formControlName="effectiveDate"
            />

            <div
              class="text-field-error mt-3 ameren-font-sm"
              *ngIf="
                addGenerationUnitForm?.get('effectiveDate')?.dirty &&
                addGenerationUnitForm?.get('effectiveDate')?.invalid
              "
            >
              <p>Effective Date is Invalid</p>
            </div>
          </div>

          <div class="d-grid gap-2 d-md-flex mt-5 justify-content-center">
            <button
              class="ameren-secondary-green-btn"
              type="submit"
              (click)="cancelAddGenerationUnit()"
            >
              Cancel
            </button>
            <button
              [disabled]="addGenerationUnitForm.invalid"
              class="btn btn-success ameren-primary-green-btn"
              type="submit"
              (click)="submitGenerationUnit()"
            >
              Save
            </button>
          </div>
        </form>
      </ng-container>
    </ng-container>

    <ng-template #apiFailureMessage>
      <div class="mt-3 alert alert-danger" role="alert">
        Sorry, Something went wrong. Please try again.
      </div>
    </ng-template>

    <ng-template #showLoadingIndicator>
      <ameren-loading class="d-flex justify-content-center"
        >Loading...</ameren-loading
      >
    </ng-template>
  </div>
</div>

<div
  id="addGenerationUnitModal"
  class="modal fade"
  aria-hidden="true"
  aria-labelledby="amerenModal"
  tabindex="-1"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header" *ngIf="!addGenerationUnitLoading">
        <div
          class="ameren__modal__success-title"
          *ngIf="!addGenerationUnitLoading && !addGenerationUnitAPIFailure"
        >
          Successfully Submitted!
        </div>
        <div
          class="ameren__modal__failure-title"
          *ngIf="!addGenerationUnitLoading && addGenerationUnitAPIFailure"
        >
          Submission Error!
        </div>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="closeAddGenerationModal()"
        ></button>
      </div>
      <div class="modal-body">
        <div *ngIf="addGenerationUnitLoading" class="text-center">
          <ameren-loading
            id="loadingIndicator"
            class="d-flex p-10 justify-content-center"
          ></ameren-loading>
          <span>Please wait...</span>
        </div>
        <div class="ameren__modal-image">
          <img
            *ngIf="!addGenerationUnitAPIFailure && !addGenerationUnitLoading"
            src="assets/images/success.svg"
            alt="Success Image"
          />
          <img
            *ngIf="addGenerationUnitAPIFailure && !addGenerationUnitLoading"
            src="assets/images/failure.svg"
            alt="Failure Image"
          />
        </div>
        <div class="ameren__modal-footer" *ngIf="!addGenerationUnitLoading">
          <div
            class="ameren__modal-message text-center justify-content-center"
            *ngIf="!addGenerationUnitLoading && !addGenerationUnitAPIFailure"
          >
            {{responseMsg}}
          </div>

          <div
            class="ameren__modal-message text-danger text-center justify-content-center"
            *ngIf="!addGenerationUnitLoading && addGenerationUnitAPIFailure"
          >
            {{ responseMsg }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="addGenerationUnitCancelModal"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="editGenUnitCancelModalLabel">
          Confirm Cancellation
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <p>Are you sure you want to cancel?</p>
      </div>
      <div class="modal-footer">
        <a
          [routerLink]="['/generation-unit']"
          class="btn btn-success"
          data-bs-dismiss="modal"
          >Yes</a
        >

        <button
          type="button"
          data-bs-dismiss="modal"
          class="btn btn-danger"
          data-bs-dismiss="modal"
        >
          No
        </button>
      </div>
    </div>
  </div>
</div>
