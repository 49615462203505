import { Injectable } from '@angular/core';
import { AmerenError } from "src/app/shared/models/amerenError";
import { IGlobalAPIResponse } from "src/app/shared/interfaces/iglobal-api-response";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { AuditTrailApiService } from './audit-trail-api.service';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuditTrailManagerService {

  constructor(private AuditApiService: AuditTrailApiService) { }

  getAllAudits(startDate: string, endDate:string) {
    return this.AuditApiService.getAllAudits(startDate, endDate).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            "Audit Trail cannot be retrieved",
            400,
            null,
            null,
            null
          );
        }

        return res;
      }),
      catchError((error: any) => {
        console.log("err", error);
        return throwError(() => error);
      })
    );
  }

  CreateAuditEntry(data: any) {
    return this.AuditApiService
      .CreateAuditEntry(data)
      .pipe(
        map((res: IGlobalAPIResponse) => {
          if (!res.success)
            throw new AmerenError('Failed to create a new Audit trail entry.',400,null,null,null);
          return res;
        }),
        catchError((error: any) => {
          return throwError(() => error);
        })
      );
  }


}
