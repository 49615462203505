import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  AbstractControlOptions,
} from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { MustMatch } from "src/app/shared/validators/must-match";
import { FineGrainAuthorization } from "src/app/shared/models/fine-grain-authorization";
import { AuthService } from "src/app/shared/services/auth.service";
import { GenerationOwner, GenerationOwnerContact, MailingAddress } from "src/app/shared/models/generationowner";
import { GenerationOwnerManagerService } from "../services/generation-owner-manager.service";
import { GeneralUtilities } from "src/app/shared/utilities/general";

@Component({
  selector: "app-add-generation-owner",
  templateUrl: "./add-generation-owner.component.html",
  styleUrls: ["./add-generation-owner.component.scss"],
})
export class AddGenerationOwnerComponent implements OnInit {
  constructor(private fb: FormBuilder, private authService: AuthService,
    private _generationOwnerMgrService: GenerationOwnerManagerService) {
    this.authService.getUser().then((res) => {
      this.currentUser = res?.profile;
    });
  }
  addGenOwnerForm: FormGroup;
  showAmerenDERContact: boolean = true;
  showExistingSubscriberContact: boolean;
  functionalityAccess: FineGrainAuthorization;
  owner: GenerationOwner;
  currentUser: any;
  isSaveLoading: boolean;  
  isSaveSuccess: boolean;
  responseMsg: string;  
  saveActionComplete: boolean;
  
  ngOnInit(): void {
    this.initAddGenOwnerForm();

    let role = this.authService.getSelectedGenerationOwnerRole();
    let functionality = "GENERATION_OWNERS"
          
    this.functionalityAccess = this.authService.getPortalFunctioanalityAccesses(functionality, role);   

    this.addGenOwnerForm
      ?.get("amerenDERContact")
      ?.get("sameContact")
      ?.valueChanges.subscribe((val) => {
        if (val) {
          (
            this.addGenOwnerForm.get("existingSubscriberContact") as FormGroup
          ).patchValue(this.addGenOwnerForm?.get("amerenDERContact")?.value);
        } else {
          (
            this.addGenOwnerForm.get("existingSubscriberContact") as FormGroup
          ).reset();
        }
      });

    this.addGenOwnerForm
      ?.get("existingSubscriberContact")
      ?.get("emailAddress")
      ?.valueChanges.subscribe((val) => {
        if (
          val !=
          this.addGenOwnerForm
            ?.get("existingSubscriberContact")
            ?.get("confirmEmailAddress")?.value
        ) {
          this.addGenOwnerForm
            ?.get("existingSubscriberContact")
            ?.get("confirmEmailAddress")
            ?.setErrors({ mustMatch: "Emails must match" });
        }
      });

    this.addGenOwnerForm
      ?.get("existingSubscriberContact")
      ?.get("confirmEmailAddress")
      ?.valueChanges.subscribe((val) => {
        if (
          val !=
          this.addGenOwnerForm
            ?.get("existingSubscriberContact")
            ?.get("emailAddress")?.value
        ) {
          this.addGenOwnerForm
            ?.get("existingSubscriberContact")
            ?.get("confirmEmailAddress")
            ?.setErrors({ mustMatch: "Emails must match" });
        }
      });

    this.addGenOwnerForm.get('amerenDERContact')?.get('country')?.valueChanges.subscribe((val) => {
      if (val){
        this.addGenOwnerForm.get('amerenDERContact')?.get('address')?.addValidators(Validators.required);
        this.addGenOwnerForm.get('amerenDERContact')?.get('city')?.setValidators(Validators.required);
        this.addGenOwnerForm.get('amerenDERContact')?.get('state')?.setValidators(Validators.required);
        this.addGenOwnerForm.get('amerenDERContact')?.get('zip')?.addValidators(Validators.required);
      }
      this.addGenOwnerForm.get('amerenDERContact')?.get('address')?.updateValueAndValidity();
      this.addGenOwnerForm.get('amerenDERContact')?.get('city')?.updateValueAndValidity();
      this.addGenOwnerForm.get('amerenDERContact')?.get('state')?.updateValueAndValidity();
      this.addGenOwnerForm.get('amerenDERContact')?.get('zip')?.updateValueAndValidity();
    });

    this.addGenOwnerForm.get('existingSubscriberContact')?.get('country')?.valueChanges.subscribe((val) => {
      if (val){
        this.addGenOwnerForm.get('existingSubscriberContact')?.get('address')?.addValidators(Validators.required);
        this.addGenOwnerForm.get('existingSubscriberContact')?.get('city')?.setValidators(Validators.required);
        this.addGenOwnerForm.get('existingSubscriberContact')?.get('state')?.setValidators(Validators.required);
        this.addGenOwnerForm.get('existingSubscriberContact')?.get('zip')?.addValidators(Validators.required);
      }
      this.addGenOwnerForm.get('existingSubscriberContact')?.get('address')?.updateValueAndValidity();
      this.addGenOwnerForm.get('existingSubscriberContact')?.get('city')?.updateValueAndValidity();
      this.addGenOwnerForm.get('existingSubscriberContact')?.get('state')?.updateValueAndValidity();
      this.addGenOwnerForm.get('existingSubscriberContact')?.get('zip')?.updateValueAndValidity();
    });
  }

  initAddGenOwnerForm() {
    this.addGenOwnerForm = this.fb.group(
      {
        generationOwnerName: new FormControl(null, [Validators.required]),
        type: new FormControl(null, [Validators.required]),
        amerenDERContact: new FormGroup({
          sameContact: new FormControl(),
          contactPerson: new FormControl(null, [Validators.required]),
          emailAddress: new FormControl(null, [
            Validators.required,
            Validators.email,
          ]),
          confirmEmailAddress: new FormControl(null, [Validators.required]),
          phoneNumber: new FormControl(null, [
            Validators.required,
            Validators.pattern("^[0-9]{3}-[0-9]{3}-[0-9]{4}$"),
          ]),
          phoneExt: new FormControl(null),
          country: new FormControl(null),
          otherCountry: new FormControl(null),
          address: new FormControl(null, [Validators.pattern("^([a-zA-Z0-9/ -]+)$")]),
          address2: new FormControl(null),
          city: new FormControl(null),
          state: new FormControl(null),
          zip: new FormControl(null, [Validators.pattern("^[0-9]{5}$|^[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9]$")]),
        }),

        existingSubscriberContact: new FormGroup({
          contactPerson: new FormControl(null, [Validators.required]),
          emailAddress: new FormControl(null, [
            Validators.required,
            Validators.email,
          ]),
          confirmEmailAddress: new FormControl(null, [Validators.required]),
          phoneNumber: new FormControl(null, [
            Validators.required,
            Validators.pattern("^[0-9]{3}-[0-9]{3}-[0-9]{4}$"),
          ]),
          phoneExt: new FormControl(null),
          country: new FormControl(null),
          otherCountry: new FormControl(null),
          address: new FormControl(null, [Validators.pattern("^([a-zA-Z0-9/ -]+)$")]),
          address2: new FormControl(null),
          city: new FormControl(null),
          state: new FormControl(null),
          zip: new FormControl(null, [Validators.pattern("^[0-9]{5}$|^[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9]$")]),
        }),
      },
      {
        validator: MustMatch(
          "amerenDERContact.emailAddress",
          "amerenDERContact.confirmEmailAddress"
        ),
      } as AbstractControlOptions
    );
  }

  onSaveClick() {
    this.isSaveLoading = true;
    let formVal = this.addGenOwnerForm.value;
    this.owner= new GenerationOwner();
    this.owner.name = formVal.generationOwnerName;    
    this.owner.ownerId = '';
    this.owner.ownerType = formVal.type == 'Off-site' ? 'O' : '3';
    this.owner.contacts = [];      
    
    let derContact : GenerationOwnerContact = new GenerationOwnerContact();
    derContact.contactType = "01";
    derContact.contactPerson = formVal.amerenDERContact.contactPerson;
    derContact.email = formVal.amerenDERContact.emailAddress;    
    derContact.contactPhone = GeneralUtilities.formatPhone(formVal.amerenDERContact.phoneNumber);
    derContact.contactPhoneExt = formVal.amerenDERContact.phoneExt ? formVal.amerenDERContact.phoneExt : '';
    let ownerAddress : MailingAddress = new MailingAddress();    
    if (formVal.amerenDERContact.country != null){
      ownerAddress.addressLine1 = formVal.amerenDERContact.address;
      ownerAddress.addressLine2 = formVal.amerenDERContact.address2 ? formVal.amerenDERContact.address2 : '';
      ownerAddress.city = formVal.amerenDERContact.city;
      ownerAddress.state = formVal.amerenDERContact.state;
      ownerAddress.postalCode = formVal.amerenDERContact.zip.replace(' ','');
      ownerAddress.country = formVal.amerenDERContact.country;      
    }
    derContact.mailingAddress = ownerAddress;    
    this.owner.contacts.push(derContact);
    
    let subContact : GenerationOwnerContact = new GenerationOwnerContact();
    subContact.contactType = "09";
    subContact.contactPerson = formVal.existingSubscriberContact.contactPerson;
    subContact.email = formVal.existingSubscriberContact.emailAddress;    
    subContact.contactPhone = GeneralUtilities.formatPhone(formVal.existingSubscriberContact.phoneNumber);
    subContact.contactPhoneExt = formVal.existingSubscriberContact.phoneExt ? formVal.existingSubscriberContact.phoneExt : '';
    ownerAddress = new MailingAddress();
    if (formVal.existingSubscriberContact.country != null){
      ownerAddress.addressLine1 = formVal.existingSubscriberContact.address;
      ownerAddress.addressLine2 = formVal.existingSubscriberContact.address2 ? formVal.amerenDERContact.address2 : '';
      ownerAddress.city = formVal.existingSubscriberContact.city;
      ownerAddress.state = formVal.existingSubscriberContact.state;
      ownerAddress.postalCode = formVal.existingSubscriberContact.zip.replace(' ','');
      ownerAddress.country = formVal.existingSubscriberContact.country;      
    }
    subContact.mailingAddress = ownerAddress;    
    this.owner.contacts.push(subContact);
    
    this._generationOwnerMgrService.addGenerationOwner(this.owner, this.currentUser?.email)
    .subscribe({
      next: (resp) => {
        console.log(resp);
        this.isSaveLoading = false;
        this.isSaveSuccess = true;        
        this.responseMsg = 'Generation owner added successfully. ' + resp.successMessage;
      },
      error: (err) => {
        console.log(err);
        this.isSaveLoading = false;
        this.isSaveSuccess = false;
        if (err.error.errorDetails.message.startsWith('Internal Error'))
          this.responseMsg = "Failed to add Generation Owner. ";
        else
          this.responseMsg = "Failed to add Generation Owner. " + err.error.errorDetails.message;
      }
    });
  }

  toggleAmerenDERContact() {
    this.showAmerenDERContact = !this.showAmerenDERContact;
  }
  toggleExistingSubscriberContact() {
    this.showExistingSubscriberContact = !this.showExistingSubscriberContact;
  }

  closeSaveModal() {
    if (this.isSaveSuccess) this.saveActionComplete = true;
  }
}
