<div class="manage_user_access_container p-1 ms-4">
  <div class="manageuseraccesss">Manage Users Access</div>
  <form>
    <div class="mt-4"></div>
    <div>
      <div class="row rectangle-content">
        <div class="row mt-1 ms-3" *ngIf="!isLoading && !isUserAccessChanged">
          <em>
            Note: Please select a user from dropdown, to manage selected user's
            access.
          </em>
        </div>
        <div class="row"></div>
        <form [formGroup]="searchForm">
          <div class="col-lg-12 form-group mt-1 ms-3" id="accountNumbertext">
            <div class="row">
              <div class="form-group col-md-12 pe-4" *ngIf="!isLoading">
                <div class="mt-2 d-flex" *ngIf="!isUserAccessChanged" >
                  <label class="label_titles col-md-1 ml-1 mt-1 break_content"
                    >Select User</label
                  >
                  <ng-select
                    class="mb-4 col-md-4 col-lg-4 mx-6 break_content"
                    id="selectedUserId"
                    [items]="UserList"
                    [selectOnTab]="true"
                    bindValue="userName"
                    bindLabel="fullName"
                    (ngModelChange)="onSearch()"
                    placeholder="Select User"
                    formControlName="selectedUserId"                                      
                  >
                  </ng-select>
                </div>
              </div>
              <div *ngIf="isLoading" class="justify-content-center text-center">
                <ameren-loading
                  id="loadingIndicator"
                  class="d-flex justify-content-center"
                ></ameren-loading>
                <p>Please wait...</p>
              </div>
              <div
                *ngIf="isAPIFailure && !isLoading"
                class="mt-3 alert alert-danger justify-content-center text-center"
              >
                {{ integrationErrorMsg }}
              </div>
            </div>
          </div>
        </form>
        <div class="mt-1"></div>
        <div
          *ngIf="!isAPIFailure && !isLoading && selectedUser"
          class="anm__accounts-container mt-2"
        >
          <div class="flex-container_1 col-md-12 d-flex row">
            <div class="col-md-4 mb-3 mt-1 ms-3">
              <span class="pr-20 text-bold break_content">User Name: </span
              ><span class="pr-20 label-color">{{
                selectedUser?.givenName + ' ' + selectedUser?.sn
              }}</span>
            </div>

            <div class="col-md-4 mb-3 mt-1 ms-3">
              <span class="pr-20 text-bold break_content">Email: </span
              ><span class="pr-20 label-color">{{
                selectedUser?.userName
              }}</span>
            </div>

            <div class="d-flex justify-content-end ms-3 mx-4">
              <div class="mx-2">
                <button
                  class="btn ameren-primary-green-btn mb-3"
                  (click)="onOpennewUserAccess(false)"
                >
                  <span class="fa fa-add"></span> Add Access
                </button>
              </div>
              <div class="ms-2">
                <button
                  class="btn ameren-primary-red-btn mb-3"
                  [disabled]="selectedAccountsforDelete.length <= 0"
                  (click)="onDeleteUsersAccess(true)"
                >
                  <span class="fa fa-remove"></span> Remove Access
                </button>
              </div>
            </div>
          </div>

          <div class="anm__accounts-list">
            <table
              id="anmaccounts"
              aria-describedby="User Access Details"
              class="align-middle bg-white"
            >
              <thead class="anm__accounts__table-header">
                <tr>
                  <th scope="col"></th>
                  <th scope="col">
                    <div class="flex-container_1 col-md-8">
                      <div class="flex-child1">Generation Owner</div>
                    </div>
                  </th>
                  <th scope="col">
                    <div class="flex-container_1 col-md-8">
                      <div class="flex-child1">Role</div>
                    </div>
                  </th>
                  <th scope="col">
                    <div class="flex-container_1 col-md-8">
                      <div class="flex-child1">Status</div>
                    </div>
                  </th>
                  <th scope="col">
                    <div class="flex-container_1 col-md-8">
                      <div class="flex-child1">Rebate Approval Level</div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let UserAccount of revisedUserAccounts; let i = index"
                >
                  <td>
                    <input
                      class="select__Account-checkbox"
                      type="checkbox"
                      [attr.data-UserAccount]="UserAccount.generationOwnerId"
                      [attr.data-role]="UserAccount.roleId"
                      (click)="selectUserAccounts($event, i)"
                    />
                  </td>
                  <td>
                    <div class="d-flex align-items-center">
                      <p class="fw-normal mb-1">
                        {{ UserAccount?.generationOwner }}
                      </p>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex align-items-center">
                      <p class="fw-normal mb-1">{{ UserAccount?.role }}</p>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex align-items-center">
                      <p class="fw-normal mb-1">{{ UserAccount?.status }}</p>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex align-items-center">
                      <p class="fw-normal mb-1">
                        {{
                          rebateApprovalLevelMapping[
                            UserAccount?.anmRebateApprovalLevel
                          ]
                        }}
                      </p>
                    </div>
                  </td>
                </tr>
                <tr
                  *ngIf="
                    !isLoading && (isAPIFailure || UserAccounts?.length == 0)
                  "
                  class="mt-3 alert alert-danger"
                >
                  <td colspan="5">No User access available to view.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="flex-container_1 col-md-12">
            <div class="justify-content-center text-center d-flex mt-2" *ngIf="isUserAccessChanged && !isnewUserAccesssubmitting">
              <span class="required-asterisk justify-content-center mr-3">*</span>
              <label class="label-color justify-content-center"
              > Please click 'Save' to complete your request or 'Reset' to clear all your changes.            
            </label>
          </div>
           
        </div>

          <div class="flex-container_1 col-md-12">
          
            <div class="justify-content-center text-center d-flex mt-2">
              <button
                class="btn ameren-primary-green-btn mb-3 justify-content-center"
                [disabled]="!isUserAccessChanged || isnewUserAccesssubmitting"
                (click)="onUpdateUsersAccess()"
              >
                Save
              </button>
              <button
              class="btn ameren-tertiary-green-btn mb-3 ml-3 justify-content-center"
              *ngIf="isUserAccessChanged && !isnewUserAccesssubmitting"
              (click)="onResetUsersAccess()"
            >
              Reset
            </button>
            </div>
            <div class="mt-3 d-flex justify-content-center text-center ml-3">
              <div
                *ngIf="isnewUserAccesssubmitting"
                class="d-flex flex-row" 
              >
                <p class="label-color">Updating User Access! Please wait...</p>
                <ameren-loading
                  id="loadingIndicator"
                  class="d-flex justify-content-center"
                ></ameren-loading>
              </div>
              <div
                *ngIf="isAPIFailure && !isnewUserAccesssubmitting"
                class="mt-3 alert alert-danger"
              >
                {{ integrationErrorMsg }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div
  class="modal fade modal-center"
  id="addnewAccess"
  tabindex="-1"
  aria-labelledby="addnewAccessLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" id="addnewAccessDialog">
    <div class="modal-content" id="addnewAccessContent">
      <div class="pop-header row adjustHeader" id="editStatusModelHeader">
        <span
          class="modal-title modal-edit mb-0"
          id="addnewAccessLabel"
          *ngIf="!isthisDeleteProcess"
          style="width: 90%"
        >
          Add new user acccess</span
        >
        <span
          class="modal-title modal-edit mb-0"
          id="addnewAccessLabel"
          *ngIf="isthisDeleteProcess"
          style="width: 90%"
        >
          Delete User Access?</span
        >
        <button
          type="button"
          class="btn-close text-right ml-auto mr-2"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="onCancel()"
        ></button>
      </div>
      <hr />
      <div class="modal-body">
        <div class="flex-container_1 col-md-12">
          <div class="col-md-6 mt-2 mb-3">
            <span class="pr-20 text-bold break_content">User Name: </span
            ><span class="pr-20 label-color">{{
              selectedUser?.givenName + ' ' + selectedUser?.sn
            }}</span>
          </div>
          <div class="col-md-6 mt-2 mb-3">
            <span class="pr-20 text-bold break_content">Email: </span
            ><span class="pr-20 label-color">{{ selectedUser?.userName }}</span>
          </div>
        </div>

        <div class="mt-3 flex-container_1" *ngIf="!isthisDeleteProcess">
          <label class="col-md-4 text-bold">Role: </label><br />
          <select
            [(ngModel)]="role"
            class="role-dropdown"
            (ngModelChange)="onSelectedRoleChange($event)"
          >
            <option [value]="">Select Role</option>
            <option *ngFor="let opt of roles; let i = index" [value]="opt">
              {{ roleOptions[opt] }}
            </option>
          </select>
        </div>

        <div
          class="mt-3 flex-container_1"
          *ngIf="!isthisDeleteProcess && showGenOwnerOption"
        >
          <label class="col-md-4 text-bold">Generation Owner: </label><br />

          <select
            [(ngModel)]="generationOwner"
            class="role-dropdown"
            (ngModelChange)="onSelectedOwnerChange($event)"
          >
            <option [value]="">Select Generation Owner</option>
            <option
              *ngFor="let owners of ownerList; let i = index"
              [value]="owners.ownerId"
            >
              {{ owners.name }} ({{ owners.ownerId }})
            </option>
          </select>
        </div>
        <div
          class="mt-3 flex-container_1"
          *ngIf="!isthisDeleteProcess && showRebateLevelOption"
        >
          <label class="col-md-4 text-bold">Rebate Approval Level: </label
          ><br />

          <select
            [(ngModel)]="rebateApproverLevel"
            class="role-dropdown"
            (ngModelChange)="onSelectedApprovalLevelChange($event)"
          >
            <option [value]="">Select Approval Level</option>
            <option
              *ngFor="let lvl of rebateApprovalLevelOptions; let i = index"
              [value]="lvl"
            >
              {{ rebateApprovalLevelMapping[lvl] }}
            </option>
          </select>
        </div>

        <div class="anm__deleteAccounts-list" *ngIf="isthisDeleteProcess">
          <table
            id="anmdeleteAccounts"
            aria-describedby="Confirm delete User Access "
            class="align-middle bg-white"
          >
            <thead class="anm__accounts__table-header">
              <tr>
                <th scope="col">
                  <div class="flex-container_1 col-md-8">
                    <div class="flex-child1 col-md-3">Generation Owner</div>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex-container_1 col-md-8">
                    <div class="flex-child1 col-md-3">Role</div>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex-container_1 col-md-8">
                    <div class="flex-child1 col-md-3">Status</div>
                  </div>
                </th>
                <th scope="col">
                  <div class="flex-container_1 col-md-8">
                    <div class="flex-child1 col-md-3">
                      Rebate Approval Level
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let UserAccount of selectedAccountsforDelete">
                <td>
                  <div class="d-flex align-items-center">
                    <p class="fw-normal mb-1">
                      {{ UserAccount?.generationOwner }}
                    </p>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <p class="fw-normal mb-1">{{ UserAccount?.role }}</p>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <p class="fw-normal mb-1">{{ UserAccount?.status }}</p>
                  </div>
                </td>
                <td>
                  <div class="d-flex align-items-center">
                    <p class="fw-normal mb-1">
                      {{  rebateApprovalLevelMapping[
                        UserAccount?.anmRebateApprovalLevel
                      ] }}
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          *ngIf="isnewAcctAPIFailure"
          class="mt-3 alert alert-danger"
          role="alert"
        >
          {{ integrationnewUserAccessErrorMsg }}
        </div>

        <div class="row mb-3 mt-5 justify-content-center">
          <div class="col-md-3 text-right">
            <button class="btn btn-outline-success w-100" (click)="onCancel()">
              Cancel
            </button>
          </div>
          <div class="col-md-4">
            <button
              class="btn btn-success btn-update w-100"
              [disabled]="!isAddUserformValid"
              *ngIf="!isthisDeleteProcess"
              (click)="onAddUserAccessSubmit()"
            >
              Add New User Access
            </button>
            <button
              class="btn ameren-primary-red-btn w-100"
              *ngIf="isthisDeleteProcess"
              (click)="onDeleteUserAccessSubmit()"
            >
              Delete User Access
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade modal-center"
  id="addnewAccesstMsgPopup"
  tabindex="-1"
  aria-labelledby="addnewAccesstMsgPopup"
  aria-hidden="true"
>
  <div class="modal-dialog" id="addnewAccesstMsgPopup">
    <div class="modal-content" id="addMsgContent">
      <div class="pop-header row adjustHeader" id="addMsgModelHeader">
        <span
          class="modal-title modal-edit mb-0"
          id="eidtStatusLabel"
          style="width: 90%"
          *ngIf="!isthisDeleteProcess && !isnewUserAccesssubmitting"
          >Add New user access</span
        >
        <span
          class="modal-title modal-edit mb-0"
          id="deleteStatusLabel"
          style="width: 90%"
          *ngIf="isthisDeleteProcess && !isnewUserAccesssubmitting"
          >Delete User Access</span
        >
        <span
          class="modal-title modal-edit mb-0"
          id="updateStatusLabel"
          style="width: 90%"
          *ngIf="isnewUserAccesssubmitting && !isthisDeleteProcess"
          >Update User Access</span
        >
        <button
          type="button"
          class="btn-close text-right ml-auto mr-2"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <hr />
      <div class="modal-body text-center">
        <div class="row text-center">
          <div class="col-md-12">
            <img src="../../../assets/images/success.svg" alt="success" />
            <p
              class="mt-2 modal-edit"
              *ngIf="!isthisDeleteProcess && !isnewUserAccesssubmitting"
            >
              New user access added successfully.
            </p>
            <p
              class="mt-2 modal-edit"
              *ngIf="isthisDeleteProcess && !isnewUserAccesssubmitting"
            >
              User access deleted successfully.
            </p>
            <p
              class="mt-2 modal-edit"
              *ngIf="isnewUserAccesssubmitting && !isthisDeleteProcess"
            >
              User access updated successfully.
            </p>

            <p
              class="mt-2 modal-edit label-color"
              *ngIf="isthisDeleteProcess || !isnewUserAccesssubmitting"
            >
              Please click "Save" to complete your request.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
