<div class="add-generation-owner-container">
  <div class="gen_owner_header">Add Generation Owner</div>

  <div class="container centered-form">
    <form [formGroup]="addGenOwnerForm">
      <div class="mb-3" id="genOwner_container">
        <label for="generationOwnerName" class="form-label"
          >Generation Owner Name:*</label
        >
        <input
          type="text"
          class="form-control"
          formControlName="generationOwnerName"
        />
        <div
          class="field-validation-error"
          *ngIf="
            addGenOwnerForm.get('generationOwnerName')?.invalid &&
            addGenOwnerForm.get('generationOwnerName')?.dirty
          "
        >
          Generation Owner Name is required.
        </div>
      </div>
      <div class="mb-3" id="type_container">
        <label for="formControlName" class="form-label">Type:*</label>
        <select
          class="form-control form-select gen_owner_type"
          formControlName="type"
        >
          <option>Off-site</option>
          <option>3rd Party</option>
        </select>
        <div
          class="field-validation-error"
          *ngIf="
            addGenOwnerForm.get('type')?.invalid &&
            addGenOwnerForm.get('type')?.dirty
          "
        >
          Type is required.
        </div>
      </div>

      <div class="card border" formGroupName="amerenDERContact">
        <div class="card-header" (click)="toggleAmerenDERContact()">
          Contact for Ameren Distributed Energy Resources - Required

          <span type="button" class="add_gen_owner_accordian_btn">{{
            showAmerenDERContact ? "-" : "+"
          }}</span>
        </div>
        <div class="card-body" *ngIf="showAmerenDERContact">
          <em>
            The following contact information will be used by Ameren to contact
            you
          </em>
          <div class="mb-3 mt-3" id="contact_person_container">
            <label for="contactPerson" class="form-label"
              >Contact Person:*</label
            >
            <input
              type="text"
              class="form-control"
              formControlName="contactPerson"
            />
            <div
              class="field-validation-error"
              *ngIf="
                addGenOwnerForm.get('amerenDERContact')?.get('contactPerson')
                  ?.invalid &&
                addGenOwnerForm.get('amerenDERContact')?.get('contactPerson')
                  ?.dirty
              "
            >
              Contact Person is required.
            </div>
          </div>
          <div class="mb-3">
            <div class="row">
              <div class="col-md-6" id="email_container">
                <label for="emailAddress" class="form-label"
                  >Email Address:*</label
                >
                <input
                  type="text"
                  class="form-control"
                  formControlName="emailAddress"
                />
                <div
                  class="field-validation-error"
                  *ngIf="
                    addGenOwnerForm.get('amerenDERContact')?.get('emailAddress')
                      ?.invalid &&
                    addGenOwnerForm.get('amerenDERContact')?.get('emailAddress')
                      ?.dirty
                  "
                >
                  Please enter a valid email address.
                </div>
              </div>
              <div class="col-md-6" id="confirm_email_container">
                <label for="confirmEmailAddress" class="form-label"
                  >Confirm Email Address:*</label
                >
                <input
                  type="text"
                  class="form-control"
                  formControlName="confirmEmailAddress"
                />
                <div
                  class="field-validation-error"
                  *ngIf="
                    addGenOwnerForm
                      .get('amerenDERContact')
                      ?.get('confirmEmailAddress')?.invalid &&
                    addGenOwnerForm
                      .get('amerenDERContact')
                      ?.get('confirmEmailAddress')?.dirty
                  "
                >
                  <p
                    *ngIf="addGenOwnerForm?.get('amerenDERContact')?.get('confirmEmailAddress')?.errors?.['required']"
                  >
                    Confirm Email Address is required.
                  </p>
                  <p
                    *ngIf="addGenOwnerForm?.get('amerenDERContact')?.get('confirmEmailAddress')?.errors?.['mustMatch']"
                  >
                    Email Addresses must match.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <div class="row">
              <div class="col-md-9">
                <label for="phoneNumber" id="phn_container" class="form-label"
                  >Phone Number:*</label
                >
                <input
                  amerenInputMask="999-999-9999"
                  type="text"
                  class="form-control"
                  formControlName="phoneNumber"
                />
                <div
                  class="field-validation-error"
                  *ngIf="
                    addGenOwnerForm.get('amerenDERContact')?.get('phoneNumber')
                      ?.invalid &&
                    addGenOwnerForm.get('amerenDERContact')?.get('phoneNumber')
                      ?.dirty
                  "
                >
                  <p
                    *ngIf="addGenOwnerForm?.get('amerenDERContact')?.get('phoneNumber')?.errors?.['required']"
                  >
                    Phone Number is required.
                  </p>
                  <p
                    *ngIf="addGenOwnerForm?.get('amerenDERContact')?.get('phoneNumber')?.errors?.['pattern']"
                  >
                    Invalid Phone Number.
                  </p>
                </div>
              </div>
              <div class="col-md-3" id="phnExt_container">
                <label for="phoneExt" class="form-label">Ext:</label>
                <input
                  type="text"
                  inputmode="numeric"
                  class="form-control"
                  formControlName="phoneExt"
                />
              </div>
            </div>
          </div>
          <h5 class="add_gen_owner_address_header">Mailing Address:</h5>
          <div class="mb-3" id="country_container">
            <label for="country" class="form-label">Country</label>
            <select class="form-control form-select" formControlName="country">
              <option>USA</option>
              <option>CANADA</option>
              <option>OTHER</option>
            </select>
            <div
              class="field-validation-error"
              *ngIf="
                addGenOwnerForm.get('amerenDERContact')?.get('country')
                  ?.invalid &&
                addGenOwnerForm.get('amerenDERContact')?.get('country')?.dirty
              "
            >
              Country is required.
            </div>
          </div>
          <div
            class="mb-3"
            id="other_country_container"
            *ngIf="
              addGenOwnerForm.get('amerenDERContact')?.get('country')?.value ==
              'Other'
            "
          >
            <label for="country" class="form-label">Country Name</label>
            <input
              type="text"
              class="form-control"
              formControlName="otherCountry"
            />
          </div>
          <div class="mb-3" id="address_container">
            <label for="address" class="form-label">Address:</label>
            <input type="text" class="form-control" formControlName="address" maxlength="28" />
            <div
              class="field-validation-error"
              *ngIf="
                addGenOwnerForm.get('amerenDERContact')?.get('address')
                  ?.invalid &&
                addGenOwnerForm.get('amerenDERContact')?.get('address')?.dirty
              "
            >
              Address is invalid.
            </div>
          </div>
          <div class="mb-3">
            <input
              type="text"
              class="form-control"
              formControlName="address2" maxlength="28"
            />
          </div>
          <div class="mb-3" id="city_state_container">
            <div class="row">
              <div class="col-md-4">
                <label for="city" class="form-label">City</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="city" maxlength="18"
                />
                <div
                  class="field-validation-error"
                  *ngIf="
                    addGenOwnerForm.get('amerenDERContact')?.get('city')
                      ?.invalid &&
                    addGenOwnerForm.get('amerenDERContact')?.get('city')?.dirty
                  "
                >
                  City is required.
                </div>
              </div>
              <div class="col-md-4" id="state_container">
                <label for="state" class="form-label">State/Province</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="state" maxlength="2"
                />
                <div
                  class="field-validation-error"
                  *ngIf="
                    addGenOwnerForm.get('amerenDERContact')?.get('state')
                      ?.invalid &&
                    addGenOwnerForm.get('amerenDERContact')?.get('state')?.dirty
                  "
                >
                  State is required.
                </div>
              </div>
              <div class="col-md-4" id="zip_container">
                <label for="zip" class="form-label">Zip Code</label>
                <input type="text" class="form-control" formControlName="zip" maxlength="7" />
                <div
                  class="field-validation-error"
                  *ngIf="
                    addGenOwnerForm.get('amerenDERContact')?.get('zip')
                      ?.invalid &&
                    addGenOwnerForm.get('amerenDERContact')?.get('zip')?.dirty
                  "
                >
                  Zip Code is invalid.
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3" id="same_contact_chk">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                formControlName="sameContact"
                id="sameContact"
              />
              <label class="form-check-label" for="sameContact">
                Check this box if contact for Ameren DER and contact for
                subscribers are the same.
              </label>
              <em
                >* Contact for subscribers will be used by Ameren to forward
                existing customers to generation owners.</em
              >
            </div>
          </div>
        </div>
      </div>
      <div class="card border mt-3" formGroupName="existingSubscriberContact">
        <div class="card-header" (click)="toggleExistingSubscriberContact()">
          Contact for Subscribers - Required
          <span type="button" class="add_gen_owner_accordian_btn">{{
            showExistingSubscriberContact ? "-" : "+"
          }}</span>
        </div>
        <div class="card-body" *ngIf="showExistingSubscriberContact">
          <em>
            The following contact information will be used by Ameren to forward
            existing customers to generation owners.
          </em>
          <div
            class="mb-3 mt-3"
            id="existingSubscriberContact_contact_person_container"
          >
            <label for="contactPerson" class="form-label"
              >Contact Person:*</label
            >
            <input
              type="text"
              class="form-control"
              formControlName="contactPerson"
            />
            <div
              class="field-validation-error"
              *ngIf="
                addGenOwnerForm
                  .get('existingSubscriberContact')
                  ?.get('contactPerson')?.invalid &&
                addGenOwnerForm
                  .get('existingSubscriberContact')
                  ?.get('contactPerson')?.dirty
              "
            >
              Contact Person is required.
            </div>
          </div>
          <div class="mb-3">
            <div class="row">
              <div
                class="col-md-6"
                id="existingSubscriberContact_email_container"
              >
                <label for="emailAddress" class="form-label"
                  >Email Address:*</label
                >
                <input
                  type="text"
                  class="form-control"
                  formControlName="emailAddress"
                />
                <div
                  class="field-validation-error"
                  *ngIf="
                    addGenOwnerForm
                      .get('existingSubscriberContact')
                      ?.get('emailAddress')?.invalid &&
                    addGenOwnerForm
                      .get('existingSubscriberContact')
                      ?.get('emailAddress')?.dirty
                  "
                >
                  Please enter a valid email address.
                </div>
              </div>
              <div
                class="col-md-6"
                id="existingSubscriberContact_confirm_email_container"
              >
                <label for="confirmEmailAddress" class="form-label"
                  >Confirm Email Address:*</label
                >
                <input
                  type="text"
                  class="form-control"
                  formControlName="confirmEmailAddress"
                />
                <div
                  class="field-validation-error"
                  *ngIf="
                    addGenOwnerForm
                      .get('existingSubscriberContact')
                      ?.get('confirmEmailAddress')?.invalid &&
                    addGenOwnerForm
                      .get('existingSubscriberContact')
                      ?.get('confirmEmailAddress')?.dirty
                  "
                >
                  <p
                    *ngIf="addGenOwnerForm?.get('existingSubscriberContact')?.get('confirmEmailAddress')?.errors?.['required']"
                  >
                    Confirm Email Address is required.
                  </p>
                  <p
                    *ngIf="addGenOwnerForm?.get('existingSubscriberContact')?.get('confirmEmailAddress')?.errors?.['mustMatch']"
                  >
                    Email Addresses must match.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <div class="row">
              <div class="col-md-9" id="existingSubscriberContact_phn_number">
                <label for="phoneNumber" id="phn_container" class="form-label"
                  >Phone Number:*</label
                >
                <input
                  amerenInputMask="999-999-9999"
                  type="text"
                  class="form-control"
                  formControlName="phoneNumber"
                />
                <div
                  class="field-validation-error"
                  *ngIf="
                    addGenOwnerForm
                      .get('existingSubscriberContact')
                      ?.get('phoneNumber')?.invalid &&
                    addGenOwnerForm
                      .get('existingSubscriberContact')
                      ?.get('phoneNumber')?.dirty
                  "
                >
                  <p
                    *ngIf="addGenOwnerForm?.get('existingSubscriberContact')?.get('phoneNumber')?.errors?.['required']"
                  >
                    Phone Number is required.
                  </p>
                  <p
                    *ngIf="addGenOwnerForm?.get('existingSubscriberContact')?.get('phoneNumber')?.errors?.['pattern']"
                  >
                    Invalid Phone Number.
                  </p>
                </div>
              </div>
              <div
                class="col-md-3"
                id="existingSubscriberContact_phnExt_container"
              >
                <label for="phoneExt" class="form-label">Ext:</label>
                <input
                  type="text"
                  inputmode="numeric"
                  class="form-control"
                  formControlName="phoneExt"
                />
              </div>
            </div>
          </div>
          <h5 class="add_gen_owner_address_header">Mailing Address:</h5>
          <div class="mb-3" id="existingSubscriberContact_country_container">
            <label for="country" class="form-label">Country</label>
            <select class="form-control form-select" formControlName="country">
              <option>USA</option>
              <option>CANADA</option>
              <option>OTHER</option>
            </select>
            <div
              class="field-validation-error"
              *ngIf="
                addGenOwnerForm.get('existingSubscriberContact')?.get('country')
                  ?.invalid &&
                addGenOwnerForm.get('existingSubscriberContact')?.get('country')
                  ?.dirty
              "
            >
              Country is required.
            </div>
          </div>
          <div
            class="mb-3"
            id="existing_other_country_container"
            *ngIf="
              addGenOwnerForm.get('existingSubscriberContact')?.get('country')
                ?.value == 'Other'
            "
          >
            <label for="country" class="form-label">Country Name</label>
            <input
              type="text"
              class="form-control"
              formControlName="otherCountry"
            />
          </div>
          <div class="mb-3" id="existingSubscriberContact_address_container">
            <label for="address" class="form-label">Address:</label>
            <input type="text" class="form-control" formControlName="address" maxlength="28" />
            <div
              class="field-validation-error"
              *ngIf="
                addGenOwnerForm.get('existingSubscriberContact')?.get('address')
                  ?.invalid &&
                addGenOwnerForm.get('existingSubscriberContact')?.get('address')
                  ?.dirty
              "
            >
              Address is invalid.
            </div>
          </div>
          <div class="mb-3" id="existingSubscriberContact_address2">
            <input
              type="text"
              class="form-control"
              formControlName="address2" maxlength="28"
            />
          </div>
          <div class="mb-3" id="existingSubscriberContact_city_container">
            <div class="row">
              <div class="col-md-4">
                <label for="city" class="form-label">City</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="city" maxlength="18"
                />
                <div
                  class="field-validation-error"
                  *ngIf="
                    addGenOwnerForm
                      .get('existingSubscriberContact')
                      ?.get('city')?.invalid &&
                    addGenOwnerForm
                      .get('existingSubscriberContact')
                      ?.get('city')?.dirty
                  "
                >
                  City is required.
                </div>
              </div>
              <div
                class="col-md-4"
                id="existingSubscriberContact_state_container"
              >
                <label for="state" class="form-label">State/Province</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="state" maxlength="2"
                />
                <div
                  class="field-validation-error"
                  *ngIf="
                    addGenOwnerForm
                      .get('existingSubscriberContact')
                      ?.get('state')?.invalid &&
                    addGenOwnerForm
                      .get('existingSubscriberContact')
                      ?.get('state')?.dirty
                  "
                >
                  State is required.
                </div>
              </div>
              <div
                class="col-md-4"
                id="existingSubscriberContact_zip_container"
              >
                <label for="zip" class="form-label">Zip Code</label>
                <input type="text" class="form-control" formControlName="zip" maxlength="7" />
                <div
                  class="field-validation-error"
                  *ngIf="
                    addGenOwnerForm.get('existingSubscriberContact')?.get('zip')
                      ?.invalid &&
                    addGenOwnerForm.get('existingSubscriberContact')?.get('zip')
                      ?.dirty
                  "
                >
                  Zip Code is invalid.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3 text-center">
        <a
          [routerLink]="['/generation-owner']"
          class="btn btn-default add_gen_owner_btn add_gen_owner_cancel_btn"
          >Cancel</a
        >
        <button data-bs-toggle="modal" data-bs-target="#addOwnerModal"
          type="button"
          class="btn btn-success add_gen_owner_btn ms-2"
          [disabled]="!addGenOwnerForm.valid || saveActionComplete"
          (click)="onSaveClick()"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</div>
<div class="modal fade" id="addOwnerModal" tabindex="-1" aria-labelledby="addOwnerModal" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header save_gen_owner_modal_header border-0">
        <h5 class="modal-title text-success save_gen_owner_modal_title" id="saveGenUnitModalLabel">
          Add Generation Owner
        </h5>
        <button type="button" (click)="closeSaveModal()" *ngIf="!isSaveLoading" class="btn-close gen_unit_modal_close"
          data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="remove_gen_unit_modal_content">
          <div *ngIf="isSaveLoading" class="text-center justify-content-center">
            <ameren-loading></ameren-loading>
            <p>Please wait..</p>
          </div>
          <div class="text-danger text-center justify-content-center" *ngIf="!isSaveLoading && !isSaveSuccess">
            <img src="../../../assets/images/failure.svg" alt="success" />
            <p class="mt-2">{{responseMsg}}</p>
          </div>
          <div class="text-success text-center justify-content-center" *ngIf="!isSaveLoading && isSaveSuccess">
            <img src="../../../assets/images/success.svg" alt="success" />
            <p class="mt-2">{{responseMsg}}</p>
          </div>
        </div>
      </div>      
    </div>
  </div>
</div>
