import { NgModule } from "@angular/core";

import { RebateRoutingModule } from "./rebate-routing.module";
import { ManageRebateComponent } from "./manage-rebate/manage-rebate.component";
import { SharedModule } from "src/app/shared/shared.module";
import { RebateDetailComponent } from './rebate-detail/rebate-detail.component';

@NgModule({
  declarations: [ManageRebateComponent, RebateDetailComponent],
  imports: [SharedModule, RebateRoutingModule],
  })
export class RebateModule {}
