import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuditTrailRoutingModule } from "./audit-trail-routing.module";
import { SharedModule } from "src/app/shared/shared.module";
import { ExportUtility } from "src/app/shared/utilities/utility.export";
import { AuditTrailApiService } from "./services/audit-trail-api.service";
import { AuditTrailManagerService } from "./services/audit-trail-manager.service";
import { AuditTrailComponent } from "./audit-trail.component";
import {MatCardModule} from '@angular/material/card';

@NgModule({
  declarations: [AuditTrailComponent],
  imports: [
    CommonModule,SharedModule, AuditTrailRoutingModule,MatCardModule
  ],
  providers: [
    ExportUtility,
    AuditTrailApiService,
    AuditTrailManagerService,
  ],
})
export class AuditTrailModule { }
