<div class="anm__users-container">
  <div
    class="justify-content-center text-center mt-4"
    *ngIf="isUsersLoading && !isDataExist"
  >
    <p>Loading users, Please wait...</p>
    <ameren-loading class="d-flex justify-content-center"></ameren-loading>
  </div>

  <mat-card>
    <mat-card-header class="row d-flex flex-row">
      <div class="col-md-2 Users_page_header">Users</div>
      <div class="col-md-3 users_search_container mb-3" *ngIf="!isUsersLoading">
        <input
          class="form-check-input"
          type="checkbox"
          value=""
          id="includeInactiveUsers"
          [checked]="includeInactiveUsers"
          (click)="onToggleActiveUsers()"
        />
        <label class="form-check-label"> Show Inactive Users </label>
      </div>
      <div *ngIf="isDataExist" class="col-md-3">
        <mat-form-field>
          <div class="d-flex flex-row">
            <input
              matInput
              (keyup)="applyFilter($event)"
              placeholder="Search"
            /><span><em class="fa fa-search"></em></span>
          </div>
        </mat-form-field>
      </div>
      <div class="col-md-3" *ngIf="isDataExist">
        <button class="btn ameren-tertiary-green-btn" (click)="exportUsers()">
          <img
            src="assets/icon/excelicon.svg"
            alt="CalendarImage"
            width="22"
            height="19"
          />
          Export
        </button>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div
        *ngIf="isUserAPIFailure"
        class="mt-3 alert alert-danger"
        role="alert"
      >
        {{ integrationErrorMsg }}
      </div>
      <table
        mat-table
        #outerSort="matSort"
        [dataSource]="dataSource"
        multiTemplateDataRows
        class="mat-elevation-z8"
        matSort
        matSortActive="anmAccountStatus"
        matSortDirection="desc"
        aria-describedby="Internal Users"
      >
        <ng-container matColumnDef="givenName">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="usertableHeader"
            mat-sort-header
          >
            First Name
          </th>
          <td mat-cell *matCellDef="let element" class="usertableContent">
            {{ element.givenName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="sn">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="usertableHeader"
            mat-sort-header
          >
            Last Name
          </th>
          <td mat-cell *matCellDef="let element" class="usertableContent">
            {{ element.sn }}
          </td>
        </ng-container>
        <ng-container matColumnDef="userName">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="usertableHeader"
            mat-sort-header
          >
            Email
          </th>
          <td mat-cell *matCellDef="let element" class="usertableContent">
            {{ element.userName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="anmAccountStatus">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="usertableHeader"
            mat-sort-header
          >
            User Status
          </th>
          <td mat-cell *matCellDef="let element" class="usertableContent">
            <span>
              <h3
                class="usertableContent"
                [ngStyle]="{
                  color:
                    element.anmAccountStatus === 'Pending'
                      ? 'Orange'
                      : element.anmAccountStatus === 'Active'
                      ? 'Green'
                      : 'Red'
                }"
              >
                {{ element.anmAccountStatus }}
              </h3>
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="usertableHeader">
            Active/Inactive
          </th>
          <td mat-cell *matCellDef="let element" class="userActiontableContent">
            <mat-button-toggle-group
              name="anmAccountStatus"
              #toggleBtn="matButtonToggleGroup"
              class="d-flex flex-row"
              *ngIf="
                element.anmAccountStatus != 'Pending' &&
                functionalityAccess.isAdmin
              "
            >
              <mat-button-toggle
                *ngIf="element.anmAccountStatus == 'Active'"
                value="Active"
                class="btn toggleButton toggleButtonActive"
              >
                Active
              </mat-button-toggle>
              <mat-button-toggle
                *ngIf="element.anmAccountStatus == 'Inactive'"
                value="Active"
                class="btn toggleButton toggleButtonActiveDisabled"
                (click)="activateInactivateUser('Active', element.userName)"
              >
                Active
              </mat-button-toggle>
              <mat-button-toggle
                *ngIf="element.anmAccountStatus == 'Inactive'"
                value="Inactive"
                class="btn toggleButton toggleButtonInactive"
              >
                Inactive
              </mat-button-toggle>
              <mat-button-toggle
                *ngIf="element.anmAccountStatus == 'Active'"
                value="Inactive"
                class="btn toggleButton toggleButtonInactiveDisabled"
                (click)="activateInactivateUser('Inactive', element.userName)"
              >
                Inactive
              </mat-button-toggle>
            </mat-button-toggle-group>
          </td>
        </ng-container>

        <ng-container matColumnDef="toggleRows">
          <th mat-header-cell *matHeaderCellDef class="usertableHeader"></th>
          <td mat-cell *matCellDef="let element">
            <span
              *ngIf="
                element.anmRoleMap?.length &&
                element.anmAccountStatus != 'Pending'
              "
              (click)="element.expand = !element.expand; toggleRow(element)"
              type="button"
              class="toggle_accordian_btn"
              [ngClass]="
                element.expand
                  ? 'fa-solid fa-chevron-down'
                  : 'fa-solid fa-chevron-right'
              "
            ></span>
            <a
              *ngIf="
                element.anmAccountStatus == 'Pending' &&
                functionalityAccess.isAdmin
              "
              [routerLink]="['/edit-user']"
              [queryParams]="{
                email: element.userName
              }"
            >
              Edit</a
            >
          </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td
            mat-cell
            *matCellDef="let element"
            [attr.colspan]="columnsToDisplay.length"
          >
            <div
              class="user-element-detail"
              *ngIf="element.anmRoleMap?.length"
              [@detailExpand]="
                element == expandedElement ? 'expanded' : 'collapsed'
              "
            >
              <div class="inner-table mat-elevation-z8" *ngIf="expandedElement">
                <table
                  id="innerTables"
                  #innerTables
                  mat-table
                  #innerSort="matSort"
                  [dataSource]="element.anmRoleMap"
                  matSort
                  aria-describedby="Internal Users"
                >
                  <ng-container matColumnDef="generationOwner">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      class="userInnertableHeader"
                      mat-sort-header
                    >
                      Generation Owner
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      class="usertableContent"
                    >
                      {{ getOwnerName(element.generationOwner) }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="role">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      class="userInnertableHeader"
                      mat-sort-header
                    >
                      Role
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      class="usertableContent"
                    >
                      {{ userRoleOptions[element.role] }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="status">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      class="userInnertableHeader"
                      mat-sort-header
                    >
                      Status
                    </th>
                    <td
                      mat-cell
                      *matCellDef="let element"
                      class="usertableContent"
                    >
                      <span>
                        <h3
                          class="usertableContent"
                          [ngStyle]="{
                            color: element.status === 'Active' ? 'Green' : 'Red'
                          }"
                        >
                          {{ element.status }}
                        </h3>
                      </span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="action">
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      class="userInnertableHeader"
                    >
                      Lock/Unlock
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <mat-button-toggle-group
                        name="anmAccountStatus"
                        #toggleBtn="matButtonToggleGroup"
                        *ngIf="functionalityAccess.isAdmin"
                      >
                        <mat-button-toggle
                          *ngIf="element.status == 'Active'"
                          value="Active"
                          class="btn toggleButton toggleButtonActive"
                        >
                          Unlocked
                        </mat-button-toggle>
                        <mat-button-toggle
                          *ngIf="element.status == 'Inactive'"
                          value="Active"
                          class="btn toggleButton toggleButtonActiveDisabled"
                          (click)="
                            lockUnlockUser(
                              'Active',
                              element.userName,
                              element.generationOwner
                            )
                          "
                        >
                          Unlocked
                        </mat-button-toggle>
                        <mat-button-toggle
                          *ngIf="element.status == 'Inactive'"
                          value="Inactive"
                          class="btn toggleButton toggleButtonInactive"
                        >
                          Locked
                        </mat-button-toggle>
                        <mat-button-toggle
                          *ngIf="element.status == 'Active'"
                          value="Inactive"
                          class="btn toggleButton toggleButtonInactiveDisabled"
                          (click)="
                            lockUnlockUser(
                              'Inactive',
                              element.userName,
                              element.generationOwner
                            )
                          "
                        >
                          Locked
                        </mat-button-toggle>
                      </mat-button-toggle-group>
                    </td>
                  </ng-container>

                  <tr
                    mat-header-row
                    *matHeaderRowDef="innerDisplayedColumns"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: innerDisplayedColumns"
                  ></tr>
                </table>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>

        <tr mat-row *matRowDef="let element; columns: columnsToDisplay"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: ['expandedDetail']"
          class="user-detail-row"
        ></tr>
      </table>
    </mat-card-content>
    <mat-card-footer>
      <div>
        <mat-paginator
          [pageSizeOptions]="[10, 15, 20]"
          showFirstLastButtons
        ></mat-paginator>
      </div>
    </mat-card-footer>
  </mat-card>
</div>

<div
  class="modal fade"
  id="updateStatusModal"
  tabindex="-1"
  aria-labelledby="updateStatusModal"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header justify-content-center border-0">
        <h5
          class="modal-title text-success save_user_status_modal_title"
          id="saveGenUnitModalLabel"
        >
          {{
            !isSaveLoading
              ? updateBody?.generationOwner
                ? 'Lock/Unlock User?'
                : 'Update User Status?'
              : updateBody?.genOwner
              ? 'Lock/Unlock inprogress...'
              : 'User status update inprogress...'
          }}
        </h5>
        <button
          type="button"
          (click)="closeSaveModal()"
          *ngIf="!isSaveLoading"
          class="btn-close user_status_modal_close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="update_modal_content">
          <div class="row d-flex flex-row User-details" *ngIf="isSaveLoading">
            <label class="col-md-4 User-details_header">User Name: </label>
            <label class="col-md-6 User-details_content"
              >{{ updateBody?.email }}
            </label>
          </div>
          <div
            class="row d-flex flex-row User-details"
            *ngIf="isSaveLoading && updateBody?.generationOwner"
          >
            <label class="col-md-4 User-details_header"
              >Generation Owner:
            </label>
            <label class="col-md-6 User-details_content"
              >{{ getOwnerName(updateBody?.generationOwner) }}
            </label>
          </div>
          <div class="row d-flex flex-row User-details" *ngIf="isSaveLoading">
            <label class="col-md-4 User-details_header">Action: </label>
            <label class="col-md-6 User-details_content"
              >{{
                updateBody?.generationOwner
                  ? updateBody?.status == 'Active'
                    ? 'Unlock'
                    : 'Lock'
                  : updateBody?.anmAccountStatus == 'Active'
                  ? 'Activate'
                  : 'Inactivate'
              }}
            </label>
          </div>
          <hr />
          <div
            class="text-center"
            *ngIf="!isSaveLoading && !isSaveError && !isSaveSuccess"
          >
            Are you sure you want to
            <b
              [ngStyle]="{
                color:
                  updateBody?.status === 'Active' ||
                  updateBody?.anmAccountStatus == 'Active'
                    ? 'Green'
                    : 'Red'
              }"
              >{{
                updateBody?.generationOwner
                  ? updateBody?.status == 'Active'
                    ? 'Unlock'
                    : 'Lock'
                  : updateBody?.anmAccountStatus == 'Active'
                  ? 'Activate'
                  : 'Inactivate'
              }}
            </b>
            this user <b>{{ updateBody?.email }} </b>
            <span *ngIf="updateBody?.generationOwner"
              >and
              <b>{{ getOwnerName(updateBody.generationOwner) }}</b> Generation
              owner level access</span
            >?
          </div>

          <div
            class="d-flex flex-row justify-content-center"
            *ngIf="isSaveLoading"
          >
            <p class="userlables">Status update inprogress ! Please wait...</p>
            <ameren-loading
              class="d-flex justify-content-center"
            ></ameren-loading>
          </div>

          <div
            class="text-danger text-center justify-content-center"
            *ngIf="!isSaveLoading && isSaveError"
          >
            <img src="../../../assets/images/failure.svg" alt="success" />
            <p class="mt-2">Failed to update user status information.</p>
          </div>
          <div
            class="text-success text-center justify-content-center"
            *ngIf="!isSaveLoading && isSaveSuccess"
          >
            <img src="../../../assets/images/success.svg" alt="success" />
            <p class="mt-2">{{ successMsg }}</p>
          </div>
        </div>
      </div>
      <div
        class="text-center justify-content-center mb-3"
        *ngIf="!isSaveSuccess && !isSaveError && !isSaveLoading"
      >
        <button
          type="button"
          (click)="closeSaveModal()"
          class="btn btn-outline-secondary ps-4 px-4"
          data-bs-dismiss="modal"
        >
          Cancel
        </button>

        <button
          type="button"
          (click)="confirmSaveModel()"
          class="btn btn-success ms-2 ps-4 px-4"
        >
          Confirm
        </button>
      </div>
    </div>
  </div>
</div>
