<div class="login" *ngIf="!isAuthenticated">
  <div class="container p-4 mb-5">
    <div class="form-group">
      <div class="text-center login__heading">Renewables Portal</div>
    </div>
    <div class="form-group">
      <button class="form-control form-btn login_view" (click)="login()">
        <span class="login_text">Log In</span>
      </button>
    </div>
    <div class="form-group">
      <button
        class="form-control form-btn register_btn"
        [routerLink]="'registration'"
      >
        <span class="register_text">Register New User</span>
      </button>
    </div>
  </div>
</div>
<div class="login" *ngIf="isAuthenticated">
  <div class="container p-4">
    <div class="form-group">
      <div class="text-center login__heading">Welcome to Renewables Portal</div>
    </div>
    <div class="form-group text-center">
      View your generation owner, units, subscribers and rebates.
    </div>
    <div class="form-group">
      <button
        class="form-control form-btn btn-success"
        *ngIf="isAdmin"
        (click)="goToPath()"
      >
        Go to my Home Page
      </button>
    </div>
    <div class="form-group">
      <div class="login__border text-center">or</div>
    </div>
    <div class="form-group">
      <button
        class="form-control form-btn rounded-pill border border-info"
        (click)="logout()"
      >
        Log Out
      </button>
    </div>
  </div>
</div>
