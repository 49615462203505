import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { GenerationOwnerComponent } from "./generation-owner.component";
import { AddGenerationOwnerComponent } from "./add-generation-owner/add-generation-owner.component";
import { OwnerDashboardComponent } from "./owner-dashboard/owner-dashboard.component";
import { EditGenerationOwnerComponent } from "./edit-generation-owner/edit-generation-owner.component";
import { SelectGenerationOwnerComponent } from "./select-generation-owner/select-generation-owner.component";

const routes: Routes = [
  {
    path: "",
    component: GenerationOwnerComponent,
  },
  { path: "add-generation-owner", component: AddGenerationOwnerComponent },
  {
    path: "generation-owner/owner-dashboard",
    component: OwnerDashboardComponent,
  },
  { path: "generation-owner/edit", component: EditGenerationOwnerComponent },
  {
    path: "select-generation-owner",
    component: SelectGenerationOwnerComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GenerationOwnerRoutingModule {}
