import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../../src/app/shared/services/auth.service';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  AbstractControlOptions,
} from '@angular/forms';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MustMatch } from 'src/app/shared/validators/must-match';
import { AmerenError } from 'src/app/shared/models/amerenError';
import { RegistrationManagerService } from '../../../../app/public/registration/services/registration-manager.service';
import { ProfileManagerService } from 'src/app/shared/services/profilemanager.service';
import { Profile } from 'src/app/shared/models/profile';
import * as _ from 'lodash';

declare var window: any;
interface EditUserDetails {
  email: string;
  updateEmail: string;
  firstName: string;
  lastName: string;
  phone: any;
}
@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit {
  currentUser: any;
  integrationErrorMsg: string;
  isAPIFailure: boolean = false;
  isLoading: boolean = false;
  formModal: any;
  conformationModal: any;
  mode: string = '';
  profile: Profile;
  firstName: any;
  lastName: any;
  emailAddress: any;
  companyname: any;
  isProfileUpdateSuccess: boolean = false;
  isPhoneNumberValidFlg: boolean = true;
  constructor(
    private _fb: FormBuilder,
    private _authService: AuthService,
    private _router: Router,
    private registrationManagerService: RegistrationManagerService,
    private activeRoute: ActivatedRoute,
    private profileMgr: ProfileManagerService
  ) {
    this._authService.getUser().then((user) => {
      this.currentUser = user;
    });
  }
  editProfileForm: FormGroup;
  @ViewChild('phoneNumber') phoneNumberInput: ElementRef;

  ngOnInit(): void {
    if (this.currentUser) {
      this.initEditProfileForm();
    }

    this.profileMgr.profile.forEach((a: Profile) => {
      this.profile = a;
    });

    let params = JSON.parse(
      JSON.stringify(this.activeRoute.snapshot.queryParams)
    );
    this.mode = params.ref;

    this.formModal = new window.bootstrap.Modal(
      document.getElementById('myModal')
    );
    this.conformationModal = new window.bootstrap.Modal(
      document.getElementById('conformationModal')
    );
  }

  initEditProfileForm() {
  
    this.firstName = Array.isArray(this.currentUser.profile.firstname)
      ? this.currentUser.profile.firstname[0]
      : this.currentUser.profile.firstname;

    this.lastName = Array.isArray(this.currentUser.profile.lastname)
      ? this.currentUser.profile.lastname[0]
      : this.currentUser.profile.lastname;

    this.emailAddress = Array.isArray(this.currentUser.profile.email)
      ? this.currentUser.profile.email[0]
      : this.currentUser.profile.email;   

    this.editProfileForm = this._fb.group(
      {
        firstName: new FormControl(this.firstName),
        lastName: new FormControl(this.lastName),       
        currentEmail: new FormControl(this.emailAddress),
        newEmail: new FormControl(null, [Validators.email]),
        confirmNewEmail: new FormControl(null, [Validators.email]),
        phoneNumber: new FormControl(this.maskPhoneNumber(this.currentUser?.profile?.telephoneNumber), [
          Validators.required,
          Validators.minLength(14)
        ]),
      },
      {
        validator: MustMatch('newEmail', 'confirmNewEmail'),
      } as AbstractControlOptions
    );
  }

  onBackClick() {
    this._router.navigate(['/administration']);
  }

  validatePhoneNo(event: any) {
    let phoneNumDigits = event.value.replace(/\D/g, '');
   
    this.isPhoneNumberValidFlg = (phoneNumDigits.length == 0 || phoneNumDigits.length == 10);
    let formattedNumber = phoneNumDigits;
    if (phoneNumDigits.length > 6)
      formattedNumber =   '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3, 6) + '-' + phoneNumDigits.substring(6);
    else if (phoneNumDigits.length > 3)
      formattedNumber =   '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3);
    event.value = formattedNumber;
  }


  maskPhoneNumber(phonenumber: any): any {
    let newVal = phonenumber.replace(/\D/g, '');
    if (newVal.length <= 6) {
      newVal = newVal.substring(0, newVal.length - 1);
    }
    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,3})/, '($1)');
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
    } else if (newVal.length <= 10) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    } else {
      newVal = newVal.substring(0, 10);
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    }
    return newVal;
  }
  openModal() {
    if (this.editProfileForm.invalid) {
      for (const control of Object.keys(this.editProfileForm.controls)) {
        this.editProfileForm.controls[control].markAsTouched();
      }
      return;
    }
    this.formModal.show();
  }

  updateProfile() {
    this.formModal.hide();
    this.isLoading = true;
    this.isAPIFailure = false;
    this.isProfileUpdateSuccess = false;
    let trimphone = this.editProfileForm.controls['phoneNumber'].value
      ?.replace(/[^0-9 ]/g, '')
      .replace(/\s/g, '');
    let isEmailAddressChanged = this.currentUser.email == this.editProfileForm.controls["confirmNewEmail"].value ? false : true;
    const body: EditUserDetails = {
      email: this.emailAddress,
      updateEmail: isEmailAddressChanged ? this.editProfileForm.controls["confirmNewEmail"].value : '',
      firstName: this.editProfileForm.controls["firstName"].value,
      lastName: this.editProfileForm.controls["lastName"].value,
      phone: (trimphone)
     
    };
    this.registrationManagerService.updateProfile(body).subscribe(
      (res) => {
        this.isLoading = false;
        if (!res.success) {
          this.isAPIFailure = true;
          this.integrationErrorMsg =
            'Sorry, something went wrong. Please try again later.';
        } else {
          this.isAPIFailure = false;
          if (isEmailAddressChanged) {
            this.conformationModal.show();
            setTimeout(() => {
              this._authService.Logout();
            }, 2000);
          } else {
            this.isProfileUpdateSuccess = true;
            setTimeout(() => {
              this._router.navigateByUrl('/edit-profile');
            }, 1000);
          }
        }
      },
      (error: AmerenError) => {
        this.isLoading = false;
        this.isAPIFailure = true;
        if (error.status === 400) {
          this.integrationErrorMsg =
            'Sorry, something is not right. Please try again.';
        } else {
          this.integrationErrorMsg =
            'Sorry, something went wrong. Please try again later.';
        }
      }
    );
  }
}
