
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private cache: any = {}; 

  constructor() { }

  Cache<T>(name: string, callback: () => Observable<T>): Observable<T> {
    let sub = new Subject<T>();
    if ( this.cache[name] === undefined) {
        let observable = callback();
        observable.subscribe(data => {
            this.cache[name] = data;
            sub.next(this.cache[name]);
        });
        return sub.asObservable();
    }
    return new Observable(subscribe => subscribe.next(this.cache[name]));
}

clearCache(){
  this.cache = {};
}
}
