import { ReportsManagerService } from './../services/reports-manager.service';
import { ExportUtility } from "./../../../shared/utilities/utility.export";
import { GenerationUnitManagerService } from "./../../generation-unit/services/generation-unit-manager.service";
import { GenerationOwnerManagerService } from "./../../generation-owner/services/generation-owner-manager.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-gen-unit-bill-acct-subscription-report",
  templateUrl: "./gen-unit-bill-acct-subscription-report.component.html",
  styleUrls: ["./gen-unit-bill-acct-subscription-report.component.scss"],
})
export class GenUnitBillAcctSubscriptionReportComponent implements OnInit {
  constructor(
    private genOwnerMgr: GenerationOwnerManagerService,
    private _export: ExportUtility,
    private reportMgr: ReportsManagerService,
    private genUnitMgr: GenerationUnitManagerService,
  ) {}
  genOwners: any[];
  selectedOwner: any;
  cssDate: string;
  minDate: string;
  maxDate: string;
  reportData: any[];
  units: any[];
  selectedUnit: any;
  isApiLoading: boolean;
  isReportLoading: boolean;
  searchClicked: boolean;
  selectedAccount: any;
  billAccounts: any[];
  integrationErrorMsg: any;

  dtBegin: string;
  dtEnd: string;
  ngOnInit(): void {
    this.getOwners();
  }

  getOwners() {
    this.genOwnerMgr.getAllOwners().subscribe({
      next: (resp) => {
        this.genOwners = resp;
      },
    });
  }

  onOwnerChange() {
    this.selectedUnit = undefined;
    this.selectedAccount = undefined;
    this.getUnitsByOwner();
  }

  getUnitsByOwner() {
    this.integrationErrorMsg = "";
    this.isApiLoading = true;
    this.genOwnerMgr
      .getGenerationUnitsByOwnerId(this.selectedOwner.ownerId)
      .subscribe({
        next: (resp) => {        
          this.isApiLoading = false;         
          if(resp)
          {
            this.units = resp;
            if (this.units.length == 1) {
              this.selectedUnit = this.units[0];              
              this.onUnitChange();
          }
        }
        else
          this.integrationErrorMsg = resp?.errorDetails ? resp?.errorDetails?.message : 'Sorry, something went wrong. Please try again later.';
        },        
      error: (err) => {
        this.isApiLoading = false;
        this.units = []; 
        this.integrationErrorMsg = err.error?.errorDetails ? err.error.errorDetails?.message : err.errorDescription; 
      },
      });
  }
  getSubscribersByOwner() {
    this.integrationErrorMsg = "";
    this.isApiLoading = true;
    let allSubscribers = [];
    let subscribers = [];
    this.genUnitMgr.getSubscribers('0', '0', this.selectedOwner.ownerId).subscribe({
      next: (resp) => {
        this.isApiLoading = false;
       
        allSubscribers = resp;
        allSubscribers.forEach((sub: any, i:any) => {
          if (sub.statusCode == '01') sub.statusDescription = 'Inactive';
          else if (sub.statusCode == '02') sub.statusDescription = 'Active';
          else if (sub.statusCode == '03') sub.statusDescription = 'Pending';
          else sub.statusDescription = 'Finaled';

          sub['selected'] = false;
          sub['index'] = i;
        });       

        subscribers = allSubscribers.filter(
          (s:any) => s.statusCode == '02' && s.ownerId == this.selectedOwner?.ownerId
        ); 

        this.billAccounts = subscribers.filter(
          (x:any) => x.unitId == this.selectedUnit.unitId
        );

        if (this.billAccounts.length == 1) {
          this.selectedAccount = this.billAccounts[0];
        }
       
      },
      error: (err) => {
        this.isApiLoading = false;
        allSubscribers = [];
        subscribers = [];
        this.integrationErrorMsg = err.error?.errorDetails ? err.error.errorDetails?.message : err.errorDescription; 
       
      },
    });
  }

  onUnitChange() {
    this.selectedAccount = undefined;
    this.getSubscribersByOwner();
  
  }

  search() {
    this.reportData = [];
    this.integrationErrorMsg = "";
    this.searchClicked = true;
    this.isReportLoading = true;
    this.reportMgr
      .getBillAccountSubscriptionReport(
        this.selectedAccount?.billAccountNumber,
        this.selectedUnit.unitId,
        this.selectedOwner.ownerId
      )
      .subscribe({
        next: (resp) => {         
          this.isReportLoading = false;
          this.reportData = resp;
        },
        error:(AmerenError) =>
        {
          this.reportData = [];
          this.isReportLoading = false;
          this.integrationErrorMsg = AmerenError.error?.errorDetails ? AmerenError.error.errorDetails?.message : AmerenError.errorDescription; 
        }
      });
  }

  exportReport() {
    let keyHeaders: string[] = [];
    let modifiedlist: any = [];
    let displayHeaders: string[] = [
      "Bill Account",
      "Subscriber Name",
      "Subscription Rate",
      "Effective Date",
      "Termination Date"
    ];

    keyHeaders = [
      "billAccountNumber",
      "subscriberName",
      "subscriptionRate",
      "effectiveDate",
      "terminationDate"
    ];

    this.reportData.forEach((row: any) => {
      let temp: any = {};

      temp["billAccountNumber"] = row.billAccountNumber;
      temp["subscriberName"] = row.subscriberName;
      temp["subscriptionRate"] = row.subscriptionRate;
      temp["effectiveDate"] = row.effectiveDate;

      temp["terminationDate"] = row.terminationDate;
      modifiedlist.push(temp);
    });
    this._export.exportJsonToExcelFile(
      modifiedlist,
      "generation unit bill account subscription report",
      false,
      keyHeaders,
      displayHeaders
    );
  }
}
