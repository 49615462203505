<div class="add-subscriber-container">
  <div class="d-flex align-items-center justify-content-center">
    <div class="text-center add-subscriber-form" *ngIf="isGetUnitApiLoading">
      <ameren-loading></ameren-loading>
      <p>Please wait..</p>
    </div>
    <form
      [formGroup]="addSubscriberForm"
      class="needs-validation add-subscriber-form"
      *ngIf="!isGetUnitApiLoading"
    >
      <div class="add-subscriber-header form-group">Add Subscriber</div>

      <div class="form-group">
        <label
          class="billaccount-number form-label required"
          for="billAccountNumber"
          >Bill Account Number:</label
        >
        <input
          (blur)="validateSubscriber()"
          class="form-control"
          type="text"
          name="billAccountNumber"
          id="billAccountNumber"
          formControlName="billAccountNumber"
        />
        <div
          class="text-field-error mt-3 ameren-font-sm"
          *ngIf="
            addSubscriberForm?.get('billAccountNumber')?.dirty &&
            addSubscriberForm?.get('billAccountNumber')?.invalid
          "
        >
          <p
            *ngIf="addSubscriberForm?.get('billAccountNumber')?.errors?.['required']"
          >
            Bill Account Number is required.
          </p>
          <p
            *ngIf="
                  addSubscriberForm
                      ?.get('billAccountNumber')
                      ?.errors?.['maxlength'] || addSubscriberForm
                      ?.get('billAccountNumber')
                      ?.errors?.['minlength']"
          >
            Bill Account Number should be 10 digits.
          </p>

          <p
            *ngIf="
                  addSubscriberForm
                    ?.get('billAccountNumber')
                    ?.errors?.['pattern']"
          >
            Please enter only numbers.
          </p>
        </div>
      </div>

      <div class="form-group">
        <label class="meter-number form-label required" for="meterNo"
          >Meter Number:</label
        >
        <input
          class="form-control"
          type="text"
          name="meterNo"
          id="meterNo"
          formControlName="meterNo"
          (blur)="validateSubscriber()"
        />
        <div
          class="text-field-error mt-3 ameren-font-sm"
          *ngIf="
            addSubscriberForm?.get('meterNo')?.dirty &&
            addSubscriberForm?.get('meterNo')?.invalid
          "
        >
          <p *ngIf="addSubscriberForm?.get('meterNo')?.errors?.['required']">
            Meter Number is required.
          </p>
          <p *ngIf="addSubscriberForm?.get('meterNo')?.errors?.['pattern']">
            Please enter only numbers.
          </p>
        </div>
      </div>

      <div class="form-group" *ngIf="unit && !units">
        <label class="gen_unit"
          >Generation Unit:
          <span class="selected-gen-unit">{{ unit?.nickName }}</span></label
        >
      </div>

      <div class="form-group" *ngIf="units && !unit">
        <label class="form-label required" for="unit"> Generation Unit: </label>
        <ng-select formControlName="unitId" (change)="validateSubscriber()">
          <ng-option *ngFor="let u of units" [value]="u.unitId">
            {{ 'Id: ' + u.unitId + ' ' + u.nickName }}
          </ng-option>
        </ng-select>
        <div
          class="text-field-error mt-3 ameren-font-sm"
          *ngIf="
            addSubscriberForm?.get('unitId')?.dirty &&
            addSubscriberForm?.get('unitId')?.invalid
          "
        >
          <p *ngIf="addSubscriberForm?.get('unitId')?.errors?.['required']">
            Please select a generetion unit.
          </p>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label required" for="subscriberName"
          >Subscriber Name:</label
        >
        <input
          type="text"
          formControlName="subscriberName"
          class="form-control"
        />
        <div
          class="text-field-error mt-3 ameren-font-sm"
          *ngIf="
            addSubscriberForm?.get('subscriberName')?.dirty &&
            addSubscriberForm?.get('subscriberName')?.invalid
          "
        >
          <p
            *ngIf="addSubscriberForm?.get('subscriberName')?.errors?.['required']"
          >
            Subscriber Name is required.
          </p>
        </div>
      </div>
      <div class="form-group subscribed_kw_container">
        <label class="form-label required" for="usageQuantity"
          >Subscribed kW:</label
        >
        <input
          [readonly]="unitId && !billAccountValid"
          type="text"
          formControlName="usageQuantity"
          class="form-control"
        />
        <span class="subscribed_kw">KW</span>

        <div
          class="text-field-error mt-3 ameren-font-sm"
          *ngIf="
            addSubscriberForm?.get('usageQuantity')?.dirty &&
            addSubscriberForm?.get('usageQuantity')?.invalid
          "
        >
          <p
            *ngIf="addSubscriberForm?.get('usageQuantity')?.errors?.['required']"
          >
            Subscriber kW is required.
          </p>
          <p
            *ngIf="addSubscriberForm?.get('usageQuantity')?.errors?.['pattern']"
          >
            Invalid quantity.
          </p>
        </div>
      </div>
      <div class="form-group" *ngIf="resSubscriptionRate">
        <label class="form-label" for="usageQuantity"
          >Current Default Residential Subscription Rate:
          <span class="res-subscription-rate">{{ resSubscriptionRate }}%</span>
        </label>
      </div>
      <div class="form-group" *ngIf="nonResSubscriptionRate">
        <label class="form-label" for="usageQuantity"
          >Current Default Non-Residential Subscription Rate:
          <span class="non-res-subscription-rate"
            >{{ nonResSubscriptionRate }}%</span
          >
        </label>
      </div>
      <div class="form-group">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            formControlName="overrideSubscription"
            id="overrideSubscription"
          />
          <label class="form-check-label" for="overrideSubscription">
            Override Subscription Rate
          </label>
        </div>
      </div>
      <div
        class="form-group subscription_rate_container"
        *ngIf="addSubscriberForm?.get('overrideSubscription')?.value"
      >
        <label class="form-label required" for="subscriberRate">
          Subscription Rate
        </label>
        <input
          type="text"
          class="form-control"
          formControlName="subscriberRate"
        />
        <span class="subscription_rate_percentage">%</span>
        <div
          class="text-field-error mt-3 ameren-font-sm"
          *ngIf="
            addSubscriberForm?.get('subscriberRate')?.dirty &&
            addSubscriberForm?.get('subscriberRate')?.invalid
          "
        >
          <p
            *ngIf="addSubscriberForm?.get('subscriberRate')?.errors?.['pattern']"
          >
            Invalid Subscription Rate.
          </p>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label required" for="usageQuantity"
          >Effective Date:</label
        >
        <input
          [readonly]="unitId && !billAccountValid"
          [attr.min]="minDate"
          type="date"
          formControlName="effectiveDate"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            formControlName="savingsReleaseFlag"
            id="savingsReleaseFlag"
          />
          <label class="form-check-label" for="savingsReleaseFlag">
            I have received authorization from this Subscriber for Ameren
            Illinois to provide me with their bill credit rate, total kWh and
            total monetary credit value that is applied to their monthly bill
            for this generation unit. I understand that for certain 3rd party
            supplied customers, Ameren Illinois may be unable to provide me with
            the bill credit rate and the total monetary credit value.
          </label>
        </div>
      </div>
      <div class="form-group">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            formControlName="customerConsent"
            id="customerConsent"
          />
          <label class="form-check-label" for="customerConsent">
            <span class="text-field-error">* </span>The customer at this meter
            has given me permission to add them to this generation account and
            to switch their account to billing under Rider NM-Net Metering.
          </label>
          <div
            class="text-field-error mt-3 ameren-font-sm"
            *ngIf="
              addSubscriberForm?.get('customerConsent')?.dirty &&
              addSubscriberForm?.get('customerConsent')?.invalid
            "
          >
            <p *ngIf="addSubscriberForm?.get('customerConsent')?.errors">
              This field is required.
            </p>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label
          >Important: Adding the subscriber will not grant them access to the
          portal. Subscribers to Renewables should not have access to the
          Renewables Portal.</label
        >
      </div>
      <div class="d-grid gap-2 d-md-flex mt-5 justify-content-center">
        <button
          data-bs-toggle="modal"
          data-bs-target="#addSubscriberCancelModal"
          class="ameren-secondary-green-btn"
          type="submit"
        >
          Cancel
        </button>
        <button
          [disabled]="addSubscriberForm.invalid"
          (click)="addSubscriber()"
          class="btn btn-success ameren-primary-green-btn"
          type="button"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</div>

<div
  class="modal fade"
  id="addSubscriberCancelModal"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="addSubscriberCancelModalLabel">
          Confirm Cancellation
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <p>Are you sure you want to cancel?</p>
      </div>
      <div class="modal-footer">
        <a
          *ngIf="!unitId"
          [routerLink]="['/subscribers']"
          class="btn btn-success"
          data-bs-dismiss="modal"
          >Yes</a
        >
        <a
          *ngIf="unitId"
          [routerLink]="['/subscription-detail']"
          [queryParams]="{
            unitId: unitId,
            ownerId: ownerId,
            acct: billAccountNumber
          }"
          class="btn btn-success"
          data-bs-dismiss="modal"
          >Yes</a
        >

        <button
          type="button"
          data-bs-dismiss="modal"
          class="btn btn-danger"
          data-bs-dismiss="modal"
        >
          No
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="addSubscriberLoadingModal"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="addSubscriberLoadingModalLabel"></h5>
        <button
          type="button"
          (click)="closeLoadingModal()"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body text-center">
        <div class="text-center" *ngIf="isApiLoading">
          <ameren-loading></ameren-loading>
          <p>Please wait..</p>
        </div>
        <div class="ameren__modal-image">
          <img
            *ngIf="!saveError && !isApiLoading && saveSuccess"
            src="assets/images/success.svg"
            alt="Success Image"
          />
          <img
            *ngIf="
              (saveError || !billAccountValid) && !isApiLoading && !saveSuccess
            "
            src="assets/images/failure.svg"
            alt="Failure Image"
          />
        </div>
        <div class="ameren__modal-footer" *ngIf="!isApiLoading">
          <div
            class="ameren__modal-message text-center justify-content-center"
            *ngIf="!isApiLoading && !saveError && saveSuccess"
          >
            Success!.
          </div>

          <div
            class="ameren__modal-message text-center justify-content-center text-danger"
            *ngIf="!isApiLoading && saveError && !saveSuccess"
          >
            {{ cssErrorMessage }}
          </div>
          <div
            class="ameren__modal-message text-center justify-content-center bill_acct_msg text-danger"
            *ngIf="!isApiLoading && !billAccountValid && !saveSuccess"
          >
            Invalid Bill Account and Meter Number combination.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
