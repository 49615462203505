import { Injectable } from '@angular/core';
import { ReportsApiService } from './reports-api.service';
import { catchError, map, throwError } from 'rxjs';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';
import { AmerenError } from 'src/app/shared/models/amerenError';

@Injectable({
  providedIn: 'root',
})
export class ReportsManagerService {
  constructor(private reportsAPIService: ReportsApiService) {}

  getGenUnitSubDailyUsage(
    dtBegin: string,
    dtEnd: string,
    genOwner: string,
    genUnit: string
  ) {
    return this.reportsAPIService
      .getGenUnitSubDailyUsage(dtBegin, dtEnd, genOwner, genUnit)
      .pipe(
        map((res: IGlobalAPIResponse) => {
          if (!res.success) {
            throw new AmerenError(
              'Get Gen Unit Sub Daily Usage has failed',
              400,
              null,
              null,
              null
            );
          }

          return res.data;
        }),
        catchError((error: any) => {
          return throwError(() => error);
        })
      );
  }

  getRegulatoryComplianceReport(dtBegin: string, dtEnd: string) {
    return this.reportsAPIService.getRegulatoryComplianceReport(dtBegin,dtEnd).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Get Regulatory Compliance Report failed',
            400,
            null,
            null,
            null
          );
        }
        return res.data;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  getSubDailyKwhAllocationReport(dtBegin: string, dtEnd: string, billAccountNumber: string) {
    return this.reportsAPIService.getSubDailyKwhAllocationReport(dtBegin,dtEnd, billAccountNumber).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Get Subscriber Daily Kwh Allocation Report failed',
            400,
            null,
            null,
            null
          );
        }
        return res.data;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  genUnitSubDailyUsage(kyGenOwner: string, kyGenUnit: string, dtBegin: string, dtEnd: string) {
    return this.reportsAPIService.genUnitSubDailyUsage(kyGenOwner,kyGenUnit,dtBegin,dtEnd).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Get Generation Unit Daily Output Report failed',
            400,
            null,
            null,
            null
          );
        }
        return res.data;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  getBillAccountSubscriptionReport(
    billAccount: string,
    genUnit: string,
    genOwner: string
  ) {
    return this.reportsAPIService
      .getGenUnitBillAccountSubscriptionRateReport(
        billAccount,
        genUnit,
        genOwner
      )
      .pipe(
        map((res: IGlobalAPIResponse) => {
          if (!res.success) {
            throw new AmerenError(
              "Get Generation Unit Bill Account Subscription Report Failed",
              400,
              null,
              null,
              null
            );
          }

          return res.data;
        }),
        catchError((error: any) => {
          return throwError(() => error);
        })
      );
  }

  getBillPeriods(billAccount: string, servicePt: string) {
    return this.reportsAPIService.getBillPeriods(billAccount,servicePt).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Get Subscriber Bill Periods failed',
            400,
            null,
            null,
            null
          );
        }

        return res.data;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  getGenUnitSubBillAccountReport(billAccount: string,dtBegin: string, dtEnd: string, genOwner: string) {
    return this.reportsAPIService.getGenUnitSubscriberBillAccountReport(billAccount,dtBegin,dtEnd, genOwner).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Get Generation Unit Subscriber Bill Account Report failed',
            400,
            null,
            null,
            null
          );
        }

        return res.data;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }
  
  getGenUnitSubscriberContractReport(dtBegin: string, dtEnd: string, genOwner: string, genUnit: string) {
    return this.reportsAPIService.getGenUnitSubscriberContractReport(dtBegin,dtEnd, genOwner, genUnit).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Get Generation Subscriber Contract Report failed',
            400,
            null,
            null,
            null
          );
        }

        return res.data;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  getGenerationOwnerReportData(kyGenOwner: string, effectiveDate: string) {
    return this.reportsAPIService.getGenerationOwnerReportData(kyGenOwner,effectiveDate).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Get Generation Owner Report failed',
            400,
            null,
            null,
            null
          );
        }

        return res.data;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }

  getGenUnitOwnerSubFeeReport(dtBegin: string, dtEnd: string, genOwner: string, genUnit: string) {
    return this.reportsAPIService.getGenUnitOwnerSubFeeReport(dtBegin,dtEnd, genOwner, genUnit).pipe(
      map((res: IGlobalAPIResponse) => {
        if (!res.success) {
          throw new AmerenError(
            'Get Generation Owner Unit Subscriber Fee Report failed',
            400,
            null,
            null,
            null
          );
        }

        return res.data;
      }),
      catchError((error: any) => {
        return throwError(() => error);
      })
    );
  }
}



//kyGenOwner: string, kyGenUnit: string, dtBegin: string, dtEnd: string
