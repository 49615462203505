import { NgModule } from '@angular/core';
import { ManageUsersAccessComponent } from './manage-users-access.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ManageUsersAccessRoutingModule } from './manage-users-access-routing.module';



@NgModule({
  declarations: [ManageUsersAccessComponent],
  imports: [SharedModule, ManageUsersAccessRoutingModule], 
})

export class ManageUsersAccessModule { }
