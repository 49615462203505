<div class="generation-unit-subscription-detail-container">
  <div class="row generation-unit-details">
    <div class="mb-3">
      <div class="generation-unit-subscription-detail-header">
        Generation Unit Subscription Detail
      </div>
      <div class="col-md-12" *ngIf="unit?.type === 'COGF'">
        <em>
          Disclaimer: COGF Participating Customers will be referred to
          throughout this portal as Subscribers.
        </em>
      </div>
    </div>
    <div class="card border">
      <div class="card-body">
        <div class="card-title fw-bolder">Generation Unit Details</div>
        <div
          *ngIf="isGetUnitApiLoading && isBankingStatusLoading"
          class="text-center justify-content-center"
        >
          <p>Please wait..</p>
          <ameren-loading></ameren-loading>
        </div>

        <div
          class="container mt-3"
          *ngIf="!isGetUnitApiLoading && !isBankingStatusLoading"
        >
          <div class="row align-items-start">
            <div class="col-md-4 subscription_detail_name_section">
              <ul class="list-unstyled">
                <li><label>Owner:</label> {{ unit?.ownerName }}</li>
                <li><label>UnitID:</label> {{ unit?.unitId }}</li>
                <li><label>Nickname:</label> {{ unit?.nickName }}</li>
                <li><label>Type:</label> {{ unit?.type }}</li>
              </ul>
            </div>
            <div class="col subscription_detail_capacity">
              <ul class="list-unstyled">
                <li><label>Capacity:</label> {{ unit?.capacity }}</li>
                <li>
                  <label>Rebate Capacity:</label>
                  {{ unit?.rebateCapacityQuantity }}
                </li>
              </ul>
            </div>
            <div class="col subscription_detail_quantities">
              <ul class="list-unstyled">
                <li>
                  <label>Number of Subscribers:</label>
                  {{ unit?.numOfSubscriptions }}
                </li>
                <li>
                  <label>Total Allocation:</label>
                  {{ unit?.totalSubscriberUsage }}
                </li>
                <li>
                  <label>Delivery Service Rate:</label>
                  {{ unit?.deliveryServiceDescription }}
                </li>
                <li><label>Rider:</label> {{ unit?.riderDescription }}</li>
              </ul>
            </div>
            <div class="col">
              <div class="m-3">
                <button
                  type="button"
                  class="ameren-primary-blue-btn"
                  [routerLink]="['/generation-unit/edit']"
                  [queryParams]="{ unitid: unitId }"
                  *ngIf="functionalityAccess?.allowEditGenerationUnit"
                >
                  Edit Unit Profile
                </button>
              </div>
              <div class="m-3">
                <button
                  type="button"
                  class="ameren-primary-blue-btn"
                  [routerLink]="['/rebate-detail']"
                  [queryParams]="{
                    uId: unitId,
                    oId: ownerId,
                    ba: billAccountNumber
                  }"
                  *ngIf="
                    functionalityAccess.isAmerenAdmin ||
                    functionalityAccess.isRebateApprover
                  "
                >
                  Manage Rebate
                </button>
              </div>
              <div class="m-3">
                <button
                  type="button"
                  class="ameren-primary-blue-btn"
                  [ngClass]="!bankingActiveStatus ? 'btn' : ''"
                  [routerLink]="['/subscription-fee']"
                  [queryParams]="{ unitid: unitId }"
                  [disabled]="unit?.type === 'COGF' || !bankingActiveStatus"
                  *ngIf="functionalityAccess?.allowEditGenerationUnit"
                >
                  Manage Subscription Fees
                  <span
                    *ngIf="
                      pennytestInprogressMsg ||
                      genunitsTerminatedMsg ||
                      noBankInfoMsg
                    "
                    >*</span
                  >
                </button>
              </div>
              <div class="m-3">
                <button
                  type="button"
                  class="ameren-primary-red-btn remove-gen-unit-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#removeGenUnitModal"
                  [disabled]="!unit || unit.numOfSubscriptions != '0'"
                  *ngIf="functionalityAccess?.allowRemoveGenerationUnit"
                >
                  Remove Generation Unit
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="subscription-fee-wordings panel-body small"
          *ngIf="!bankingActiveStatus"
        >
          <div
            class="alert alert-primary no-bank-info-msg"
            role="alert"
            *ngIf="noBankInfoMsg"
          >
            *Before you can enter your subscription fee data, you will need to
            provide your banking information using the Edit Generation Owner
            Detail Window
          </div>
          <div
            class="alert alert-primary pennytest-inprogress-msg"
            role="alert"
            *ngIf="pennytestInprogressMsg"
          >
            *Please check the Edit Generation Owner Detail window for your
            Banking Status as the Penny Test is in-progress
          </div>
          <div
            class="alert alert-primary genunits-terminated-msg"
            role="alert"
            *ngIf="genunitsTerminatedMsg"
          >
            *Before you can enter your subscription fee data , you will need to
            agree to Ameren Terms and Conditions and verify your current Banking
            Information using the Edit Generation Owner Detail window
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 subs-search-container-main">
      <div class="row subscriber_search_container">
        <div class="col-md-1"></div>
        <div
          (click)="onToggleActiveInactive()"
          class="col-md-2 form-check pl-2 gen_unit_subscriber_active_switch"
        >
          <input
            class="form-check-input"
            type="checkbox"
            value=""
            id="showInactiveSubscribers"
            [checked]="showInactiveSubscribers"
          />
          <label class="form-check-label" for="showInactiveSubscribers">
            Show Inactive Subscribers
          </label>
        </div>
        <div class="subscriber_search_container_search_box col-md-4">
          <div class="d-flex flex-row gen_owner__search">
            <div class="input-group search_button searchuser mb-3 ms-auto">
              <input
                type="text"
                class="form-control mt-0 searchinput"
                placeholder="Search for Subscriber..."
                aria-label="searchAmerenUser"
                [(ngModel)]="searchString"
                (ngModelChange)="searchFilter()"
                aria-describedby="searchAmerenUser"
              />
              <span
                class="input-group-text"
                id="searchAmerenUser"
                (click)="searchFilter()"
                ><em class="fa fa-search"></em
              ></span>
            </div>
          </div>
        </div>
        <div
          class="subscriber_search_container_btn_container text-right col-md-5"
        >
          <button
            type="button"
            class="btn btn-success gen_owner_export"
            (click)="exportSubscribers()"
          >
            <img
              src="assets/icon/excelicon.svg"
              alt="CalendarImage"
              width="22"
              height="19"
            />
            Export
          </button>
          <button
            type="button"
            class="btn ameren-primary-red-btn"
            data-bs-toggle="modal"
            data-bs-target="#removeSubscribersModal"
            [disabled]="
              !selectedSubscribersToRemove ||
              !selectedSubscribersToRemove.length
            "
            *ngIf="functionalityAccess?.allowEnableDisableSubscribers"
          >
            Remove Subscriber
          </button>
          <a
            [routerLink]="['/subscribers/add']"
            [queryParams]="{
              unitId: unitId,
              ownerId: ownerId,
              acct: billAccountNumber
            }"
            class="btn btn-success"
            *ngIf="functionalityAccess?.allowCreateSubscriber"
            >Add Subscriber</a
          >
        </div>
      </div>

      <div class="mt-3">
        <mat-paginator
          *ngIf="subscribers && subscribers.length > 9"
          #paginator
          [pageSize]="pageSize"
          [pageSizeOptions]="[10]"
          [showFirstLastButtons]="true"
          [length]="totalSize"
          [pageIndex]="currentPage"
          (page)="handlePage($event)"
        >
        </mat-paginator>
      </div>

      <table class="table subscriber_grid">
        <caption class="sr-only">
          Subscribers List
        </caption>
        <thead>
          <tr>
            <th scope="col">Select</th>
            <th scope="col">Subscriber Bill Account Number</th>
            <th scope="col">Subscriber Name</th>
            <th scope="col">Meter Number</th>
            <th scope="col">Subscribed KW</th>
            <th scope="col">Active Date</th>
            <th scope="col">Address</th>
            <th scope="col">Termination Date</th>
            <th scope="col" *ngIf="unit?.type != 'CRGP'">Annual Period</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="!isSubscriberListLoading && !subscribers.length">
            <td colspan="9" class="text-center">No data</td>
          </tr>
          <tr *ngIf="isSubscriberListLoading">
            <td colspan="9" class="text-center">
              <p>Please wait..</p>
              <ameren-loading></ameren-loading>
            </td>
          </tr>
          <tr *ngFor="let sub of pagedSubscribers">
            <td>
              <input
                type="checkbox"
                [checked]="sub.selected"
                (click)="selectSubscriber(sub)"
              />
            </td>
            <td>
              {{ sub.billAccountNumber }}
            </td>
            <td>
              <a
                *ngIf="sub.statusCode === '02'"
                [routerLink]="['/subscribers/edit']"
                [queryParams]="{
                  oId: sub.ownerId,
                  uId: sub.unitId,
                  ba: sub.billAccountNumber
                }"
              >
                {{ sub.subscriberName }}</a
              >
              <span *ngIf="sub.statusCode != '02'">{{
                sub.subscriberName
              }}</span>
            </td>
            <td>
              {{ sub.meterNo }}
            </td>
            <td>
              {{ sub.usageQuantity }}
            </td>
            <td>
              {{ sub.effectiveDate }}
            </td>
            <td>
              {{ sub.subscriberAddress }}
            </td>
            <td>
              <p *ngIf="sub.showTerminationDate">
                {{ sub.terminationDate }}
              </p>
              <span *ngIf="showInactiveSubscribers">
                {{ sub.terminationDate }}</span
              >
            </td>
            <td *ngIf="unit?.type != 'CRGP'">{{ sub.annualMonth }}</td>
            <td>
              {{ sub.statusDescription }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div
  class="modal remove-subscribers-modal fade"
  id="removeSubscribersModal"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog remove-subscribers-modal">
    <div class="modal-content remove-subscribers-modal">
      <div class="modal-header remove_gen_unit_modal_header border-0">
        <h5
          class="modal-title text-success remove_gen_unit_modal_title"
          id="removeSubscribersModalLabel"
        >
          {{
            !isRemoveSuccess && !isRemoveError
              ? 'Remove Subscriber(s)'
              : 'Remove Status'
          }}
        </h5>
        <button
          type="button"
          (click)="closeRemoveModal()"
          *ngIf="!isRemoveLoading"
          class="btn-close gen_unit_modal_close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body remove-subscribers-modal">
        <div class="remove_gen_unit_modal_content">
          <div *ngIf="!isRemoveLoading && !isRemoveError && !isRemoveSuccess">
            <div class="form-group col-md-6">
              <label>Termination Date:*</label>
              <input
                type="date"
                class="form-control remove_subscriber_term_date"
                [(ngModel)]="removeEffectiveDt"
              />
            </div>
            <table
              class="table subscriber_grid"
              id="remove_subscriber_modal_grid"
            >
              <caption class="sr-only">
                Selected Subscribers to be removed
              </caption>
              <thead>
                <tr>
                  <th scope="col">Generation Owner</th>
                  <th scope="col">Generation Unit Nickname</th>
                  <th scope="col">Subscriber Name</th>
                  <th scope="col">Subscriber Bill Account Number</th>
                  <th scope="col">Meter Number Date</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let s of selectedSubscribersToRemove">
                  <td>{{ s.ownerName }}</td>
                  <td>{{ s.unitName }}</td>
                  <td>{{ s.subscriberName }}</td>
                  <td>{{ s.billAccountNumber }}</td>
                  <td>{{ s.meterNo }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            *ngIf="isRemoveLoading"
            class="text-center justify-content-center"
          >
            <ameren-loading></ameren-loading>
            <p>Please wait..</p>
          </div>
          <div
            class="text-danger text-center justify-content-center"
            *ngIf="isRemoveError"
          >
            <img src="../../../assets/images/failure.svg" alt="success" />
            <p class="mt-2">{{ removeErrorMsg }}</p>
          </div>
          <div
            class="text-success text-center justify-content-center"
            *ngIf="isRemoveSuccess"
          >
            <img src="../../../assets/images/success.svg" alt="success" />
            <p class="mt-2">Subscriber(s) removed Successfully.</p>
          </div>
        </div>
      </div>
      <div
        class="text-center justify-content-center mb-3"
        *ngIf="!isRemoveSuccess && !isRemoveError && !isRemoveLoading"
      >
        <button
          type="button"
          (click)="closeRemoveModal()"
          class="btn btn-outline-secondary ps-4 px-4"
          data-bs-dismiss="modal"
        >
          Cancel
        </button>

        <button
          type="button"
          (click)="removeSubscribers()"
          class="btn btn-success ms-2 ps-4 px-4"
        >
          Remove
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal remove-genunit-modal fade"
  id="removeGenUnitModal"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog remove-genunit-modal">
    <div class="modal-content remove-genunit-modal">
      <div class="modal-header remove_gen_unit_modal_header border-0">
        <h5
          class="modal-title text-success remove_gen_unit_modal_title"
          id="removeGenUnitModalLabel"
        >
          {{
            !isRemoveSuccess && !isRemoveError
              ? 'Remove Generation Unit'
              : 'Remove Status'
          }}
        </h5>
        <button
          type="button"
          (click)="closeRemoveUnitModal()"
          *ngIf="!isRemoveLoading"
          class="btn-close gen_unit_modal_close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body remove-genunit-modal">
        <div class="remove_gen_unit_modal_content">
          <div
            class="text-center"
            *ngIf="!isRemoveLoading && !isRemoveError && !isRemoveSuccess"
          >
            Are you sure you want to remove this generation unit?
          </div>
          <div
            *ngIf="isRemoveLoading"
            class="text-center justify-content-center"
          >
            <ameren-loading></ameren-loading>
            <p>Please wait..</p>
          </div>
          <div
            class="text-danger text-center justify-content-center"
            *ngIf="isRemoveError"
          >
            <img src="../../../assets/images/failure.svg" alt="success" />
            <p class="mt-2">{{ removeErrorMsg }}</p>
          </div>
          <div
            class="text-success text-center justify-content-center"
            *ngIf="isRemoveSuccess"
          >
            <img src="../../../assets/images/success.svg" alt="success" />
            <p class="mt-2">Generation Unit removed Successfully.</p>
          </div>
        </div>
      </div>
      <div
        class="text-center justify-content-center mb-3"
        *ngIf="!isRemoveSuccess && !isRemoveError && !isRemoveLoading"
      >
        <button
          type="button"
          (click)="closeRemoveUnitModal()"
          class="btn btn-outline-secondary ps-4 px-4"
          data-bs-dismiss="modal"
        >
          No
        </button>

        <button
          type="button"
          (click)="confirmRemoveUnit()"
          class="btn btn-success ms-2 ps-4 px-4"
        >
          Yes
        </button>
      </div>
    </div>
  </div>
</div>
