export class Banking{
    account:string;
    routing:string;
    amerenApproved:string;
    ownerApproved:string;
    termsAndConditions:string;
    status:string;

    constructor(){
        this.account = '';
        this.routing = '';
        this.amerenApproved = '';
        this.ownerApproved = '';
        this.termsAndConditions = 'N';
        this.status = 'Unknown';
    }
} 