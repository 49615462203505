<div class="rebate-detail-header">Rebate Detail</div>

<div class="card rebate_detail p-3 shadow rounded">
  <div class="row" *ngIf="!isUnitLoading">
    <div class="col">
      <table aria-describedby="Rebate Detail">
        <th class="sr-only"></th>
        <tr>
          <td class="field_label">Owner:</td>
          <td class="field_value">
            {{ unit?.ownerName }}
          </td>
        </tr>
        <tr>
          <td class="field_label">Unit ID:</td>
          <td class="field_value">
            {{ unit?.unitId }}
          </td>
        </tr>
        <tr>
          <td class="field_label">Nickname:</td>
          <td class="field_value">
            {{ unit?.nickName }}
          </td>
        </tr>
        <tr>
          <td class="field_label">Capacity</td>
          <td class="field_value">
            {{ unit?.capacity }}
          </td>
        </tr>
        <tr>
          <td class="field_label">Type:</td>
          <td class="field_value">
            {{ unit?.type }}
          </td>
        </tr>
      </table>
    </div>
    <div class="col">
      <tr>
        <td class="field_label">Rebate Capacity:</td>
        <td class="field_value">
          {{ unit?.rebateCapacityQuantity }}
        </td>
      </tr>
      <tr>
        <td class="field_label">Rebate Eligible:</td>
        <td class="field_value">
          {{ unit?.rebateEligibleQuantity }}
        </td>
      </tr>
      <tr>
        <td class="field_label">Rebate Requested:</td>
        <td class="field_value">
          {{ unit?.rebateRequestQuantity }}
        </td>
      </tr>
    </div>
    <div class="col">
      <tr>
        <td class="field_label">Delivery Service Rate:</td>
        <td class="field_value">
          {{ unit?.deliveryServiceDescription }}
        </td>
      </tr>
      <tr>
        <td class="field_label">Rider:</td>
        <td class="field_value">
          {{ unit?.riderDescription }}
        </td>
      </tr>
    </div>
  </div>
  <div class="text-center" *ngIf="isUnitLoading">
    <p>Please wait..</p>
    <ameren-loading></ameren-loading>
  </div>
</div>

<div class="rebates_grid_container">
  <div class="row justify-content-end m-1">
    <div
      (click)="toggleAllRebates()"
      class="col-2 p-0 form-check gen_unit_subscriber_active_switch"
      *ngIf="isInternalAdmin"
    >
      <input class="form-check-input" type="checkbox" value="" />
      <label class="form-check-label" for="showInactiveSubscribers">
        Show All Rebates
      </label>
    </div>

    <div class="col-1 p-0">
      <button
        type="button"
        class="btn btn-success gen_owner_export"
        (click)="exportRebates()"
      >
        <img
          src="assets/icon/excelicon.svg"
          alt="CalendarImage"
          width="22"
          height="19"
        />
        Export
      </button>
    </div>
    <div class="col-1 text-right">
      <button
        *ngIf="
          isRebateApprover ||
          isInternalAdmin ||
          functionalityAccess.allowEditRebate
        "
        type="button"
        [disabled]="selectedRebates.length === 0"
        class="btn btn-success"
        (click)="onSaveClick()"
      >
        Save
      </button>
    </div>
  </div>

  <div class="col-md-12">
    <table class="table rebate_grid">
      <caption class="sr-only">
        Rebates List
      </caption>
      <thead>
        <tr>
          <th
            scope="col"
            *ngIf="isRebateApprover || functionalityAccess.allowEditRebate"
          >
            Approve
          </th>
          <th scope="col">Bill Account Number</th>
          <th scope="col">Owner Bill Account Number</th>
          <th scope="col">Name</th>
          <th scope="col">Meter Number</th>
          <th scope="col">Subscribed KW</th>
          <th scope="col" *ngIf="!functionalityAccess.isRebateApprover">
            Rebate Status
          </th>
          <th scope="col">FEIN Status</th>
          <th scope="col">Rebate Amount</th>
          <th scope="col" *ngIf="functionalityAccess.isAmerenAdmin">
            Request Rebate
          </th>
          <th scope="col" *ngIf="functionalityAccess.isAmerenAdmin">
            Cancel Rebate
          </th>
          <th
            scope="col"
            *ngIf="isRebateApprover || functionalityAccess.allowEditRebate"
          >
            Reject
          </th>
          <th scope="col">Comments</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngIf="
            !isRebateListLoading && rebates.length === 0 && !rebatesAPIFailure
          "
        >
          <td colspan="9" class="text-center">No data</td>
        </tr>
        <tr *ngIf="rebatesAPIFailure">
          <td colspan="9" class="text-center text-danger">
            {{
              rebatebyUnitIdAPIFailureMessage
                ? rebatebyUnitIdAPIFailureMessage
                : 'Sorry. Something went wrong. Please try again later.'
            }}
          </td>
        </tr>
        <tr *ngIf="isRebateListLoading">
          <td colspan="9" class="text-center">
            <p>Please wait..</p>
            <ameren-loading></ameren-loading>
          </td>
        </tr>
        <ng-container *ngIf="!isRebateListLoading">
          <tr *ngFor="let reb of rebates">
            <td *ngIf="isRebateApprover || functionalityAccess.allowEditRebate">
              <input
                type="checkbox"
                [(ngModel)]="reb.isApproved"
                [checked]="reb.isApproved && !reb.isDenied"
                (ngModelChange)="approveRejectRequestCancelRebate(reb)"
              />
            </td>
            <td>
              {{ reb.billAccountNumber }}
            </td>
            <td>
              {{ reb.ownerBillAccountNumber }}
            </td>
            <td>
              {{ reb.subscriberName }}
            </td>
            <td>
              {{ reb.meterNumber }}
            </td>
            <td>
              {{ reb.subscribedQuantity }}
            </td>
            <td *ngIf="!functionalityAccess.isRebateApprover">
              <span *ngIf="!functionalityAccess.isAmerenAdmin">
                {{ reb.rebateStatusDescription }}
              </span>
              <span *ngIf="functionalityAccess.isAmerenAdmin">
                {{
                  reb.rebateStatus === '1' ? reb.rebateStatusDescription : ''
                }}
              </span>
            </td>
            <td>
              {{ reb.feinStatus }}
            </td>
            <td>
              {{ reb.rebateAmount }}
            </td>
            <td *ngIf="functionalityAccess.isAmerenAdmin">
              <input
                type="checkbox"
                [(ngModel)]="reb.requestRebate"
                [checked]="reb.requestRebate && !reb.cancelRebate"
                [disabled]="reb.rebateStatus === '1'"
                (ngModelChange)="approveRejectRequestCancelRebate(reb)"
              />
            </td>

            <td *ngIf="functionalityAccess.isAmerenAdmin">
              <input
                type="checkbox"
                [(ngModel)]="reb.cancelRebate"
                [checked]="reb.cancelRebate && !reb.requestRebate"
                [disabled]="reb.rebateStatus === '8' || reb.rebateStatus === ''"
                (ngModelChange)="approveRejectRequestCancelRebate(reb)"
              />
            </td>

            <td *ngIf="isRebateApprover || functionalityAccess.allowEditRebate">
              <input
                type="checkbox"
                [(ngModel)]="reb.isDenied"
                [checked]="!reb.isApproved && reb.isDenied"
                (ngModelChange)="approveRejectRequestCancelRebate(reb)"
              />
            </td>
            <td>
              <input
                type="text"
                [(ngModel)]="reb.comments"
                *ngIf="isRebateApprover || functionalityAccess.allowEditRebate"
              />
              <span *ngIf="!functionalityAccess.allowEditRebate">
                {{ reb.comments }}
              </span>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>

<div
  id="saveRebatesModal"
  class="modal fade"
  aria-hidden="true"
  aria-labelledby="amerenModal"
  tabindex="-1"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header" *ngIf="!saveLoading">
        <div
          class="ameren__modal__success-title"
          *ngIf="!saveLoading && !saveError"
        >
          Successfully Submitted!
        </div>
        <div
          class="ameren__modal__failure-title text-danger"
          *ngIf="!saveLoading && saveError"
        >
          Submission Error!
        </div>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="closeSaveModal()"
        ></button>
      </div>
      <div class="modal-body">
        <div *ngIf="saveLoading" class="text-center">
          <ameren-loading
            id="loadingIndicator"
            class="d-flex p-10 justify-content-center"
          ></ameren-loading>
          <span>Please wait...</span>
        </div>
        <div class="ameren__modal-image text-center">
          <img
            *ngIf="!saveError && !saveLoading"
            src="assets/images/success.svg"
            alt="Success Image"
          />
          <img
            *ngIf="saveError && !saveLoading"
            src="assets/images/failure.svg"
            alt="Failure Image"
          />
        </div>
        <div class="ameren__modal-footer" *ngIf="!saveLoading">
          <div
            class="ameren__modal-message text-center justify-content-center"
            *ngIf="!saveLoading && !saveError"
          >
            {{ rebateSuccessMessage }}
          </div>

          <div
            class="ameren__modal-message text-center justify-content-center text-field-error"
            *ngIf="!saveLoading && saveError"
          >
            Rebates approvals have not been submitted successfully.. Please try
            again to submit your request.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  id="rebateInEligibilityModal"
  class="modal fade"
  aria-hidden="true"
  aria-labelledby="amerenModal"
  tabindex="-1"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <div class="ameren__modal__failure-title text-danger">
          Submission Error!
        </div>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="closeInEligibilityModal()"
        ></button>
      </div>
      <div class="modal-body">
        <div class="ameren__modal-image text-center">
          <img src="assets/images/failure.svg" alt="Failure Image" />
        </div>
        <div class="ameren__modal-footer">
          <div
            class="ameren__modal-message text-center justify-content-center text-field-error"
          >
            {{ rebateInEligibilityMessage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
