import { AddSubscriberComponent } from './add-subscriber/add-subscriber.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SubscriberComponent } from './subscriber/subscriber.component';
import { EditSubscriberComponent } from './edit-subscriber/edit-subscriber.component';

const routes: Routes = [
  { path: '', component: SubscriberComponent },

  { path: 'subscribers/add', component: AddSubscriberComponent },
  { path: 'subscribers/edit', component: EditSubscriberComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SubscriberRoutingModule {}
