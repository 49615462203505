import { Component, OnInit } from '@angular/core';
import { jsPDF } from 'jspdf';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';

@Component({
  selector: 'app-registration-success',
  templateUrl: './registration-success.component.html',
  styleUrls: ['./registration-success.component.scss']
})
export class RegistrationSuccessComponent implements OnInit {
email:any = '';
  constructor(private _utility:UtilitiesService) { }

  ngOnInit(): void {
    const obj = this._utility.GetPdfObject();
    const data =  JSON.parse(obj)
    this.email = data.id
  }

  downloadPDF() {
    const doc = new jsPDF();
    let pdfObject = this._utility.GetPdfObject();
    const body = JSON.parse(pdfObject);

    let x = 50;
    if (body) {
      doc.setFontSize(22);
      doc.text("New User Registration", x, 15);

      doc.setFontSize(16);
      doc.text("First Name: " + body.firstname, x, 30);
      doc.text("Last Name: " + body.lastname, x, 40);
      doc.text("Email: " + body.email, x, 50);
      doc.text("phone: " + body.phonenumber, x, 60);
      doc.text("state: " + body.stateProvince, x, 70);     

      doc.save("registrationInfo.pdf");
    }

  }
}
