import { Injectable } from '@angular/core';
import { catchError, throwError, map } from 'rxjs';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';
import { AmerenError } from 'src/app/shared/models/amerenError';
import { ManageprofileApiService } from './manageprofile-api.service';

@Injectable({
  providedIn: 'root'
})
export class ManageprofileApiManagerService {

  constructor(private manageProfileApiService: ManageprofileApiService) { }

}
