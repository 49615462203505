import { Component, Input } from "@angular/core";

@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.scss"],
})
export class FaqComponent {
  @Input() selectedState = "";

  getFaqs = (state: string): any[] => {
    if(state == 'illinois')
    return this.faqs_IL;
    else if(state == 'missouri')
    return this.faqs_MO;
    else
    return this.faqs_Ameren;
  };

  faqs_IL: any[] = [
    {
      header: "What is a Generation Facility?",
      detail:
        `<p>A Community Renewable (Community Solar) Generation Facility (CRGP) means an electric generating facility located in Ameren Illinois service territory, powered by solar, wind, dedicated crops grown for electricity generation, agricultural residues, untreated and unadulterated wood waste, landscaping trimmings, livestock manure, anaerobic digestion of livestock or food processing waste, fuel cells or micro-turbines powered by renewable fuels, or hydro-electric energy interconnected at a distribution level voltages under the provisions of 83 Ill. Adm. Code 466.</p>
        <p>A Collectively Owned Generation Facility (COGF) means an electric generating facility located in Ameren Illinois service territory specific to a geographical location, powered by solar, wind, dedicated crops grown for electricity generation, agricultural residues, untreated and unadulterated wood waste, landscaping trimmings, livestock manure, anaerobic digestion of livestock or food processing waste, fuel cells or micro-turbines powered by renewable fuels, or hydro-electric energy interconnected at a distribution level voltages the under the provisions of 83 Ill. Adm. Code 466
        </p>
        `,
    },
    {
      header: "Can subscribers log into the portal?",
      detail:
        "No, the Aggregated Net Metering Portal is just for the Generation Owner; however, you can access your account through Ameren Illinois.",
    },
    {
      header:
        "Whom do I talk to about becoming a subscriber or my active subscription?",
      detail:
        "You need to locate a Generation Owner who offers subscriptions within Ameren Illinois' service territory to begin the process. The Generation Owner will provide answers to your questions related to new or existing subscriptions which may include the following: how do I get a subscription, whom do I pay to get a subscription, when does my subscription begin, how do I increase my subscription amount, will I receive a rebate?",
    },
    {
      header: "Whom do I speak with about billing questions?",
      detail:
        "For questions related to the terms and conditions for your subscription contact your Generation Owner. Questions related to your usage, delivery & supply costs, and/or taxes on your Ameren Illinois bill contact Ameren Illinois' Customer Service at 1-800-232-2477.",
    },
    {
      header: "Do I need a special meter to be a subscriber?",
      detail:
        "No. The existing meter at your house will support your subscription.",
    },
    {
      header: "Can I subscribe to multiple generation units?",
      detail: "Yes.",
    },
    {
      header:
        "I already have net metering from my on-sight generator. Can I also subscribe to my community solar facility?",
      detail: "Yes, locate/contact a Generation Owner.",
    },
    {
      header:
        "Can Generation Owners have access to customer data for subscription sizing purposes?",
      detail:
        '<div>Please note that the customer MUST specifically authorize any release of customer usage data. In general, a "typical" Ameren Illinois residential customer who uses gas appliances for space heating purposes uses 10,000kWh annually. A "typical" Ameren Illinois residential customer who uses electric   appliances for space heating purposes uses 18,000kWh annually. Residential customer usage data is  accessible by authorized 3rd parties through the Share My Data functionality, also known as Green Button  Connect My Data, which is based on national standards for providing data access. The Company adopted this functionality as part of its Smart Grid implementation efforts. Third Parties who want to be listed as a potential data recipient may <a rel="" class="" target="_blank" href="https://www.ameren.com/illinois/business-partners/account-data-management">register here</a>.</div>',
    },
  ];
  faqs_MO: any[] = [
    {
      header: "What is a Generation Facility?",
      detail:

      `<p>A Community Renewable (Community Solar) Generation Facility (CRGP) means an electric generating facility located in Ameren Illinois service territory, powered by solar, wind, dedicated crops grown for electricity generation, agricultural residues, untreated and unadulterated wood waste, landscaping trimmings, livestock manure, anaerobic digestion of livestock or food processing waste, fuel cells or micro-turbines powered by renewable fuels, or hydro-electric energy interconnected at a distribution level voltages under the provisions of 83 Ill. Adm. Code 466.</p>
        <p>A Collectively Owned Generation Facility (COGF) means an electric generating facility located in Ameren Illinois service territory specific to a geographical location, powered by solar, wind, dedicated crops grown for electricity generation, agricultural residues, untreated and unadulterated wood waste, landscaping trimmings, livestock manure, anaerobic digestion of livestock or food processing waste, fuel cells or micro-turbines powered by renewable fuels, or hydro-electric energy interconnected at a distribution level voltages the under the provisions of 83 Ill. Adm. Code 466
        </p>
        `
    },
    {
      header: "Can subscribers log into the portal?",
      detail:
        "No, the Aggregated Net Metering Portal is just for the Generation Owner; however, you can access your account through Ameren Illinois.",
    },
    {
      header:
        "Whom do I talk to about becoming a subscriber or my active subscription?",
      detail:
        "You need to locate a Generation Owner who offers subscriptions within Ameren Illinois' service territory to begin the process. The Generation Owner will provide answers to your questions related to new or existing subscriptions which may include the following: how do I get a subscription, whom do I pay to get a subscription, when does my subscription begin, how do I increase my subscription amount, will I receive a rebate?",
    },
    {
      header: "Do I need a special meter to be a subscriber?",
      detail:
        "No. The existing meter at your house will support your subscription.",
    },
    {
      header: "Can I subscribe to multiple generation units?",
      detail: "Yes.",
    },
    {
      header:
        "I already have net metering from my on-sight generator. Can I also subscribe to my community solar facility?",
      detail: "Yes, locate/contact a Generation Owner.",
    },
    {
      header:
        "Can Generation Owners have access to customer data for subscription sizing purposes?",
      detail:
        'Please note that the customer MUST specifically authorize any release of customer usage data. In general, a "typical" Ameren Illinois residential customer who uses gas appliances for space heating purposes uses 10,000kWh annually. A "typical" Ameren Illinois residential customer who uses electric   appliances for space heating purposes uses 18,000kWh annually. Residential customer usage data is  accessible by authorized 3rd parties through the Share My Data functionality, also known as Green Button  Connect My Data, which is based on national standards for providing data access. The Company adopted this functionality as part of its Smart Grid implementation efforts. The Share My Data functionality enables  Third Parties to register to retrieve usage data from customers who authorize the release of data. Third Parties who want to be listed as a potential data recipient may <a rel="" class="" target="_blank" href="https://www.ameren.com/illinois/business-partners/account-data-management">register here</a>.</div>',
    },
  ];
  faqs_Ameren: any[] = [
    {
      header: "What is a Generation Facility?",
      detail:
      `<p>A Community Renewable (Community Solar) Generation Facility (CRGP) means an electric generating facility located in Ameren Illinois service territory, powered by solar, wind, dedicated crops grown for electricity generation, agricultural residues, untreated and unadulterated wood waste, landscaping trimmings, livestock manure, anaerobic digestion of livestock or food processing waste, fuel cells or micro-turbines powered by renewable fuels, or hydro-electric energy interconnected at a distribution level voltages under the provisions of 83 Ill. Adm. Code 466.</p>
      <p>A Collectively Owned Generation Facility (COGF) means an electric generating facility located in Ameren Illinois service territory specific to a geographical location, powered by solar, wind, dedicated crops grown for electricity generation, agricultural residues, untreated and unadulterated wood waste, landscaping trimmings, livestock manure, anaerobic digestion of livestock or food processing waste, fuel cells or micro-turbines powered by renewable fuels, or hydro-electric energy interconnected at a distribution level voltages the under the provisions of 83 Ill. Adm. Code 466
      </p>
      `,
  },
    {
      header: "Can subscribers log into the portal?",
      detail:
        "No, the Aggregated Net Metering Portal is just for the Generation Owner; however, you can access your account through Ameren Illinois.",
    },
    {
      header:
        "Whom do I talk to about becoming a subscriber or my active subscription?",
      detail:
        "You need to locate a Generation Owner who offers subscriptions within Ameren Illinois' service territory to begin the process. The Generation Owner will provide answers to your questions related to new or existing subscriptions which may include the following: how do I get a subscription, whom do I pay to get a subscription, when does my subscription begin, how do I increase my subscription amount, will I receive a rebate?",
    },
    {
      header: "Whom do I speak with about billing questions?",
      detail:
        "Questions related to the terms and conditions for your subscription contact your Generation Owner. Questions related to your usage, delivery & supply costs, and/or taxes on your Ameren Illinois bill contact Ameren Illinois' Customer Service at 1-800-232-2477.",
    },
    {
      header: "Do I need a special meter to be a subscriber?",
      detail:
        "No. The existing meter at your house will support your subscription.",
    },
    {
      header: "Can I subscribe to multiple generation units?",
      detail: "Yes.",
    },
    {
      header:
        "I already have net metering from my on-sight generator. Can I also subscribe to my community solar facility?",
      detail: "Yes, locate/contact a Generation Owner.",
    },
    {
      header:
        "Can Generation Owners have access to customer data for subscription sizing purposes?",
      detail:
        'Please note that the customer MUST specifically authorize any release of customer usage data. In general, a "typical" Ameren Illinois residential customer who uses gas appliances for space heating purposes uses 10,000kWh annually. A "typical" Ameren Illinois residential customer who uses electric   appliances for space heating purposes uses 18,000kWh annually. Residential customer usage data is  accessible by authorized 3rd parties through the Share My Data functionality, also known as Green Button  Connect My Data, which is based on national standards for providing data access. The Company adopted this functionality as part of its Smart Grid implementation efforts. The Share My Data functionality enables  Third Parties to register to retrieve usage data from customers who authorize the release of data. Third Parties who want to be listed as a potential data recipient may <a rel="" class="" target="_blank" href="https://www.ameren.com/illinois/business-partners/account-data-management">register here</a>.</div>',
    },
  ];
}
