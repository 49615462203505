import { Directive, Input, ElementRef, Renderer2, HostListener, Output, EventEmitter } from '@angular/core';
import { Sort } from '../utilities/sort';

@Directive({
  selector: '[appSort]'
})

export class SortDirective {
  @Input() appSort: Array<any>;
  @Output() sortComplete:EventEmitter<boolean> = new EventEmitter();
  constructor(private renderer: Renderer2, private targetElem: ElementRef) { }

  @HostListener("click")
  sortData() {
    const sort = new Sort();
    const element = this.targetElem.nativeElement;
    const order = element.getAttribute("data-order");
    const dataType = element.getAttribute("data-type");
    const property = element.getAttribute("data-name");
    
    if (order === "desc") {
      this.appSort.sort(sort.startSort(property, order, dataType));
      element.setAttribute("data-order", "asc");
    } else {
      this.appSort.sort(sort.startSort(property, order, dataType));
      element.setAttribute("data-order", "desc");
    }
    this.sortComplete.emit(true);
  }
}