<div class="reports-container">
  <div class="reports-header">Reports</div>
  <div class="col-md-12">
    <em>
      Disclaimer: COGF Participating Customers will be referred to throughout this portal as Subscribers.
    </em>
  </div>

  <div class="card p-3 mt-3">
    <div class="row">
      <div class="col">
        <div class="m-2">
          <a [routerLink]="['gen-owner']" *ngIf="functionalityAccess.allowGenerationOwnerReport">Generation Owner Report</a>
        </div>
        <div class="m-2">
          <a [routerLink]="['gen-unit-sub-billaccount']" *ngIf="functionalityAccess.allowGenerationUnitSubscriberBillAccountReport"
            >Generation Unit Subscriber Bill Account Report</a
          >
        </div>
      
        <div class="m-2">
          <a [routerLink]="['gen-unit-all-sub-billaccount']"  *ngIf="functionalityAccess.allowGenerationUnitAllSubscriberBillAccountReport"
            >Generation Unit All Subscriber Bill Account Report</a
          >
        </div>
        <div class="m-2">
          <a [routerLink]="['ipa-gen-unit-sub-billaccount']" *ngIf="functionalityAccess.allowIPAGenerationUnitSubscriberContractReport || isIPAUser"
            >IPA Generation Unit Subscriber Contract Report</a
          >
        </div>
       
      </div>

      <div class="col">
        <div class="m-2">
          <a [routerLink]="['gen-unit-sub-daily-usage']" *ngIf="functionalityAccess?.allowGenerationUnitSubscriberDailyAllocationReport"
            >Generation Unit Subscriber Daily kWh Allocation Report</a
          >
        </div>
       
        <div class="m-2">
          <a [routerLink]="['gen-unit-bill-acct-subscription']" *ngIf="functionalityAccess.allowGenerationUnitBillAccountSubscriptionReport"
            >Generation Unit Bill Account Subscription Report</a
          >
        </div>
        <div class="m-2">
          <a [routerLink]="['sub-daily-kwh-allocation-rpt']" *ngIf="functionalityAccess.allowSubscriberDailyAllocationReport"
            >Subscriber Daily Kwh Allocation Report</a
          >
        </div>
       
        <div class="m-2">
          <a [routerLink]="['gen-unit-subscriber-contract']" *ngIf="functionalityAccess?.allowGenerationUnitSubscriberContractReport"
            >Generation Unit Subscriber Contract</a
          >
        </div>
      
      </div>
      <div class="col">
        <div class="m-2">
          <a [routerLink]="['gen-unit-daily-output']" *ngIf="functionalityAccess?.allowGenerationUnitDailyOutputReport"
            >Generation Unit Daily Output Report</a
          >
        </div>
        <div class="m-2">
          <a [routerLink]="['gen-unit-owner_sub-fee']" *ngIf="functionalityAccess.allowGenerationUnitBillAccountSubscriptionRateReport"
            >Generation Unit Owner Subscription Fee Report</a
          >
        </div>
        <div class="m-2">
          <a [routerLink]="['regulatory-compliance-rpt']" *ngIf="functionalityAccess?.allowRegulatoryComplianceReport">Regulatory Compliance Report</a>
        </div>
      </div>
    </div>
  </div>
</div>

