import { Component, Input, OnInit, ViewChild } from '@angular/core';

import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  ValidatorFn,
  ValidationErrors,
  FormControlDirective,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  ConfirmPasswordValidation,
  PasswordValidation,
} from '../../shared/directives/password';
import { RegistrationManagerService } from './services/registration-manager.service';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { states } from 'src/app/shared/constants/statesList';
import { AmerenError } from '../../shared/models/amerenError';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  @Input() options: any[];
  @Input() value: string;
  @Input() text: string;
  @Input() selectedValue: string;
  @Input() optionLabel: string;

  @Input() frmControl: FormControl;
  @Input() frmControlName: string;

  @ViewChild(FormControlDirective, { static: true })
  formControlDirective?: FormControlDirective;

  public isPhoneNumberValidFlg: boolean = true;
  registrationForm!: FormGroup;
  submitted = false;
  user: IUser;
  pwdErrors: any[] = [];
  public mailmatch: boolean = false;
  public pwdmatch: boolean = false;
  public showPassword: boolean = false;
  public showPasswordOnPress: boolean;
  public showConfirmPassword: boolean = false;
  public isCheckboxSelected: boolean = false;
  integrationErrorMsg: string;
  isAPIFailure: boolean = false;
  isLoading: boolean = false;
  states: any[];
  isPinSearchLoading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private _router: Router,
    private registrationManagerService: RegistrationManagerService,
    private _utility: UtilitiesService,
    private recaptchaService: ReCaptchaV3Service
  ) {
    this.user = {} as IUser;
  }

  ngOnInit(): void {
    this.initalizeForm();
    this.registrationForm.valueChanges.subscribe(() => {
      this.isCheckboxSelected =
        this.acceptprivacy.value && this.acceptterms.value ? true : false;
    });
  }
  initalizeForm() {
    this.states = states;
    this.registrationForm = new FormGroup({
      firstname: new FormControl(this.user.firstname, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(50),
        Validators.pattern('[a-zA-Z0-9 ]*'),
      ]),
      lastname: new FormControl(this.user.lastname, [
        Validators.required,

        Validators.minLength(1),
        Validators.maxLength(50),
        Validators.pattern('[a-zA-Z0-9 ]*'),
      ]),
      email: new FormControl(this.user.email, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(250),
        emailValidator(),
      ]),

      confirmemail: new FormControl(this.user.confirmemail, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(250),
        emailValidator(),
      ]),
      password: new FormControl(this.user.password, [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(250),
        PasswordValidation.PasswordRules,
      ]),

      confirmpassword: new FormControl(this.user.confirmpassword, [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(250),
        ConfirmPasswordValidation.ConfirmPasswordRules,
      ]),
      companyname: new FormControl(this.user.companyname, [
        Validators.minLength(0),
        Validators.maxLength(100),
      ]),

      stateprovince: new FormControl('Illinois', [Validators.required]),
      phonenumber: new FormControl(this.user.phonenumber, []),
      acceptprivacy: new FormControl(this.user.acceptprivacy, [
        Validators.required,
      ]),

      acceptterms: new FormControl(this.user.acceptterms, [
        Validators.required,
      ]),
    });
  }

  public verifyRecaptchaForRegistration(): void {
    this.isLoading = true;
    this.recaptchaService
      .execute('registrationAction')
      .subscribe((token) => this.handleToken(token));
  }

  handleToken(token: any) {
    const recaptchaBody = {
      recaptchaResponse: token,
    };

    this.registrationManagerService
      .recaptchaVerification(recaptchaBody)
      .subscribe({
        next: (res: any) => {
          this.isAPIFailure = false;
          this.isLoading = false;
          if (res.score > 0.5) {
            this.submitRegistration();
          } else {
            this.isAPIFailure = true;
            this.integrationErrorMsg =
              'Sorry, something went wrong. Please try again later.';
            this.isLoading = false;
          }
        },
        error: () => {
          this.isAPIFailure = true;
          this.isLoading = false;
          this.integrationErrorMsg =
            'Sorry, something went wrong. Please try again later.';
        },
      });
  }

  get firstname() {
    return this.registrationForm.get('firstname')!;
  }

  get lastname() {
    return this.registrationForm.get('lastname')!;
  }

  get email() {
    return this.registrationForm.get('email')!;
  }

  get confirmemail() {
    return this.registrationForm.get('confirmemail')!;
  }

  get password() {
    return this.registrationForm.get('password')!;
  }

  get confirmpassword() {
    return this.registrationForm.get('confirmpassword')!;
  }

  get phonenumber() {
    return this.registrationForm.get('phonenumber')!;
  }

  get companyname() {
    return this.registrationForm.get('companyname')!;
  }

  get acceptprivacy() {
    return this.registrationForm.get('acceptprivacy')!;
  }
  get acceptterms() {
    return this.registrationForm.get('acceptterms')!;
  }

  get stateprovince() {
    return this.registrationForm.get('stateprovince')!;
  }

  submitRegistration() {
    if (this.registrationForm.invalid) {
      for (const control of Object.keys(this.registrationForm.controls)) {
        this.registrationForm.controls[control].markAsTouched();
      }
      return;
    }
    this.isLoading = true;
    this.isAPIFailure = false;
    let phone = this.registrationForm.controls['phonenumber'].value;
    let trimphone = '';

    if (phone != undefined && phone != null) {
      trimphone = phone.replace(/[^0-9 ]/g, '').replace(/\s/g, '');
    }

    let body = {
      email: this.registrationForm.controls['email'].value,
      password: this.registrationForm.controls['password'].value,
      firstName: this.registrationForm.controls['firstname'].value,
      lastName: this.registrationForm.controls['lastname'].value,
      companyName: this.registrationForm.controls['companyname'].value
        ? this.registrationForm.controls['companyname'].value
        : ' ',
      phone: trimphone,
      stateProvince: 'IL',
    };

    this._utility.SetPdfObject(JSON.stringify(body));

    this.isLoading = false;
    this.registrationManagerService.submitRegistration(body).subscribe(
      (res) => {
        if (!res.success) {
          this.isLoading = false;
          this.isAPIFailure = true;
          if (res.errorDetails?.code === 400) {
            this.integrationErrorMsg =
              'Sorry, something is not right. Please try again.';
          } else if (res.errorDetails?.code === 409) {
            this.integrationErrorMsg = 'Sorry, User already exists.';
          } else {
            this.integrationErrorMsg =
              'Sorry, something went wrong. Please try again later.';
          }
        } else {
          this.isLoading = false;
          this.isAPIFailure = false;
          this._router.navigateByUrl('/registration-success');
        }
      },
      (error: AmerenError) => {
        this.isLoading = false;
        this.isAPIFailure = true;
        if (error.status === 400) {
          this.integrationErrorMsg =
            'Sorry, something is not right. Please try again.';
        } else if (error.status === 409) {
          this.integrationErrorMsg = 'Sorry. User already exists.';
        } else {
          this.integrationErrorMsg =
            'Sorry, something went wrong. Please try again later.';
        }
      }
    );
  }

  passwordValidate() {
    let pwd = this.registrationForm.controls['password'].value;
    if (pwd != null || pwd != '') {
      let isNoLeadingTrailingSpacesValid =
        !pwd.startsWith(' ') && !pwd.endsWith(' ');
      let isLetterAndNumberAndSpecialCharValid =
        /^(?=.*[a-zA-Z])(?=.*\d)(?=.*([~`!@#$%\^\(\)_*+=\-\[\]\\';,/{}|\\"":\?])).+$/.test(
          pwd
        );
      let isRepeatingCharactersValid = !/(\S)\1\1+/.test(pwd);
      let isNoControlCharactersValid = /^[\S ]+$/.test(pwd);
      let isNoTwoContinuousSpacesValid = !/\s\s/.test(pwd);
      let invalidCharacters = !/[<]|[>]|[&]|[-]/.test(pwd);
      let isMinMaxLimitExceeded = !/^(?=.{8,250}$).*/.test(pwd);
      let errors = {
        NoLeadingTrailingSpaces: false,
        InvalidCharacters: false,
        LetterAndNumberAndSpecialChar: false,
        RepeatingCharacters: false,
        NoControlCharacters: false,
        NoTwoContinuousSpaces: false,
        WeakPassword: false,
      };

      let confirmPassworderrors = { IsNotPasswordMatching: false };
      if (!isNoLeadingTrailingSpacesValid) {
        errors.NoLeadingTrailingSpaces = true;
      }
      if (!invalidCharacters) {
        errors.InvalidCharacters = true;
      }
      if (!isLetterAndNumberAndSpecialCharValid) {
        errors.LetterAndNumberAndSpecialChar = true;
      }
      if (!isRepeatingCharactersValid) {
        errors.RepeatingCharacters = true;
      }
      if (!isNoControlCharactersValid) {
        errors.NoControlCharacters = true;
      }
      if (!isNoTwoContinuousSpacesValid) {
        errors.NoTwoContinuousSpaces = true;
      }
      if (
        !invalidCharacters ||
        !isNoControlCharactersValid ||
        !isNoTwoContinuousSpacesValid ||
        !isNoLeadingTrailingSpacesValid ||
        !isLetterAndNumberAndSpecialCharValid ||
        !isRepeatingCharactersValid ||
        isMinMaxLimitExceeded
      ) {
        this.pwdErrors = [];

        this.pwdErrors.push(errors);
      } else this.pwdErrors = [];
    }
  }
  emailmatch() {
    this.mailmatch = false;
    if (
      this.registrationForm.controls['email'].value !=
      this.registrationForm.controls['confirmemail'].value
    ) {
      this.mailmatch = true;
    }
  }

  passwordmatch() {
    this.pwdmatch = false;
    if (
      this.registrationForm.controls['password'].value !=
      this.registrationForm.controls['confirmpassword'].value
    ) {
      this.pwdmatch = true;
    }
  }

  onBackClick() {
    this._router.navigate(['/']);
  }

  validatePhoneNo(event: any) {
    let phoneNumDigits = event.value.replace(/\D/g, '');
    this.isPhoneNumberValidFlg =
      phoneNumDigits.length == 0 || phoneNumDigits.length == 10;
    let formattedNumber = phoneNumDigits;
    if (phoneNumDigits.length > 6)
      formattedNumber =
        '(' +
        phoneNumDigits.substring(0, 3) +
        ') ' +
        phoneNumDigits.substring(3, 6) +
        '-' +
        phoneNumDigits.substring(6);
    else if (phoneNumDigits.length > 3)
      formattedNumber =
        '(' +
        phoneNumDigits.substring(0, 3) +
        ') ' +
        phoneNumDigits.substring(3);
    event.value = formattedNumber;
  }

  keyPressNumbers(event: any) {
    let charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
}

interface IUser {
  firstname: string;
  lastname: string;
  email: string;
  confirmemail: string;
  password: string;
  confirmpassword: string;
  phonenumber: string;
  companyname: string;
  acceptprivacy: boolean;
  stateprovince: string;
  acceptterms: boolean;
}

export function emailValidator(): ValidatorFn {
  const EMAIL_REGEXP =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  return (control: AbstractControl): ValidationErrors | null => {
    const isValid = EMAIL_REGEXP.test(control.value);

    if (isValid) {
      return null;
    } else {
      return {
        emailValidator: {
          valid: false,
        },
      };
    }
  };
}
