import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { environment } from "src/environments/environment";
import { ActivatedRoute } from '@angular/router';
import { lowerCase } from "lodash";

@Component({
  selector: "app-emulation",
  templateUrl: "./emulation.component.html",
  styleUrls: ["./emulation.component.scss"],
})
export class EmulationComponent implements OnInit {
  emulationForm: FormGroup;
  flipVersion : boolean = false;

  constructor(private fb: FormBuilder, private _route: ActivatedRoute) {
    this._route.params.subscribe(params => {

      this.flipVersion = params["flip"] ? (lowerCase(params["flip"]) == 'true' ? true : false) : false;
      
    });
  }

  ngOnInit(): void { 
    
    this.initEmulationForm();
  }

  initEmulationForm() {
    this.emulationForm = this.fb.group({
      emailAddress: new FormControl(null, [
        Validators.required,
        Validators.email,
      ]),
    });
  }

  emulateANMUser() {
    let email = encodeURIComponent(
      this.emulationForm.controls["emailAddress"].value.trim()
    );    

      if (this.flipVersion)
      {
        let encryptedEmulateUrl = `${environment.frXUI}/?service=impersonateANM&impusername=${email}&realm=partner&ForceAuth=true#login`;

        location.href = encryptedEmulateUrl;
      }
      else
      {       
        let encryptedEmulateUrl = encodeURIComponent(`${environment.frXUI}/?service=impersonateANM&impusername=${email}&realm=/partner&ForceAuth=true#login`);
        let encryptedRpid = encodeURIComponent(environment.adfs);
        let baseURL = encodeURIComponent(`=${encryptedRpid}&RelayState=${encryptedEmulateUrl}`);
        location.href = `${environment.fed}?RelayState=RPID${baseURL}`
      }
  }
}
