import { NgModule } from "@angular/core";

import { SubscriberRoutingModule } from "./subscriber-routing.module";
import { SubscriberComponent } from "./subscriber/subscriber.component";
import { SharedModule } from "src/app/shared/shared.module";
import { AddSubscriberComponent } from './add-subscriber/add-subscriber.component';
import { EditSubscriberComponent } from './edit-subscriber/edit-subscriber.component';

@NgModule({
  declarations: [SubscriberComponent, AddSubscriberComponent, EditSubscriberComponent],
  imports: [SharedModule, SubscriberRoutingModule]
})
export class SubscriberModule {}
