import { AuthService } from './../../../shared/services/auth.service';
import { SubscriberManagerServiceService } from './../services/subscriber-manager-service.service';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GenerationUnitManagerService } from './../../generation-unit/services/generation-unit-manager.service';
import { Component, OnInit } from '@angular/core';
declare var window: any;

@Component({
  selector: 'app-add-subscriber',
  templateUrl: './add-subscriber.component.html',
  styleUrls: ['./add-subscriber.component.scss'],
})
export class AddSubscriberComponent implements OnInit {
  constructor(
    private genUnitMgr: GenerationUnitManagerService,
    private _route: ActivatedRoute,
    private _fb: FormBuilder,
    private subService: SubscriberManagerServiceService,
    private authService: AuthService
  ) {
    this._route.queryParams.subscribe((data) => {
      this.unitId = data['unitId'];
      this.ownerId = data['ownerId'];
      this.billAccountNumber = data['acct'];
    });

    this.authService.getUser().then((res) => {
      this.currentUser = res?.profile;
    });
  }
  currentUser: any;
  isGetUnitApiLoading: boolean;
  isGetUnitApiError: boolean;
  unitId: string;
  billAccountNumber: string;
  ownerId: string;
  unit: any;
  units: any[];
  selectedUnit: any;
  addSubscriberForm: FormGroup;
  resSubscriptionRate: any;
  nonResSubscriptionRate: any;
  billAccountValid: boolean;
  isApiLoading: boolean;
  loadingModal: any;
  cssDate: string;
  saveSuccess: boolean;
  saveError: boolean;
  cssErrorMessage: string;
  minDate: string;
  ngOnInit(): void {
    if (this.unitId) {
      this.getUnitByUnitId();
    } else {
      this.getGenerationUnits();
    }
    this.initAddSubscriberForm();
    this.getCssDate();

    this.addSubscriberForm.get('unitId')?.valueChanges.subscribe((val) => {
      if (val && this.units) {
        this.selectedUnit = this.units.filter((x: any) => x.unitId == val)[0];
        this.addSubscriberForm.get('owner')?.setValue(this.selectedUnit.owner);
      }
    });

    this.loadingModal = new window.bootstrap.Modal(
      document.getElementById('addSubscriberLoadingModal')
    );
  }

  getUnitByUnitId() {
    this.isGetUnitApiLoading = true;
    this.genUnitMgr.getGenerationUnitByUnitId(this.unitId).subscribe({
      next: (resp) => {
        this.isGetUnitApiLoading = false;
        this.isGetUnitApiError = false;
        if (+resp.rebateCapacityQuantity <= +resp.rebateRequestQuantity)
          resp.rebateEligibleFlag = false;
        this.unit = resp;
        this.selectedUnit = resp;
      },
      error: (err) => {
        this.isGetUnitApiLoading = false;
        this.isGetUnitApiError = true;
      },
    });
  }

  initAddSubscriberForm() {
    this.addSubscriberForm = this._fb.group({
      unitId: new FormControl(this.unitId, [Validators.required]),
      owner: new FormControl(this.ownerId),
      billAccountNumber: new FormControl(null, [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern(/^[0-9]\d*$/),
      ]),
      premiseNo: new FormControl(),
      servicePt: new FormControl(),
      meterNo: new FormControl(null, [
        Validators.required,
        Validators.pattern(/^[0-9]\d*$/),
      ]),
      effectiveDate: new FormControl(),
      timeStart: new FormControl('12.00.00'),
      usageQuantity: new FormControl('0.000', [
        Validators.required,
        Validators.pattern(/^\d*\.?.*/),
      ]),
      annualMonth: new FormControl(),
      annualMonthReq: new FormControl(),
      subscriberName: new FormControl(null, [Validators.required]),
      transferBillAccountNumber: new FormControl(),
      subscriberStatReason: new FormControl('A'),
      savingsReleaseFlag: new FormControl(false),
      subscriberRate: new FormControl(null, [Validators.pattern(/^[0-9]\d*$/)]),
      subscriberRateEffectiveDate: new FormControl(),
      customerConsent: new FormControl(false, [Validators.requiredTrue]),
      overrideSubscription: new FormControl(false),
    });
  }

  getGenerationUnits() {
    this.isGetUnitApiLoading = true;

    this.genUnitMgr.getGenUnits().subscribe({
      next: (resp) => {
        this.isGetUnitApiLoading = false;
        this.isGetUnitApiError = false;
        this.units = resp.filter((x: any) => x.type != 'POSG');
      },
      error: (err) => {
        this.isGetUnitApiLoading = false;
        this.isGetUnitApiError = true;
      },
    });
  }

  validateSubscriber() {
    if (
      this.addSubscriberForm.get('billAccountNumber')?.valid &&
      this.addSubscriberForm.get('meterNo')?.valid &&
      this.addSubscriberForm.get('unitId')?.valid
    ) {
      this.loadingModal.show();
      this.isApiLoading = true;

      let billAcct =
        this.addSubscriberForm.get('billAccountNumber')?.value || '';
      let meter = this.addSubscriberForm.get('meterNo')?.value || '';

      this.subService
        .validateSubscriber(
          this.addSubscriberForm.get('unitId')?.value || '0',
          meter,
          billAcct,
          this.currentUser.email
        )
        .subscribe({
          next: (resp) => {
            this.isApiLoading = false;
            if (resp.billAccountNumber) {
              this.billAccountValid = true;
              this.loadingModal.hide();
              this.isApiLoading = false;
              this.addSubscriberForm.get('premiseNo')?.setValue(resp.premiseNo);
              this.addSubscriberForm.get('servicePt')?.setValue(resp.servicePt);
              this.addSubscriberForm
                .get('subscriberName')
                ?.setValue(resp.subscriberName);
              this.addSubscriberForm
                .get('annualMonth')
                ?.setValue(resp.annualMonth);
              this.addSubscriberForm
                .get('annualMonthReq')
                ?.setValue(resp.annualMonthReq);
            } else {
              this.billAccountValid = false;
            }
            if (
              resp.resUnitSubscriberRate &&
              resp.resUnitSubscriberRate != 0 &&
              resp.resUnitSubscriberRate != '0'
            ) {
              this.resSubscriptionRate = resp.resUnitSubscriberRate;
              this.addSubscriberForm
                .get('subscriberRate')
                ?.setValue(resp.resUnitSubscriberRate);
            } else {
              this.resSubscriptionRate = undefined;
            }
            if (
              resp.comUnitSubscriberRate &&
              resp.comUnitSubscriberRate != 0 &&
              resp.comUnitSubscriberRate != '0'
            ) {
              this.nonResSubscriptionRate = resp.comUnitSubscriberRate;
              this.addSubscriberForm
                .get('subscriberRate')
                ?.setValue(resp.comUnitSubscriberRate);
            } else {
              this.nonResSubscriptionRate = undefined;
            }
          },
          error: (err) => {
            this.isApiLoading = false;
            this.billAccountValid = false;
          },
        });
    }
  }

  getCssDate() {
    this.genUnitMgr.getCSSDate().subscribe({
      next: (resp) => {
        this.cssDate = resp.substring(0, 10);
        this.addSubscriberForm.get('effectiveDate')?.setValue(this.cssDate);
        if (this.authService.getPortalFunctioanalityAccesses()?.isExternal) {
          this.minDate = this.cssDate;
        }
      },
    });
  }

  closeLoadingModal() {
    this.saveError = false;
    this.saveSuccess = false;
    this.cssErrorMessage = '';
  }

  addSubscriber() {
    let model = this.addSubscriberForm.value;
    model.subscriberRateEffectiveDate = model.effectiveDate;
    model.transferBillAccountNumber = model.billAccountNumber;
    if (!model.subscriberRate) model.subscriberRate = 0;
    this.loadingModal.show();
    this.isApiLoading = true;
    this.subService.addSubscriber(model, this.currentUser.email).subscribe({
      next: (resp) => {
        this.isApiLoading = false;
        this.saveError = false;
        this.saveSuccess = true;
      },
      error: (err) => {
        this.isApiLoading = false;
        this.saveError = true;
        this.saveSuccess = false;
        if (err && err?.status == 400) {
          this.cssErrorMessage = err?.error?.errorDetails?.message;
        } else {
          this.cssErrorMessage = 'Something went wrong. Please try again.';
        }
      },
    });
  }
}
