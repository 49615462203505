import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { GenerationUnitManagerService } from '../services/generation-unit-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { IGenerationUnit } from '../interfaces/Igeneration-unit';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { IRates } from '../interfaces/Irates';
import { GenerationOwnerManagerService } from '../../generation-owner/services/generation-owner-manager.service';
import { Subject, delay, takeUntil, tap } from 'rxjs';
import { InvalidDate } from 'src/app/shared/validators/invalid-date';
import * as moment from 'moment';
import 'moment-timezone';
import { AuthService } from 'src/app/shared/services/auth.service';

declare var window: any;

@Component({
  selector: 'app-manage-subscription-fee',
  templateUrl: './manage-subscription-fee.component.html',
  styleUrls: ['./manage-subscription-fee.component.scss'],
})
export class ManageSubscriptionFeeComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(MatPaginator) paginator: MatPaginator;

  isRateHistoryAPIFailure: boolean;
  isRateHistoryLoading: boolean;
  rebateHistory: any[] = [];
  unitId: any;
  pageSize = 5;
  currentPage = 0;
  totalSize = 0;
  bankingStatus: string;
  viewLoaded: Subject<null>;
  destroyed$: Subject<boolean>;

  rates: any[] = [];
  ratesData: IRates[] = [];
  generationUnitDetails: IGenerationUnit;
  isGenerationUnitAPIFailure: boolean;
  isGenerationUnitLoading: boolean;

  subscriptionFeeForm: FormGroup;

  isSubmitScriptionFailed: boolean;
  isSubmitScriptionLoading: boolean;

  submitSubscriptionModal: any;

  showEffectiveDate: boolean;

  subscriptionFeeCancelModal: any;
  role: any;
  currentDateValue = moment().tz('America/Chicago').format('YYYY-MM-DD');

  constructor(
    private generationUnitManagerService: GenerationUnitManagerService,
    private _route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    private generationOwnerManager: GenerationOwnerManagerService,
    private authService: AuthService
  ) {
    this._route.queryParams.subscribe((data) => {
      this.unitId = data['unitid'];
    });

    this.viewLoaded = new Subject();
    this.destroyed$ = new Subject();
  }

  ngOnInit(): void {
    this.role = this.authService.getSelectedGenerationOwnerRole();

    this.unitSubscriptionFeeForm();
    this.getGenerationUnitRateHistory();
    this.getGenerationUnitByUnitId();

    this.submitSubscriptionModal = new window.bootstrap.Modal(
      document.getElementById('submitSubscriptionModal'),
      {
        backdrop: true,
        keyboard: true,
      }
    );

    this.subscriptionFeeCancelModal = new window.bootstrap.Modal(
      document.getElementById('subscriptionFeeCancelModal'),
      {
        backdrop: true,
        keyboard: true,
      }
    );

    this.viewLoaded
      .pipe(
        delay(0),
        tap(() => {
          this.listenForFormChanges();
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe();
  }

  listenForFormChanges() {
    this.subscriptionFeeForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data: any) => {
        if (this.subscriptionFeeForm.dirty) {
          this.showEffectiveDate = true;

          if (
            moment(moment(data.resEffectiveDate, 'YYYY-MM-DD')).isBefore(
              this.currentDateValue
            )
          ) {
            this.subscriptionFeeForm
              ?.get('resEffectiveDate')
              ?.setErrors({ InvalidDate: 'Date must be current or future' });
          }
          if (
            moment(moment(data.nonResEffectiveDate, 'YYYY-MM-DD')).isBefore(
              this.currentDateValue
            )
          ) {
            this.subscriptionFeeForm
              ?.get('nonResEffectiveDate')
              ?.setErrors({ InvalidDate: 'Date must be current or future' });
          }
        } else {
          this.showEffectiveDate = false;
        }
      });
  }

  unitSubscriptionFeeForm() {
    this.subscriptionFeeForm = this.fb.group({
      resBillName: new FormControl(null, [
        Validators.pattern('^[-_ a-zA-Z0-9]+$'),
      ]),
      resSubPercentage: new FormControl(null, [
        Validators.pattern(/^[0-9]\d*$/),
      ]),
      resEffectiveDate: new FormControl(null, [InvalidDate]),
      nonResBillName: new FormControl(null, [
        Validators.pattern('^[-_ a-zA-Z0-9]+$'),
      ]),
      nonResSubPercentage: new FormControl(null, [
        Validators.pattern(/^[0-9]\d*$/),
      ]),
      nonResEffectiveDate: new FormControl(null, [InvalidDate]),
      termindateBillingSubscriptionFee: new FormControl(null),
    });
  }

  getGenerationUnitRateHistory() {
    this.isRateHistoryLoading = true;
    this.isRateHistoryAPIFailure = false;
    this.rates = [];
    this.rebateHistory = [];
    this.generationUnitManagerService
      .getGenerationUnitRateHistory(this.unitId)
      .subscribe({
        next: (res: any) => {
          this.isRateHistoryLoading = false;
          this.isRateHistoryAPIFailure = false;
          this.rates = res;
          this.ratesData = this.rates.filter(
            (data: IRates) => data.terminationDate === ''
          );
          this.populateSubscriptionFees(this.ratesData);
          this.iterator();
        },
        error: (error) => {
          this.isRateHistoryLoading = false;
          this.isRateHistoryAPIFailure = true;
          this.subscriptionFeeForm.get('resBillName')?.setValue('');
          this.subscriptionFeeForm.get('resSubPercentage')?.setValue('');
          this.subscriptionFeeForm.get('resEffectiveDate')?.setValue('');
          this.subscriptionFeeForm.get('nonResBillName')?.setValue('');
          this.subscriptionFeeForm.get('nonResSubPercentage')?.setValue('');
          this.subscriptionFeeForm.get('nonResEffectiveDate')?.setValue('');
        },
      });
  }

  populateSubscriptionFees(data: any[]) {
    data.forEach((res: IRates) => {
      if (res.tariffType && res.tariffType === 'R') {
        this.subscriptionFeeForm?.get('resBillName')?.setValue(res.billName);
        this.subscriptionFeeForm
          .get('resSubPercentage')
          ?.setValue(res.subPercentage);
        this.subscriptionFeeForm
          .get('resEffectiveDate')
          ?.setValue(this.currentDateValue);
      } else if (res.tariffType && res.tariffType === 'C') {
        this.subscriptionFeeForm.get('nonResBillName')?.setValue(res.billName);
        this.subscriptionFeeForm
          .get('nonResSubPercentage')
          ?.setValue(res.subPercentage);
        this.subscriptionFeeForm
          .get('nonResEffectiveDate')
          ?.setValue(this.currentDateValue);
      } else {
        this.subscriptionFeeForm.get('resBillName')?.setValue('');
        this.subscriptionFeeForm.get('resSubPercentage')?.setValue('');
        this.subscriptionFeeForm.get('resEffectiveDate')?.setValue('');
        this.subscriptionFeeForm.get('nonResBillName')?.setValue('');
        this.subscriptionFeeForm.get('nonResSubPercentage')?.setValue('');
        this.subscriptionFeeForm.get('nonResEffectiveDate')?.setValue('');
      }
    });
  }

  getGenerationUnitByUnitId() {
    this.isGenerationUnitAPIFailure = false;
    this.isGenerationUnitLoading = true;
    this.generationUnitManagerService
      .getGenerationUnitByUnitId(this.unitId)
      .subscribe({
        next: (res: IGenerationUnit) => {
          this.generationUnitDetails = res;
          this.isGenerationUnitAPIFailure = false;
          this.isGenerationUnitLoading = false;
          this.getBankingStatus(this.generationUnitDetails.owner);
        },
        error: (error: any) => {
          console.log('error', error);
          this.isGenerationUnitAPIFailure = true;
          this.isGenerationUnitLoading = false;
        },
      });
  }

  getBankingStatus(owner: any) {
    this.isGenerationUnitAPIFailure = false;
    this.isGenerationUnitLoading = true;
    this.generationOwnerManager.getOwnerBankingInfo(owner).subscribe({
      next: (data: any) => {
        this.bankingStatus = data.status;
        this.isGenerationUnitAPIFailure = false;
        this.isGenerationUnitLoading = false;
      },
      error: (error: any) => {
        console.log('error', error);
        this.isGenerationUnitAPIFailure = true;
        this.isGenerationUnitLoading = false;
      },
    });
  }
  cancelSubscriptionFee() {
    this.subscriptionFeeCancelModal.show();
  }

  submitSubscriptionFee() {
    let terminateSubscriptionFee: boolean =
      this.subscriptionFeeForm.get('termindateBillingSubscriptionFee')
        ?.value === true
        ? true
        : false;
    let body = {
      ownerId: this.generationUnitDetails.owner,
      billAccountNumber: this.generationUnitDetails.billAccountNumber,
      resBillName: this.subscriptionFeeForm.get('resBillName')?.value,
      resSubPercentage: !terminateSubscriptionFee
        ? this.subscriptionFeeForm.get('resSubPercentage')?.value
        : 0,
      resEffectiveDate: this.subscriptionFeeForm.get('resEffectiveDate')?.value,
      comBillName: this.subscriptionFeeForm.get('nonResBillName')?.value,
      comSubPercentage: !terminateSubscriptionFee
        ? this.subscriptionFeeForm.get('nonResSubPercentage')?.value
        : 0,
      comEffectiveDate: this.subscriptionFeeForm.get('nonResEffectiveDate')
        ?.value,
      priceTermFlag: terminateSubscriptionFee ? 'Y' : 'N',
    };

    this.submitSubscriptionModal.show();
    this.isSubmitScriptionFailed = false;
    this.isSubmitScriptionLoading = true;
    this.generationUnitManagerService
      .submitSubscriptionFee(
        body,
        this.unitId,
        this.role === 'anmInternalAdmin' ? 'Y' : 'N'
      )
      .subscribe({
        next: () => {
          this.isSubmitScriptionFailed = false;
          this.isSubmitScriptionLoading = false;
          this.submitSubscriptionModal.show();
          this.getGenerationUnitRateHistory();
        },
        error: (error: any) => {
          console.log('error', error);
          this.isSubmitScriptionFailed = true;
          this.isSubmitScriptionLoading = false;
          this.submitSubscriptionModal.show();
        },
      });
  }

  ngAfterViewInit(): void {
    this.viewLoaded.next(null);
    this.showEffectiveDate = false;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();
  }
  private iterator() {
    this.totalSize = this.rates.length;
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    this.rebateHistory = this.rates.slice(start, end);
  }
}
