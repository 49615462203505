import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { GenerationOwnerManagerService } from "../services/generation-owner-manager.service";
import { AuthService } from "src/app/shared/services/auth.service";
import { ExportUtility } from "src/app/shared/utilities/utility.export";
import { FineGrainAuthorization } from "src/app/shared/models/fine-grain-authorization";
declare var window: any;
@Component({
  selector: "app-owner-dashboard",
  templateUrl: "./owner-dashboard.component.html",
  styleUrls: ["./owner-dashboard.component.scss"],
})
export class OwnerDashboardComponent implements OnInit {
  ownerId: any;
  selectedOldOwnerId : any;

  owner: any;
  ownerContact: any;
  units: any[];
  allUnits: any[];
  currentUser: any;
  searchString: string;
  isApiLoading = false;
  isApiError = false;
  unitStatus: string = "2";
  displayedColumns: string[] = [
    "name",
    "email",
    "genOwner",
    "role",
    "regCompDate",
    "lastLogin",
    "status",
  ];
  dataSource: MatTableDataSource<any>;
  loggedInUser:any;
  isInternalUser:boolean= false;
  selectedOwnerUserRole:any;
  functionalityAccess: FineGrainAuthorization;
  genOwnerMsgPopup: any;
  integrationErrorMsg:boolean;

  constructor(
    private _route: ActivatedRoute,
    private genOwnerMgrService: GenerationOwnerManagerService,
    private authService: AuthService,
    private _export: ExportUtility
  ) {
    this.selectedOldOwnerId = this.authService.getSelectedGenerationOwnerID();
    this._route.queryParams.subscribe((data) => {      
      this.ownerId = data["ownerId"];     
    });    

    this.authService.getUser().then((res) => {
      this.currentUser = res?.profile;
    });

  
  }

  ngOnInit(): void {
    this.selectedOwnerUserRole = '';
    this.loggedInUser = this.authService.getCurrentUser(); 

    this.genOwnerMsgPopup = new window.bootstrap.Modal(
      document.getElementById('genOwnerMsgPopup'));


    if (this.loggedInUser != null)
      this.currentUser = this.loggedInUser.profile;

    if(this.currentUser)
      {
        this.currentUser.anmRoleMap = this.currentUser?.anmRoleMap?.filter(
          (lst:any, i:any, arr:any) => arr.findIndex((t:any) => t.generationOwner === lst.generationOwner && t.status === 'Active') === i
        ); 
      }      
      
      let ownerAccess = this.authService.getCurrentUserGenerationOwnersAccess();

      if(!ownerAccess.includes('00018'))
        {
          if ( ownerAccess?.filter(r => this.ownerId == r).length <= 0) 
          {   
            this.ownerId = this.selectedOldOwnerId;

            this.genOwnerMsgPopup.show();     
            setTimeout(() => {             
              this.genOwnerMsgPopup.hide();
            }, 2000);  
           }
      
        }

    this.getOwnerById();
  }

  getOwnerById() {
    
    this.isApiLoading = true;
    this.genOwnerMgrService.getOwnersByOwnerId(this.ownerId).subscribe({
      next: (owner) => {      
        let DER_contact = owner.contacts.filter(
          (x: any) => x.contactType == "01"
        )[0];
        let ExSub_contact = owner.contacts.filter(
          (x: any) => x.contactType == "09"
        )[0];
        let poSub_contact = owner.contacts.filter(
          (x: any) => x.contactType == "06"
        )[0];

        if (DER_contact) {
          this.ownerContact = DER_contact;          
          this.ownerContact.contactTypeDecode = "DER";
        } else if (ExSub_contact) {
          this.ownerContact = ExSub_contact;
          this.ownerContact.contactTypeDecode = "ExSub";
        } else if (poSub_contact) {
          this.ownerContact = poSub_contact;
          this.ownerContact.contactTypeDecode = "PoSub";
        }
        this.owner = owner;
        this.authService.setSelectedGenerationOwner(owner);

        let internal_roles = this.currentUser?.anmRoleMap.filter(
          (a:any) =>
          (a.role != 'anmExternalAdmin' && 
          a.role != 'anmExternalViewOnly') && (a.status === 'Active')
        );     
      
        this.isInternalUser = internal_roles[0] != undefined && internal_roles.length >= 0;    
                
        this.selectedOwnerUserRole = this.isInternalUser ?  internal_roles[0]?.role.split(",") : (this.currentUser?.anmRoleMap ? this.currentUser.anmRoleMap.filter((a:any) =>
            a.generationOwner === this.ownerId && a.status === 'Active').map((x:any) => x.role) : undefined) ;

        if(this.selectedOwnerUserRole)        
          this.authService.setSelectedGenerationOwnerRole(this.selectedOwnerUserRole);  


        let functionality = "";          
        this.functionalityAccess = this.authService.getPortalFunctioanalityAccesses(functionality, this.selectedOwnerUserRole);    

        this.getUnitsByOwnerId();
      },

      error: (AmerenError) => {      
        this.isApiLoading = false;
        this.isApiError = true;
        if (AmerenError.error?.errorDetails?.message.startsWith('Internal Error'))
          this.integrationErrorMsg = true;
      },
    });
  }

  getUnitsByOwnerId() {
    this.genOwnerMgrService
      .getGenerationUnitsByOwnerId(this.ownerId)
      .subscribe({
        next: (resp) => {
          this.allUnits = resp;
          this.units = resp;
          this.units = this.units.filter((x) => x.status == 2);
          this.isApiError = false;
          this.isApiLoading = false;
        },
        error: (AmerenError) => {
          this.isApiError = true;
          this.isApiLoading = false;
          if (AmerenError.error?.errorDetails?.message.startsWith('Internal Error'))
            this.integrationErrorMsg = true;
        },
      });
  }

  onStatusChange() {
    if (this.unitStatus == "-1") {
      this.units = this.allUnits;
    } else {
      this.units = this.allUnits?.filter((x) => x.status == this.unitStatus);
    }
  }
  searchFilter() {
    let originalUnits: any[] = [];

    if (this.unitStatus == "-1") {
      originalUnits = this.allUnits;
    } else {
      originalUnits = this.allUnits.filter((x) => x.status == this.unitStatus);
    }

    if (this.searchString && this.searchString.trim() != "") {
      let adjustedSearch = this.searchString.trim().toLocaleLowerCase();
      this.units = originalUnits.filter(
        (data) =>
          data.nickName.match(adjustedSearch) ||
          data.billAccountNumber.match(adjustedSearch) ||
          data.numOfSubscriptions.match(adjustedSearch) ||
          data.totalSubscriberUsage.match(adjustedSearch) ||
          data.unitId.match(adjustedSearch) ||
          data.capacity.match(adjustedSearch) ||
          data.typeDesc.match(adjustedSearch) ||
          data.type.match(adjustedSearch)
      );
    } else {
      this.units = originalUnits;
    }
  }

  
  exportgenUnits() {
    let keyHeaders: string[] = [];
    let modifiedlist: any = [];
    let displayHeaders: string[] = [
      "Unit ID",
      "Nickname",
      "Number Of Subscribers",
      "Bill AccountNumber",
      "Capacity",
      "Subscribed Allocation",
      "Type",
      "Status",
    ];
    keyHeaders = [
      "unitID",
      "nickName",
      "numOfSubscriptions",
      "billAccountNumber",
      "capacity",
      "totalSubscriberUsage",
      "type",
      "status",
    ];

    this.allUnits.forEach((row: any) => {
      let temp: any = {};

      temp["unitID"] = row.unitId;
      temp["nickName"] = row.nickName;
      temp["numOfSubscriptions"] = row.numOfSubscriptions;
      temp["billAccountNumber"] = row.billAccountNumber;
      temp["capacity"] = row.capacity;
      temp["totalSubscriberUsage"] = row.totalSubscriberUsage;
      temp["type"] = row.type;
      temp["status"] =
        row.status == "1"
          ? "Inactive"
          : row.status == "2"
          ? "Active"
          : "Pending";

      modifiedlist.push(temp);
    });
    this._export.exportJsonToExcelFile(
      modifiedlist,
      "generationUnits",
      false,
      keyHeaders,
      displayHeaders
    );
  }
}
