import { IRates } from '../interfaces/Irates';
import * as _ from 'lodash';

export class RatesModel {
  tariffType: string;
  billName: string;
  subPercentage: number;
  effectiveDate: string;
  terminationDate: string;
  constructor(rates: IRates) {
    this.tariffType = !_.isNil(rates.tariffType) ? rates.tariffType : '';
    this.billName = !_.isNil(rates.billName) ? rates.billName : '';
    this.subPercentage = !_.isNil(rates.subPercentage)
      ? rates.subPercentage
      : 0;
    this.effectiveDate = !_.isNil(rates.effectiveDate)
      ? rates.effectiveDate
      : '';
    this.terminationDate =
      rates.terminationDate === '9999-12-31' ? '' : rates.terminationDate;
  }
}
