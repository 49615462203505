<div>
  <p class="fs-3 text-center inEligibleTitle fw-bolder mt-5">
    Features Not Available!
  </p>
  <p class="text-center p-2 m-2">
    If you have questions about this message, please call Ameren Illinois
    Renewables Portal at 1-800-755-5000 or email
    <a href="mailto:RenewablesIllinois@ameren.com"
      >RenewablesIllinois@ameren.com</a
    >
  </p>
</div>
