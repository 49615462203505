import { ReportsManagerService } from './../services/reports-manager.service';
import { ExportUtility } from './../../../shared/utilities/utility.export';
import { GenerationUnitManagerService } from './../../generation-unit/services/generation-unit-manager.service';
import { GenerationOwnerManagerService } from './../../generation-owner/services/generation-owner-manager.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-genunit-owner-sub-fee',
  templateUrl: './genunit-owner-sub-fee.component.html',
  styleUrls: ['./genunit-owner-sub-fee.component.scss'],
})
export class GenunitOwnerSubFeeComponent implements OnInit {
  constructor(
    private genOwnerMgr: GenerationOwnerManagerService,
    private genUnitMgr: GenerationUnitManagerService,
    private reportMgrSvc: ReportsManagerService,
    private _export: ExportUtility
  ) {}
  genOwners: any[];
  selectedOwner: any;
  cssDate: string;
  minDate: string;
  maxDate: string;
  reportData: any[];
  units: any[];
  selectedUnit: any;
  isApiLoading: boolean;
  isReportLoading: boolean;
  searchClicked: boolean;

  dtBegin: string;
  dtEnd: string;
  integrationErrorMsg:any;

  ngOnInit(): void {
    this.getOwners();
    this.getCSSdate();
  }

  getOwners() {
    this.genOwnerMgr.getAllOwners().subscribe({
      next: (resp) => {
        this.genOwners = resp;
      },
    });
  }

  getCSSdate() {
    this.genUnitMgr.getCSSDate().subscribe({
      next: (resp) => {
        this.cssDate = resp?.substring(0, 10);
        let dt = new Date(this.cssDate);
       
        this.maxDate = new Date(dt.setDate(dt.getDate() - 1))
          .toISOString()
          .split('T')[0];
        this.minDate = new Date(dt.setDate(dt.getDate() - 90))
          .toISOString()
          .split('T')[0];
      },
    });
  }

  onOwnerChange() {
    this.getUnitsByOwner();
  }
  getUnitsByOwner() {
    this.integrationErrorMsg = "";
    this.isApiLoading = true;
    this.genOwnerMgr
      .getGenerationUnitsByOwnerId(this.selectedOwner.ownerId)
      .subscribe({
        next: (resp) => {
          this.isApiLoading = false;
          if (resp) {
            this.units = resp;
          }
          else
            this.integrationErrorMsg = resp?.errorDetails ? resp?.errorDetails?.message : 'Sorry, something went wrong. Please try again later.';
        },
        error: (err) => {
          this.isApiLoading = false;
          this.units = [];
          this.integrationErrorMsg = err.error?.errorDetails ? err.error.errorDetails?.message : err.errorDescription;
        },
      });
  }
  search() {
    this.integrationErrorMsg = "";
    this.reportData = [];
    this.searchClicked = true;
    this.isReportLoading = true;
    this.reportMgrSvc
      .getGenUnitOwnerSubFeeReport(
        this.dtBegin,
        this.dtEnd,
        this.selectedOwner.ownerId,
        this.selectedUnit.unitId || '0'
      )
      .subscribe({
        next: (resp: any) => {

          this.isReportLoading = false;
          if (resp.errorDetails) {
            this.integrationErrorMsg = resp.errorDetails ? resp.errorDetails?.message : 'Sorry, something is not right. Please try again.';
          } else {

            this.reportData = resp;
          }
        },
        error: (AmerenError) => {
          this.isReportLoading = false;
          this.integrationErrorMsg = AmerenError.error?.errorDetails ? AmerenError.error.errorDetails?.message : AmerenError.errorDescription;
        }
      });
  }

  exportReport() {
    let keyHeaders: string[] = [];
    let modifiedlist: any = [];
    let displayHeaders: string[] = [
      'Bill Account',
      'Date Processed',
      'Subscriber Name',
      'Meter Number',
      'Unit Nick Name',
      'Subscriber Fee',
      'Admin Fee',
      'Transaction Amount',
      'Transaction Status',
    ];

    keyHeaders = [
      'billAccountNumber',
      'dateProcessed',
      'subscriberName',
      'meterNumber',
      'unitNickName',
      'subscriberFee',
      'adminFee',
      'transactionAmount',
      'transactionStatus',
    ];

    this.reportData.forEach((row: any) => {
      let temp: any = {};

      temp['billAccountNumber'] = row.billAccountNumber;
      temp['dateProcessed'] = row.dateProcessed;
      temp['subscriberName'] = row.subscriberName;
      temp['meterNumber'] = row.meterNumber;
      temp['unitNickName'] = row.unitNickName;

      temp['subscriberFee'] = row.subscriberFee;
      temp['adminFee'] = row.adminFee;
      temp['transactionAmount'] = row.transactionAmount;

      temp['transactionStatus'] = row.transactionStatus;
      modifiedlist.push(temp);
    });
    this._export.exportJsonToExcelFile(
      modifiedlist,
      'generation owner unit subscription fee report',
      false,
      keyHeaders,
      displayHeaders
    );
  }
}
