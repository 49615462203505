import { ProfileManagerService } from './../../shared/services/profilemanager.service';
import {
  HostListener,
  Component,
  Input,
  OnInit,
  SimpleChanges,
  OnDestroy,
  OnChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../../src/app/shared/services/auth.service';
import { GenerationRoleDetails, Profile } from '../../shared/models/profile';
import { isUndefined } from 'lodash';
import { GenerationOwner } from 'src/app/shared/models/generationowner';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ameren-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  isMobile = window.innerWidth <= 1023;
  isAuthenticated: boolean;
  currentUser: any;
  isAdmin: boolean;
  isAmerenAdmin: boolean = false;
  isAmerenSupport: boolean = false;
  isAmerenInternal: boolean;
  loggedinUser: any;
  isUserWithMultiGenerationOwner: boolean = false;
  isExternalAdmin: boolean = false;
  showMenuOptions: boolean = false;
  isUsersAllowed: boolean = false;
  isGenerationOwnersAllowed: boolean = false;
  isGenerationUnitsAllowed: boolean = false;
  isSubscribersAllowed: boolean = false;
  isRebatesAllowed: boolean = false;
  isReportsAllowed: boolean = false;
  isAuditAllowed: boolean = false;

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: any) {
    let navbar = document.getElementById('navbar');
    if (
      this.isMobile &&
      navbar?.classList.contains('show-menu') &&
      !navbar?.contains(event.target)
    ) {
      navbar?.classList.remove('show-menu');
      navbar?.classList.add('hide-menu');
    }
  }

  @Input() login_menu: boolean;
  @Input() genOwnerSelected: boolean = false;
  showStateSelector: boolean = false;
  selectedState: string = '';
  generationOwnerSubscription: Subscription;
  selectedGenerationOwner: GenerationOwner;
  hasSelectedGenerationOwner: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private profileMgerService: ProfileManagerService
  ) {
    this.authService.loginChanged.subscribe((t) => {
      this.isAuthenticated = t;

      this.authService.getUser().then((user: any) => {
        this.currentUser = user;
        this.loggedinUser = this.currentUser?.profile;
      });
    });

    this.generationOwnerSubscription =
      authService.generationOwnerUpdated.subscribe((ownrStatus: any) => {
        this.hasSelectedGenerationOwner = ownrStatus;
      });
  }

  ngOnInit() {
    this.authService.userLoaded.subscribe(async (user: any) => {
      this.currentUser = user;

      this.selectedState = user?.profile?.stateProvince
        ? user.profile?.stateProvince == 'IL'
          ? 'illinois'
          : 'missouri'
        : '';
      this.getUserData();
    });
    if (isUndefined(this.isAdmin)) {
      this.authService.getUser().then(async (user: any) => {
        this.currentUser = user;
        this.getUserData();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getUserData();
  }

  getUserData() {
    this.isAdmin, (this.isAmerenInternal = false);
    let userRole: GenerationRoleDetails[] = [];

    userRole = this.currentUser?.profile?.anmRoleMap.filter(
      (lst: any, i: any, arr: any) =>
        arr.findIndex(
          (t: any) =>
            t.generationOwner === lst.generationOwner && t.status === 'Active'
        ) === i
    );

    this.selectedGenerationOwner =
      this.authService.getSelectedGenerationOwner();
    this.hasSelectedGenerationOwner = this.selectedGenerationOwner
      ? true
      : false;

    if (userRole) {
      userRole = userRole.filter(
        (item, i, arr) =>
          arr.findIndex((t) => t.generationOwner === item.generationOwner) === i
      );

      this.isUserWithMultiGenerationOwner =
        userRole && userRole.length > 1 ? true : false;

      userRole.forEach((a) => {
        if (a.role === 'anmExternalAdmin' || a.role === 'anmInternalAdmin')
          this.isAdmin = true;

        if (a.role != 'anmExternalAdmin' && a.role != 'anmExternalViewOnly')
          this.isAmerenInternal = true;

        if (a.role === 'anmInternalAdmin') this.isAmerenAdmin = true;

        if (a.role === 'anmExternalAdmin') this.isExternalAdmin = true;

        if (a.role === 'anmIPAViewOnly') this.isReportsAllowed = true;

        if (
          a.role === 'anmInternalAdmin' ||
          a.role === 'anmInternalViewOnly' ||
          a.role === 'anmRebateApprover'
        ) {
          this.isUsersAllowed = true;
          this.isGenerationUnitsAllowed = true;
          this.isSubscribersAllowed = true;
          this.isReportsAllowed = true;
          this.isAuditAllowed = true;
        }
        if (
          a.role === 'anmInternalAdmin' ||
          a.role === 'anmInternalViewOnly' ||
          a.role === 'anmRebateApprover' ||
          a.role === 'anmRebateViewOnly'
        ) {
          this.isGenerationOwnersAllowed = true;
          this.isRebatesAllowed = true;
        }

        this.showMenuOptions =
          !this.isAmerenInternal && this.hasSelectedGenerationOwner;
      });
    }
  }

  unselectAgency() {
    this.router.navigate(['administration']);
  }

  login() {
    this.authService.Login();
  }

  logout() {
    this.authService.Logout();
  }

  toggleStateSelector() {
    this.showStateSelector = !this.showStateSelector;
  }

  onStateSelected(selectedState?: string) {
    this.showStateSelector = !this.showStateSelector;
    this.selectedState = selectedState ? selectedState : '';
  }

  showMenu(event: any) {
    let navbar = document.getElementById('navbar');
    navbar?.classList.remove('desktop-only');
    navbar?.classList.remove('hide-menu');
    navbar?.classList.add('show-menu');
    event.stopPropagation();
  }
  hideMenu(event: any) {
    let navbar = document.getElementById('navbar');
    navbar?.classList.remove('desktop-only');
    navbar?.classList.remove('show-menu');
    navbar?.classList.add('hide-menu');
    event.stopPropagation();
  }

  editUserProfile() {
    this.router.navigate(['edit-profile'], {
      queryParams: { ref: 'menu' },
      queryParamsHandling: 'merge',
    });
  }
  ngOnDestroy(): void {
    this.generationOwnerSubscription.unsubscribe();
  }

  ResetSelection() {
    this.authService.setSelectedGenerationOwner(null);
    this.hasSelectedGenerationOwner = false;

    if (this.isUserWithMultiGenerationOwner) {
      this.router.navigateByUrl('/select-generation-owner');
    } else if (!this.isUserWithMultiGenerationOwner) {
      this.router.navigateByUrl('/generation-owner');
    } else if (this.isAmerenInternal && this.isReportsAllowed) {
      this.router.navigateByUrl('/reports');
    }
  }
}
