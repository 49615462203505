import { Component, Input, OnChanges } from "@angular/core";

@Component({
  selector: "app-program-banner",
  templateUrl: "./program-banner.component.html",
  styleUrls: ["./program-banner.component.scss"],
})
export class ProgramBannerComponent implements OnChanges {
  ngOnChanges(changes: any) {
    this.state =
      this.selectedState == "" ||
      this.selectedState == null ||
      this.selectedState == undefined
        ? ""
        : this.selectedState;
  }
  @Input() selectedState = "";
  state: string =
    this.selectedState == "" ||
    this.selectedState == null ||
    this.selectedState == undefined
      ? ""
      : this.selectedState;

  Links: any[] = [
    {
      illinois: {
        href: "https://www.ameren.com/illinois/residential/supply-choice/renewables/community-solar",
        long_text: "Community Solar",
        short_text: "CS",
        icon: "assets/icon/community_solar.svg",
      },
      missouri: {
        href: "https://www.ameren.com/missouri/company/environment-and-sustainability/solar",
        long_text: "Solar Energy",
        short_text: "Solar",
        icon: "assets/images/banner-images/Flexpay.svg",
      },
    },
    {     
      illinois: {
        href: "https://www.ameren.com/illinois/residential/supply-choice/renewables/net-metering",
        long_text: "Net Metering",
        short_text: "Net Metering",
        icon: "assets/icon/net_metering.svg",
      },
      missouri: {
        href: "https://www.ameren.com/missouri/business/clean-energy-customer-programs/solar/neighborhood-solar",
        long_text: "Neighborhood Solar",
        short_text: "ns",
        icon: "assets/images/banner-images/WNCF.svg",
      },
    },
    {
      illinois: {
        href: "https://www.ameren.com/illinois/residential/supply-choice/renewables/qualifying-facilities",
        long_text: "Qualifying Facilities",
        short_text: "Qualifying Facilities",
        icon: "assets/icon/qualifying_facility.svg",
      },
      missouri: {
        href: "https://www.ameren.com/missouri/residential/clean-energy-customer-programs",
        long_text: "Clean Energy",
        short_text: "CE",
        icon: "assets/images/banner-images/Flexpay.svg",
      },
    },
    {
      illinois: {
        href: "https://www.ameren.com/illinois/residential/supply-choice/renewables/rebates",
        long_text: "Rebates",
        short_text: "Rebates",
        icon: "assets/images/banner-images/Budget Billing.svg",
      },
      missouri: {
        href: "https://www.amerenmissourisavings.com/?_gl=1*105e9om*_ga*MTkyNjYxNzcxNS4xNjkzMjUxMDI4*_ga_8C6F435CY5*MTY5MzI1MTQxNS4xLjEuMTY5MzI1NDU2Mi41OC4wLjA.",
        long_text: "Energy Efficiency",
        short_text: "CE",
        icon: "assets/images/banner-images/Flexpay.svg",
      },
    },
  ];
}
