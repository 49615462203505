import {
  Component,
  ViewChild,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { UsersManagerService } from '../services/users-manager.service';
import { userRoleOptions } from 'src/app/shared/enums/anm-userrole';
import { GenerationOwnerManagerService } from '../../generation-owner/services/generation-owner-manager.service';
import { MatPaginator } from '@angular/material/paginator';
import { ExportUtility } from 'src/app/shared/utilities/utility.export';
import { AuthService } from 'src/app/shared/services/auth.service';
import { FineGrainAuthorization } from 'src/app/shared/models/fine-grain-authorization';

declare var window: any;

export interface User {
  givenName: string;
  sn: string;
  userName: string;
  email: string;
  anmAccountStatus: string;
  anmRoleMap?: roleMap[] | MatTableDataSource<roleMap>;
}
export interface roleMap {
  generationOwner: string;
  role: string;
  status: string;
}
@Component({
  selector: 'app-internalusers',
  templateUrl: './internalusers.component.html',
  styleUrls: ['./internalusers.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class InternalusersComponent implements OnInit {
  @ViewChild('outerSort', { static: true }) sort: MatSort;
  @ViewChildren('innerSort') innerSort: QueryList<MatSort>;
  @ViewChildren('innerTables') innerTables: QueryList<MatTable<roleMap>>;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  dataSource: MatTableDataSource<User>;
  usersList: User[] = [];
  searchusersList: User[] = [];
  filteredUsersList: User[] = [];
  columnsToDisplay = [
    'toggleRows',
    'givenName',
    'sn',
    'userName',
    'anmAccountStatus',
    'action',
  ];
  innerDisplayedColumns = ['generationOwner', 'role', 'status', 'action'];
  selectedUser: string;
  expandedElement: User | null;
  expandedSubElement: roleMap | null;
  isUsersLoading: boolean = true;
  isUserAPIFailure: boolean = false;
  integrationErrorMsg: string;
  public userRoleOptions: any = JSON.parse(JSON.stringify(userRoleOptions));
  includeInactiveUsers: boolean = false;
  ownerList: any[];
  isDataExist: boolean = false;
  updateMsg: any;
  eidtStatus: any;
  isSaveLoading: boolean;
  isSaveError: boolean;
  isSaveSuccess: boolean;
  successMsg: string;
  updateBody: any;
  currentUser: any;
  loggedInUser: any;
  isInternalUser: boolean = false;
  functionalityAccess: FineGrainAuthorization;

  constructor(
    private UsersManagerService: UsersManagerService,
    private cd: ChangeDetectorRef,
    private generationOwnerManager: GenerationOwnerManagerService,
    private exportUtility: ExportUtility,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.loggedInUser = this.authService.getCurrentUser();

    if (this.loggedInUser != null) this.currentUser = this.loggedInUser.profile;

    this.currentUser.anmRoleMap = this.currentUser?.anmRoleMap.filter(
      (lst: any, i: any, arr: any) =>
        arr.findIndex(
          (t: any) =>
            t.generationOwner === lst.generationOwner && t.status === 'Active'
        ) === i
    );

    let internal_roles = this.currentUser?.anmRoleMap.filter(
      (a: any) =>
        a.role != 'anmExternalAdmin' &&
        a.role != 'anmExternalViewOnly' &&
        a.status === 'Active'
    );

    this.isInternalUser =
      internal_roles[0] != undefined && internal_roles.length >= 0;

    this.getGenerationOwnerList();
    this.getData(this.includeInactiveUsers);

    this.updateMsg = new window.bootstrap.Modal(
      document.getElementById('updateStatusModal')
    );

    let role = this.authService.getSelectedGenerationOwnerRole();
    let functionality = 'USERS';

    role = role
      ? role
      : this.isInternalUser
      ? this.currentUser?.anmRoleMap.map((x: any) => x.role)
      : ['anmExternalAdmin'];

    this.functionalityAccess = this.authService.getPortalFunctioanalityAccesses(
      functionality,
      role
    );
  }

  getData(includeInactive: boolean = false) {
    this.updateBody = '';
    this.selectedUser = '';
    this.isUsersLoading = true;
    this.searchusersList = [];
    this.isDataExist = false;
    this.filteredUsersList = [];
    this.dataSource = new MatTableDataSource(this.searchusersList);

    let genOwners = this.isInternalUser
      ? undefined
      : this.currentUser.anmRoleMap
      ? this.currentUser.anmRoleMap
          .filter(
            (a: any) => a.role === 'anmExternalAdmin' && a.status === 'Active'
          )
          .map((x: any) => x.generationOwner)
          .join(',')
      : undefined;
    if (this.isInternalUser || genOwners) {
      this.UsersManagerService.getAllUsers(
        includeInactive,
        genOwners
      ).subscribe({
        next: (anm: any) => {
          if (anm.success) {
            this.usersList = anm.data;

            this.usersList.forEach((user) => {
              if (
                user.anmRoleMap &&
                Array.isArray(user.anmRoleMap) &&
                user.anmRoleMap.length
              ) {
                if (!this.isInternalUser) {
                  let roleMap = user.anmRoleMap?.filter((a: any) =>
                    genOwners?.includes(a.generationOwner)
                  );

                  this.searchusersList = [
                    ...this.searchusersList,
                    { ...user, anmRoleMap: roleMap },
                  ];
                } else
                  this.searchusersList = [
                    ...this.searchusersList,
                    { ...user, anmRoleMap: user.anmRoleMap },
                  ];
              } else {
                this.searchusersList = [...this.searchusersList, user];
              }
            });

            this.dataSource = new MatTableDataSource(this.searchusersList);
            this.dataSource.sort = this.sort;

            this.dataSource.paginator = this.paginator;
            this.isDataExist = this.searchusersList?.length > 0;

            this.isUsersLoading = false;
            this.isUserAPIFailure = false;
          }
        },
        error: (e: any) => {
          this.isUsersLoading = false;
          this.isUserAPIFailure = true;
          this.integrationErrorMsg = e.error?.errorDetails
            ? e.error.errorDetails?.message
            : e.errorDescription;
          console.log(this.integrationErrorMsg);
          if (!this.integrationErrorMsg)
            this.integrationErrorMsg =
              'Something went wrong. Please try again.';
        },
      });
    } else {
      this.isUsersLoading = false;
      this.isUserAPIFailure = true;
      this.integrationErrorMsg =
        'You are not authorized to access this functiaonlity!';
    }
  }

  toggleRow(element: User) {
    this.selectedUser = element.userName;
    // element.anmRoleMap && (element.anmRoleMap as MatTableDataSource<roleMap>)
    //   ? (this.expandedElement =
    //       this.expandedElement === element ? null : element)
    //   : null;

    if (
      element.anmRoleMap &&
      (element.anmRoleMap as MatTableDataSource<roleMap>)
    ) {
      if (this.expandedElement === element) {
        this.expandedElement = null;
      } else {
        this.expandedElement = element;
      }
    } else {
      this.expandedElement = null;
    }

    if (this.expandedElement) this.selectedUser = element.userName;
    else this.selectedUser = '';

    this.cd.detectChanges();
    this.innerTables.forEach(
      (table, index) =>
        ((table.dataSource as MatTableDataSource<roleMap>).sort =
          this.innerSort.toArray()[index])
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue;
    this.filteredUsersList = this.dataSource.filteredData;
  }

  getGenerationOwnerList() {
    this.ownerList = [];
    let genOwners = this.currentUser?.anmRoleMap
      ? this.currentUser?.anmRoleMap
          .filter(
            (a: any) => a.role === 'anmExternalAdmin' && a.status === 'Active'
          )
          .map((x: any) => x.generationOwner)
          .join(',')
      : '00018';

    this.generationOwnerManager.getAllOwners().subscribe({
      next: (data: any) => {
        this.ownerList = data.sort((a: any, b: any) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });

        if (!this.isInternalUser) {
          this.ownerList = this.ownerList?.filter((a: any) =>
            genOwners?.includes(a.ownerId)
          );
        }
      },
      error: (error: any) => {
        console.log('error', error);
      },
    });
  }

  getOwnerName(ownerId: string) {
    if (ownerId == '00018') return 'Ameren Illinois';
    else {
      let owner = this.ownerList.filter((onr) => onr.ownerId == ownerId);
      return owner[0] != undefined ? owner[0].name : '';
    }
  }

  onToggleActiveUsers() {
    this.includeInactiveUsers = !this.includeInactiveUsers;
    this.getData(this.includeInactiveUsers);
  }

  activateInactivateUser(status: string, mail: string) {
    this.updateBody = '';
    const body = {
      email: mail,
      stateProvince: 'IL',
      anmAccountStatus: status,
    };
    this.updateBody = body;
    this.updateMsg.show();
  }

  lockUnlockUser(status: string, mail: string, genOwner: string) {
    this.updateBody = '';
    const body = {
      email: mail ? mail : this.selectedUser,
      generationOwner: genOwner,
      status: status,
    };
    this.updateBody = body;
    this.updateMsg.show();
  }

  changeUserStatus(body: any) {
    this.isSaveLoading = true;
    this.UsersManagerService.updateUserStatus(body).subscribe({
      next: (res) => {
        if (!res.success) {
          this.isUserAPIFailure = true;
          this.integrationErrorMsg = res.errorDetails;
        } else {
          this.isSaveLoading = false;
          this.isSaveSuccess = true;
          this.isSaveError = false;
          this.successMsg = 'User status updated succesfully';

          this.getData(this.includeInactiveUsers);
          setTimeout(() => {
            this.updateBody = '';
            this.closeSaveModal();
          }, 2000);
        }
      },
      error: (error: any) => {
        this.isSaveLoading = false;
        this.isSaveSuccess = false;
        this.isSaveError = true;
        this.successMsg = 'Failed to update user status';
        this.integrationErrorMsg = error.error?.errorDetails
          ? error.error.errorDetails?.message
          : error.errorDescription;
        console.log(this.integrationErrorMsg);

        if (!this.integrationErrorMsg)
          this.integrationErrorMsg = 'Something went wrong. Please try again.';
      },
    });
  }

  changeUserRoleStatus(body: any) {
    this.isSaveLoading = true;
    this.UsersManagerService.updateUserRoleStatus(body).subscribe({
      next: (res) => {
        if (!res.success) {
          this.isUserAPIFailure = true;
          this.integrationErrorMsg = res.errorDetails;
        } else {
          this.isSaveLoading = false;
          this.isSaveSuccess = true;
          this.isSaveError = false;
          this.successMsg = 'User role status updated succesfully';

          this.getData(this.includeInactiveUsers);
          setTimeout(() => {
            this.updateBody = '';
            this.closeSaveModal();
          }, 2000);
        }
      },
      error: (error: any) => {
        this.isSaveLoading = false;
        this.isSaveSuccess = false;
        this.isSaveError = true;
        this.successMsg = 'Failed to update user role status';
        this.integrationErrorMsg = error.error?.errorDetails
          ? error.error.errorDetails?.message
          : error.errorDescription;
        if (!this.integrationErrorMsg)
          this.integrationErrorMsg = 'Something went wrong. Please try again.';

        console.log(this.integrationErrorMsg);
      },
    });
  }
  closeSaveModal() {
    this.isSaveError = false;
    this.isSaveLoading = false;
    this.isSaveSuccess = false;
    this.successMsg = '';
    this.updateMsg.hide();
  }

  confirmSaveModel() {
    if (this.updateBody?.generationOwner)
      this.changeUserRoleStatus(this.updateBody);
    else this.changeUserStatus(this.updateBody);
  }

  exportUsers() {
    let exportUserList =
      this.filteredUsersList?.length > 0
        ? this.filteredUsersList
        : this.searchusersList;

    let keyHeaders: string[] = [
      'givenName',
      'sn',
      'userName',
      'anmAccountStatus',
      'generationOwner',
      'role',
      'status',
    ];

    let displayHeaders: string[] = [
      'First Name',
      'Last Name',
      'Email',
      'User Status',
      'Generation Owner',
      'Role',
      'Status',
    ];
    let modifiedlist: any = [];

    exportUserList.forEach((row: any) => {
      if (row.anmRoleMap && row.anmRoleMap.length > 0) {
        row.anmRoleMap.forEach((roleMap: any) => {
          let temp: any = {};
          temp['givenName'] = row.givenName;
          temp['sn'] = row.sn;
          temp['userName'] = row.userName;
          temp['anmAccountStatus'] = row.anmAccountStatus;
          temp['generationOwner'] = this.getOwnerName(roleMap.generationOwner);
          temp['role'] = this.userRoleOptions[roleMap.role];
          temp['status'] = roleMap.status;
          if (roleMap.role == 'anmRebateApprover')
            temp['anmRebateApprovalLevel'] = roleMap.anmRebateApprovalLevel
              ? roleMap.anmRebateApprovalLevel
              : '';

          modifiedlist.push(temp);
        });
      } else {
        let temp: any = {};
        temp['givenName'] = row.givenName;
        temp['sn'] = row.sn;
        temp['userName'] = row.userName;
        temp['anmAccountStatus'] = row.anmAccountStatus;
        temp['generationOwner'] = '';
        temp['role'] = '';
        temp['status'] = '';
        modifiedlist.push(temp);
      }
    });

    this.exportUtility.exportJsonToExcelFile(
      modifiedlist,
      'Renewables_Portal_Users',
      false,
      keyHeaders,
      displayHeaders
    );
  }
}
