import { environment } from './../../../../environments/environment';
import { IGlobalAPIResponse } from './../../../shared/interfaces/iglobal-api-response';
import { CorrelationService } from './../../../shared/services/correlation.service';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isNumber } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class SubscriberApiServiceService {
  constructor(
    private http: HttpClient,
    private correlationService: CorrelationService
  ) {}

  validateSubscriber(
    meter: string,
    unitId: string,
    billAccountNumber: string,
    audit: string
  ): Observable<any> {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'ANM')
      .set('domain', 'private')
      .set('state', 'IL')
      .set('meterno', meter.toString())
      .set('unitid', unitId.toString())
      .set('billaccountnumber', billAccountNumber)
      .set('ownerid', '0')
      .set('audit', audit)
      .set('X-Correlation-Id', this.correlationService.getCorrelationId());

    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/subscribers/validatesubscriber`,
      {
        headers,
      }
    );
  }

  addSubscriber(subscriber: any, audit: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'ANM')
      .set('domain', 'private')
      .set('state', 'IL')
      .set('audit', 'ANMInternalAdmin@ameren.com')
      .set('X-Correlation-Id', this.correlationService.getCorrelationId());

    return this.http.post<IGlobalAPIResponse>(
      `${environment.apig}/subscribers`,
      subscriber,
      {
        headers,
      }
    );
  }

  getSubscribers(page?: number) {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('x-modified-by-user-id', 'ANM')
      .set('state', 'IL')
      .set('audit', 'ANMInternalAdmin@ameren.com')
      .set('page',isNumber(page)?page.toString():'')
      .set('X-Correlation-Id', this.correlationService.getCorrelationId());

    return this.http.get<IGlobalAPIResponse>(
      `${environment.cloudHub}${isNumber(page)?'/metering-aggregated-reports-e/v1/subscribers/pagination':'/metering-aggregated-reports-e/v1/subscribers'}`,
      {
        headers,
      }
    );
  }

  getSubscriberByBillAccount(ownerId: any, unitId: any, ba: any) {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('x-modified-by-user-id', 'ANM')
      .set('state', 'IL')
      .set('ownerid', ownerId.toString())
      .set('unitid', unitId.toString())
      .set('domain', 'private')
      .set('vpcenv', environment.environmentName)
      .set('billaccountnumber', ba.toString())
      .set('X-Correlation-Id', this.correlationService.getCorrelationId());
    
    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/subscribers/bybillaccount`,
      {
        headers,
      }
    );
  }

  updateSusbcriber(body: any) {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('vpcenv', `${environment.environmentName}`)
      .set('x-modified-by-user-id', 'ANM')
      .set('domain', 'private')
      .set('billaccountnumber', body.billAccountNumber)
      .set('audit', 'ANMInternalAdmin@ameren.com')
      .set('X-Correlation-Id', this.correlationService.getCorrelationId());

    return this.http.put<IGlobalAPIResponse>(
      `${environment.apig}/subscribers`,
      body,
      { headers }
    );
  }
}
