<div class="ipa_gen_unit_subscriber_contract_report_container">
    <div class="ipa_gen_unit_subscriber_contract_report_title">
       IPA Generation Unit Subscriber Contract Report
    </div>


    <div class="text-center" *ngIf="!units || !units.length">
        <ameren-loading></ameren-loading>
        <p>Please wait..</p>
    </div>

    <div class="row" *ngIf="units && units.length">
        <div class="col form-group">
            <label>
                Service From Date:<span class="text-danger">*</span>
            </label>
            <input type="date" class="form-control" [(ngModel)]="dtBegin" />
        </div>
        <div class="col form-group">
            <label>
                Service To Date:<span class="text-danger">*</span>
            </label>
            <input type="date" class="form-control" [(ngModel)]="dtEnd" />
        </div>
    </div>
    <div class="row" *ngIf="units && units.length">
        <div class="col-md-3">
            <button class="btn btn-outline-success" [disabled]="!dtBegin || !dtEnd"
                (click)="search()">Search</button>
            <button class="btn btn-outline-success m-2" [disabled]="!reportData || !reportData.length"
                (click)="exportReport()">
                <img src="assets/icon/excelicon.svg" alt="CalendarImage" width="22" height="19" />
                Export</button>
        </div>
    </div>

    <table class="table ipa_gen_unit_subscriber_contract_report_grid" *ngIf="searchClicked">
        <caption class="sr-only">
            Generation Unit Subscriber Bill Account Report
        </caption>
        <thead>
            <tr>
                <th scope="col">
                    Generation Owner Name
                </th>
                <th scope="col">
                    Generation ID
                </th>
                <th scope="col">
                    Date Energized
                </th>
                <th scope="col">
                    Subscriber Name
                </th>
                <th scope="col">
                    Subscriber Identification Number
                </th>
                <th scope="col">
                    Subscribed KW
                </th>
                <th scope="col">
                    Active Date
                </th>
                <th scope="col">
                    Termination Date
                </th>
                <th scope="col">
                    DS Rate Class
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngIf="!isReportLoading && (!reportData || !reportData.length)">
                <td colspan="9" class="text-center">No data</td>
            </tr>
            <tr *ngIf="isReportLoading">
                <td colspan="9" class="text-center">
                    <p>Please wait..</p>
                    <ameren-loading></ameren-loading>
                </td>
            </tr>
            <ng-container *ngIf="!isReportLoading">
                <tr *ngFor="let rep of reportData">
                    <td>
                        {{ rep.ownerName }}
                    </td>
                    <td>
                        {{ rep.owner }}
                    </td>
                    <td>
                        {{ rep.effectiveDate | date:'MM/dd/yyyy' }}
                    </td>
                    <td>
                        {{ rep.subscriberName }}
                    </td>
                    <td>
                        {{ rep.customerIdentifier }}
                    </td>
                    <td>
                        {{rep.subscribeQuantity}}
                    </td>
                    <td>
                        {{ rep.effectiveDate | date:'MM/dd/yyyy' }}
                    </td>
                    <td>
                        {{ rep.terminationDate | date:'MM/dd/yyyy' }}
                    </td>
                    <td>
                        {{rep.rateClassDesc}}
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</div>