<div class="anm__audits-container">
    <mat-card>      

        <div class="col-md-3 Audits_page_header">Audit Trail</div>

        <mat-card-header class="row d-flex flex-row mt-3">             
            <div class="row d-flex flex-row" >
                <div class="col form-group">
                    <label>
                        Audit From Date:
                    </label>
                    <input type="date" class="form-control" [min]="minDate" [max]="maxDate" [(ngModel)]="dtBegin" />
                </div>
                <div class="col form-group">
                    <label>
                        Audit To Date:
                    </label>
                    <input type="date" class="form-control" [min]="minDate" [max]="maxDate" [(ngModel)]="dtEnd"  />
                </div>
                <div class="col form-group mt-4">
                    <button class="btn btn-outline-success" [disabled]="!dtBegin || !dtEnd" (click)="getData(dtBegin,dtEnd)">Run Report</button>
                </div>
                
            </div>

           
           
           <div *ngIf="isDataExist" class="row mt-5">
           <div class="col-md-3 Audits_page_header">Audit Trail List</div>
           
            <div class="col-md-3" *ngIf="isDataExist">
                <mat-form-field  class="auditSearchInput">
                    <div class="d-flex flex-row">
                        <input matInput (keyup)="applyFilter($event)" placeholder="Search"><span><em class="fa fa-search"></em></span>
                    </div>
                </mat-form-field>
            </div>    
             <div class="col-md-3" *ngIf="isDataExist">
                <button
                class="btn btn-outline-secondary users_export"
                (click)="exportUsers()"			
              >
                <img
                  src="assets/icon/excelicon.svg"
                  alt="CalendarImage"
                  width="22"
                  height="19"
                />
                Export
              </button>
            </div>
        </div>
       
        </mat-card-header>
        
 
    <mat-card-content>
        <div class="d-flex flex-row justify-content-center col-md-6" *ngIf="isApiLoading && !isDataExist">
			<p class="Auditlables">Loading Audit Trail, Please wait...</p> <ameren-loading  class="d-flex justify-content-center"></ameren-loading>
		</div>
		<div *ngIf="apiError" class="mt-3 alert alert-danger" role="alert">
		  {{integrationErrorMsg}}
		</div>

    <mat-table [dataSource]="dataSource" matSort multiTemplateDataRows matSortActive="tsKyTimestamp" matSortDirection="desc">       
    
      <ng-container matColumnDef="auditID">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="auditTableHeader flex-5"> Id </mat-header-cell>
        <mat-cell *matCellDef="let row" class="auditTableContent flex-5"> {{row?.auditID}} </mat-cell>
      </ng-container>
  
  
      <ng-container matColumnDef="tsKyTimestamp">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="auditTableHeader flex-10"> Date </mat-header-cell>
        <mat-cell *matCellDef="let row" class="auditTableContent flex-10"> {{row?.tsKyTimestamp}} </mat-cell>
      </ng-container>
  
    
      <ng-container matColumnDef="txEmailAddr">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="auditTableHeader flex-10"> User </mat-header-cell>
        <mat-cell *matCellDef="let row" class="auditTableContent flex-10"> {{row?.txEmailAddr}} </mat-cell>
      </ng-container>
  
     
      <ng-container matColumnDef="txPropertyName">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="auditTableHeader flex-15"> Event </mat-header-cell>
        <mat-cell *matCellDef="let row" class="auditTableContent flex-15"> {{row?.txPropertyName}} </mat-cell>
      </ng-container>
      
       <ng-container matColumnDef="txInitialValue">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="auditTableJsonHeader"> Initial Value </mat-header-cell>
        <mat-cell *matCellDef="let row" class="auditTableJsonContent">
            <pre [innerHTML]="row?.txInitialValue"></pre> 
           
        </mat-cell>
      </ng-container>

       
       <ng-container matColumnDef="txFinalValue">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="auditTableJsonHeader"> Final Value </mat-header-cell>
        <mat-cell *matCellDef="let row" class="auditTableJsonContent"> 
            <pre [innerHTML]="row?.txFinalValue"></pre>
        
         </mat-cell>
      </ng-container>
  
      <mat-header-row *matHeaderRowDef="columnsToDisplay" ></mat-header-row>
      <mat-row *matRowDef="let row; columns: columnsToDisplay;">
      </mat-row>
    </mat-table>


</mat-card-content>
<mat-card-footer>
	<div>
        <mat-paginator [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons></mat-paginator>

	</div>
</mat-card-footer>
 
</mat-card>
</div>

      
    
 