import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportsApiService {
  constructor(private http: HttpClient) {}

  getGenUnitSubDailyUsage(
    dtBegin: string,
    dtEnd: string,
    genOwner: string,
    genUnit: string
  ) {
    const headers = new HttpHeaders()
      .set('amrnenv', `${environment.cssEnv}`)
      .set('x-modified-by-user-id', 'ANM')
      .set('dtEnd', dtEnd)
      .set('dtBegin', dtBegin)
      .set('kyGenOwner', genOwner.toString())
      .set('kyGenUnit', genUnit.toString());

    return this.http.get<IGlobalAPIResponse>(
      `${environment.publicVPCUrl}/metering-aggregated-reports-e/v1/reports/genUnitSubDailyUsage`,
      {
        headers,
      }
    );
  }

  getRegulatoryComplianceReport(dtBegin: string, dtEnd: string) {
    const headers = new HttpHeaders()
      .set("amrnenv", `${environment.cssEnv}`)
      .set("x-modified-by-user-id", "ANM")
      .set('kyGenOwner',"0")
      .set('kyGenUnit',"0")
      .set('dtEnd',dtEnd)
      .set('dtBegin',dtBegin)

    return this.http.get<IGlobalAPIResponse>(
      `${environment.publicVPCUrl}/metering-aggregated-reports-e/v1/reports/subscriberContract`,
      {
        headers,
      }
    );
  }

  getSubDailyKwhAllocationReport(dtBegin: string, dtEnd: string, billAccountNumber: string) {
    const headers = new HttpHeaders()
      .set("amrnenv", `${environment.cssEnv}`)
      .set("x-modified-by-user-id", "ANM")
      .set('dtEnd',dtEnd)
      .set('dtBegin',dtBegin)
      .set('billAccountNumber',billAccountNumber.toString())

    return this.http.get<IGlobalAPIResponse>(
      `${environment.publicVPCUrl}/metering-aggregated-reports-e/v1/reports/subDailyUsage`,
      {
        headers,
      }
    );
  }

  genUnitSubDailyUsage(kyGenOwner: string, kyGenUnit: string, dtBegin: string, dtEnd: string) {
    const headers = new HttpHeaders()
      .set("amrnenv", `${environment.cssEnv}`)
      .set("x-modified-by-user-id", "ANM")
      .set('dtEnd',dtEnd)
      .set('dtBegin',dtBegin)
      .set('kyGenUnit',kyGenUnit.toString())
      .set('kyGenOwner',kyGenOwner.toString())

    return this.http.get<IGlobalAPIResponse>(
      `${environment.publicVPCUrl}/metering-aggregated-reports-e/v1/reports/genUnitDailyUsage`,
      {
        headers,
      }
    );
  }

  getGenUnitBillAccountSubscriptionRateReport(
    billAccount: string,
    kyGenUnit: string,
    genOwner: string
  ) {
    let headers = new HttpHeaders()
      .set("amrnenv", `${environment.cssEnv}`)
      .set("x-modified-by-user-id", "ANM")
      .set("kyGenOwner", genOwner.toString())
      .set("kyGenUnit", kyGenUnit.toString());    

      if(billAccount)
        headers = headers.append("billAccountNumber", billAccount.toString()); 

    return this.http.get<IGlobalAPIResponse>(
      `${environment.publicVPCUrl}/metering-aggregated-reports-e/v1/reports/genUnitBillAccountSubscriptionRate`,
      {
        headers,
      }
    );
  }

  getBillPeriods(billAccount: string,servicePt: string) {
    const headers = new HttpHeaders()
      .set("amrnenv", `${environment.cssEnv}`)
      .set("x-modified-by-user-id", "ANM")
      .set("servicepoint", servicePt.toString())
      .set("servicepoint", servicePt.toString())
      .set('vpcenv','DEV')
      .set('domain','private')
      .set("billaccountnumber", billAccount.toString())


    return this.http.get<IGlobalAPIResponse>(
      `${environment.apig}/subscribers/bybillperiod`,
      {
        headers,
      }
    );
  }

  getGenUnitSubscriberBillAccountReport(billAccount: string,dtBegin: string, dtEnd: string, genOwner: string) {
    const headers = new HttpHeaders()
      .set("amrnenv", `${environment.cssEnv}`)
      .set("x-modified-by-user-id", "ANM")
      .set('dtEnd',dtEnd)
      .set('dtBegin',dtBegin)
      .set('kyGenOwner',genOwner.toString())
      .set("billAccountNumber", billAccount.toString())


    return this.http.get<IGlobalAPIResponse>(
      `${environment.publicVPCUrl}/metering-aggregated-reports-e/v1/reports/genUnitSubBillAccount`,
      {
        headers,
      }
    );
  }

  getGenUnitSubscriberContractReport(dtBegin: string, dtEnd: string, genOwner: string, genUnit: string) {
    const headers = new HttpHeaders()
      .set("amrnenv", `${environment.cssEnv}`)
      .set("x-modified-by-user-id", "ANM")
      .set('dtEnd',dtEnd)
      .set('dtBegin',dtBegin)
      .set('kyGenOwner',genOwner.toString())
      .set('kyGenUnit',genUnit.toString())

    return this.http.get<IGlobalAPIResponse>(
      `${environment.publicVPCUrl}/metering-aggregated-reports-e/v1/reports/genUnitSubContract`,
      {
        headers,
      }
    );
  }

  getGenerationOwnerReportData(kyGenOwner: string, effectiveDate: string) {
    const headers = new HttpHeaders()
      .set("amrnenv", `${environment.cssEnv}`)
      .set("x-modified-by-user-id", "ANM")
      .set("kyGenOwner", kyGenOwner.toString())
      .set("effectiveDate", effectiveDate)


    return this.http.get<IGlobalAPIResponse>(
      `${environment.publicVPCUrl}/metering-aggregated-reports-e/v1/reports/genOwner`,
      {
        headers,
      }
    );
  }

  getGenUnitOwnerSubFeeReport(dtBegin: string, dtEnd: string, genOwner: string, genUnit: string) {
    const headers = new HttpHeaders()
      .set("amrnenv", `${environment.cssEnv}`)
      .set("x-modified-by-user-id", "ANM")
      .set('dtEnd',dtEnd)
      .set('dtBegin',dtBegin)
      .set('kyGenOwner',genOwner.toString())
      .set('kyGenUnit',genUnit.toString())

    return this.http.get<IGlobalAPIResponse>(
      `${environment.publicVPCUrl}/metering-aggregated-reports-e/v1/reports/genUnitOwnerSubFee`,
      {
        headers,
      }
    );
  }

}
