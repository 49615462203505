import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IGlobalAPIResponse } from 'src/app/shared/interfaces/iglobal-api-response';
import { CorrelationService } from 'src/app/shared/services/correlation.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuditTrailApiService {

  constructor( private http: HttpClient,
    private correlationService: CorrelationService) { 
    
  }

  private buildHttpHeaders() {
    let httpOptions: HttpHeaders = new HttpHeaders();
    httpOptions = httpOptions.append('x-modified-by-user-id', 'ANM');
    httpOptions = httpOptions.append('domain', 'private');
    httpOptions = httpOptions.append('vpcenv',`${environment.environmentName}`);
    httpOptions = httpOptions.append('amrnenv',`${environment.cssEnv}`);
    httpOptions = httpOptions.append('X-Correlation-Id',`${this.correlationService.getCorrelationId()}`);
    return httpOptions;
  }

  getAllAudits(startDate: string, endDate:string): Observable<any> {
    let headers = this.buildHttpHeaders();        
    
    if(startDate)
      headers = headers.append('startdate',`${startDate}`); 
    if(endDate)
      headers = headers.append('enddate',`${endDate}`); 

    return this.http.get<IGlobalAPIResponse>(`${environment.apig}/audits`, {
      headers,
    });
  }

  CreateAuditEntry(data: any) {  
   
    let headers = this.buildHttpHeaders();      
    headers = headers.append('Content-Type', 'application/json');   
        
    const body = data;
  
     return this.http.post<IGlobalAPIResponse>(`${environment.apig}/audits`, body, {headers});
   }
   
}
